import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { ContainerType, ContainerView } from 'model/ContainerView';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { getUuidFromString } from 'util/helpers';
import {
    getNewOuterContainer,
    removePDSContainer,
    updatePDSContainers,
} from 'components/productdatasheet/ProductDataSheetDialogHelpers';
import { deepCopy } from 'util/deep-equality-helpers';
import { ContainerSaveStore } from 'store';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import { ContainerPropsComponent } from 'components/container/ContainerPropsComponent';

export interface StepOuterContainerProps {
    containerTypes?: ContainerType[];
    outerContainers?: ContainerView[];
    allContainers?: ContainerView[];
    updateAfterSave?: () => void;
    pdsRef?: string;
}

export const StepOuterContainer = (props: StepComponentProps<StepOuterContainerProps>) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        textField: {
            paddingRight: theme.spacing(1),
        },
        emptyStateHint: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: 10,
            color: theme.palette.primary.dark,
            fontWeight: 600,
            padding: theme.spacing(3),
            textAlign: 'center',
        },
    });
    const classes = useStyles();
    const { t } = useTranslation(['productDataSheet', 'containertype', 'common', 'error']);
    const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>({});
    const isAtLeastOneInnerContainerSet = props.data.allContainers?.some((it) => !!it.unit) ?? false;

    const toggleCollapse = (id: string) => {
        setCollapseStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };
    const findById = (id: string): ContainerView | undefined => props.data.allContainers?.find((it) => it.id === id);

    const findContainerType = (id: string) => props.data.containerTypes?.find((it) => it.id === id);

    const update = (id: string, partial: Partial<ContainerView>) => {
        const containers =
            props.data.pdsRef && props.data.updateAfterSave
                ? updatePDSContainers(
                      props.data.outerContainers,
                      id,
                      partial,
                      props.data.pdsRef,
                      props.data.updateAfterSave,
                  )
                : [];
        props.setData('StepOuterContainer', { outerContainers: containers });
    };

    const calculateDepth = (container: ContainerView): number => {
        let depth = 0;
        let currentContainer: ContainerView | undefined = container;
        while (currentContainer?.innerContainer) {
            depth++;
            currentContainer = props.data.allContainers?.find((it) => it.id === currentContainer?.innerContainer);
        }
        return depth;
    };

    const outerContainersSortedByDepth = (props.data.outerContainers ?? [])
        .filter((it) => !!it.innerContainer)
        .sort((a, b) => calculateDepth(b) - calculateDepth(a));

    const normalContainers = (props.data.allContainers ?? []).filter((it) => !!it.unit);

    const innerContainerSelectOptions = [...outerContainersSortedByDepth, ...normalContainers];

    const handleInnerContainerSelect = (innerId: string, id: string) => {
        update(id, { innerContainer: innerId });
    };

    const getInnerContainerSelectOptionTitle = (container: ContainerView | undefined): string => {
        if (!props.data.containerTypes || !container) return '';
        const containerTypeSlug = findContainerType(container.containerType)?.slug;
        const containerTypeT = containerTypeSlug ? t('containertype:' + containerTypeSlug) : '';
        const innerContainer = container.innerContainer ? findById(container.innerContainer) : undefined;
        const innerContainerTypeSlug = innerContainer?.containerType
            ? findContainerType(innerContainer.containerType)?.slug
            : undefined;
        const innerContainerTypeT = innerContainerTypeSlug ? t('containertype:' + innerContainerTypeSlug) : '';
        const unitOrInnerContainerType = container.unit
            ? ' ' + t('units:' + container.unit + 'Plural')
            : innerContainer && innerContainerTypeSlug
              ? 'x ' + innerContainerTypeT
              : '';

        return `${containerTypeT}: ${container.amount}${unitOrInnerContainerType}`;
    };

    const handleContainerTypeChange = (containerType: string, id: string) => {
        update(id, { containerType });
    };

    const handleAmountChange = (amount: number | undefined, id: string) => {
        update(id, { amount });
    };

    const isUsedAsInnerContainer = (container: ContainerView): boolean => {
        return props.data.outerContainers?.some((it) => it.innerContainer === container.id) ?? false;
    };

    const handleAddContainer = () => {
        if (props.data.pdsRef) {
            ContainerSaveStore.savePDSContainers(props.data.pdsRef);
        }
        const containers = deepCopy(props.data.outerContainers) ?? [];
        containers.push(getNewOuterContainer());
        props.setData('StepOuterContainer', { outerContainers: containers });
    };

    const handleContainerPropsUpdate = (updatedContainer: ContainerView) => {
        const container = props.data.outerContainers?.find((it) => it.id === updatedContainer.id);
        update(updatedContainer.id, { ...container, ...updatedContainer });
    };

    const handleContainerRemove = (id: string) => {
        if (id && props.data.pdsRef) {
            removePDSContainer(id, props.data.pdsRef);
        }
        props.setData('StepOuterContainer', {
            outerContainers: props.data.outerContainers?.filter((it) => it.id !== id),
        });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_OUTER_CONTAINER_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            {isAtLeastOneInnerContainerSet ? (
                <Typography>{t('productDataSheet:DATASTEP_OUTER_CONTAINER_TEXT')}</Typography>
            ) : (
                <Typography className={classes.emptyStateHint}>
                    {t('productDataSheet:DATASTEP_OUTER_CONTAINER_TEXT_HINT')}
                </Typography>
            )}
        </Grid>
    );

    const showContainerSelect = (container: ContainerView) => (
        <Grid container item md={12} sx={{ justifyContent: 'space-between' }}>
            <Grid item md={5}>
                <TextField
                    value={container.containerType}
                    select
                    fullWidth
                    sx={{ paddingRight: theme.spacing(1.5) }}
                    label={t('productDataSheet:typeOfOuterPackaging')}
                    onChange={(event) => handleContainerTypeChange(event.target.value, container.id)}
                    disabled={isUsedAsInnerContainer(container)}
                >
                    {props.data.containerTypes &&
                        props.data.containerTypes.map((ct: ContainerType) => {
                            return (
                                <MenuItem key={ct.id} value={ct.id}>
                                    {t('containertype:' + ct.slug)}
                                </MenuItem>
                            );
                        })}
                </TextField>
            </Grid>
            <Grid item md={5}>
                <NumberFormat
                    helperText={
                        !container.amount || container.amount <= 0 ? t('error:noContainerSaveWithoutValue') : null
                    }
                    error={!container.amount || container.amount <= 0}
                    value={container.amount}
                    customInput={TextField}
                    fullWidth
                    className={classes.textField}
                    label={t('productDataSheet:amountPerOuterContainer')}
                    onValueChange={(value) => handleAmountChange(value.floatValue, container.id)}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            color: theme.palette.error.main,
                            fontSize: 12,
                            fontWeight: 'bold',
                        },
                    }}
                    decimalScale={0}
                    disabled={isUsedAsInnerContainer(container)}
                />
            </Grid>
            <Grid item md={1} sx={{ justifyContent: 'flex-end' }}>
                <IconButton
                    onClick={() => handleContainerRemove(container.id)}
                    sx={{
                        padding: theme.spacing(0.65),
                        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(0.5)}`,
                    }}
                    disabled={isUsedAsInnerContainer(container)}
                >
                    <Delete />
                </IconButton>
            </Grid>
            <Grid item md={1}>
                <IconButton
                    sx={{
                        backgroundColor: 'transparent',
                        padding: theme.spacing(0.65),
                        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2.5)}`,
                    }}
                    data-testid="collapse"
                    onClick={() => toggleCollapse(container.id)}
                >
                    {collapseStates[container.id] ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </Grid>
            <Grid item md={12} sx={{ paddingTop: theme.spacing(1) }}>
                <TextField
                    value={container.innerContainer ?? ''}
                    select
                    fullWidth
                    SelectProps={{
                        renderValue: (value) => {
                            const selectedContainer =
                                props.data.outerContainers?.find((ic) => getUuidFromString(ic.links.self) === value) ??
                                props.data.allContainers?.find((ic) => getUuidFromString(ic.links.self) === value);
                            return getInnerContainerSelectOptionTitle(selectedContainer);
                        },
                    }}
                    sx={{ paddingRight: theme.spacing(1.5) }}
                    label={t('productDataSheet:singlePacksIncluded')}
                    onChange={(event) => {
                        handleInnerContainerSelect(event.target.value, container.id);
                    }}
                    disabled={isUsedAsInnerContainer(container)}
                >
                    {innerContainerSelectOptions
                        ?.filter((it) => it.id !== container.id)
                        ?.map((it) => {
                            return (
                                <MenuItem value={it.id} key={it.id}>
                                    {getInnerContainerSelectOptionTitle(it)}
                                </MenuItem>
                            );
                        })}
                </TextField>
            </Grid>
            {showCollapsedInputFields(container)}
        </Grid>
    );

    const showContainerInputSection = (): ReactNode => {
        const { outerContainers } = props.data;
        return isAtLeastOneInnerContainerSet && outerContainers?.length
            ? outerContainers.map((outerContainer) => (
                  <Grid
                      key={outerContainer.id}
                      container
                      item
                      md={12}
                      sx={{
                          border: 1,
                          borderRadius: 5,
                          borderColor: theme.palette.grey[300],
                          padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(
                              2,
                          )}`,
                          marginBottom: theme.spacing(1.5),
                      }}
                  >
                      {wrapButtonWithTooltip(
                          showContainerSelect(outerContainer),
                          isUsedAsInnerContainer(outerContainer) ? t('tooltips:noEditOrDeleteOfInnerContainers') : '',
                          'top-start',
                          outerContainer.id,
                          { width: '100%' },
                      )}
                  </Grid>
              ))
            : null;
    };

    const showCollapsedInputFields = (outerContainer: ContainerView): ReactNode => {
        return collapseStates[outerContainer.id] ? (
            <ContainerPropsComponent
                container={outerContainer}
                update={handleContainerPropsUpdate.bind(this)}
                isReadOnly={isUsedAsInnerContainer(outerContainer)}
            />
        ) : null;
    };

    const showAddButton = () => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    !props.data.outerContainers ||
                    props.data.outerContainers.some((it) => !it.innerContainer || !it.amount || !it.containerType)
                }
                onClick={() => handleAddContainer()}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showContainerInputSection()}
            {isAtLeastOneInnerContainerSet ? showAddButton() : null}
        </Grid>
    );
};
