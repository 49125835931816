import { PaymentType } from 'model/Payment';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { OrderAddress, OrderAddressType } from 'model/Address';
import { OrderContainer } from 'model/ContainerView';
import { Amount } from 'model/Amount';

export class Order {
    constructor(
        public dateCreated: Date,
        public amount: Amount,
        public totalPrice: number,
        public pricePerUnit: number,
        public productLabel: string,
        public levelsOfProcessing: LevelOfProcessing[],
        public payment: PaymentType,
        public containers: OrderContainer[],
        public addresses: OrderAddress[],
        public status: OrderStatus,
        public links: OrderLinks,
        public isCompleted: boolean,
    ) {}
}

export enum OrderStatus {
    PENDING = 'PENDING',
    INVOICE_ADDED = 'INVOICE_ADDED',
    INVOICE_EXCHANGED = 'INVOICE_EXCHANGED',
}

export class OrderWriteView {
    constructor(
        public invoiceAddress: OrderAddressWriteView,
        public deliveryAddress: OrderAddressWriteView,
        public paymentType: PaymentType,
    ) {}
}

export class OrderLinks {
    constructor(
        public self: string,
        public offer?: string,
        public buyingPerson?: string,
        public buyingCompany?: string,
        public sellingPerson?: string,
        public sellingCompany?: string,
        public invoice?: string,
        public category?: string,
    ) {}
}

export class OrderAddressWriteView {
    constructor(
        public type: OrderAddressType,
        public name: string,
        public street: string,
        public zipcode: string,
        public city: string,
        public suffix?: string,
    ) {}
}

export interface OrderStatusWriteView {
    status: OrderStatus;
}
