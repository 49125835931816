import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default interface StepTemperatureProps {
    temperatureMaxStorage?: number | string;
    temperatureMaxTransport?: number | string;
    temperatureText?: string;
}

export const StepTemperature = (props: StepComponentProps<StepTemperatureProps>) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [focused, setFocused] = useState(false);

    const handleFocus = () => {
        setFocused(true);
    };

    const handleBlur = () => {
        setFocused(false);
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_TEMPERATURE_TITLE')}
            </Typography>
        </Grid>
    );

    const showInformationInput = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(0.5), paddingTop: theme.spacing(2) }}>
            <TextField
                sx={{ width: '100%' }}
                value={props.data.temperatureText ?? ''}
                onChange={(event) =>
                    props.setData('StepTemperature', {
                        ...props.data,
                        temperatureText: event.target.value,
                    })
                }
                label={t('productDataSheet:DATASTEP_TEMPERATURE_INFORMATION')}
                multiline
                rows={5}
                InputLabelProps={{
                    sx: {
                        width: 'auto',
                        whiteSpace: focused || props.data.temperatureText ? 'nowrap' : 'pre-wrap',
                    },
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
            />
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_TEMPERATURE_TEXT')}</Typography>
        </Grid>
    );

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['e', 'E', '+', '.', ','].includes(event.key) && !(event.key === '-' && event.currentTarget.value === '')) {
            event.preventDefault();
        }
    };

    const showTemperatureInput = (): ReactNode => (
        <Grid item container md={12}>
            <Grid item md={6} sx={{ display: 'grid', paddingRight: theme.spacing(2) }}>
                <TextField
                    sx={{ textOverflow: 'unset' }}
                    label={t('productDataSheet:DATASTEP_TEMPERATURE_STORAGE_MAX')}
                    value={props.data.temperatureMaxStorage ?? ''}
                    onChange={(event) => {
                        props.setData('StepTemperature', { temperatureMaxStorage: event.target.value });
                    }}
                    inputProps={{
                        inputMode: 'numeric',
                        onKeyDown: handleKeyDown,
                        endAdornment: <img width={theme.spacing(2.75)} src="/images/Celcius.svg" alt={'Celsius'} />,
                    }}
                />
            </Grid>
            <Grid item md={6} sx={{ display: 'grid', paddingLeft: theme.spacing(2) }}>
                <TextField
                    label={t('productDataSheet:DATASTEP_TEMPERATURE_TRANSPORT_MAX')}
                    value={props.data.temperatureMaxTransport ?? ''}
                    onChange={(event) => {
                        props.setData('StepTemperature', { temperatureMaxTransport: event.target.value });
                    }}
                    inputProps={{
                        inputMode: 'numeric',
                        onKeyDown: handleKeyDown,
                        endAdornment: <img width={theme.spacing(2.75)} src="/images/Celcius.svg" alt={'Celsius'} />,
                    }}
                />
            </Grid>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showTemperatureInput()}
            {showInformationInput()}
        </Grid>
    );
};
