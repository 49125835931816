import { IconButton, Tooltip } from '@mui/material';
import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { WithTranslation, withTranslation } from 'react-i18next';
import MessageWriteDialog from 'components/messaging/MessageWriteDialog';
import { Feature } from 'model/Feature';
import FeatureToggle from 'components/feature/FeatureToggle';
import MessageRestrictionDialog from 'components/messaging/MessageRestrictionDialog';
import { MessageTermsStatus, Person } from 'model/Person';
import { CompanyStore, ContactsStore, PersonStore } from 'store';
import { Company } from 'model/Company';
import { Contact } from 'model/Contact';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = () => createStyles({});

interface PublicContactMessageButtonProps extends WithStyles<typeof _styles>, WithTranslation {
    contactRef: string;
    messageTopic?: string;
    offerRef?: string;
    requestRef?: string;
    isOnCompanyPresentation?: boolean;
    calledFrom: string;
    useIconButton?: boolean;
}

interface PublicContactMessageButtonState {
    isRestrictionDialogOpen?: boolean;
    isWriteDialogOpen?: boolean;
    user: Person;
    contact: Contact;
    contactPerson: Person;
    contactCompany: Company;
    isContactEqualsSelf: boolean;
    isContactNotUsingMessaging: boolean;
    tooltipText: string;
}

class PublicContactMessageButton extends ComponentBase<
    PublicContactMessageButtonProps,
    PublicContactMessageButtonState
> {
    protected _buildState(
        props: PublicContactMessageButtonProps,
        initialBuild: boolean,
        incomingState?: Readonly<PublicContactMessageButtonState>,
    ): Partial<PublicContactMessageButtonState> | undefined {
        const newState: Partial<PublicContactMessageButtonState> = {
            user: PersonStore.getSelected(),
            contact: ContactsStore.getOne(props.contactRef),
            isContactNotUsingMessaging: true,
            isContactEqualsSelf: true,
        };

        if (newState.contact) {
            newState.contactCompany = CompanyStore.getOne(newState.contact.links.company);
            newState.contactPerson = PersonStore.getOne(newState.contact.links.person);
        }

        if (newState.user && newState.contactCompany && newState.contactPerson) {
            newState.isContactEqualsSelf = newState.contactPerson.links.self == newState.user.links.self;
            newState.isContactNotUsingMessaging =
                newState.contactPerson.messageTermsStatus != MessageTermsStatus.ACCEPTED ||
                !newState.contactCompany.verified;
        }

        newState.tooltipText = newState.isContactEqualsSelf
            ? props.t('dialogs:noSelfContact')
            : newState.isContactNotUsingMessaging
              ? props.t('dialogs:noMessagingInUse')
              : undefined;

        return newState;
    }

    render(): React.ReactElement | null {
        if (!this.state.user) return null;
        return (
            <FeatureToggle requiredFeatures={[Feature.USE_MESSAGING]} type={'allOf'}>
                <MessageRestrictionDialog
                    isOpen={!!this.state.isRestrictionDialogOpen}
                    onClose={(accepted) =>
                        accepted
                            ? this.setState({
                                  isWriteDialogOpen: true,
                                  isRestrictionDialogOpen: false,
                              })
                            : this.setState({
                                  isWriteDialogOpen: false,
                                  isRestrictionDialogOpen: false,
                              })
                    }
                />
                <MessageWriteDialog
                    topic={this.props.messageTopic}
                    isOpen={
                        this.state.user.messageTermsStatus === MessageTermsStatus.ACCEPTED
                            ? this.state.isWriteDialogOpen
                            : false
                    }
                    offerRef={this.props.offerRef}
                    requestRef={this.props.requestRef}
                    onClose={() => this.setState({ isWriteDialogOpen: false })}
                    contactRef={this.props.contactRef}
                />
                <Tooltip title={this.state.tooltipText}>
                    <>
                        {this.props.useIconButton ? (
                            <IconButton
                                disabled={this.state.isContactEqualsSelf || this.state.isContactNotUsingMessaging}
                                onClick={() => {
                                    captureWebEvent(`contact-message-button-from-${this.props.calledFrom}`);
                                    this.state.user?.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                        ? this.setState({ isWriteDialogOpen: true })
                                        : this.setState({
                                              isRestrictionDialogOpen: true,
                                          });
                                }}
                            >
                                <ChatBubbleIcon />
                            </IconButton>
                        ) : (
                            <Button
                                disabled={this.state.isContactEqualsSelf || this.state.isContactNotUsingMessaging}
                                variant="contained"
                                fullWidth={this.props.isOnCompanyPresentation}
                                onClick={() => {
                                    captureWebEvent(`contact-message-button-from-${this.props.calledFrom}`);
                                    this.state.user?.messageTermsStatus === MessageTermsStatus.ACCEPTED
                                        ? this.setState({ isWriteDialogOpen: true })
                                        : this.setState({
                                              isRestrictionDialogOpen: true,
                                          });
                                }}
                                startIcon={<ChatBubbleIcon />}
                            >
                                {this.props.t('messaging:contact')}
                            </Button>
                        )}
                    </>
                </Tooltip>
            </FeatureToggle>
        );
    }
}

export default withTranslation(['messaging', 'dialogs'])(
    withStyles(_styles, { withTheme: true })(PublicContactMessageButton),
);
