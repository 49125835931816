import * as React from 'react';
import { ComponentBase } from 'resub';
import { MenuItem, TextField } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { ContactsStore } from 'store';
import ContactSelectMenuItem from 'components/marketplace/ContactSelectMenuItem';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Contact, ContactType } from 'model/Contact';

const styles = () =>
    createStyles({
        selectInput: {
            minWidth: '65%',
            maxWidth: '100%',
        },
    });

interface ContactSelectProps extends WithStyles<typeof styles>, WithTranslation {
    companyRef: string;
    onChange: (contactRef: string | null, automatic?: boolean) => void;
    onHiddenChange?: (isShown: boolean) => void;
    defaultValue?: string | null;
    type: ContactType;
    preSelectedContactRef?: string | null;
}

interface ContactSelectState {
    contacts: readonly Contact[];
    selectedContact?: string | null | undefined;
    isShown: boolean;
}

class ContactSelectComponent extends ComponentBase<ContactSelectProps, ContactSelectState> {
    protected _buildState(
        props: ContactSelectProps,
        initialBuild: boolean,
        incomingState?: Readonly<ContactSelectState>,
    ): Partial<ContactSelectState> | undefined {
        const allContacts = ContactsStore.search(props.companyRef);
        const newState: Partial<ContactSelectState> = {};
        newState.contacts = this.filterContacts(props.type, allContacts);
        if (newState.contacts.length == 0) {
            newState.contacts = allContacts;
        }

        newState.isShown = newState.contacts ? newState.contacts.length > 0 : false;
        if (!incomingState || incomingState.isShown !== newState.isShown) {
            props.onHiddenChange?.(newState.isShown);
        }
        if (initialBuild) {
            newState.selectedContact = props.preSelectedContactRef;
        }

        if (
            newState.contacts &&
            newState.contacts.length > 0 &&
            incomingState?.selectedContact === undefined &&
            props.preSelectedContactRef === undefined
        ) {
            newState.selectedContact = newState.contacts[0].links.self;
            props.onChange(newState.selectedContact, true);
        }
        return newState;
    }

    filterContacts(type: ContactType, list: readonly Contact[]): readonly Contact[] {
        if (type == ContactType.SALES) return list.filter((contact: Contact) => contact.isSales);
        if (type == ContactType.PROCUREMENT) return list.filter((contact: Contact) => contact.isProcurement);

        return [];
    }

    render() {
        if (!this.state.isShown) {
            return null;
        }
        return (
            <TextField
                select
                label={this.props.t('offer:contact')}
                value={this.state.selectedContact || ''}
                className={this.props.classes.selectInput}
                onChange={(evt) => {
                    const value: string = evt.target.value as string;
                    let newContact: string | null = null;
                    if (value !== '') {
                        newContact = value;
                    }
                    this.setState(
                        {
                            selectedContact: newContact,
                        },
                        () => {
                            this.props.onChange(newContact);
                        },
                    );
                }}
            >
                <MenuItem value="">{this.props.t('offer:contact_none')}</MenuItem>
                {this.state.contacts.map((contact) => {
                    return (
                        <MenuItem value={contact.links.self} key={contact.links.self}>
                            <ContactSelectMenuItem contact={contact} />
                        </MenuItem>
                    );
                })}
            </TextField>
        );
    }
}

export default withTranslation('offer')(withStyles(styles)(ContactSelectComponent));
