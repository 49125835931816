import { AxiosResponse } from 'axios';
import { Product, ProductWriteView } from 'model/Product';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProductStore } from 'store';
import ProductChildrenStore from 'store/ProductChildrenStore';
import { isUriEncoded } from 'util/helpers';
import http from 'api/http';

class ProductService {
    getAllProducts(): Observable<Product[]> {
        return http
            .getAxios()
            .get<Product[]>('ontofood')
            .pipe(
                map((response) => response.data),
                tap((products) => ProductStore.setAll(products)),
            );
    }

    getProduct(productRef: string): Observable<Product> {
        return http
            .getAxios()
            .get<Product>(productRef)
            .pipe(
                map((response) => response.data),
                tap((product) => ProductStore.setOne(product)),
            );
    }

    getTopCategory(): Observable<Product> {
        return http
            .getAxios()
            .get<Product>('ontofood/top')
            .pipe(
                map((response) => response.data),
                tap((product) => ProductStore.setOne(product)),
                tap((product) => ProductStore.setSelected(product.links.self)),
            );
    }

    searchProduct(label: string): Observable<Product> {
        return http
            .getAxios()
            .get<Product>(`ontofood/search/${label}`)
            .pipe(
                map((response) => response.data),
                tap((product) => ProductStore.setOne(product)),
            );
    }

    transformLabel(label: string): string {
        return label.trim();
    }

    addProduct(superCategory: Product, product: ProductWriteView): Observable<Product> {
        // transform label into uppercase with underscores
        return http
            .getAxios()
            .post<Product>(superCategory.links.self + '/subcategories', product)
            .pipe(
                map((response) => response.data),
                // reload super categories
                tap(() => ProductStore.loadOne(superCategory.links.self)),
                tap((data) => {
                    ProductStore.setOne(data);
                    ProductChildrenStore.invalidateCache();
                }),
            );
    }

    setParents(productRef: string, superCategoryRefs: string[]): Observable<Product> {
        const product = ProductStore.getOne(productRef);
        return http
            .getAxios()
            .put<Product>(productRef + '/supercategories', superCategoryRefs)
            .pipe(
                map((response) => response.data),
                tap((category) => ProductStore.setOne(category)),
                tap(() => {
                    // difference of old and new supercategories
                    if (product) {
                        superCategoryRefs
                            .filter((x) => !product.links.supercategories.includes(x))
                            .concat(product.links.supercategories.filter((x: string) => !superCategoryRefs.includes(x)))
                            .forEach((category) => {
                                ProductStore.loadOne(category);
                            });
                    }
                    ProductChildrenStore.invalidateCache();
                }),
            );
    }

    updateProduct(productRef: string, product: ProductWriteView): Observable<Product> {
        product.label = this.transformLabel(product.label);
        return http
            .getAxios()
            .put<Product>(productRef, product)
            .pipe(
                map((response) => response.data),
                tap((_) => {
                    if (ProductStore.hasOne(productRef)) {
                        const product = ProductStore.getOne(productRef);
                        product?.links.supercategories.forEach((sup) => ProductStore.loadOne(sup));
                    }
                    ProductChildrenStore.invalidateCache();
                }),
                switchMap(() => ProductStore.loadOne(productRef)),
            );
    }

    deleteProduct(productRef: string): Observable<AxiosResponse<unknown>> {
        return http
            .getAxios()
            .delete<unknown>(productRef)
            .pipe(
                tap((response) => {
                    if (response.status <= 400) {
                        if (ProductStore.hasOne(productRef)) {
                            const product = ProductStore.getOne(productRef);
                            product?.links.supercategories.forEach((sup) => ProductStore.loadOne(sup));
                        }
                        ProductStore.removeOneById(productRef);
                        ProductChildrenStore.invalidateCache();
                    }
                }),
            );
    }

    proposeProduct(productName: string, companyId: string): Observable<boolean> {
        return http
            .getAxios()
            .post<boolean>('ontofood/propose/' + companyId, {
                label: productName,
            })
            .pipe(map((response) => response.data));
    }

    toCategoryRef(categoryId: string): string {
        const encodedCategoryId = isUriEncoded(categoryId) ? categoryId : encodeURIComponent(categoryId);
        return `${http.getBaseUrl()}/ontofood/${encodedCategoryId}`;
    }

    getAllChildren(product: string): Observable<Product[]> {
        return http
            .getAxios()
            .get<Product[]>('ontofood/subproducts', {
                params: { product },
            })
            .pipe(map((response) => response.data));
    }

    getAllParents(product: string): Observable<Product[]> {
        return http
            .getAxios()
            .get<Product[]>('ontofood/superproducts', {
                params: { product },
            })
            .pipe(map((response) => response.data));
    }
}

export default new ProductService();
