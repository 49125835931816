import * as React from 'react';
import { ComponentBase } from 'resub';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { Switch, Theme } from '@mui/material';
import { WithStyles, WithTheme } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import Dialog from '@mui/material/Dialog';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import 'moment/locale/de';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Person } from 'model/Person';
import { Company } from 'model/Company';
import { Offer } from 'model/Offer';
import { CompanyStore, ContactsStore, OfferStore, ProductStore } from 'store';
import PersonStore from 'store/PersonStore';
import { Contact } from 'model/Contact';
import { Product } from 'model/Product';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import DialogTitle from '@mui/material/DialogTitle';
import { captureWebEvent } from 'util/AnalyticUtils';
import FormControlLabel from '@mui/material/FormControlLabel';
import { OfferDetailsComponent } from 'components/marketplace/pre-order/dialog/OfferDetailsComponent';
import PreOrderDialogHandlers from 'components/marketplace/pre-order/dialog/PreOrderDialogHandlers';
import PreOrderDialogHelpers from 'components/marketplace/pre-order/dialog/PreOrderDialogHelpers';
import { ContainerView } from 'model/ContainerView';
import ContainerSelectionComponent from 'components/marketplace/pre-order/ContainerSelectionComponent';
import AmountComponent from 'components/marketplace/pre-order/AmountComponent';
import TextField from '@mui/material/TextField';
import {
    DeliveryDateFormErrors,
    DeliveryDateSelection,
} from 'components/marketplace/pre-order/dialog/DeliveryDateSelection';
import { Amount, getAmountValidationValue } from 'model/Amount';

const styles = (theme: Theme) =>
    createStyles({
        sendButton: {
            marginLeft: theme.spacing(1),
        },
        smallTypography: {
            fontSize: 'small',
        },
    });

export enum PreOrderDialogType {
    PurchaseIntent,
    PriceRequest,
}

export interface PreOrderDialogProps extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    type: PreOrderDialogType;
    isOpen: boolean;
    onClose: () => void;
    offerRef: string;
    contactRef: string;
}

export interface PreOrderDialogState {
    person?: Person;
    offer?: Offer;
    category?: Product;
    ownCompany?: Company;
    contact?: Contact;
    message?: string | undefined;
    isRecurringOrder: boolean;
    offerContainers?: ContainerView[];
    isAmountSelectionSwitchChecked?: boolean;
    rruleString: string | undefined;
    formErrors: DeliveryDateFormErrors | undefined;

    showContainerSelection?: boolean;
    amountInOfferUnit: number | undefined;
    containers?: Map<string, number>;
    showSentError: boolean;
    statusCode?: number;
}

export class PreOrderDialog extends ComponentBase<PreOrderDialogProps, PreOrderDialogState> {
    protected _buildState(
        props: PreOrderDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<PreOrderDialogState> | undefined,
    ): Partial<PreOrderDialogState> | undefined {
        const newState: Partial<PreOrderDialogState> = {
            ...incomingState,
            contact: ContactsStore.getOne(props.contactRef),
            offer: OfferStore.getOne(props.offerRef),
            ownCompany: CompanyStore.getSelected(),
        };

        if (newState.contact) {
            newState.person = PersonStore.getOne(newState.contact.links.person);
        }
        if (newState.offer) {
            newState.category = ProductStore.getOne(newState.offer.links.category);
        }

        if (initialBuild) {
            OfferStore.loadOne(props.offerRef);
            newState.isRecurringOrder = false;
            newState.isAmountSelectionSwitchChecked = false;
            newState.amountInOfferUnit = 0;
        }

        newState.offerContainers = newState.offer?.containers;
        newState.showContainerSelection =
            !incomingState?.isAmountSelectionSwitchChecked &&
            newState.offerContainers &&
            newState.offerContainers.length >= 1;

        return newState;
    }

    showMandatoryHint(): React.ReactFragment {
        return <Typography fontWeight={500}>{this.props.t('dialogs:mandatory')}</Typography>;
    }

    showRecurringOrderToggle(handlers: PreOrderDialogHandlers): React.ReactElement {
        return (
            <Grid item>
                <FormControlLabel
                    label={this.props.t('preOrder:recurringOrderToggleText')}
                    labelPlacement="end"
                    control={wrapButtonWithTooltip(
                        <Switch
                            checked={this.state.isRecurringOrder}
                            onChange={handlers.handleRecurringOrderToggle.bind(this)}
                            color="primary"
                        />,
                    )}
                />
            </Grid>
        );
    }

    showAmountSelection(handlers: PreOrderDialogHandlers): React.ReactFragment | null {
        if (this.state.offer) {
            return (
                <Grid container item>
                    {this.state.offerContainers &&
                    this.state.offerContainers.length >= 1 &&
                    this.state.showContainerSelection ? (
                        <ContainerSelectionComponent
                            offerRef={this.props.offerRef}
                            withPrice={this.props.type == PreOrderDialogType.PurchaseIntent}
                            containers={this.state.offerContainers}
                            handleChange={handlers.handleContainersChange.bind(handlers)}
                            onAmountInOfferUnitChange={(amountInOfferUnit) => this.setState({ amountInOfferUnit })}
                            isRecurringOrder={this.state.isRecurringOrder}
                        />
                    ) : (
                        <AmountComponent
                            amountInOfferUnit={this.state.amountInOfferUnit}
                            offer={this.state.offer}
                            usedInContainerSelection={false}
                            isPriceShown={this.props.type == PreOrderDialogType.PurchaseIntent}
                            changeHandler={handlers.handleAmountInputChange.bind(handlers)}
                            isRecurringOrder={this.state.isRecurringOrder}
                        />
                    )}
                </Grid>
            );
        } else return null;
    }

    showContainerToggle(handlers: PreOrderDialogHandlers): React.ReactFragment | null {
        if (this.state.offerContainers && this.state.offerContainers.length > 0) {
            return (
                <Grid container item alignItems={'center'}>
                    <Grid item>
                        <Switch
                            data-testid={'switch'}
                            checked={this.state.isAmountSelectionSwitchChecked}
                            color={'primary'}
                            onChange={handlers.handleContainerToggle.bind(handlers)}
                        />
                    </Grid>
                    <Grid item>
                        <Typography>{this.props.t('offer:amountInputWithoutContainer')}</Typography>
                    </Grid>
                </Grid>
            );
        } else return null;
    }

    showMessageTextField(handlers: PreOrderDialogHandlers): React.ReactFragment {
        return (
            <Grid item>
                <TextField
                    fullWidth
                    hiddenLabel
                    label={this.props.t(
                        this.props.type == PreOrderDialogType.PriceRequest
                            ? 'offer:priceRequestMessageLabel'
                            : 'offer:purchaseIntentMessageLabel',
                    )}
                    value={this.state.message}
                    multiline
                    rows={4}
                    onChange={handlers.handleMessageValueReset.bind(handlers)}
                />
            </Grid>
        );
    }

    showHint(): React.ReactFragment {
        return (
            <Grid item>
                <Typography>
                    {this.props.t(
                        this.props.type == PreOrderDialogType.PurchaseIntent
                            ? 'offer:purchaseIntentHint'
                            : 'offer:priceRequestHint',
                    )}
                </Typography>
            </Grid>
        );
    }

    showError(): React.ReactFragment {
        return (
            <Grid item>
                <Typography
                    color={'red'}
                    className={this.props.classes.smallTypography}
                    hidden={!this.state.showSentError}
                >
                    <Trans
                        i18nKey="offer:purchaseIntentError"
                        t={this.props.t}
                        values={{
                            status: this.state.statusCode || this.props.t('common:unknownError'),
                        }}
                        components={[
                            <a
                                key={0}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={'mailto:support@nearbuy-food.de'}
                                style={{ color: 'red' }}
                            />,
                        ]}
                    />
                </Typography>
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) return null;
        const helpers = new PreOrderDialogHelpers(this.props, this.state, this.setState.bind(this));
        const handlers = new PreOrderDialogHandlers(this.props, this.state, this.setState.bind(this), helpers);

        const hasFormErrors = this.state.formErrors && Object.keys(this.state.formErrors).length > 0;

        const amountValidationValue = getAmountValidationValue(
            new Amount(this.state.amountInOfferUnit ?? 0, this.state.offer.totalAmount.unit),
            this.state.offer.minAmount,
            this.state.offer.totalAmount,
        );

        const isAmountValid = this.state.isRecurringOrder
            ? amountValidationValue === true || amountValidationValue === 1
            : amountValidationValue === true;

        const isSendButtonDisabled = !isAmountValid || hasFormErrors;

        const shouldShowDeliveryDateSelection =
            this.state.isRecurringOrder ||
            (!this.state.isRecurringOrder && process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true');

        if (!this.state.person || !this.state.ownCompany || !this.state.offer) {
            return null;
        }

        return (
            <Dialog
                open={this.props.isOpen}
                maxWidth="md"
                fullWidth
                TransitionComponent={Transition}
                onClose={(event, reason) => {
                    if (event && reason === 'backdropClick') {
                        this.props.onClose();
                    }
                }}
            >
                <DialogTitle>
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        {this.props.t(helpers.getTitleLocale())}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <OfferDetailsComponent
                        receivingPerson={this.state.person}
                        category={this.state.category}
                        offer={this.state.offer}
                    />
                    <Divider sx={{ my: 2 }} />
                    <Grid container justifyContent={'space-between'} alignItems={'center'}>
                        <Grid>{this.state.offer.isPermanent ? this.showRecurringOrderToggle(handlers) : null}</Grid>
                        <Grid item>{this.showMandatoryHint()}</Grid>
                    </Grid>
                    <Grid container style={{ display: 'grid', justifyContent: 'end' }}>
                        <Grid item>{this.showContainerToggle(handlers)}</Grid>
                    </Grid>
                    {this.showAmountSelection(handlers)}
                    <Grid sx={{ py: 2.5 }}>
                        <Divider />
                    </Grid>
                    {shouldShowDeliveryDateSelection ? (
                        <DeliveryDateSelection
                            isRecurringOrder={this.state.isRecurringOrder}
                            offer={this.state.offer}
                            onRRuleStringChange={handlers.handleRRuleStringChange.bind(this)}
                            onFormErrorsChange={handlers.handleFormErrorsChange.bind(this)}
                        />
                    ) : null}
                    {shouldShowDeliveryDateSelection && (
                        <Grid sx={{ py: 2.5 }}>
                            <Divider />
                        </Grid>
                    )}
                    {!this.state.isRecurringOrder ? (
                        <Grid item container direction={'column'}>
                            {this.showMessageTextField(handlers)}
                            {this.showHint()}
                            {this.showError()}
                        </Grid>
                    ) : null}
                </DialogContent>
                <DialogActions>
                    <div>
                        <Button
                            variant={'outlined'}
                            onClick={(): void => {
                                captureWebEvent('marketplace-cancel-purchase-request-button');
                                handlers.handleClose();
                            }}
                        >
                            {this.props.t('dialogs:CANCEL')}
                        </Button>
                        <Button
                            variant={'contained'}
                            className={this.props.classes.sendButton}
                            onClick={() => {
                                if (this.state.message) {
                                    captureWebEvent('marketplace-send-purchase-request-with-message');
                                }
                                captureWebEvent('marketplace-send-purchase-request-button');
                                handlers.handleSend();
                            }}
                            disabled={isSendButtonDisabled}
                        >
                            {this.props.t('dialogs:SEND')}
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['common', 'dialogs', 'preOrder'])(
    withStyles(styles, { withTheme: true })(PreOrderDialog),
);
