import CancelIcon from '@mui/icons-material/Cancel';
import { Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import * as React from 'react';
import { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { LevelOfProcessingStore } from 'store';
import FormHelperText from '@mui/material/FormHelperText';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                color: theme.palette.common.black,
                backgroundColor: theme.palette.primary.light,
            },
        },
        closeIcon: {
            fill: theme.palette.primary.main,
            '&:hover': {
                fill: theme.palette.primary.dark,
            },
        },
        selectInput: {
            justifyContent: 'flex-end',
            display: 'flex',
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: 5,
        },
        formControl: {
            backgroundColor: 'transparent',
            minWidth: '100%',
            maxWidth: '100%',
        },
        checkBox: {
            padding: 2.5,
            margin: 2.5,
            color: 'grey',
            '&:hover': {
                color: 'grey',
            },
        },
        menuList: {
            '& li.Mui-selected': {
                backgroundColor: 'transparent',
            },
        },
        selectableMenuItem: {
            padding: 0.1,
            margin: 0,
            '&:hover': {
                padding: 0.1,
                margin: 0,
            },
        },
        notSelectableMenuItem: {
            padding: 0.1,
            margin: 0,
        },
        inputLabel: {
            zIndex: 1,
            padding: theme.spacing(0.8),
            paddingLeft: theme.spacing(1.7),
            '&.MuiInputLabel-shrink': {
                paddingTop: theme.spacing(1.4),
            },
        },
        helperText: {
            color: theme.palette.primary.dark,
            fontSize: '0.6rem',
            fontWeight: 'bold',
        },
    });

interface LevelOfProcessingProperties extends WithStyles<typeof styles>, WithTranslation {
    selectedLopRefs: string[];
    remove: (lop: string) => void;
    update: (lop: string[]) => void;
    excludeFromOptions?: string;
    helperText?: string;
    filterOptions?: (lop: LevelOfProcessing) => boolean;
    className?: string;
}

interface LevelOfProcessingState {
    levelsOfProcessing?: LevelOfProcessing[];
    selectedLops: LevelOfProcessing[];
    excludedLopRefs: string[];
    isUsedInMarketItemDialog: boolean;
}

export class LevelOfProcessingComponent extends ComponentBase<LevelOfProcessingProperties, LevelOfProcessingState> {
    protected _buildState(
        props: LevelOfProcessingProperties,
        initialBuild: boolean,
        incomingState: Readonly<LevelOfProcessingState> | undefined,
    ): Partial<LevelOfProcessingState> | undefined {
        const newState: Partial<LevelOfProcessingState> = {};

        if (initialBuild) {
            newState.excludedLopRefs = [];
            newState.isUsedInMarketItemDialog = false;
        }

        newState.isUsedInMarketItemDialog = props.excludeFromOptions === undefined;
        newState.levelsOfProcessing = LevelOfProcessingStore.getAll();
        newState.selectedLops = props.selectedLopRefs
            .map((lopRef) => LevelOfProcessingStore.getOne(lopRef))
            .filter((lop): lop is LevelOfProcessing => lop !== undefined);

        if (newState.selectedLops) {
            const excludedLopRefs: string[] = [];
            newState.selectedLops.forEach((lop) => {
                if (lop.links.excludedLops) {
                    excludedLopRefs.push(...lop.links.excludedLops);
                }
            });
            newState.excludedLopRefs = excludedLopRefs;
        }

        return newState;
    }

    showChips(): ReactNode {
        if (this.props.selectedLopRefs && this.state.levelsOfProcessing) {
            return this.state.levelsOfProcessing
                .filter((lop) => this.props.selectedLopRefs.includes(lop.links.self))
                .map((lop) => {
                    return (
                        <Chip
                            className={this.props.classes.chip}
                            key={lop.links.self}
                            label={this.props.t('levelsOfProcessing:' + lop.label)}
                            color="primary"
                            onDelete={(): void => this.props.remove(lop.links.self)}
                            deleteIcon={
                                <CancelIcon
                                    className={this.props.classes.closeIcon}
                                    onMouseDown={(event): void => event.stopPropagation()}
                                />
                            }
                        />
                    );
                });
        }
    }

    getSelectableMenuOptions(): ReactNode {
        if (!this.state.levelsOfProcessing) return null;
        let sortedAndFilteredLops = this.sortLops(
            this.state.levelsOfProcessing.filter((lop) =>
                !this.state.isUsedInMarketItemDialog
                    ? lop.links.self !== this.props.excludeFromOptions
                    : !this.state.excludedLopRefs.includes(lop.links.self),
            ),
        );
        if (this.props.filterOptions) {
            sortedAndFilteredLops = sortedAndFilteredLops.filter(this.props.filterOptions);
        }
        return sortedAndFilteredLops.map((lop) => (
            <MenuItem
                key={lop.links.self}
                value={lop.links.self}
                className={this.props.classes.selectableMenuItem}
                data-testid={`option:lop:${lop.label}`}
            >
                <Checkbox className={this.props.classes.checkBox} checked={this.state.selectedLops.includes(lop)} />
                <ListItemText primary={this.props.t('levelsOfProcessing:' + lop.label)} sx={{ paddingBottom: 0.25 }} />
            </MenuItem>
        ));
    }

    getNotSelectableMenuOptions(): ReactNode {
        return (
            this.state.levelsOfProcessing &&
            this.sortLops(
                this.state.levelsOfProcessing.filter((lop) => this.state.excludedLopRefs.includes(lop.links.self)),
            ).map((lop) => (
                <MenuItem
                    disabled
                    key={lop.links.self}
                    value={lop.links.self}
                    className={this.props.classes.notSelectableMenuItem}
                    data-testid={`option:lop:${lop.label}`}
                >
                    <Checkbox className={this.props.classes.checkBox} checked={false} />
                    <ListItemText
                        primary={this.props.t('levelsOfProcessing:' + lop.label)}
                        sx={{ paddingBottom: 0.25 }}
                    />
                </MenuItem>
            ))
        );
    }

    sortLops(lops: LevelOfProcessing[]): LevelOfProcessing[] {
        return lops.sort((a, b) =>
            this.props.t('levelsOfProcessing:' + a.label).localeCompare(this.props.t('levelsOfProcessing:' + b.label)),
        );
    }

    render(): React.ReactElement {
        return (
            <Grid className={this.props.className} item md={12}>
                <FormControl className={this.props.classes.formControl}>
                    <InputLabel id="select-multiple-chip-label" className={this.props.classes.inputLabel}>
                        {this.props.t('offer:processing')}
                    </InputLabel>
                    <Select
                        labelId="select-multiple-chip-label"
                        multiple
                        value={this.props.selectedLopRefs || []}
                        renderValue={(): ReactNode => this.showChips()}
                        onChange={(selectedOption): void => this.props.update(selectedOption.target.value as string[])}
                        MenuProps={{
                            MenuListProps: {
                                className: this.props.classes.menuList,
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: '40%',
                                    overflowY: 'auto',
                                },
                            },
                        }}
                        className={this.props.classes.selectInput}
                    >
                        {this.getSelectableMenuOptions()}
                        {this.state.isUsedInMarketItemDialog && this.getNotSelectableMenuOptions()}
                    </Select>
                    {this.props.helperText ? (
                        <FormHelperText className={this.props.classes.helperText} sx={{ paddingLeft: '15px' }}>
                            {this.props.helperText}
                        </FormHelperText>
                    ) : null}
                </FormControl>
            </Grid>
        );
    }
}

export default withTranslation(['offer', 'levelsOfProcessing'])(
    withStyles(styles, { withTheme: true })(LevelOfProcessingComponent),
);
