import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { Person } from 'model/Person';
import { Product } from 'model/Product';
import Typography from '@mui/material/Typography';
import { Offer } from 'model/Offer';

export default interface OfferDetailProps {
    category?: Product;
    receivingPerson: Person;
    offer: Offer;
}
export const OfferDetailsComponent = (props: OfferDetailProps) => {
    const theme = useTheme();
    const { t } = useTranslation(['preOrder', 'ontofood', 'common']);

    const showOfferDetailRow = (label: string, value: string) => (
        <Grid item xs={12} container alignItems="center">
            <Grid item xs={4}>
                <Typography fontWeight={450}>{t(label)}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography fontWeight={450}>{value}</Typography>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={theme.spacing(1)}>
            {showOfferDetailRow(
                'preOrder:receivingPerson',
                `${props.receivingPerson.firstname} ${props.receivingPerson.lastname}`,
            )}
            {showOfferDetailRow('preOrder:offer', t(`ontofood:${props.category?.label}`) ?? '')}
            {showOfferDetailRow(
                'preOrder:validity',
                props.offer.dateEnd?.toString() !== 'Invalid date'
                    ? `${props.offer.dateFrom?.format('DD.MM.YYYY')} - ${props.offer.dateEnd?.format('DD.MM.YYYY')}`
                    : `${t('common:from')} ${props.offer.dateFrom?.format('DD.MM.YYYY')}`,
            )}
            {showOfferDetailRow(
                'preOrder:price',
                `${props.offer.pricePerUnit?.toLocaleString('de-DE', {
                    style: 'currency',
                    currency: 'EUR',
                })} pro ${t(`shortunits:${props.offer.totalAmount.unit}`)}`,
            )}
        </Grid>
    );
};
