import { autoSubscribe, AutoSubscribeStore, StoreBase } from 'resub';
import { ProductDataSheetListView } from 'model/ProductDataSheet';
import ProductDataSheetService from 'api/ProductDataSheetService';

@AutoSubscribeStore
class ProductDataSheetListStoreClass extends StoreBase {
    protected pdsListViewMap: Map<string, ProductDataSheetListView[]> = new Map<string, ProductDataSheetListView[]>();
    protected isLoading = false;

    @autoSubscribe
    get(companyRef: string): ProductDataSheetListView[] | undefined {
        const pdsListViews = this.pdsListViewMap.get(companyRef);
        if (pdsListViews === undefined) this.search(companyRef);
        return pdsListViews;
    }

    private search(companyRef: string) {
        if (!this.isLoading) {
            this.isLoading = true;
            ProductDataSheetService.listProductDataSheets(companyRef).subscribe((pdsListViews) => {
                this.pdsListViewMap.set(companyRef, pdsListViews);
                this.isLoading = false;
                this.trigger();
            });
        }
    }

    invalidateCache(companyRef?: string) {
        if (companyRef !== undefined) this.pdsListViewMap.delete(companyRef);
        else this.clear();
        this.trigger();
    }

    clear() {
        this.pdsListViewMap = new Map<string, ProductDataSheetListView[]>();
        this.trigger();
    }
}

export const ProductDataSheetListStore = new ProductDataSheetListStoreClass();
export default ProductDataSheetListStore;
