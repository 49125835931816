import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { getButtonVariant } from 'shared/buttons';
import { theme } from 'style/NearbuyStyle';
import { wrapButtonWithTooltip } from 'util/style-helpers';

export interface SplitButtonOption {
    label: string;
    action: () => void;
    icon?: ReactNode;
    tooltip?: string;
}

function SplitButtonComponent(props: {
    options: SplitButtonOption[];
    buttonDefaultText?: string;
    buttonType?: 'transparent' | 'primaryLight' | 'secondaryMain';
    justifyHorizontal?: 'start' | 'center' | 'end';
    sxPropsButtonGroup?: SxProps<Theme>;
    disabled?: boolean;
}) {
    /* Hooks */
    const anchorRef = React.useRef<HTMLDivElement>(null);

    /* States and Store Subscribed Constants */
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = useState(
        props.buttonDefaultText ? undefined : props.options.length ? props.options[0] : undefined,
    );

    /* Effects */
    useEffect(() => {
        if (selectedOption && !props.options.includes(selectedOption) && props.options.length)
            setSelectedOption(props.options[0]);
    }, [props.options]);

    /* Styles und StyledComponents */

    /* Handler */
    const handleClick = () => {
        if (props.buttonDefaultText) {
            if (selectedOption !== undefined) {
                selectedOption.action();
            } else {
                handleToggle();
            }
        } else {
            selectedOption?.action();
        }
    };
    const handleMenuItemClick = (option: SplitButtonOption) => {
        setSelectedOption(option);
        setIsOpen(false);
    };
    const handleToggle = () => {
        setIsOpen((isOpen: boolean) => !isOpen);
    };
    const handleClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setIsOpen(false);
    };

    /* Util Functions */
    const getIcon = (): ReactNode => {
        return selectedOption !== undefined ? selectedOption.icon : undefined;
    };

    /* Styles und StyledComponents */

    /* JSX */
    const getLeftButton = () => (
        <Button
            disabled={props.disabled}
            variant={getButtonVariant(props.buttonType)}
            onClick={handleClick}
            startIcon={getIcon()}
            data-testid={'split-button-left'}
        >
            {props.buttonDefaultText
                ? selectedOption !== undefined
                    ? selectedOption.label
                    : props.buttonDefaultText
                : selectedOption?.label ?? ''}
        </Button>
    );

    const showButtonGroup = () => {
        const toolTip = selectedOption?.tooltip;
        const buttonGroup = (
            <ButtonGroup data-testid={'split-button'} ref={anchorRef}>
                {getLeftButton()}
                <Button
                    disabled={props.disabled}
                    variant={'contained'}
                    size="small"
                    onClick={handleToggle}
                    data-testid={'split-button-right'}
                    sx={{ marginRight: theme.spacing(1) }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
        );
        if (toolTip) {
            return wrapButtonWithTooltip(buttonGroup, toolTip);
        } else {
            return buttonGroup;
        }
    };

    const menuList = (
        <MenuList autoFocusItem>
            {props.options.map((option: SplitButtonOption) => (
                <MenuItem
                    sx={{
                        ':hover': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.primary.light,
                        },
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.primary.lighter,
                            ':hover': {
                                backgroundColor: theme.palette.primary.light,
                            },
                        },
                    }}
                    key={option.label}
                    selected={selectedOption == option}
                    onClick={() => handleMenuItemClick(option)}
                >
                    {option.label}
                </MenuItem>
            ))}
        </MenuList>
    );

    const popper = (
        <Popper
            sx={{
                zIndex: 10_000,
            }}
            open={isOpen}
            anchorEl={anchorRef.current}
        >
            <Paper>
                <ClickAwayListener onClickAway={handleClose}>{menuList}</ClickAwayListener>
            </Paper>
        </Popper>
    );

    return (
        <>
            {showButtonGroup()}
            {popper}
        </>
    );
}
export default SplitButtonComponent;
