import * as React from 'react';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import { ComponentBase } from 'resub';
import Dashlet from 'components/dashboard/Dashlet';
import { Link as RouterLink } from 'react-router-dom';
import { DefaultDashletProperties } from 'model/DashletProperties';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import { Company } from 'model/Company';
import { CompanyStore } from 'store';
import RequestDashletList from 'components/dashboard/marketplace/request/RequestDashletList';
import { withTranslation, WithTranslation } from 'react-i18next';
import { theme } from 'style/NearbuyStyle';
import MarketItemDialog from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import Button from '@mui/material/Button';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        addButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            right: theme.spacing(2),
            bottom: theme.spacing(4),
            padding: 10,
            position: 'absolute',
        },
    });

type MyRequestsDashletProps = WithStyles<typeof _styles> & DefaultDashletProperties & WithTranslation;

interface MyRequestsDashletState {
    dialogOpen: boolean;
    company: Company;
}

class MyRequestsDashlet extends ComponentBase<MyRequestsDashletProps, MyRequestsDashletState> {
    protected _buildState(
        props: MyRequestsDashletProps,
        initialBuild: boolean,
        incomingState?: Readonly<MyRequestsDashletState>,
    ): Partial<MyRequestsDashletState> | undefined {
        const newState: Partial<MyRequestsDashletState> = {};
        if (initialBuild) {
            newState.dialogOpen = false;
        }
        if (!newState.company) {
            newState.company = CompanyStore.getSelected();
        }
        return newState;
    }

    render(): React.ReactElement | null {
        return (
            <>
                {this.state.dialogOpen && (
                    <MarketItemDialog
                        isOpen
                        onClose={(): void => this.setState({ dialogOpen: false })}
                        companyRef={this.state.company.links.self}
                        onDelete={(): void => this.setState({ dialogOpen: false })}
                        isReadOnly={false}
                        requestRef={null}
                        calledFrom={'MyRequestsDashlet'}
                    />
                )}
                <Dashlet {...this.props.dashletProperties}>
                    <RequestDashletList companyRef={this.state.company.links.self} />
                    <Button
                        size={'medium'}
                        variant={'text'}
                        component={RouterLink}
                        onClick={() => captureWebEvent('request-dashlet-fullView-button')}
                        to={`/marketplace/${this.state.company.id}/requests`}
                        sx={{
                            fontWeight: 600,
                            padding: `${theme.spacing(0)} ${theme.spacing(1)}`,
                            borderRadius: '8px',
                            color: theme.palette.primary.dark,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.lighter,
                                color: theme.palette.primary.dark,
                            },
                        }}
                    >
                        {this.props.t('common:linkToFullView')}
                    </Button>
                    <IconButton
                        id="add-request-dialog-open-button"
                        className={this.props.classes.addButton}
                        onClick={() => {
                            captureWebEvent('request-dashlet-add-button');
                            this.setState({ dialogOpen: true });
                        }}
                        size="large"
                    >
                        <AddIcon />
                    </IconButton>
                </Dashlet>
            </>
        );
    }
}

export default withTranslation('common')(withStyles(_styles, { withTheme: true })(MyRequestsDashlet));
