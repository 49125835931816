export class Ingredient {
    constructor(
        public percentage: number | string | null,
        public isMainIngredient: boolean,
        public links: IngredientLinks,
    ) {}
}

export class IngredientLinks {
    constructor(public product?: string) {}
}

export class IngredientWriteView {
    constructor(
        public productRef: string,
        public isMainIngredient: boolean,
        public percentage?: number,
    ) {}
}
