import { Moment } from 'moment';
import { RecurringOrder } from 'model/RecurringOrder';

export enum RecurringOrderType {
    RECURRING_ORDER_REQUESTS = 'RECURRING_ORDER_REQUESTS',
    RECURRING_ORDER_PURCHASE = 'RECURRING_ORDER_PURCHASE',
}

export enum RecurringOrderTab {
    ACTIVE = 'ACTIVE',
    TERMINATED = 'TERMINATED',
}

export interface RecurringOrderTableFilter {
    recurringOrderType: RecurringOrderType;
    dateFrom: Moment | null;
    dateUntil: Moment | null;
    tab: RecurringOrderTab;
    areOnlyCurrentUserResultsShown: boolean;
    searchString: string;
}

export interface RecurringOrderTableItems {
    toShow: RecurringOrder[];
    all: RecurringOrder[] | undefined;
}
