import { useTheme } from '@mui/styles';
import * as React from 'react';
import Box from '@mui/material/Box';

export const VerticalStepConnector = () => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                borderLeftWidth: 1,
                borderLeftStyle: 'solid',
                borderLeftColor: theme.palette.grey['300'],
                height: 40,
                marginLeft: 3,
            }}
        />
    );
};
