import http from 'api/http';
import { Enumber } from 'model/Additive';
import { ProductDataSheet, ProductDataSheetListView, ProductDataSheetWriteView } from 'model/ProductDataSheet';
import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ProductDataSheetListStore, ProductDataSheetStore } from 'store';
import { getUuidFromString } from 'util/helpers';

class ProductDataSheetService {
    public listProductDataSheets(companyRef: string): Observable<ProductDataSheetListView[]> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) return of([]);
        else
            return http
                .getAxios()
                .get<ProductDataSheetListView[]>(`/product_data_sheets`, { headers: { 'Current-Company': companyId } })
                .pipe(map((response) => response.data));
    }

    public addProductDataSheet(productDataSheetWriteView: ProductDataSheetWriteView): Observable<ProductDataSheet> {
        return http
            .getAxios()
            .post<ProductDataSheet>('/product_data_sheets', productDataSheetWriteView)
            .pipe(
                map((response) => response.data),
                tap((pds) => {
                    ProductDataSheetStore.loadOne(pds.links.self);
                }),
            );
    }

    public addContainerToProductDataSheet(pdsRef: string, containerRef: string): Observable<ProductDataSheet> {
        return http
            .getAxios()
            .post<number>(pdsRef + '/containers', containerRef)
            .pipe(
                map((value) => value.data),
                switchMap(() => ProductDataSheetStore.loadOne(pdsRef)),
            );
    }

    public getProductDataSheet(productDataSheetRef: string): Observable<ProductDataSheet> {
        return http
            .getAxios()
            .get<ProductDataSheet>(productDataSheetRef)
            .pipe(map((value) => value.data));
    }

    public updateProductDataSheet(
        productDataSheetRef: string,
        productDataSheetWriteView: ProductDataSheetWriteView,
    ): Observable<ProductDataSheet> {
        return http
            .getAxios()
            .put<ProductDataSheet>(productDataSheetRef, productDataSheetWriteView)
            .pipe(
                map((response) => response.data),
                tap((pds) => ProductDataSheetStore.setOne(pds)),
            );
    }

    public deleteProductDataSheet(productDataSheetRef: string): Observable<number> {
        return http
            .getAxios()
            .delete<number>(productDataSheetRef)
            .pipe(
                map((value) => value.data),
                tap((_) => {
                    const pdsId = getUuidFromString(productDataSheetRef);
                    pdsId && ProductDataSheetStore.removeOneById(pdsId);
                    ProductDataSheetListStore.clear();
                }),
            );
    }

    public listEnumbersWithValidUsage(): Observable<Enumber[]> {
        return http
            .getAxios()
            .get<Enumber[]>('/product_data_sheets/enumbers')
            .pipe(map((response) => response.data));
    }
}

export default new ProductDataSheetService();
