import { WithStyles } from '@mui/styles';
import { Box, Switch, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CompanyStore, PersonStore, UserSettingsStore } from 'store';
import NearbuyTabsWindow from 'components/shared/NearbuyTabsWindowOld';
import { getSortSettingPrefixAndValue } from 'util/TableHelpers';
import { SettingsKey } from 'model/UserSettings';
import * as React from 'react';
import { ReactFragment, ReactNode } from 'react';
import { ComponentBase } from 'resub';
import {
    RecurringOrderTab,
    RecurringOrderTableFilter,
    RecurringOrderTableItems,
    RecurringOrderType,
} from 'components/marketplace/recurring-order/RecurringOrderType';
import {
    applyFilter,
    composeMaps,
    getActiveRecurringNavBarTitleLocaleTag,
    getInfoTextLocaleTag,
    getRecurringOrderPlaceHolderLocaleTag,
    getRecurringOrderType,
    getRecurringSortSettingKey,
    getRecurringSwitchSettingKey,
    getTradeRole,
    setRowData,
    sortItems,
} from 'components/marketplace/recurring-order/RecurringOrderTableHelpers';
import Grid from '@mui/material/Grid';
import { theme } from 'style/NearbuyStyle';
import TextField from '@mui/material/TextField';
import { captureWebEvent } from 'util/AnalyticUtils';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import { Moment } from 'moment';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TradeTableHeaderSelect from 'components/marketplace/tradetable/TradeTrableHeaderSelect';
import RecurringOrderTableRow, {
    RecurringOrderTableRowData,
} from 'components/marketplace/recurring-order/RecurringOrderTableRow';
import TableBody from '@mui/material/TableBody';
import RecurringOrderListStore from 'store/RecurringOrderListStore';
import Table from '@mui/material/Table';
import UserSettingsService from 'api/UserSettingsService';
import withStyles from '@mui/styles/withStyles';
import { NearbuyLoadingIndicator } from 'components/shared/NearbuyLoadingIndicator';
import { SelectChangeEvent } from '@mui/material/Select';

const styles = (theme: Theme) =>
    createStyles({
        searchBar: {
            width: '100%',
            marginTop: 0,
            '& .MuiOutlinedInput-root': {
                paddingLeft: theme.spacing(1),
            },
        },
        div: {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: theme.spacing(3),
            paddingTop: theme.spacing(3),
        },
        searchIcon: {
            color: theme.palette.primary.dark,
        },
        tableHeader: {
            backgroundColor: theme.palette.background.default,
            borderRadius: 8,
        },
        tableHeaderCell: {
            color: theme.palette.text.primary,
            fontWeight: 'bold',
            padding: theme.spacing(2),
        },
    });

interface RecurringOrderTableComponentProps extends WithStyles<typeof styles>, WithTranslation, RouteComponentProps {}

export interface RecurringOrderTableComponentState {
    userRef: string;
    ownCompanyRef: string;
    items: RecurringOrderTableItems;
    filter: RecurringOrderTableFilter;
    initialBuild: boolean;
    currentSwitchSettingsKey: SettingsKey;
    currentSortSettingsKey: SettingsKey;
    currentSortSetting: string | null | undefined;
    currentSortSettingPrefix: string | undefined;
    currentSortSettingValue: string | undefined;
    isInitialFetchingComplete: boolean;
    rowDataMap: Map<string, RecurringOrderTableRowData>;
    itemRefToTranslatedProductMap: Map<string, string>;
    itemRefToCompanyNameMap: Map<string, string>;
    refToSearchStrings: Map<string, string[]>;
    itemRefToResponsiblePersonNameMap: Map<string, string>;
}

class RecurringOrderTableComponent extends ComponentBase<
    RecurringOrderTableComponentProps,
    RecurringOrderTableComponentState
> {
    protected _buildState(
        props: RecurringOrderTableComponentProps,
        initialBuild: boolean,
        incomingState: Readonly<RecurringOrderTableComponentState> | undefined,
    ): Partial<RecurringOrderTableComponentState> | undefined {
        const newState: Partial<RecurringOrderTableComponentState> = {
            ...incomingState,
            ownCompanyRef: CompanyStore.getSelected()?.links.self,
            userRef: PersonStore.getSelected()?.links.self,
        };

        if (initialBuild) {
            newState.initialBuild = initialBuild;
            RecurringOrderListStore.invalidateCache();
            newState.filter = {
                recurringOrderType: RecurringOrderType.RECURRING_ORDER_PURCHASE,
                dateFrom: null,
                dateUntil: null,
                tab: RecurringOrderTab.ACTIVE,
                areOnlyCurrentUserResultsShown: false,
                searchString: '',
            };
            newState.items = {
                toShow: [],
                all: [],
            };
            newState.rowDataMap = new Map<string, RecurringOrderTableRowData>();
            newState.isInitialFetchingComplete = false;
        }

        if (!newState.items || !newState.filter || !newState.ownCompanyRef || !newState.userRef) return newState;

        const oldTradeType = newState.filter.recurringOrderType;
        newState.filter.recurringOrderType = getRecurringOrderType(props.location);
        if (oldTradeType != newState.filter.recurringOrderType) {
            newState.filter.tab = RecurringOrderTab.ACTIVE;
        }

        if (newState.filter.recurringOrderType) {
            newState.currentSwitchSettingsKey = getRecurringSwitchSettingKey(newState.filter.recurringOrderType);

            newState.filter.areOnlyCurrentUserResultsShown =
                UserSettingsStore.getOne(newState.currentSwitchSettingsKey)?.settings == 'true';

            newState.currentSortSettingsKey = getRecurringSortSettingKey(newState.filter);
            newState.currentSortSetting = UserSettingsStore.getOne(newState.currentSortSettingsKey)?.settings?.replace(
                /['"]+/g,
                '',
            );

            [newState.currentSortSettingPrefix, newState.currentSortSettingValue] = getSortSettingPrefixAndValue(
                newState.currentSortSetting,
            );
        }

        newState.items.all = RecurringOrderListStore.get(newState.ownCompanyRef);

        setRowData(newState);
        composeMaps(newState, this.props.t);

        newState.items.toShow = applyFilter(
            newState.items,
            newState.filter,
            newState.ownCompanyRef,
            newState.userRef,
            newState.refToSearchStrings,
        );

        newState.items.toShow = sortItems(
            newState.items.toShow,
            newState.currentSortSettingPrefix,
            newState.currentSortSettingValue,
            newState.itemRefToCompanyNameMap,
            newState.itemRefToTranslatedProductMap,
            newState.itemRefToResponsiblePersonNameMap,
        );

        return newState;
    }

    showTableRows(): ReactFragment | null {
        return this.state.items.toShow.map((item, index) => {
            if (item) {
                const rowData = this.state.rowDataMap.get(item.links.self);

                if (rowData) {
                    return (
                        <RecurringOrderTableRow
                            key={`${index}_${item.links.self}`}
                            styling={
                                index % 2
                                    ? { background: theme.palette.background.default }
                                    : { background: theme.palette.common.white }
                            }
                            rowData={rowData}
                            role={getTradeRole(item, this.state.ownCompanyRef)}
                            recurringOrder={item}
                        />
                    );
                }
            }
            return null;
        });
    }

    handleSearchBarInput(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        this.setState((prevState) => {
            return {
                filter: {
                    ...prevState.filter,
                    searchString: event.target.value ?? '',
                },
            };
        });
    }

    showSearchBar(): ReactNode {
        return (
            <Grid item md={2.5}>
                <TextField
                    placeholder={this.props.t('common:search')}
                    className={this.props.classes.searchBar}
                    size={'small'}
                    onClick={() => {
                        captureWebEvent('my_market-recurringOrderTable-enter-search-field');
                    }}
                    onChange={this.handleSearchBarInput.bind(this)}
                    InputProps={{
                        startAdornment: (
                            <IconButton
                                sx={{
                                    backgroundColor: 'transparent',
                                    paddingLeft: 0,
                                    paddingRight: theme.spacing(0.5),
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <SearchIcon className={this.props.classes.searchIcon} />
                            </IconButton>
                        ),
                    }}
                />
            </Grid>
        );
    }

    handleShowResultsByCurrentUserChange(checked: boolean): void {
        UserSettingsService.saveSetting(this.state.currentSwitchSettingsKey, checked).subscribe(() => {
            UserSettingsStore.invalidateCache(this.state.currentSwitchSettingsKey);
            this.setState((prevState) => {
                return {
                    filter: {
                        ...prevState.filter,
                        areOnlyCurrentUserResultsShown: checked,
                    },
                };
            });
        });
    }

    handleDateFromChange(date: Moment | null): void {
        if (date === null || date.isValid()) {
            captureWebEvent('my_market-recurringOrderTable-dateFrom-change');
            this.setState({ filter: { ...this.state.filter, dateFrom: date } });
        }
    }

    handleDateEndChange(date: Moment | null): void {
        if (date === null || date.isValid()) {
            captureWebEvent('my_market-recurringOrderTable-dateEnd-change');
            this.setState({ filter: { ...this.state.filter, dateUntil: date } });
        }
    }

    handleSortSettingChange(event: SelectChangeEvent) {
        const sortValue = [event.target.name, event.target.value].join('_');

        UserSettingsService.saveSetting(
            this.state.currentSortSettingsKey,
            event.target.value != 'NONE' ? sortValue : null,
        ).subscribe(() => {
            UserSettingsStore.invalidateCache(this.state.currentSortSettingsKey);
            this.setState({
                currentSortSetting: event.target.value != 'NONE' ? sortValue : null,
            });
        });
    }

    showDatePicker(): ReactNode {
        return (
            <>
                <Grid item md={2.5} container sx={{ paddingBottom: theme.spacing(0.75) }}>
                    <StyledDatePickerComponent
                        label={this.props.t('recurringOrder:from')}
                        value={this.state.filter.dateFrom}
                        onChange={(date): void => this.handleDateFromChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                inputProps={{ ...props.inputProps, readOnly: true }}
                                fullWidth
                                sx={{ button: { backgroundColor: 'transparent' } }}
                            />
                        )}
                        maxDate={this.state.filter.dateUntil}
                        disableManualInput
                        shouldShowClearButton
                    />
                </Grid>
                <Grid item md={2.5} container sx={{ paddingBottom: theme.spacing(0.75) }}>
                    <StyledDatePickerComponent
                        label={this.props.t('recurringOrder:until')}
                        value={this.state.filter.dateUntil}
                        onChange={(date) => this.handleDateEndChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                inputProps={{ ...props.inputProps, readOnly: true }}
                                fullWidth
                                sx={{ button: { backgroundColor: 'transparent' } }}
                            />
                        )}
                        minDate={this.state.filter.dateFrom}
                        disableManualInput
                        shouldShowClearButton
                    />
                </Grid>
            </>
        );
    }

    handleTabChange = () => {
        this.setState((prevState) => {
            return {
                filter: {
                    ...prevState.filter,
                    tab:
                        prevState.filter.tab === RecurringOrderTab.ACTIVE
                            ? RecurringOrderTab.TERMINATED
                            : RecurringOrderTab.ACTIVE,
                },
            };
        });
    };

    showSwitch(): ReactFragment {
        return (
            <Grid item container md={4.5} sx={{ mb: 1 }} alignItems="center">
                <Grid item md={2.5}>
                    <Switch
                        checked={this.state.filter.areOnlyCurrentUserResultsShown ?? false}
                        onChange={(event) => this.handleShowResultsByCurrentUserChange(event.target.checked)}
                    />
                </Grid>
                <Grid item md={9.5}>
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {this.props.t('recurringOrder:recurringOrderTableSwitchHint')}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    showHeaderActions(): ReactFragment {
        return (
            <Grid container md={12} className={this.props.classes.div} direction={'row'}>
                <Grid container item md={12} spacing={theme.spacing(1)} alignItems={'center'}>
                    {this.showSearchBar()}
                    {this.showDatePicker()}
                    {this.showSwitch()}
                </Grid>
            </Grid>
        );
    }

    showTitleRow() {
        const titles: (string | undefined)[] = [];

        const productTitle = this.props.t('common:product');
        const firstDeliveryTitle = this.props.t('offer:firstDelivery');
        const dateTitle = this.props.t('offer:dateRequestOrder');
        const companyTitle = this.props.t('common:COMPANY');
        const statusTitle = this.props.t('common:status');
        const isRequest = getRecurringOrderType(this.props.location) === RecurringOrderType.RECURRING_ORDER_REQUESTS;
        const responsiblePersonTitle = this.props.t('company:responsiblePerson');
        const amountPerDelivery = this.props.t('offer:amountPerDelivery');
        const deliveryCycle = this.props.t('offer:deliveryCycle');
        const pricePerDelivery = this.props.t('offer:pricePerDelivery');

        titles.push(productTitle);
        titles.push(amountPerDelivery);
        titles.push(firstDeliveryTitle);
        titles.push(deliveryCycle);
        titles.push(isRequest ? this.props.t('offer:endOfDelivery') : this.props.t('offer:lastShipment'));
        titles.push(pricePerDelivery);
        titles.push(dateTitle);
        titles.push(companyTitle);
        titles.push(statusTitle);
        titles.push(responsiblePersonTitle);
        titles.push(undefined);

        return (
            <TableRow>
                {titles.map((title, index) => {
                    let name = 'DEFAULT';
                    let onlyTitle = false;
                    let options: string[] = ['ASC', 'DESC', 'NONE'];

                    switch (title) {
                        case productTitle:
                            name = 'PRODUCT';
                            break;
                        case firstDeliveryTitle:
                            name = 'DELIVERY';
                            break;
                        case dateTitle:
                            name = 'DATE';
                            break;
                        case companyTitle:
                            name = 'COMPANY';
                            break;
                        case statusTitle:
                            name = 'STATUS';
                            options =
                                this.state.filter.tab == 'ACTIVE'
                                    ? ['PENDING', 'ACCEPTED', 'ORDERED', 'INVOICE_PRESENT', 'NONE']
                                    : ['CANCELED', 'REJECTED', 'NONE'];
                            break;
                        case responsiblePersonTitle:
                            name = 'PERSON';
                            break;
                        default:
                            onlyTitle = true;
                    }

                    return (
                        <TableCell className={this.props.classes.tableHeaderCell} key={index}>
                            {onlyTitle ? (
                                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{title}</Typography>
                            ) : (
                                <TradeTableHeaderSelect
                                    name={name}
                                    title={title}
                                    options={options}
                                    currentSortSettingPrefix={this.state.currentSortSettingPrefix}
                                    currentSortSettingValue={this.state.currentSortSettingValue}
                                    handleSortSettingChange={this.handleSortSettingChange.bind(this)}
                                    isUserBuyer={!isRequest}
                                    tradeItemType={'RecurringOrder'}
                                />
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    showPlaceHolderText(): ReactNode {
        const { searchString, dateFrom, dateUntil, areOnlyCurrentUserResultsShown } = this.state.filter;
        const { currentSortSettingPrefix, currentSortSettingValue } = this.state;
        const isFilteredByStatus = currentSortSettingPrefix === 'STATUS' && currentSortSettingValue != 'NONE';
        return (
            <Typography
                sx={{
                    padding: '3%',
                    fontSize: 20,
                    fontWeight: 'bold',
                    zIndex: 99,
                    textAlign: 'center',
                    color: theme.palette.primary.dark,
                }}
                data-testid={'empty-state-text'}
            >
                {searchString || dateFrom || dateUntil || areOnlyCurrentUserResultsShown || isFilteredByStatus
                    ? this.props.t('sales:emptyTableBySearch')
                    : this.props.t(getRecurringOrderPlaceHolderLocaleTag(this.state.filter))}
            </Typography>
        );
    }

    render(): React.ReactNode {
        return (
            <NearbuyTabsWindow
                maxWidth={false}
                title={this.props.t(getActiveRecurringNavBarTitleLocaleTag(this.state.filter.recurringOrderType))}
                titles={[`${this.props.t('common:active')}`, `${this.props.t('common:terminated')}`]}
                handleChange={this.handleTabChange}
                activeTabIndex={this.state.filter.tab == RecurringOrderTab.ACTIVE ? 0 : 1}
            >
                <Grid container item md={12}>
                    <Grid item md={10}>
                        <Typography>{this.props.t(getInfoTextLocaleTag(this.state.filter.tab))}</Typography>
                    </Grid>
                    {this.showHeaderActions()}
                    <Box
                        sx={{
                            minHeight: '20vh',
                            maxHeight: '64vh',
                            width: '100%',
                            overflow: 'auto',
                            borderRadius: '12px',
                        }}
                    >
                        <Table
                            sx={{
                                tableLayout: 'auto',
                            }}
                        >
                            <TableHead className={this.props.classes.tableHeader}>{this.showTitleRow()}</TableHead>
                            <TableBody>{this.showTableRows()}</TableBody>
                        </Table>
                        {!this.state.items.all ? (
                            <Grid container justifyContent={'center'} alignContent={'center'} sx={{ padding: '3%' }}>
                                <NearbuyLoadingIndicator />
                            </Grid>
                        ) : !this.state.items.toShow.length ? (
                            this.showPlaceHolderText()
                        ) : null}
                    </Box>
                </Grid>
            </NearbuyTabsWindow>
        );
    }
}

export default withTranslation(['recurringOrder', 'common'])(
    withStyles(styles, { withTheme: true })(withRouter(RecurringOrderTableComponent)),
);
