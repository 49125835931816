import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { FormHelperText, Grid, TextField, Theme } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { StepOfferComponentProps } from 'components/search/result-items/offers/OfferDialogStructure';
import 'moment/locale/de';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
    helperText: {
        color: theme.palette.primary.dark,
        fontSize: '0.6rem',
        fontWeight: 'bold',
        paddingLeft: theme.spacing(2),
        marginTop: '-4px',
    },
}));

export default interface OfferStepProductDescriptionProps {
    description?: string;
}

export const OfferStepProductDescription = (props: StepOfferComponentProps<OfferStepProductDescriptionProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['offerDialog']);
    const classes = useStyles();

    const showTitle = (): ReactNode => (
        <Grid container sx={{ paddingBottom: theme.spacing(4) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>{t('offerDialog:productDescription')}</Typography>
        </Grid>
    );

    const showDescriptionHint = (): ReactNode => <Typography>{t('offerDialog:productDescriptionHint')}</Typography>;

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        props.setData('OfferStepProductDescription', {
            description: event.target.value,
        });
    };

    const showProductDescriptionTextField = (): ReactNode => (
        <Grid item container xs={12}>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight: 600 }}>{t('offerDialog:productDescription')}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '8px' }}>
                {showDescriptionHint()}
            </Grid>
            <Grid item xs={12} sx={{ marginTop: '8px' }}>
                <TextField
                    value={props.data.description}
                    id="product-description"
                    multiline={true}
                    placeholder={t('offerDialog:productDescriptionPlaceholder')}
                    label={t('offerDialog:productDescription')}
                    InputLabelProps={{
                        style: {
                            whiteSpace: 'pre-wrap',
                            fontSize: '1em',
                        },
                        margin: 'dense',
                    }}
                    InputProps={{
                        style: {
                            paddingTop: 10,
                        },
                        inputProps: {
                            maxLength: 2000,
                        },
                    }}
                    rows={4}
                    type={'text'}
                    onChange={handleDescriptionChange}
                    fullWidth
                />
                <FormHelperText className={classes.helperText}>
                    {t('offerDialog:productDescriptionFormHelper')}
                </FormHelperText>
            </Grid>
        </Grid>
    );

    return (
        <Grid container direction={'column'} sx={{ paddingLeft: theme.spacing(5) }}>
            {showTitle()}
            {showProductDescriptionTextField()}
        </Grid>
    );
};
