import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
    isGtinValid,
} from 'components/productdatasheet/ProductDataSheetHelpers';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { ProductNumber } from 'model/ProductNumber';
import * as React from 'react';
import { ChangeEvent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface StepProductNumberProps {
    productNumbers?: ProductNumberWithKey[];
    formError?: boolean;
}

export interface ProductNumberWithKey {
    productNumber: ProductNumber;
    key: number;
}

export const StepProductNumber = (props: StepComponentProps<StepProductNumberProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['dialogs', 'productDataSheet']);
    const initialOptions = [
        t('productDataSheet:DATASTEP_PRODUCT_ID_GTIN'),
        t('productDataSheet:DATASTEP_PRODUCT_ID_NUMBER'),
    ];
    const [options, setOptions] = useState<string[]>(initialOptions);

    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleSelectChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: number) => {
        const selectedType = event.target.value;
        const prevDataArray = props.data.productNumbers;
        props.setData('StepProductNumber', {
            productNumbers: prevDataArray?.map((pn) =>
                pn.key === key ? { ...pn, productNumber: { ...pn.productNumber, type: selectedType } } : pn,
            ),
        });
    };

    const handleNumberInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: number) => {
        const selectedNumber = event.target.value;
        const prevDataArray = props.data.productNumbers;
        props.setData('StepProductNumber', {
            productNumbers: prevDataArray?.map((pn) =>
                pn.key === key ? { ...pn, productNumber: { ...pn.productNumber, number: selectedNumber } } : pn,
            ),
        });
    };

    const handleDelete = (key: number) => {
        props.setData('StepProductNumber', {
            productNumbers: props.data.productNumbers?.filter((pn) => pn.key !== key),
        });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_PRODUCT_ID_TITLE')}
            </Typography>
        </Grid>
    );

    const addButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    !props.data.productNumbers ||
                    props.data.productNumbers.some((pn) => !pn.productNumber.type || !pn.productNumber.number)
                }
                onClick={() => {
                    const productNumbers = props.data.productNumbers ?? [];
                    productNumbers.push({
                        key: Math.random(),
                        productNumber: new ProductNumber('', ''),
                    });
                    props.setData('StepProductNumber', { productNumbers });
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_PRODUCT_ID_TEXT')}</Typography>
        </Grid>
    );

    const selectAndInput = (productNumber: ProductNumberWithKey): ReactNode => {
        const autoCompleteValue: string | null = productNumber.productNumber.type
            ? productNumber.productNumber.type
            : null;
        const autoCompleteOptions: (string | null)[] = new Array<string | null>();
        if (inputValue && !options.includes(inputValue)) {
            autoCompleteOptions.push(inputValue);
            options.forEach((o) => autoCompleteOptions.push(o));
        } else {
            options.forEach((o) => autoCompleteOptions.push(o));
        }

        return (
            <Grid container sx={{ justifyContent: 'space-between', alignItems: 'flex-start' }} key={productNumber.key}>
                <Grid item md={5.5}>
                    <Autocomplete
                        sx={getProductDataSheetDialogAutoCompleteSX()}
                        getOptionLabel={(option) =>
                            option
                                ? !initialOptions.includes(option) && option != autoCompleteValue
                                    ? `${option} ${t('productDataSheet:create')}`
                                    : option
                                : ''
                        }
                        filterOptions={(options) =>
                            options.filter(
                                (option) =>
                                    option &&
                                    !props.data.productNumbers?.map((it) => it.productNumber.type).includes(option),
                            )
                        }
                        noOptionsText={t('productDataSheet:noProductNumberOptions')}
                        options={autoCompleteOptions}
                        value={autoCompleteValue}
                        onChange={(_, newValue) => {
                            newValue ? setInputValue(newValue) : setOptions(initialOptions);
                            handleSelectChange(
                                { target: { value: newValue } } as ChangeEvent<HTMLInputElement>,
                                productNumber.key,
                            );
                        }}
                        onInputChange={(_, newValue) => handleInputChange(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={t('productDataSheet:DATASTEP_PRODUCT_ID_TYPE')}
                                fullWidth
                                sx={getAutoCompleteTextFieldSX()}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={5.5}>
                    <TextField
                        label={t('productDataSheet:DATASTEP_PRODUCT_ID_NUMBER')}
                        value={productNumber.productNumber.number ?? ''}
                        onChange={(event) => {
                            handleNumberInputChange(event, productNumber.key);
                        }}
                        fullWidth
                        error={
                            productNumber.productNumber.number !== '' &&
                            productNumber.productNumber.type === t('productDataSheet:DATASTEP_PRODUCT_ID_GTIN')
                                ? !isGtinValid(productNumber.productNumber.number)
                                : false
                        }
                        helperText={
                            productNumber.productNumber.number !== '' &&
                            !isGtinValid(productNumber.productNumber.number) &&
                            productNumber.productNumber.type === t('productDataSheet:DATASTEP_PRODUCT_ID_GTIN')
                                ? t('productDataSheet:gtinNotValid')
                                : undefined
                        }
                    />
                </Grid>
                <Grid item sx={{ paddingTop: theme.spacing(0.5) }}>
                    <IconButton onClick={() => handleDelete(productNumber.key)}>
                        <Delete />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const showSelectAndInput = (): ReactNode => {
        return props.data.productNumbers?.map((pn) => {
            return selectAndInput(pn);
        });
    };

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showSelectAndInput()}
            {addButton()}
        </Grid>
    );
};
