import {
    MarketItemDialogProperties,
    MarketItemDialogState,
} from 'components/marketplace/marketitem/dialog/MarketItemDialog';
import OfferService from 'api/OfferService';
import { GraduatedPrice, Offer, OfferWriteView } from 'model/Offer';
import RequestService from 'api/RequestService';
import { Subscription } from 'rxjs';
import ContainerService from 'api/ContainerService';
import { ContainerWriteView } from 'model/ContainerView';
import { RequestWriteView } from 'model/Request';
import { NumberFormatValues } from 'react-number-format';
import * as React from 'react';
import moment, { Moment } from 'moment/moment';
import { MarketItemDialogHelpers } from 'components/marketplace/marketitem/dialog/MarketItemDialogHelpers';
import { OfferStore } from 'store';
import { Unit } from 'model/Unit';
import { captureMultipleEvents, captureWebEvent, EventParam, getDifferingKeys } from 'util/AnalyticUtils';

export default class MarketItemDialogHandlers {
    constructor(
        private props: MarketItemDialogProperties,
        private state: MarketItemDialogState,
        private setState: <K extends keyof MarketItemDialogState>(
            state:
                | ((
                      prevState: Readonly<MarketItemDialogState>,
                      props: Readonly<MarketItemDialogProperties>,
                  ) => Pick<MarketItemDialogState, K> | MarketItemDialogState | null)
                | Pick<MarketItemDialogState, K>
                | MarketItemDialogState
                | null,
            callback?: () => void,
        ) => void,
        private helpers: MarketItemDialogHelpers,
    ) {}

    onClose = (event: any, reason: string) => {
        if (event && reason === 'backdropClick') {
            if (this.state.hasChanges) {
                this.setState({
                    isSaveLeavePreventionOpen: true,
                });
            } else {
                this.props.onClose?.();
            }
        }
    };

    onCancel = () => {
        this.props.onClose?.();
        this.setState({
            isNoMessagingInUseHintShown: false,
        });
    };

    onSave = (): void => {
        this.captureOfferAndRequestDetails();
        const afterSave = (offerRef?: string) => {
            if (offerRef) {
                this.saveNewContainers(offerRef);
                this.deleteRemovedOfferContainers();
                if (this.props.isOfferProvided && this.props.limitingProductRef && this.props.offerRef === null) {
                    OfferStore.setSelected(offerRef);
                }
            }
            this.props.onClose?.();
            this.setState({
                isSaveLeavePreventionOpen: false,
            });
        };

        if (this.state.isOffer) {
            if (!this.state.offerWriteView) return;
            const offerWriteView = {
                ...this.state.offerWriteView,
                pricePerUnit:
                    this.state.offerWriteView.pricePerUnit === 0 ? null : this.state.offerWriteView.pricePerUnit,
            };
            if (this.state.offer) {
                OfferService.updateOffer(offerWriteView, this.state.offer.id, this.props.companyRef).subscribe(
                    (offer: Offer) => afterSave(offer.links.self),
                );
            } else {
                OfferService.addOffer(offerWriteView, this.props.companyRef).subscribe((offer: Offer) =>
                    afterSave(offer.links.self),
                );
            }
        } else if (this.state.isRequest) {
            if (!this.state.requestWriteView) return;
            if (this.state.request) {
                RequestService.updateRequest(this.state.requestWriteView, this.state.request.links.self).subscribe(() =>
                    afterSave(),
                );
            } else {
                RequestService.addRequest(this.state.requestWriteView, this.props.companyRef).subscribe(() =>
                    afterSave(),
                );
            }
        }
    };

    captureOfferAndRequestEvent = (button: 'save' | 'cancel'): void => {
        const { offerRef, requestRef, calledFrom } = this.props;
        const eventPrefix = button === 'save' ? 'marketplace-edit-and-save' : 'marketplace-cancel-edit';

        const captureEvent = (type: string, isNew: boolean) => {
            const status = isNew ? 'new' : 'existing';
            captureWebEvent(`${eventPrefix}-${status}-${type}-calledFrom-${calledFrom}`);
        };

        const isNew = (ref: any) => this.state.isCreateNew && ref === null;

        if (offerRef || isNew(offerRef)) {
            captureEvent('offer', isNew(offerRef));
        }

        if (requestRef || isNew(requestRef)) {
            captureEvent('request', isNew(requestRef));
        }
    };

    captureOfferAndRequestDetails = (): void => {
        const {
            isOffer,
            isRequest,
            initialRequestWriteView,
            requestWriteView,
            initialOfferWriteView,
            offerWriteView,
            newContainers,
            containerIdsToRemove,
        } = this.state;

        const originsOffer = [
            'MyOffersDashlet',
            'OfferDashletItem-SendOffer',
            'OfferDashletItem-Dashboard',
            'ProvideOfferDialog',
            'OffersComponent',
            'OfferTableRow',
        ];
        const originsRequest = ['MyRequestsDashlet', 'RequestDashletItem', 'RequestTableRow', 'RequestsComponent'];

        const shouldTrackOffer = isOffer && originsOffer.includes(this.props.calledFrom);
        const shouldTrackRequest = isRequest && originsRequest.includes(this.props.calledFrom);
        const hasPendingContainerUpdates = !!newContainers?.length || !!containerIdsToRemove?.length;

        const captureEvents = (eventName: string, paramsList: EventParam[]) => {
            if (paramsList.length > 0) {
                captureMultipleEvents({ params: paramsList, eventName });
            }
            if (hasPendingContainerUpdates) {
                captureWebEvent(`${eventName}-container`);
            }
        };

        const trackRequest = () => {
            if (initialRequestWriteView && requestWriteView) {
                const requestWriteViewDiff = getDifferingKeys(
                    [requestWriteView, initialRequestWriteView],
                    ['description', 'dateFrom', 'dateEnd', 'levelsOfProcessing', 'totalAmount'],
                );
                const eventName = this.props.requestRef ? 'marketplace-existing-request' : 'marketplace-new-request';
                captureEvents(eventName, requestWriteViewDiff);
            }
        };

        const trackOffer = () => {
            if (initialOfferWriteView && offerWriteView) {
                const offerWriteViewDiff = getDifferingKeys(
                    [offerWriteView, initialOfferWriteView],
                    [
                        'description',
                        'dateFrom',
                        'dateEnd',
                        'levelsOfProcessing',
                        'totalAmount',
                        'minAmount',
                        'pricePerUnit',
                    ],
                );
                const eventName = this.props.offerRef ? 'marketplace-existing-offer' : 'marketplace-new-offer';
                captureEvents(eventName, offerWriteViewDiff);
            }
        };

        if (shouldTrackRequest) trackRequest();
        if (shouldTrackOffer) trackOffer();
    };
    updateOfferWriteView = (partialOfferWriteView: Partial<OfferWriteView>) => {
        if (!this.state.offerWriteView) return;
        const offerWriteView: OfferWriteView = {
            ...this.state.offerWriteView,
            ...partialOfferWriteView,
        };
        this.setState({ offerWriteView });
    };

    updateRequestWriteView = (partialRequestWriteView: Partial<RequestWriteView>) => {
        if (!this.state.requestWriteView) return;
        const requestWriteView: RequestWriteView = {
            ...this.state.requestWriteView,
            ...partialRequestWriteView,
        };
        this.setState({ requestWriteView });
    };

    handleContainerUpdate = () => {
        if (!this.state.newContainers) return;
        this.setState({
            newContainers: [...this.state.newContainers],
        });
    };

    saveNewContainers = (offerRef: string): Subscription | undefined => {
        if (!this.state.newContainers) return undefined;
        this.state.newContainers.map((container) => {
            if (!container) return undefined;
            return ContainerService.addOfferContainer(
                this.props.companyRef,
                new ContainerWriteView(container.containerType, container.amount, container.unit),
                offerRef,
            ).subscribe();
        });
    };

    addNewContainer = () => {
        if (!this.state.offerWriteView || !this.state.newContainers) return;
        const unit = this.state.offerWriteView.totalAmount.unit;
        if (unit) {
            this.setState({
                newContainers: [...this.state.newContainers, new ContainerWriteView('', 1, unit)],
            });
        }
    };

    removeNewContainer = (container: ContainerWriteView) => {
        this.setState({
            newContainers: this.state.newContainers!.filter((c) => c !== container),
        });
    };

    removeContainerById = (containerId: string) => {
        if (!this.state.containerIdsToRemove) return;

        this.setState({
            containerIdsToRemove: [...this.state.containerIdsToRemove, containerId],
        });
    };

    deleteRemovedOfferContainers = () => {
        if (!this.state.containerIdsToRemove?.length) return;
        this.state.containerIdsToRemove.map((containerId) => {
            OfferService.deleteContainerByOffer(this.props.offerRef!, containerId).subscribe();
        });
    };

    updateGraduatedPrice = (graduatedPrice: GraduatedPrice) => {
        if (!this.state.offerWriteView) return;
        const graduatedPrices = [...(this.state.offerWriteView.graduatedPrices ?? [])];
        const index = graduatedPrices.findIndex((gp) => gp === graduatedPrice);
        if (index != -1) {
            graduatedPrices[index] = graduatedPrice;
            this.updateOfferWriteView({ graduatedPrices });
        }
    };

    removeGraduatedPrice = (graduatedPrice: GraduatedPrice) => {
        if (!this.state.offerWriteView) return;
        this.updateOfferWriteView({
            graduatedPrices: (this.state.offerWriteView.graduatedPrices ?? []).filter((gp) => gp !== graduatedPrice),
        });
    };

    addGraduatedPrices = () => {
        if (!this.state.offerWriteView) return;
        this.updateOfferWriteView({
            graduatedPrices: [...(this.state.offerWriteView.graduatedPrices ?? []), new GraduatedPrice(0, 1)],
        });
    };

    priceOnRequestChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        this.updateOfferWriteView({
            pricePerUnit: checked ? null : 1,
        });
        this.setState({
            isPriceUponRequest: checked,
        });
    };

    updateLevelsOfProcessing = (levelsOfProcessing: string[] | undefined) => {
        if (!levelsOfProcessing) return;
        if (this.state.isOffer) {
            this.updateOfferWriteView({ levelsOfProcessing });
        } else {
            this.updateRequestWriteView({ levelsOfProcessing });
        }
    };

    onSelectedContactRefChange = (contactRef: string | null | undefined, automatic?: boolean) => {
        this.setState({
            isNoMessagingInUseHintShown: false,
        });
        const contact = contactRef !== undefined && contactRef !== '' ? contactRef : null;
        if (automatic) {
            this.setState({
                automaticallySetContact: contactRef ?? null,
            });
        }
        if (this.state.isOffer) {
            this.updateOfferWriteView({ contact });
        } else {
            this.updateRequestWriteView({ contact });
        }
    };

    onContactSelectComponentHiddenChange = (isShown: boolean) => {
        this.setState({
            isContactComponentShowing: isShown,
        });
    };

    removeLevelOfProcessing = (toRemoveLopRef: string) => {
        if (this.state.isOffer) {
            if (!this.state.offerWriteView?.levelsOfProcessing?.length) return;
            this.updateOfferWriteView({
                levelsOfProcessing: this.state.offerWriteView.levelsOfProcessing.filter(
                    (lopRef) => lopRef !== toRemoveLopRef,
                ),
            });
        } else {
            if (!this.state.requestWriteView?.levelsOfProcessing.length) return;
            this.updateRequestWriteView({
                levelsOfProcessing: this.state.requestWriteView.levelsOfProcessing.filter(
                    (lopRef) => lopRef !== toRemoveLopRef,
                ),
            });
        }
    };

    handleTotalAmountAmountChange = (numbers: NumberFormatValues) => {
        const totalAmount = this.helpers.getTotalAmount();
        if (!totalAmount) return;
        if (this.state.isOffer) {
            this.updateOfferWriteView({
                totalAmount: {
                    ...totalAmount,
                    amount: numbers.floatValue !== undefined ? numbers.floatValue : 0,
                },
            });
        } else {
            this.updateRequestWriteView({
                totalAmount: {
                    ...totalAmount,
                    amount: numbers.floatValue !== undefined ? numbers.floatValue : 0,
                },
            });
        }
    };

    handleTotalAmountUnitChange = (unit: Unit) => {
        const totalAmount = this.helpers.getTotalAmount();
        const minAmount = this.helpers.getMinAmount();
        const minAmountUnit = this.helpers.getMinAmountUnit();

        if (this.state.isOffer && totalAmount && minAmount) {
            this.updateOfferWriteView({
                totalAmount: {
                    ...totalAmount,
                    unit: unit.unit,
                },
                minAmount: {
                    ...minAmount,
                    unit: minAmountUnit
                        ? minAmountUnit.unitType == unit.unitType
                            ? minAmountUnit.unit
                            : unit.unit
                        : unit.unit,
                },
            });
        } else if (totalAmount) {
            this.updateRequestWriteView({
                totalAmount: {
                    ...totalAmount,
                    unit: unit.unit,
                },
            });
        }
    };

    handleMinAmountAmountChange = (numbers: NumberFormatValues) => {
        const minAmount = this.helpers.getMinAmount();
        if (!minAmount) return;
        this.updateOfferWriteView({
            minAmount: {
                ...minAmount,
                amount: numbers.floatValue !== undefined ? numbers.floatValue : 0,
            },
        });
    };

    handleMinAmountUnitChange = (unit: Unit) => {
        const minAmount = this.helpers.getMinAmount();
        if (!minAmount) return;
        this.updateOfferWriteView({
            minAmount: {
                ...minAmount,
                unit: unit.unit,
            },
        });
    };

    onProductDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (this.state.isOffer) {
            this.updateOfferWriteView({
                description: event.target.value,
            });
        } else {
            this.updateRequestWriteView({
                description: event.target.value,
            });
        }
    };

    onDateEndChange = (value: any) => {
        const moment = value as Moment;
        const isInputValid = moment && moment.isValid();

        if (this.state.isOffer) {
            this.updateOfferWriteView({
                dateEnd: isInputValid ? moment.toDate() : undefined,
            });
        } else {
            this.updateRequestWriteView({
                dateEnd: isInputValid ? moment.toDate() : undefined,
            });
        }
    };

    onDateFromChange = (value: any) => {
        const momentFromValue = value as Moment;
        const yesterday = moment().subtract(1, 'days').startOf('day');
        const isInputValid = momentFromValue && momentFromValue.isValid() && yesterday.isBefore(momentFromValue);
        if (this.state.isOffer) {
            this.updateOfferWriteView({
                dateFrom: isInputValid ? momentFromValue.toDate() : undefined,
            });
        } else {
            this.updateRequestWriteView({
                dateFrom: isInputValid ? momentFromValue.toDate() : undefined,
            });
        }
    };

    onOntofoodSelectChange = (productRef: string | undefined) => {
        this.state.isOffer
            ? this.updateOfferWriteView({
                  category: productRef,
              })
            : this.updateRequestWriteView({
                  category: productRef,
              });
    };

    onConfirmationDialogDiscardClick = () => {
        this.setState({
            isSaveLeavePreventionOpen: false,
        });
        this.props.onClose?.();
    };

    onPricePerUnitChange = (numbers: NumberFormatValues) => {
        this.updateOfferWriteView({
            pricePerUnit: numbers.floatValue ?? 0,
        });
    };
}
