import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { DietType } from 'model/ProductDataSheet';
import { CheckboxStyled } from 'components/search/filter/OrganicFilterComponent';

export interface StepDietTypeProps {
    diets?: DietType[];
}

export const StepDietType = (props: StepComponentProps<StepDietTypeProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['productDataSheet']);

    const handleDietChange = (diet: DietType) => {
        let diets: DietType[] | undefined = props.data.diets?.slice() ?? [];
        if (diets.includes(diet)) {
            diets = diets.filter((d) => d != diet);
        } else {
            diets.push(diet);
        }
        diets = diets.length ? diets : undefined;
        props.setData('StepDietType', { diets });
    };

    const showTitle = () => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_DIET_TYPE_TITLE')}
            </Typography>
        </Grid>
    );

    const showDietTypes = (): ReactNode =>
        Object.values(DietType).map((diet: DietType) => (
            <Grid key={diet.toString()} item container alignItems={'start'}>
                <Grid item>
                    <CheckboxStyled
                        checked={props.data.diets?.includes(diet) ?? false}
                        onChange={() => handleDietChange(diet)}
                    />
                </Grid>
                <Grid item alignSelf={'center'}>
                    <Typography sx={{ fontWeight: 600 }}>{t('productDataSheet:' + diet)}</Typography>
                </Grid>
            </Grid>
        ));

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showDietTypes()}
        </Grid>
    );
};
