import {
    PreOrderDialogProps,
    PreOrderDialogState,
    PreOrderDialogType,
} from 'components/marketplace/pre-order/dialog/PreOrderDialog';
import PreOrderDialogHelpers from 'components/marketplace/pre-order/dialog/PreOrderDialogHelpers';
import { Amount } from 'model/Amount';
import { Observable } from 'rxjs';
import { PurchaseIntent, PurchaseIntentWriteView } from 'model/PurchaseIntent';
import { PriceRequest, PriceRequestWriteView } from 'model/PriceRequest';
import PurchaseIntentService from 'api/PurchaseIntentService';
import PriceRequestService from 'api/PriceRequestService';
import { NotificationStore, OfferStore } from 'store';
import { NotificationInfo, PreOrderSentNotification } from 'model/NearbuyNotification';
import { now } from 'moment';
import MessageService from 'api/MessageService';
import { AxiosError } from 'axios';
import { DeliveryDateFormErrors } from 'components/marketplace/pre-order/dialog/DeliveryDateSelection';
import { RecurringOrderEntryWriteView, RecurringOrderWriteView } from 'model/RecurringOrder';
import RecurringOrderService from 'api/RecurringOrderService';

export default class PreOrderDialogHandlers {
    constructor(
        private props: PreOrderDialogProps,
        private state: PreOrderDialogState,
        private setState: <K extends keyof PreOrderDialogState>(
            state:
                | ((
                      prevState: Readonly<PreOrderDialogState>,
                      props: Readonly<PreOrderDialogProps>,
                  ) => Pick<PreOrderDialogState, K> | PreOrderDialogState | null)
                | Pick<PreOrderDialogState, K>
                | PreOrderDialogState
                | null,
            callback?: () => void,
        ) => void,
        private helpers: PreOrderDialogHelpers,
    ) {}

    handleRRuleStringChange = (newRRuleString: string) => {
        if (this.state.rruleString !== newRRuleString) {
            this.setState({ rruleString: newRRuleString });
        }
    };

    handleFormErrorsChange(errors: DeliveryDateFormErrors): void {
        if (this.state.formErrors !== errors) {
            this.setState({ formErrors: errors });
        }
    }

    handleRecurringOrderToggle() {
        this.setState({ isRecurringOrder: !this.state.isRecurringOrder, message: undefined });
    }

    handleContainerToggle(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            isAmountSelectionSwitchChecked: event.target.checked,
            containers: undefined,
            amountInOfferUnit: 0,
        });
    }

    handleMessageValueReset(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            message: event.target.value,
        });
    }

    handleContainersChange = (containers: Map<string, number>): void => {
        this.setState({ containers });
    };

    handleAmountInputChange = (amount: number | undefined): void => {
        this.setState({ amountInOfferUnit: amount });
    };

    handleClose(): void {
        this.props.onClose();
    }

    handleSend(): void {
        if (!this.state.rruleString || !this.state.ownCompany) return;
        const startAndUntilDate = this.helpers.getDatesFromRRule(this.state.rruleString);

        let containers = undefined;
        let totalAmount = undefined;
        if (this.state.offer && this.state.amountInOfferUnit) {
            if (!this.state.showContainerSelection) {
                totalAmount = new Amount(this.state.amountInOfferUnit, this.state.offer.totalAmount.unit);
            }
            if (this.state.containers) {
                containers = Array.from(this.state.containers)
                    .filter(([_, amount]) => !!amount)
                    .map(([second, first]) => ({ first, second }));
            }
        }
        if (
            this.state.isRecurringOrder &&
            startAndUntilDate &&
            startAndUntilDate.startDate &&
            startAndUntilDate.untilDate
        ) {
            const recurringOrderWriteView = new RecurringOrderWriteView(
                startAndUntilDate.startDate,
                startAndUntilDate.untilDate,
                [new RecurringOrderEntryWriteView(this.props.offerRef, containers, totalAmount)],
                this.state.rruleString.split('RRULE:')[1],
            );

            RecurringOrderService.sendRecurringOrder(recurringOrderWriteView).subscribe(() => this.handleClose());
        } else {
            let observable: Observable<PurchaseIntent | PriceRequest>;
            if (this.props.type == PreOrderDialogType.PurchaseIntent) {
                const purchaseIntentWrite = new PurchaseIntentWriteView(
                    this.props.offerRef,
                    this.state.message,
                    containers,
                    totalAmount,
                );
                observable = PurchaseIntentService.sendPurchaseIntent(purchaseIntentWrite);
            } else {
                const priceRequestWrite = new PriceRequestWriteView(
                    this.props.offerRef,
                    this.state.message,
                    containers,
                    totalAmount,
                );
                observable = PriceRequestService.sendPriceRequest(priceRequestWrite);
            }
            observable.subscribe({
                next: (value) => {
                    this.handleClose();
                    NotificationStore.setOne(new NotificationInfo(PreOrderSentNotification(), now()));
                    MessageService.invalidateDialogByPreOrder(value, true);
                    OfferStore.loadOne(this.props.offerRef);
                },
                error: (error: AxiosError) => {
                    if (error.response?.status === 409) {
                        OfferStore.loadOne(this.props.offerRef);
                    } else {
                        this.setState({
                            showSentError: true,
                            statusCode: error?.response?.status,
                        });
                    }
                },
            });
        }
    }
}
