import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { ReactNode } from 'react';
import { StepOfferComponentProps } from 'components/search/result-items/offers/OfferDialogStructure';
import AmountSelectComponent from 'components/search/result-items/offers/offerblocks/AmountSelectComponent';
import { Amount } from 'model/Amount';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { getUnitFromString, Unit, UnitType } from 'model/Unit';
import { createFormError } from 'util/FormErrors';
import { OfferDialogFormErrors } from 'components/search/result-items/offers/OfferDialog';
import { ContainerUsageType, ContainerWithKey, ContainerWriteView } from 'model/ContainerView';
import ContainerComponent from 'components/container/ContainerComponent';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { deepCopy } from 'util/deep-equality-helpers';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import TextField from '@mui/material/TextField';
import UnitSelectComponent from 'components/marketplace/UnitSelectComponent';
import { AmountRange } from 'model/AmountRange';
import SplitButtonComponent, { SplitButtonOption } from 'components/shared/SplitButtonComponent';
import IconButton from '@mui/material/IconButton';
import { DeleteIcon } from 'components/shared/Icons';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';

export default interface OfferStepAmountAndPackagingProps {
    isPermanent?: boolean;
    totalAmount?: Amount;
    minAmount?: Amount;
    caliber?: AmountRange;
    weight?: AmountRange;
    containers?: ContainerWithKey[];
    formErrors?: OfferDialogFormErrors;
    offerRef?: string;
}

export const OfferStepAmountAndPackaging = (props: StepOfferComponentProps<OfferStepAmountAndPackagingProps>) => {
    const useStyles = makeStyles((theme: Theme) => ({
        errorTypography: {
            color: theme.palette.error.main,
            fontSize: 13,
            fontWeight: '500',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    }));

    const classes = useStyles();

    const theme = useTheme();
    const { t } = useTranslation(['common', 'offerDialog', 'offer', 'tooltips']);

    const areContainersSet = !!props.data.containers?.length;

    const onTotalAmountChange = (value: NumberFormatValues) => {
        const totalAmount = new Amount(value.floatValue ?? 0, props.data.totalAmount?.unit ?? '');
        props.setData('OfferStepAmountAndPackaging', { totalAmount: totalAmount });
    };

    const onMinAmountChange = (value: NumberFormatValues) => {
        const minAmount = new Amount(value.floatValue ?? 0, props.data.minAmount?.unit ?? '');
        props.setData('OfferStepAmountAndPackaging', { minAmount: minAmount });
    };

    const onTotalAmountUnitChange = (value: Unit) => {
        const totalAmount = new Amount(props.data.totalAmount?.amount ?? 0, value.unit);
        const minAmount = new Amount(props.data.minAmount?.amount ?? 0, value.unit);
        props.setData('OfferStepAmountAndPackaging', { totalAmount: totalAmount, minAmount: minAmount });
    };

    const onMinAmountUnitChange = (value: Unit) => {
        const minAmount = new Amount(props.data.minAmount?.amount ?? 0, value.unit);
        props.setData('OfferStepAmountAndPackaging', { minAmount: minAmount });
    };

    const showTitleAndMandatoryHint = (): ReactNode => (
        <Grid container sx={{ paddingBottom: theme.spacing(4) }} justifyContent="space-between">
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('offerDialog:OFFER_STEP_AMOUNT_AND_PACKAGING_TITLE')}
            </Typography>
            <Typography sx={{ fontSize: '14px' }}>{t('offerDialog:mandatory')}</Typography>
        </Grid>
    );

    const showTotalAmountSelect = (): ReactNode => {
        const { totalAmount, isPermanent, formErrors } = props.data;
        const { helperText: amountHelperText, showError: amountShowError } = formErrors?.totalAmountAmount || {};
        const { helperText: unitHelperText, showError: unitShowError } = formErrors?.totalAmountUnit || {};
        const amountError = amountShowError ? createFormError(amountHelperText) : undefined;
        const unitError = unitShowError ? createFormError(unitHelperText) : undefined;

        return (
            <Grid container>
                {wrapButtonWithTooltip(
                    <AmountSelectComponent
                        amountValue={totalAmount?.amount}
                        onAmountChange={(value) => onTotalAmountChange(value)}
                        amountLabel={isPermanent ? t('offer:maxQuantityPerDay') : t('offer:amount')}
                        amountFormError={amountError}
                        unitFormError={unitError}
                        unitValue={totalAmount?.unit}
                        onUnitChange={(value) => onTotalAmountUnitChange(value)}
                        isAmountRequired
                        fullLabelWidth
                        isUnitReadOnly={areContainersSet || !!props.data.offerRef}
                    />,
                    areContainersSet && !props.data.offerRef ? t('tooltips:noUnitEditIfContainersAreSet') : '',
                    'top',
                    'unit-select',
                    { width: '100%' },
                )}
            </Grid>
        );
    };

    const showMinAmountSelect = (): ReactNode => {
        const { minAmount, totalAmount, isPermanent, formErrors } = props.data;
        const totalAmountUnit = getUnitFromString(totalAmount?.unit ?? '');
        const { helperText: amountHelperText, showError: amountShowError } = formErrors?.minAmountAmount || {};
        const { helperText: unitHelperText, showError: unitShowError } = formErrors?.minAmountUnit || {};
        const amountError = amountShowError ? createFormError(amountHelperText) : undefined;
        const unitError = unitShowError ? createFormError(unitHelperText) : undefined;
        return (
            <AmountSelectComponent
                amountValue={minAmount?.amount}
                onAmountChange={(value) => onMinAmountChange(value)}
                amountLabel={isPermanent ? t('offer:minimumPurchasePerDelivery') : t('offer:minimumPurchase')}
                amountFormError={amountError}
                unitFormError={unitError}
                unitValue={minAmount?.unit}
                onUnitChange={(value) => onMinAmountUnitChange(value)}
                typeLimit={totalAmountUnit?.unitType}
                unitLimit={totalAmountUnit}
                isAmountRequired
                isUnitReadOnly={areContainersSet || !!props.data.offerRef}
            />
        );
    };

    const showContainerInputSection = (): ReactNode => {
        return props.data.containers?.map((containerWithKey) => (
            <Grid
                key={containerWithKey.key}
                container
                item
                md={12}
                sx={{
                    border: 1,
                    borderRadius: 5,
                    borderColor: theme.palette.grey[300],
                    padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(2)}`,
                    marginBottom: theme.spacing(1.5),
                }}
            >
                {showContainerSelect(containerWithKey)}
            </Grid>
        ));
    };

    function handleContainerUpdate(containerWriteView: ContainerWriteView | undefined, key: number) {
        if (containerWriteView === undefined) return null;

        let containers = deepCopy(props.data.containers) ?? [];
        containers = containers.map((it) => {
            if (it.key === key) {
                return {
                    key: it.key,
                    container: containerWriteView,
                };
            } else {
                return it;
            }
        });
        props.setData('OfferStepAmountAndPackaging', { containers });
        return true;
    }

    const showContainerSelect = (containerWithKey: ContainerWithKey) => {
        const { container, key } = containerWithKey;
        const hasShownContainerFormError =
            props.data.formErrors &&
            Object.prototype.hasOwnProperty.call(props.data.formErrors, 'containers') &&
            (!container.containerType || !container.amount || !container.unit) &&
            props.data.formErrors['containers'].showError;
        const isDuplicate = props.data.containers
            ?.filter((it) => it.key != containerWithKey.key)
            ?.map((it) => it.container)
            ?.some(
                (it) =>
                    it.amount == container.amount &&
                    it.containerType == container.containerType &&
                    it.unit == container.unit,
            );

        return (
            <Grid container item md={12}>
                <Grid item md={11}>
                    <ContainerComponent
                        container={container}
                        onUpdate={(writeView) => handleContainerUpdate(writeView, key)}
                        offerUnit={getUnitFromString(props.data.totalAmount?.unit)}
                        remove={() => handleContainerDelete(key)}
                        key={containerWithKey.key}
                        isDuplicate={isDuplicate ?? false}
                        containerUsageType={ContainerUsageType.OFFER}
                        showInvalidErrors={hasShownContainerFormError}
                    />
                </Grid>
                {hasShownContainerFormError && props.data.formErrors && (
                    <Grid item md={12}>
                        <Typography className={classes.errorTypography}>
                            {props.data.formErrors['containers'].helperText}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    };

    const handleContainerDelete = (key: number) => {
        const containers = props.data.containers?.filter((ct) => ct.key !== key);
        props.setData('OfferStepAmountAndPackaging', { containers: containers });
    };

    const showAddButton = () => (
        <Grid item sx={{ marginTop: 1 }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    !props.data.totalAmount?.unit ||
                    props.data.containers
                        ?.map((it) => it.container)
                        .some((it) => !it.containerType || !it.amount || !it.unit)
                }
                onClick={() => {
                    handleAddContainer();
                    props.setShowAllFormErrors(false);
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showFirstChooseUnit = () => {
        if (!props.data.totalAmount?.unit) {
            return (
                <Grid container item>
                    <Typography>{t('offer:firstChooseUnit')}</Typography>
                </Grid>
            );
        }
    };

    const handleAddContainer = () => {
        const containers = deepCopy(props.data.containers) ?? [];
        const unit = getUnitFromString(props.data.totalAmount?.unit);
        containers.push({
            key: Math.random(),
            container: {
                containerType: '',
                unit: unit?.unit,
                amount: 1,
            },
        });

        props.setData('OfferStepAmountAndPackaging', { containers: containers });
    };

    const showCaliberInput = (): ReactNode => {
        const { formErrors } = props.data;
        const { helperText: caliberHelperText, showError: showCaliberError } = formErrors?.caliber || {};
        const isMinValid =
            !!props.data.caliber?.min &&
            props.data.caliber.min > 0 &&
            (props.data.caliber.max ? props.data.caliber.max >= props.data.caliber.min : true);

        const isMaxValid =
            !!props.data.caliber?.max &&
            props.data.caliber.max > 0 &&
            (props.data.caliber.min ? props.data.caliber.max >= props.data.caliber.min : true);

        return (
            <Grid item container xs={12} spacing={theme.spacing(1)} sx={{ marginTop: 1 }}>
                <Grid
                    item
                    container
                    xs={11}
                    sx={{ border: 1, borderColor: theme.palette.grey.A400, borderRadius: 3, padding: 2 }}
                >
                    <Grid item xs={12} sx={{ paddingTop: theme.spacing(1) }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '16', color: theme.palette.primary.dark }}>
                            {t('offerDialog:caliber')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                        <NumberFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            value={props.data.caliber?.min}
                            onValueChange={({ floatValue }) =>
                                props.setData('OfferStepAmountAndPackaging', {
                                    caliber: {
                                        ...props.data.caliber,
                                        min: floatValue,
                                    },
                                })
                            }
                            label={t('offerDialog:from')}
                            error={!isMinValid && showCaliberError}
                            helperText={!isMinValid && showCaliberError ? caliberHelperText : undefined}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                        <NumberFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            value={props.data.caliber?.max}
                            onValueChange={({ floatValue }) =>
                                props.setData('OfferStepAmountAndPackaging', {
                                    caliber: {
                                        ...props.data.caliber,
                                        max: floatValue,
                                    },
                                })
                            }
                            label={t('offerDialog:until')}
                            error={!isMaxValid && showCaliberError}
                            helperText={!isMaxValid && showCaliberError ? caliberHelperText : undefined}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <UnitSelectComponent
                            selectedUnit={getUnitFromString(props.data.caliber?.unit)}
                            onChange={({ unit }) => {
                                props.setData('OfferStepAmountAndPackaging', {
                                    caliber: {
                                        ...props.data.caliber,
                                        unit,
                                    },
                                });
                            }}
                            conversationFactorToLimitTo={getUnitFromString('Centimeter')?.conversionFactor}
                            typeToLimitTo={UnitType.LENGTH}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={1} sx={{ alignItems: 'top' }}>
                    <IconButton
                        onClick={(): void => props.setData('OfferStepAmountAndPackaging', { caliber: undefined })}
                        className={'DeleteIconCaliber'}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const showWeightInput = (): ReactNode => {
        const { formErrors } = props.data;
        const { helperText: weightHelperText, showError: weightShowError } = formErrors?.weight || {};

        const isMinValid =
            !!props.data.weight?.min &&
            props.data.weight.min > 0 &&
            (props.data.weight.max ? props.data.weight.max >= props.data.weight.min : true);

        const isMaxValid =
            !!props.data.weight?.max &&
            props.data.weight.max > 0 &&
            (props.data.weight.min ? props.data.weight.max >= props.data.weight.min : true);

        return (
            <Grid item container xs={12} spacing={theme.spacing(1)} sx={{ marginTop: 1 }}>
                <Grid
                    item
                    container
                    xs={11}
                    sx={{ border: 1, borderColor: theme.palette.grey.A400, borderRadius: 3, padding: 2 }}
                >
                    <Grid item xs={12} sx={{ paddingTop: theme.spacing(1) }}>
                        <Typography sx={{ fontWeight: 600, fontSize: '16', color: theme.palette.primary.dark }}>
                            {t('offerDialog:weightPerPiece')}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                        <NumberFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            value={props.data.weight?.min}
                            onValueChange={({ floatValue }) =>
                                props.setData('OfferStepAmountAndPackaging', {
                                    weight: {
                                        ...props.data.weight,
                                        min: floatValue,
                                    },
                                })
                            }
                            label={t('offerDialog:from')}
                            error={!isMinValid && weightShowError}
                            helperText={!isMinValid && weightShowError ? weightHelperText : undefined}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'grid', paddingRight: theme.spacing(1.5) }}>
                        <NumberFormat
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            decimalScale={2}
                            customInput={TextField}
                            value={props.data.weight?.max}
                            onValueChange={({ floatValue }) =>
                                props.setData('OfferStepAmountAndPackaging', {
                                    weight: {
                                        ...props.data.weight,
                                        max: floatValue,
                                    },
                                })
                            }
                            label={t('offerDialog:until')}
                            error={!isMaxValid && weightShowError}
                            helperText={!isMaxValid && weightShowError ? weightHelperText : undefined}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <UnitSelectComponent
                            selectedUnit={getUnitFromString(props.data.weight?.unit)}
                            onChange={({ unit }) => {
                                props.setData('OfferStepAmountAndPackaging', {
                                    weight: {
                                        ...props.data.weight,
                                        unit,
                                    },
                                });
                            }}
                            conversationFactorToLimitTo={getUnitFromString('Kilogram')?.conversionFactor}
                            typeToLimitTo={UnitType.WEIGHT}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={1}>
                    <IconButton
                        onClick={(): void => props.setData('OfferStepAmountAndPackaging', { weight: undefined })}
                        className={'DeleteIconWeight'}
                        size="small"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            </Grid>
        );
    };

    const noUnitSelectedHint = (): ReactNode => {
        return <Typography>{props.data.totalAmount?.unit ? null : t('offer:firstChooseUnit')}</Typography>;
    };

    const showCaliberAndWeightTitle = (): ReactNode => {
        return (
            <Grid item>
                <Typography sx={{ fontWeight: 600, marginTop: 2 }}>{t('offerDialog:weightAndCaliber')}</Typography>
                {noUnitSelectedHint()}
            </Grid>
        );
    };

    const showSplitButtonForWeightAndCaliber = (): ReactNode => {
        const hasUnit = !props.data.totalAmount?.unit;

        const weightOption: SplitButtonOption = {
            label: t('offerDialog:weightPerPiece'),
            action: () =>
                props.setData('OfferStepAmountAndPackaging', {
                    weight: new AmountRange(0, 0, 'Gram'),
                }),
            icon: <AddIcon key={'weightPerPieceIcon'} />,
        };
        const caliberOption: SplitButtonOption = {
            label: t('offerDialog:caliber'),
            action: () =>
                props.setData('OfferStepAmountAndPackaging', {
                    caliber: new AmountRange(0, 0, 'Millimeter'),
                }),
            icon: <AddIcon key={'caliberIcon'} />,
        };

        const options: SplitButtonOption[] = [];
        if (!props.data.weight) options.push(weightOption);
        if (!props.data.caliber) options.push(caliberOption);

        return options.length ? (
            <Grid item md={12} sx={{ marginTop: props.data.totalAmount?.unit ? 2 : 0 }}>
                <span>
                    {wrapButtonWithTooltip(
                        <SplitButtonComponent
                            sxPropsButtonGroup={{
                                marginRight: theme.spacing(1),
                            }}
                            options={options}
                            disabled={hasUnit || (!!props.data.weight && !!props.data.caliber)}
                        />,
                        hasUnit || (!!props.data.weight && !!props.data.caliber)
                            ? t('offer:firstChooseUnit')
                            : t('offerDialog:add'),
                    )}
                </span>
            </Grid>
        ) : null;
    };

    const showCaliberAndWeightSection = (): ReactNode => {
        const unitType = getUnitFromString(props.data.totalAmount?.unit)?.unitType;
        if (props.data.totalAmount?.unit && !(unitType && [UnitType.WEIGHT, UnitType.PIECE].includes(unitType)))
            return null;

        return (
            <>
                {showCaliberAndWeightTitle()}
                {props.data.caliber ? showCaliberInput() : null}
                {props.data.weight ? showWeightInput() : null}
                {showSplitButtonForWeightAndCaliber()}
            </>
        );
    };

    return (
        <Grid container sx={{ paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) }}>
            {showTitleAndMandatoryHint()}
            <Grid container item rowSpacing={1}>
                <Typography sx={{ fontWeight: 600, marginBottom: theme.spacing(2) }}>
                    {t('offerDialog:OFFER_STEP_AMOUNT_AND_PACKAGING_AMOUNT')}
                </Typography>
                {showTotalAmountSelect()}
                {showMinAmountSelect()}
                {showCaliberAndWeightSection()}
            </Grid>
            <Grid container item rowSpacing={1}>
                <Typography
                    sx={{
                        fontWeight: 600,
                        mt: 4,
                        marginBottom: props.data.totalAmount?.unit ? theme.spacing(2) : 0,
                    }}
                >
                    {t('offer:container')}
                </Typography>
            </Grid>
            {showFirstChooseUnit()}
            {showContainerInputSection()}
            {showAddButton()}
        </Grid>
    );
};
