import { WithStyles } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import * as React from 'react';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import { Offer } from 'model/Offer';
import Grid from '@mui/material/Grid';
import { MetaStore, OfferStore } from 'store';
import { ContainerType, ContainerView } from 'model/ContainerView';
import Chip from '@mui/material/Chip';
import { MetaStaticEntityType } from 'model/Meta';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AmountComponent from 'components/marketplace/pre-order/AmountComponent';
import TextField from '@mui/material/TextField';
import { getUnitFromString } from 'model/Unit';
import { theme } from 'style/NearbuyStyle';

const styles = (theme: Theme) =>
    createStyles({
        heading: {
            fontSize: '18px',
            fontWeight: 'bold',
            paddingBottom: theme.spacing(0.5),
        },
        row: {
            padding: theme.spacing(1) + 'px 0px ' + theme.spacing(2) + 'px 0px',
        },
        chip: {
            fontSize: '15px',
            margin: theme.spacing(0.5),
            backgroundColor: theme.palette.primary.light,
        },
        typography: {
            fontSize: '15px',
        },
        changeButton: {
            borderRadius: '20px',
            width: '35px',
            height: '35px',
            margin: '0',
            transform: 'scale(0.80, 0.80)',
            backgroundColor: theme.palette.background.default,
            '&:hover': {
                backgroundColor: theme.palette.background.default,
                border: '1px solid gray',
            },
        },
        icon: {
            transform: 'scale(0.9)',
            color: theme.palette.common.black,
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        amountInput: {
            transform: 'scale(0.96)',
            padding: '4px',
            border: 'none',
            margin: '0',
            width: '35%',
            //removes up-down arrows from the textfield:
            '& input[type=number]': {
                '-moz-appearance': 'textfield',
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0,
            },
        },
    });

interface ContainerSelectionComponentProperties extends WithStyles<typeof styles>, WithTranslation {
    withPrice: boolean;
    containers: ContainerView[];
    onAmountInOfferUnitChange: (goodsAmount: number) => void;
    handleChange: (amountContainers: Map<string, number>) => void;
    offerRef: string;
    isRecurringOrder?: boolean;
}

interface ContainerSelectionComponentState {
    containerTypes: ContainerType[];
    containerAmounts: Map<string, number>;
    amountInOfferUnit: number;
    amountInputDialogContainerId: string;
    offer: Offer;
}

export class ContainerSelectionComponent extends ComponentBase<
    ContainerSelectionComponentProperties,
    ContainerSelectionComponentState
> {
    protected _buildState(
        props: ContainerSelectionComponentProperties,
        initialBuild: boolean,
        incomingState?: Readonly<ContainerSelectionComponentState>,
    ): Partial<ContainerSelectionComponentState> | undefined {
        const newState: Partial<ContainerSelectionComponentState> = {
            containerTypes: [],
            offer: OfferStore.getOne(props.offerRef),
        };

        const metaContainerType = MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE);
        if (metaContainerType) newState.containerTypes = metaContainerType.data as ContainerType[];

        return newState;
    }

    componentDidMount(): void {
        super.componentDidMount();
        const tempContainerAmounts = new Map<string, number>();
        this.props.containers.forEach((offerContainer) => {
            tempContainerAmounts.set(offerContainer.id, 0);
        });
        this.setState({
            containerAmounts: tempContainerAmounts,
            amountInOfferUnit: 0,
        });
    }

    getNumberDecimal(n: number): string {
        return new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 1,
            maximumFractionDigits: 3,
        }).format(n);
    }

    calculateAmountInOfferUnit(): void {
        let amountInOfferUnit = 0;
        const offerUnitConversionFactor = getUnitFromString(this.state.offer.totalAmount.unit)?.conversionFactor;
        this.props.containers.forEach((offerContainer) => {
            const containerUnitConversionFactor = getUnitFromString(offerContainer.unit)?.conversionFactor;
            const containerSizeInBaseUnit = containerUnitConversionFactor
                ? offerContainer.amount * containerUnitConversionFactor
                : undefined;
            const containerSizeInOfferUnit =
                containerSizeInBaseUnit && offerUnitConversionFactor
                    ? containerSizeInBaseUnit / offerUnitConversionFactor
                    : undefined;
            const selectedAmountOfContainer = this.state.containerAmounts.get(offerContainer.id);
            if (containerSizeInOfferUnit && selectedAmountOfContainer)
                amountInOfferUnit += containerSizeInOfferUnit * selectedAmountOfContainer;
        });
        this.setState({ amountInOfferUnit }, () => {
            this.props.onAmountInOfferUnitChange(this.state.amountInOfferUnit);
        });
    }

    decrementAmount(containerID: string): void {
        let amount = this.state.containerAmounts.get(containerID);
        if (amount !== undefined && amount > 0) {
            const tempMap = this.state.containerAmounts;
            tempMap.set(containerID, --amount);
            this.setContainerAmountsAndHandleChange(tempMap, () => this.calculateAmountInOfferUnit());
        }
    }

    incrementAmount(containerID: string): void {
        let amount = this.state.containerAmounts.get(containerID);
        if (amount !== undefined) {
            const tempMap = this.state.containerAmounts;
            tempMap.set(containerID, ++amount);
            this.setContainerAmountsAndHandleChange(tempMap, () => this.calculateAmountInOfferUnit());
        }
    }

    enterAmount(containerID: string, value: number): void {
        const amount = this.state.containerAmounts.get(containerID);
        if (amount !== undefined) {
            const tempMap = this.state.containerAmounts;
            tempMap.set(containerID, value);
            this.setContainerAmountsAndHandleChange(tempMap, () => this.calculateAmountInOfferUnit());
        }
    }

    setContainerAmountsAndHandleChange(tempMap: Map<string, number>, callBackFn?: () => void): void {
        this.setState({ containerAmounts: tempMap }, () => {
            this.props.handleChange(this.state.containerAmounts);
            if (callBackFn) callBackFn();
        });
    }

    showTitle(): React.ReactFragment {
        return (
            <Grid item>
                <Typography className={this.props.classes.heading}>
                    {this.props.t('offer:containerSelection')}
                </Typography>
            </Grid>
        );
    }

    showContainerSelection(): React.ReactFragment | null {
        if (this.props.containers && this.state.containerTypes) {
            return (
                <>
                    {this.props.containers.map((oc) => {
                        if (oc !== undefined) {
                            return (
                                <Grid
                                    container
                                    direction={'column'}
                                    key={`${oc.id}${oc.amount}${oc.unit}`}
                                    paddingBottom={theme.spacing(1)}
                                >
                                    <Grid container item alignItems={'center'} columns={24}>
                                        <Grid item xs={2.5}>
                                            <Typography>
                                                {this.props.t(
                                                    'containertype:' +
                                                        this.state.containerTypes!.find(
                                                            (containerType) => oc.containerType === containerType.id,
                                                        )!.slug,
                                                )}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2.5}>
                                            <Chip
                                                label={
                                                    `${this.getNumberDecimal(oc!.amount)} ` +
                                                    this.props.t(`shortunits:${oc!.unit}`)
                                                }
                                                className={this.props.classes.chip}
                                                color="primary"
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={'row'}
                                            xs={3.5}
                                            alignItems={'center'}
                                            justifyContent={'center'}
                                            style={{ paddingTop: '0px' }}
                                        >
                                            <IconButton
                                                className={this.props.classes.changeButton}
                                                onClick={(): void => this.decrementAmount(oc.id)}
                                                data-testid={'decrementButton'}
                                            >
                                                <RemoveIcon className={this.props.classes.icon} />
                                            </IconButton>

                                            <TextField
                                                type={'number'}
                                                value={
                                                    this.state.containerAmounts
                                                        ? this.state.containerAmounts.get(oc.id)
                                                        : ' '
                                                }
                                                onChange={(event) => {
                                                    event.target.value = Math.round(
                                                        Number(event.target.value),
                                                    ).toString();
                                                    if (parseInt(event.target.value) < 0) {
                                                        event.target.value = '0';
                                                    }
                                                    this.enterAmount(oc.id, Number(event.target.value));
                                                }}
                                                className={this.props.classes.amountInput}
                                                data-testid={'containerAmountInput'}
                                            />

                                            <IconButton
                                                className={this.props.classes.changeButton}
                                                onClick={(): void => this.incrementAmount(oc.id)}
                                                data-testid={'incrementButton'}
                                            >
                                                <AddIcon className={this.props.classes.icon} />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        }
                    })}
                </>
            );
        } else return null;
    }

    showAmountComponent(): React.ReactFragment {
        return (
            <Grid item xs={3}>
                <AmountComponent
                    usedInContainerSelection
                    isPriceShown={this.props.withPrice}
                    offer={this.state.offer}
                    amountInOfferUnit={this.state.amountInOfferUnit}
                    isRecurringOrder={this.props.isRecurringOrder}
                />
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <>
                <Grid container item direction={'column'} className={this.props.classes.row}>
                    {this.showTitle()}
                    {this.showContainerSelection()}
                    {this.showAmountComponent()}
                </Grid>
            </>
        );
    }
}

export default withTranslation(['shortunits', 'containertype', 'offer'])(
    withStyles(styles, { withTheme: true })(ContainerSelectionComponent),
);
