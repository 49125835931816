import { Observable, of } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import { RecurringOrderListStore, RecurringOrderStore } from 'store';
import {
    RecurringOrder,
    RecurringOrderStatus,
    RecurringOrderStatusWriteView,
    RecurringOrderWriteView,
} from 'model/RecurringOrder';
import { getUuidFromString } from 'util/helpers';
import { OrderAddressWriteView } from 'model/Order';

class RecurringOrderService {
    public sendRecurringOrder(recurringOrderWriteView: RecurringOrderWriteView): Observable<RecurringOrder> {
        return http
            .getAxios()
            .post<RecurringOrder>('/recurring_orders/', recurringOrderWriteView)
            .pipe(
                map((value) => value.data),
                tap((ro) => {
                    RecurringOrderStore.setOne(ro);
                    RecurringOrderListStore.invalidateCache();
                }),
            );
    }

    public getRecurringOrder(recurringOrderRef: string): Observable<RecurringOrder> {
        return http
            .getAxios()
            .get(recurringOrderRef)
            .pipe(map((value) => value.data));
    }

    public listRecurringOrders(companyRef: string): Observable<RecurringOrder[]> {
        const companyId = getUuidFromString(companyRef);
        if (!companyId) return of([]);
        return http
            .getAxios()
            .get('/recurring_orders/', { headers: { 'Current-Company': companyId } })
            .pipe(map((value) => value.data));
    }

    public updateRecurringOrderStatus(
        recurringOrderRef: string,
        status: RecurringOrderStatus,
    ): Observable<RecurringOrder> {
        return http
            .getAxios()
            .put(recurringOrderRef, new RecurringOrderStatusWriteView(status))
            .pipe(
                map((value) => value.data),
                tap((ro) => {
                    RecurringOrderStore.setOne(ro);
                    RecurringOrderListStore.invalidateCache();
                }),
            );
    }

    public uploadRecurringOrderInvoice(
        recurringOrderRef: string,
        file: Blob,
        filename: string,
    ): Observable<RecurringOrder> {
        const formData = new FormData();
        formData.append('file', file, filename);
        formData.append('filename', filename);

        return http
            .getAxios()
            .post<RecurringOrder>(`${recurringOrderRef}/invoice`, formData)
            .pipe(
                map((value) => value.data),
                tap((ro) => {
                    RecurringOrderListStore.invalidateCache();
                    RecurringOrderStore.setOne(ro);
                }),
            );
    }

    public removeRecurringOrderInvoice(
        recurringOrderRef: string,
        invoiceRef: string,
    ): Observable<RecurringOrder> | undefined {
        const invoiceId = getUuidFromString(invoiceRef);
        if (!invoiceId) return;
        return http
            .getAxios()
            .delete<RecurringOrder>(`${recurringOrderRef}/invoice/${invoiceId}`)
            .pipe(
                map((value) => value.data),
                tap((ro) => {
                    RecurringOrderStore.setOne(ro);
                }),
            );
    }

    public updateRecurringOrderAddress(
        recurringOrderRef: string,
        orderAddressWriteView: OrderAddressWriteView,
    ): Observable<RecurringOrder> {
        const recurringOrderId = getUuidFromString(recurringOrderRef);
        return http
            .getAxios()
            .put(`/recurring_orders/${recurringOrderId}/address`, orderAddressWriteView)
            .pipe(
                map((value) => value.data),
                tap(() => RecurringOrderStore.invalidateCache(recurringOrderRef)),
            );
    }
}

export default new RecurringOrderService();
