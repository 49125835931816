import http from 'api/http';
import OfferService from 'api/OfferService';
import ProductDataSheetService from 'api/ProductDataSheetService';
import { ContainerView, ContainerWriteView } from 'model/ContainerView';
import { concatMap, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ContainerStore, ProductDataSheetStore } from 'store';

class ContainerService {
    public getContainer(containerRef: string): Observable<ContainerView> {
        return http
            .getAxios()
            .get<ContainerView>(containerRef)
            .pipe(map((value) => value.data));
    }

    addOfferContainer(companyRef: string, container: ContainerWriteView, offerRef: string): Observable<ContainerView> {
        return http
            .getAxios()
            .post<ContainerView>(companyRef + '/containers', container)
            .pipe(
                map((response) => response.data),
                tap((_container) => OfferService.addContainerToOffer(offerRef, _container.id).subscribe()),
            );
    }

    addProductDataSheetContainer(
        companyRef: string,
        container: ContainerWriteView,
        productDataSheetRef: string,
    ): Observable<ContainerView> {
        return http
            .getAxios()
            .post<ContainerView>(companyRef + '/containers', container)
            .pipe(
                map((response) => response.data),
                concatMap((container) =>
                    ProductDataSheetService.addContainerToProductDataSheet(productDataSheetRef, container.id).pipe(
                        map((_) => container),
                    ),
                ),
                tap((container) => {
                    ContainerStore.setOne(container);
                    ProductDataSheetStore.loadOne(productDataSheetRef);
                }),
            );
    }

    removeProductDataSheetContainer(productDataSheetRef: string, containerId: string): Observable<number> {
        if (containerId) {
            return http
                .getAxios()
                .delete<number>(productDataSheetRef + '/containers/' + containerId)
                .pipe(
                    map((response) => response.data),
                    tap((number) => {
                        if (number) ProductDataSheetStore.loadOne(productDataSheetRef);
                    }),
                );
        } else return of(0);
    }
}

export default new ContainerService();
