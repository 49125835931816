import * as React from 'react';
import { ComponentClass, FunctionComponent, ReactNode } from 'react';
import { OfferDialogData, structure } from 'components/search/result-items/offers/OfferDialogStructure';
import { WithStyles } from '@mui/styles';
import { Offer, OfferWriteView } from 'model/Offer';
import { Step, StepLabel, Stepper, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import {
    _buildState_applyDefaultsToStepData,
    _buildState_ensureStateConsistency,
    _buildState_getInitialData,
    _buildState_updateCurrentStepValues,
    _buildState_updateOfferWriteView,
    _buildState_validateAndSetFormErrors,
} from 'components/search/result-items/offers/OfferDialogBuildStateHelpers';
import Helpers from 'components/search/result-items/offers/OfferDialogHelpers';
import Handlers from 'components/search/result-items/offers/OfferDialogHandlers';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogActions from '@mui/material/DialogActions';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FormControlLabel from '@mui/material/FormControlLabel';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import Switch from '@mui/material/Switch';
import { theme } from 'style/NearbuyStyle';
import { ContactsStore, LevelOfProcessingStore, OfferStore, PersonStore } from 'store';
import { OfferDialogVerticalStepComponent } from 'components/search/result-items/offers/Stepper/OfferDialogVerticalStepper';
import { deepCopy } from 'util/deep-equality-helpers';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { VerticalStepConnector } from 'components/productdatasheet/stepper/VerticalStepConnector';
import { MessageTermsStatus, Person } from 'model/Person';

const styles = (theme: Theme) =>
    createStyles({
        editIcon: {
            color: theme.palette.primary.dark,
        },
        offerDot: {
            fill: theme.palette.secondary.dark,
            marginRight: theme.spacing(1),
            '&:hover': {
                fill: theme.palette.secondary.dark,
            },
        },
    });

export type OfferDialogFormErrors = Record<string, { helperText: string; showError: boolean }>;

export interface OfferDialogProps extends WithStyles<typeof styles>, WithTranslation {
    isOpen: boolean;
    onClose: () => void;
    companyRef: string;
    offerRef?: string;
    isOfferProvided?: boolean;
    limitingProductRef?: string;
}

export interface OfferDialogState {
    allLevelsOfProcessing?: LevelOfProcessing[];
    isSaveLeavePreventionOpen: boolean;

    offerRef?: string;
    offer?: Offer;
    offerWriteView?: OfferWriteView;
    initialOfferWriteView?: OfferWriteView;
    data: OfferDialogData;

    activeDataBlock: number;
    activeDataStep: number;
    currentStepComponent?: FunctionComponent<any> | ComponentClass<any, any>;
    currentStepId?: keyof OfferDialogData;
    isActive: boolean;
    isOpen: boolean;
    showAllFormErrors: boolean;

    personBySelectedContact?: Person;
    isNoMessagingInUseHintShown: boolean;
    isContactComponentShowing: boolean;
    priceOnRequestChange: boolean;
    isPriceUponRequest: boolean;
}

class OfferDialog extends ComponentBase<OfferDialogProps, OfferDialogState> {
    protected _buildState(
        props: OfferDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<OfferDialogState> | undefined,
    ): Partial<OfferDialogState> | undefined {
        const isDialogJustOpening = !!(initialBuild || (incomingState && props.isOpen && !incomingState.isOpen));
        let newState: Partial<OfferDialogState> = {
            ...incomingState,
            data: props.offerRef
                ? !incomingState?.data
                    ? incomingState?.offer
                        ? _buildState_getInitialData(incomingState.offerWriteView)
                        : undefined
                    : incomingState.data
                : incomingState?.data ?? _buildState_getInitialData(OfferWriteView.create()),
            activeDataBlock: incomingState?.activeDataBlock ?? 0,
            activeDataStep: incomingState?.activeDataStep ?? 0,
            offerRef: props.offerRef ? props.offerRef : incomingState?.offerRef,
            isActive: incomingState?.isActive ?? false,
            isOpen: props.isOpen,
            offer: props.offerRef ? OfferStore.getOne(props.offerRef) : undefined,
            allLevelsOfProcessing: LevelOfProcessingStore.getAll(),
            showAllFormErrors: incomingState?.showAllFormErrors ?? false,
        };

        if (isDialogJustOpening) OfferStore.invalidateCache(props.offerRef);

        if (isDialogJustOpening || !newState.offerWriteView) {
            if (props.offerRef) {
                newState.offerWriteView = OfferWriteView.create(newState.offer);
            } else {
                newState.offerWriteView = OfferWriteView.create();
            }
            if (newState.offerWriteView) {
                newState.isActive = newState.offerWriteView.active;
                newState.initialOfferWriteView = deepCopy(newState.offerWriteView);
                newState.isPriceUponRequest = newState.offerWriteView.pricePerUnit === null;
            }
        }

        if (props.isOfferProvided) {
            newState.isActive = true;
            newState.offerWriteView.active = true;
        }

        newState.data = props.offerRef
            ? !newState.data
                ? newState.offer
                    ? _buildState_getInitialData(newState.offerWriteView)
                    : undefined
                : newState.data
            : newState.data ?? _buildState_getInitialData(newState.offerWriteView);

        newState = _buildState_updateCurrentStepValues(newState);
        newState = _buildState_ensureStateConsistency(newState);
        newState = _buildState_applyDefaultsToStepData(newState, this.props);
        newState = _buildState_updateOfferWriteView(newState);
        newState = _buildState_validateAndSetFormErrors(newState, this.props);

        const contactRef = newState.offerWriteView?.contact;
        if (contactRef) {
            const contact = ContactsStore.getOne(contactRef);
            if (contact) {
                newState.personBySelectedContact = PersonStore.getOne(contact.links.person);
                if (newState.personBySelectedContact) {
                    newState.isNoMessagingInUseHintShown =
                        newState.personBySelectedContact.messageTermsStatus != MessageTermsStatus.ACCEPTED;
                } else newState.isNoMessagingInUseHintShown = false;
            }
        }

        return newState;
    }

    showContinueButton(handlers: Handlers): React.ReactElement {
        return (
            <Button
                sx={{ marginLeft: theme.spacing(1) }}
                variant={'contained'}
                disabled={
                    this.state.activeDataBlock === structure.offerBlocks.length - 1 &&
                    this.state.activeDataStep ===
                        structure.offerBlocks[this.state.activeDataBlock].offerSteps.length - 1
                }
                onClick={handlers.onContinueButtonClick}
            >
                {this.props.t('offerDialog:buttonContinue')}
            </Button>
        );
    }

    showSaveLeavePrevention(handlers: Handlers, helpers: Helpers): React.ReactFragment {
        return helpers.hasOfferShownFormErrors() || helpers.hasOfferFormErrors() ? (
            <ConfirmationDialog
                title={this.props.t('dialogs:saveChanges')}
                content={this.props.t('dialogs:savePreventionDialogContentLong')}
                defaultButtonText={this.props.t('dialogs:backToEdit')}
                buttonText2={this.props.t('dialogs:discard')}
                isOpen={this.state.isSaveLeavePreventionOpen}
                onClose={() => this.setState({ isSaveLeavePreventionOpen: false })}
                buttonText1={this.props.t('dialogs:SAVE')}
                buttonAction1={() => {
                    this.setState({ isSaveLeavePreventionOpen: false });
                    handlers.handleSaveClick();
                }}
                buttonAction2={() => {
                    this.props.onClose();
                    this.setState({ isSaveLeavePreventionOpen: false });
                }}
                isButtonAction1Disabled={true}
                actionButton1Tooltip={this.props.t('offerDialog:savingWithFormErrorsHint')}
            />
        ) : (
            <ConfirmationDialog
                title={this.props.t('dialogs:saveChanges')}
                content={this.props.t('dialogs:savePreventionDialogContentLong')}
                defaultButtonText={this.props.t('dialogs:backToEdit')}
                buttonText2={this.props.t('dialogs:discard')}
                isOpen={this.state.isSaveLeavePreventionOpen}
                onClose={() => this.setState({ isSaveLeavePreventionOpen: false })}
                buttonText1={this.props.t('dialogs:SAVE')}
                buttonAction1={() => {
                    this.setState({ isSaveLeavePreventionOpen: false });
                    handlers.handleSaveClick();
                }}
                buttonAction2={() => {
                    this.props.onClose();
                    this.setState({ isSaveLeavePreventionOpen: false });
                }}
            />
        );
    }

    showSaveAndCloseButton(helpers: Helpers, handlers: Handlers) {
        return helpers.hasOfferShownFormErrors() || helpers.hasOfferMissingMandatories() ? (
            wrapButtonWithTooltip(
                <Button
                    onClick={() => handlers.handleSaveClick()}
                    variant={'contained'}
                    sx={{ marginRight: 'auto' }}
                    disabled={
                        !helpers.hasChanges() ||
                        helpers.hasOfferShownFormErrors() ||
                        helpers.hasOfferMissingMandatories()
                    }
                >
                    {this.props.t(
                        this.state.isActive
                            ? 'offerDialog:buttonSaveAndPublishOnMarketplace'
                            : 'offerDialog:buttonSave',
                    )}
                </Button>,
                this.props.t('offerDialog:savingWithFormErrorsHint'),
            )
        ) : (
            <Button
                onClick={() => handlers.handleSaveClick()}
                variant={'contained'}
                sx={{ marginRight: 'auto' }}
                disabled={!helpers.hasChanges()}
            >
                {this.props.t(
                    this.state.isActive ? 'offerDialog:buttonSaveAndPublishOnMarketplace' : 'offerDialog:buttonSave',
                )}
            </Button>
        );
    }

    showBackButton(handlers: Handlers): React.ReactElement {
        return (
            <Button
                variant={'outlined'}
                disabled={this.state.activeDataBlock === 0 && this.state.activeDataStep === 0}
                onClick={handlers.onBackButtonClick}
            >
                {this.props.t('offerDialog:buttonBack')}
            </Button>
        );
    }

    showMarketplaceToggle(handlers: Handlers): React.ReactElement {
        return (
            <Grid
                item
                sx={{
                    marginLeft: theme.spacing(3),
                    backgroundColor: theme.palette.background.default,
                    border: `1px solid ${theme.palette.grey['300']}`,
                    borderRadius: '33px',
                }}
            >
                <FormControlLabel
                    sx={{ paddingRight: theme.spacing(2) }}
                    label={this.props.t('offerDialog:showInMarketplace') as string}
                    labelPlacement="start"
                    disabled={this.props.isOfferProvided}
                    control={wrapButtonWithTooltip(
                        <Switch
                            data-testid="showOnMarketplaceToggle"
                            onChange={handlers.handlePublishOnMarketToggle.bind(this)}
                            checked={this.state.isActive}
                            color="primary"
                        />,
                        this.props.t('offerDialog:showOnMarketplaceHint'),
                    )}
                />
            </Grid>
        );
    }

    showDataStepStepper(helpers: Helpers): React.ReactElement {
        return (
            <Stepper
                activeStep={this.state.activeDataStep}
                orientation="vertical"
                connector={<VerticalStepConnector />}
            >
                {structure.offerBlocks[this.state.activeDataBlock].offerSteps.map((dataStep, idx) => (
                    <Step
                        key={dataStep.title}
                        completed={
                            helpers.isDataStepComplete(dataStep) && !helpers.hasDataStepFormValidationErrors(dataStep)
                        }
                        onClick={() => this.setState({ activeDataStep: idx })}
                    >
                        <StepLabel
                            sx={{ padding: 0 }}
                            StepIconComponent={(stepIconProps) => (
                                <OfferDialogVerticalStepComponent
                                    {...stepIconProps}
                                    error={
                                        helpers.hasDataStepMissingMandatory(dataStep) ||
                                        helpers.hasDataStepShownFormValidationErrors(dataStep)
                                    }
                                    hasMissingMandatories={helpers.hasDataStepMissingMandatory(dataStep)}
                                    offerStep={dataStep}
                                    idx={idx + 1}
                                    usage={'offer'}
                                />
                            )}
                        />
                    </Step>
                ))}
            </Stepper>
        );
    }

    showDialogTitle(helpers: Helpers, handlers: Handlers): ReactNode {
        return (
            <Grid container item direction={'row'} justifyContent={'start'} xs={12} alignContent={'center'}>
                <Grid item container xs={0.3} justifyContent={'start'}>
                    <FiberManualRecordIcon className={this.props.classes.offerDot} />
                </Grid>
                <Grid item xs={11.4}>
                    <Typography
                        sx={{
                            paddingRight: '2%',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontWeight: 600,
                        }}
                    >
                        {helpers.getTitleString()}
                    </Typography>
                </Grid>
                <Grid item container xs={0.3} justifyContent={'end'}>
                    <CloseIcon
                        sx={{ cursor: 'pointer', marginLeft: 'auto', color: 'black' }}
                        onClick={() => handlers.onClose()}
                    />
                </Grid>
            </Grid>
        );
    }

    showStepperAndCurrentStep(helpers: Helpers): ReactNode {
        return (
            <Grid item sx={{ paddingBottom: 4 }}>
                <Grid container justifyContent="center">
                    <Grid
                        item
                        xs={1}
                        lg={4}
                        sx={{
                            borderRight: { xs: 'none', lg: '1px solid lightgray' },
                        }}
                    >
                        {this.showDataStepStepper(helpers)}
                    </Grid>
                    <Grid item xs={10.7} lg={7.7}>
                        {this.state.currentStepComponent &&
                            this.state.currentStepId &&
                            React.createElement(
                                this.state.currentStepComponent,
                                helpers.getComponentProps(this.state.currentStepId),
                            )}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        const helpers = new Helpers(this.props, this.state, this.setState.bind(this));
        const handlers = new Handlers(this.props, this.state, this.setState.bind(this), helpers);

        return this.props.isOpen ? (
            <Dialog
                maxWidth={'xl'}
                open={true}
                onClose={handlers.onDialogClose}
                PaperProps={{ sx: { width: '86vw', maxWidth: '1280px' } }}
            >
                {this.showSaveLeavePrevention(handlers, helpers)}
                <DialogTitle>{this.showDialogTitle(helpers, handlers)}</DialogTitle>
                <DialogContent sx={{ height: '86vh' }}>
                    <Grid container direction="column" maxWidth={'lg'} sx={{ paddingTop: theme.spacing(3) }}>
                        {this.showStepperAndCurrentStep(helpers)}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container alignItems="center">
                        <Grid item>{this.showSaveAndCloseButton(helpers, handlers)}</Grid>
                        <Grid item>{this.showMarketplaceToggle(handlers)}</Grid>
                        <Grid item sx={{ flexGrow: 1 }}>
                            <Grid container justifyContent="flex-end">
                                <Grid item>{this.showBackButton(handlers)}</Grid>
                                <Grid item>{this.showContinueButton(handlers)}</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>
        ) : null;
    }
}

export default withTranslation(['ontofood', 'offerDialog'])(withStyles(styles, { withTheme: true })(OfferDialog));
