import { Percent } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default interface StepTaxProps {
    tax?: number;
}

export const StepTax = (props: StepComponentProps<StepTaxProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['productDataSheet', 'dialogs']);
    const useStyles = makeStyles({
        percentageIcon: {
            right: '89.25%',
            position: 'absolute',
        },
        '@media (min-width: 1200px)': {
            percentageIcon: {
                right: '88%',
            },
        },
        '@media (max-width: 1000px)': {
            percentageIcon: {
                right: '89%',
            },
        },
        '@media (max-width: 960px)': {
            percentageIcon: {
                right: '87%',
            },
        },
    });
    const classes = useStyles();

    useEffect(() => {
        props.setData('StepTax', {
            tax: props.data.tax ?? 19.0,
        });
    }, []);

    const showTitle = (): ReactNode => (
        <Grid container item direction={'row'} md={12} justifyContent={'space-between'}>
            <Grid item sx={{ paddingBottom: theme.spacing(3) }}>
                <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                    {t('productDataSheet:DATASTEP_TAX_TITLE')}
                </Typography>
            </Grid>
            <Grid>
                <Typography sx={{ fontSize: 14 }}>{t('dialogs:mandatory')}</Typography>
            </Grid>
        </Grid>
    );

    const handleNumberChange = (value?: string) => {
        let newValue: number;
        if (value === undefined || isNaN(Number(value))) newValue = 19;
        else newValue = Number(value) < 0 ? 0 : Number(value) > 100 ? 100 : Number(value);

        props.setData('StepTax', { tax: newValue });
    };

    const showTextField = (): ReactNode => (
        <Grid item md={12} display={'grid'}>
            <TextField
                sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                        WebkitTextFillColor: theme.palette.primary.dark,
                    },
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start" className={classes.percentageIcon}>
                            <Percent sx={{ fontSize: 'large', color: theme.palette.primary.dark }} />
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    step: 1,
                    min: 0,
                    max: 100,
                    pattern: '^(d*.?d{0,2}|.d{0,2}|0)$',
                }}
                type={'number'}
                size={'small'}
                value={
                    props.data.tax !== undefined
                        ? props.data.tax.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                        : ''
                }
                label={t('productDataSheet:DATASTEP_TAX_RATE')}
                onChange={(event) => handleNumberChange(event.target.value)}
            />
        </Grid>
    );

    return (
        <Grid container item md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showTextField()}
        </Grid>
    );
};
