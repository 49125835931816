import moment from 'moment/moment';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/styles';

interface StyledDatePickerComponentProps {
    value: string | moment.Moment | null | undefined;
    label: string;
    onChange: (_date: any) => void;
    renderInput: (_props: any) => ReactElement;
    readOnly?: boolean;
    disabled?: boolean;
    disablePast?: boolean;
    disableMaskedInput?: boolean;
    minDate?: moment.Moment | null | undefined;
    maxDate?: moment.Moment | null | undefined;
    shouldShowClearButton?: boolean;
    disableManualInput?: boolean;
}

function StyledDatePickerComponent(props: StyledDatePickerComponentProps) {
    const theme = useTheme();
    const { t } = useTranslation('dialogs');

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const CustomActionBar = (actionBarProps: React.PropsWithChildren<PickersActionBarProps>) => (
        <DialogActions>
            <Button sx={{ color: theme.palette.error.main }} onClick={() => actionBarProps.onClear()}>
                {t('dialogs:remove')}
            </Button>
        </DialogActions>
    );

    return (
        <DesktopDatePicker
            PopperProps={{
                sx: {
                    '& .MuiPaper-root': {
                        borderRadius: 4,
                        boxShadow: '1px 1px 10px 5px rgba(0, 0, 0, 0.08)',
                    },
                    '& .MuiButtonBase-root': {
                        '&.Mui-selected': {
                            color: theme.palette.common.white,
                            fontWeight: 600,
                            '&: hover': {
                                backgroundColor: theme.palette.primary.lighter,
                                color: theme.palette.primary.dark,
                                fontWeight: 900,
                            },
                        },
                    },
                    '& .MuiPickersDay-root': {
                        color: theme.palette.primary.dark,
                        fontWeight: 600,
                        '&: hover': {
                            backgroundColor: theme.palette.primary.lighter,
                            color: theme.palette.primary.dark,
                            fontWeight: 900,
                        },
                    },
                    '& .MuiIconButton-root': {
                        backgroundColor: 'transparent',
                        '&: hover': {
                            color: theme.palette.primary.dark,
                            backgroundColor: theme.palette.primary.lighter,
                        },
                    },
                    '& .PrivatePickersYear-yearButton': {
                        color: theme.palette.primary.dark,
                        fontWeight: 600,
                        '&.Mui-selected': {
                            color: theme.palette.common.white,
                        },
                        '&: hover': {
                            fontWeight: 900,
                            backgroundColor: theme.palette.primary.lighter,
                        },
                    },
                },
            }}
            components={props.shouldShowClearButton ? { ActionBar: CustomActionBar } : undefined}
            componentsProps={props.shouldShowClearButton ? { actionBar: { actions: ['clear'] } } : undefined}
            disablePast={props.disablePast}
            renderInput={
                props.disableManualInput && !props.readOnly
                    ? (iProps) =>
                          props.renderInput({
                              ...iProps,
                              onClick: () => handleOpen(),
                              inputProps: {
                                  ...iProps.inputProps,
                                  readOnly: true,
                              },
                          })
                    : props.renderInput
            }
            label={props.label}
            value={props.value}
            onChange={props.onChange}
            minDate={props.minDate}
            maxDate={props.maxDate}
            disabled={props.disabled}
            readOnly={props.readOnly}
            disableMaskedInput={props.disableMaskedInput}
            open={props.disableManualInput && !props.readOnly ? open : undefined}
            onOpen={props.disableManualInput && !props.readOnly ? handleOpen : undefined}
            onClose={props.disableManualInput && !props.readOnly ? handleClose : undefined}
        />
    );
}

export default StyledDatePickerComponent;
