import { Help, Info, Percent } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { InputAdornment } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import OntofoodProposeDialog from 'components/ontofood/OntofoodProposeDialog';
import OntofoodSelectComponent from 'components/ontofood/OntofoodSelectComponent';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { Ingredient } from 'model/Ingredient';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { wrapWithTooltip } from 'util/style-helpers';

export default interface StepIngredientsProps {
    companyId?: string;
    ingredients?: IngredientWithKey[];
    isRawProduct?: boolean;
}

export interface IngredientWithKey {
    ingredient: Ingredient;
    key: number;
}

export const StepIngredients = (props: StepComponentProps<StepIngredientsProps>) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

    const closeDialog = () => {
        setIsHelpDialogOpen(false);
    };

    const handleDelete = (key: number) => {
        props.setData('StepIngredients', {
            ingredients: props.data.ingredients?.filter((it) => it.key !== key),
        });
    };

    const toggleMainIngredient = (key: number) => {
        props.setData('StepIngredients', {
            ingredients: props.data.ingredients?.map((it) =>
                it.key === key
                    ? { ...it, ingredient: { ...it.ingredient, isMainIngredient: !it.ingredient.isMainIngredient } }
                    : it,
            ),
        });
    };

    const handleAddButton = () => {
        const key = Math.random();
        const newIngredient: IngredientWithKey = {
            ingredient: {
                percentage: '',
                isMainIngredient: false,
                links: { product: undefined },
            },
            key,
        };

        props.setData('StepIngredients', {
            ingredients: [...(props.data.ingredients ?? []), newIngredient],
        });
    };

    const getTooltipContent = (): ReactNode => (
        <Trans
            i18nKey="productDataSheet:DATASTEP_INGREDIENTS_TOOLTIP"
            t={t}
            components={[
                <a
                    style={{ fontWeight: 'bold', color: theme.palette.secondary.darkest }}
                    key={0}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={'https://eur-lex.europa.eu/legal-content/DE/TXT/?uri=celex%3A32011R1169'}
                />,
            ]}
        />
    );

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_INGREDIENTS_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid
            container
            item
            md={12}
            spacing={1}
            sx={{
                fontSize: 14,
                borderRadius: 5,
                backgroundColor: theme.palette.grey[100],
                padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)}`,
                marginBottom: theme.spacing(2),
            }}
        >
            <Grid item md={11} padding={0}>
                <Typography
                    sx={{
                        lineHeight: theme.spacing(2.5),
                    }}
                >
                    {t('productDataSheet:DATASTEP_INGREDIENTS_TEXT')}
                </Typography>
            </Grid>
            <Grid item md={1} sx={{ alignContent: 'center' }}>
                <IconButton
                    size="medium"
                    onClick={() => {
                        setIsHelpDialogOpen(true);
                    }}
                >
                    <Help />
                </IconButton>
            </Grid>
        </Grid>
    );

    const handleProductChange = (selectedProductRef: string | undefined, key: number) => {
        props.setData('StepIngredients', {
            ingredients: props.data.ingredients?.map((ing) => {
                if (ing.key === key) {
                    return { ...ing, ingredient: { ...ing.ingredient, links: { product: selectedProductRef ?? '' } } };
                } else return ing;
            }),
        });
    };

    const showProductSelectField = (ingredient: IngredientWithKey): ReactNode => {
        return (
            <Grid item lg={5} md={5} xs={4}>
                <OntofoodSelectComponent
                    value={ingredient.ingredient.links.product ?? null}
                    onChange={(value) => handleProductChange(value, ingredient.key)}
                    productType={'market'}
                    isProposeDialogHidden={true}
                    label={t('productDataSheet:DATASTEP_INGREDIENTS_CHOOSE_INGREDIENT')}
                    calledFrom={'StepIngredients'}
                />
            </Grid>
        );
    };

    const ontofoodProposalDialog = (): ReactNode => {
        return props.data.companyId ? (
            <OntofoodProposeDialog
                open={isHelpDialogOpen}
                companyId={props.data.companyId}
                close={closeDialog}
                calledFrom={'StepIngredients'}
            />
        ) : null;
    };

    const handlePercentageChange = (key: number, values: string) => {
        const floatValue = parseFloat(values.replace(',', '.'));

        const updatedIngredients = (props.data.ingredients ?? []).map((ing) =>
            ing.key === key
                ? {
                      ...ing,
                      ingredient: {
                          ...ing.ingredient,
                          percentage: !isNaN(floatValue) && floatValue <= 100 && floatValue > 0 ? floatValue : '',
                      },
                  }
                : ing,
        );

        props.setData('StepIngredients', { ingredients: updatedIngredients });
    };

    const showPercentageInput = (ingredient: IngredientWithKey): ReactNode => {
        return (
            <Grid item lg={2.7} md={2.7} xs={3}>
                <TextField
                    sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                            WebkitTextFillColor: theme.palette.primary.dark,
                        },
                        minWidth: '95%',
                    }}
                    InputProps={{
                        endAdornment: ingredient.ingredient.percentage ? (
                            <InputAdornment position="start">
                                <Percent sx={{ fontSize: 'large', color: theme.palette.primary.dark }} />
                            </InputAdornment>
                        ) : undefined,
                    }}
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 100,
                        pattern: '^(d*.?d{0,2}|.d{0,2}|0)$',
                    }}
                    type={'number'}
                    value={
                        ingredient.ingredient.percentage
                            ? ingredient.ingredient.percentage.toLocaleString('en-US', {
                                  minimumFractionDigits: 1,
                                  maximumFractionDigits: 1,
                              })
                            : ''
                    }
                    label={t('productDataSheet:DATASTEP_INGREDIENTS_PROPORTION_OF_THE_INGREDIENT')}
                    onChange={(event) => handlePercentageChange(ingredient.key, event.target.value)}
                />
            </Grid>
        );
    };

    const showMainIngredientCheckbox = (ingredient: IngredientWithKey): ReactNode => (
        <Grid
            item
            container
            lg={3.3}
            md={3.3}
            xs={3}
            direction={'row'}
            justifyContent={'center'}
            sx={{
                alignItems: 'center',
                border: 1,
                borderRadius: 3,
                borderColor: theme.palette.grey[300],
            }}
        >
            <Checkbox
                sx={{
                    '&:hover': {
                        color: theme.palette.primary.dark,
                    },
                }}
                checked={ingredient.ingredient.isMainIngredient}
                onChange={() => toggleMainIngredient(ingredient.key)}
            />
            <Typography>{t('productDataSheet:DATASTEP_INGREDIENTS_MAIN_INGREDIENT')}</Typography>
            {wrapWithTooltip(
                <Info
                    sx={{
                        marginLeft: theme.spacing(1),
                        marginRight: theme.spacing(1),
                        color: theme.palette.primary.dark,
                    }}
                />,
                t('productDataSheet:DATASTEP_INGREDIENTS_TOOLTIP'),
                undefined,
                'bottom',
                getTooltipContent(),
            )}
        </Grid>
    );

    const showDeleteButton = (ingredient: IngredientWithKey): ReactNode => (
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
            <IconButton onClick={() => handleDelete(ingredient.key)}>
                <Delete />
            </IconButton>
        </Grid>
    );

    const showIngredientRows = (): ReactNode => {
        return props.data.ingredients?.map((ingredient) => {
            return (
                <Grid key={ingredient.key} item container md={12} alignItems={'center'}>
                    {showProductSelectField(ingredient)}
                    {showPercentageInput(ingredient)}
                    {showMainIngredientCheckbox(ingredient)}
                    {showDeleteButton(ingredient)}
                </Grid>
            );
        });
    };

    const showAddButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                disabled={
                    !props.data.ingredients ||
                    props.data.ingredients.some((it) => !it.ingredient.links.product) ||
                    props.data.isRawProduct
                }
                startIcon={<AddIcon />}
                variant={'contained'}
                onClick={handleAddButton}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {ontofoodProposalDialog()}
            {showTitle()}
            {showText()}
            {showIngredientRows()}
            {showAddButton()}
        </Grid>
    );
};
