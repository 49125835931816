import * as React from 'react';
import { ComponentBase } from 'resub';
import { OfferStore } from 'store';
import { Offer } from 'model/Offer';
import OfferReferenceItem from 'components/dashboard/marketplace/offer/OfferReferenceComponent';
import OfferDialog from 'components/search/result-items/offers/OfferDialog';

interface OfferDashletItemProps {
    offerRef: string;
    isOfferProvided?: boolean;
    isChosen?: boolean;
}

interface OfferDashletItemState {
    offer?: Offer;
    editDialogOpen?: boolean;
}

class OfferDashletItem extends ComponentBase<OfferDashletItemProps, OfferDashletItemState> {
    protected _buildState(
        props: OfferDashletItemProps,
        initialBuild: boolean,
        incomingState?: Readonly<OfferDashletItemState>,
    ): Partial<OfferDashletItemState> | undefined {
        return {
            offer: OfferStore.getOne(props.offerRef),
        };
    }

    openEditDialog(): void {
        this.setState({ editDialogOpen: true });
    }

    closeEditDialog(): void {
        this.setState({ editDialogOpen: false });
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) {
            return null;
        }
        return (
            <>
                {this.state.editDialogOpen ? (
                    <OfferDialog
                        isOpen
                        offerRef={this.state.offer.links.self}
                        onClose={() => {
                            this.closeEditDialog();
                        }}
                        companyRef={this.state.offer.links.company}
                        isOfferProvided={this.props.isOfferProvided}
                    />
                ) : null}
                <OfferReferenceItem
                    offerRef={this.props.offerRef}
                    onClick={() => this.openEditDialog()}
                    isProvideOfferListItem={this.props.isOfferProvided}
                    isChosen={this.props.isChosen}
                />
            </>
        );
    }
}

export default OfferDashletItem;
