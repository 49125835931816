import { AutoSubscribeStore, autoSubscribeWithKey, StoreBase } from 'resub';
import { ContainerView } from 'model/ContainerView';
import ProductDataSheetDialogHelpers, {
    isContainerValid,
} from 'components/productdatasheet/ProductDataSheetDialogHelpers';
import { CompanyStore } from 'store/index';
import { isUUID } from 'util/helpers';
import ContainerService from 'api/ContainerService';

export interface ContainerSaveData {
    container: ContainerView;
    pdsOrOfferRef: string;
    updateAfterSave?: () => void;
}

const PRODUCT_DATA_SHEET = 'PRODUCT_DATA_SHEET';

@AutoSubscribeStore
class ContainerSaveStoreClass extends StoreBase {
    protected toSave: ContainerSaveData[] = [];

    addOrReplace(saveData: ContainerSaveData): void {
        this.filter(saveData.pdsOrOfferRef, saveData.container.id);
        this.toSave.push(saveData);
    }

    removeByContainerIdAndRef(id: string, pdsOrOfferRef: string): void {
        this.filter(pdsOrOfferRef, id);
    }

    removeAllByRef(pdsOrOfferRef: string): void {
        this.filter(pdsOrOfferRef);
    }

    peekAllByRef(pdsOrOfferRef: string): ContainerSaveData[] {
        return this.toSave.filter((it) => it.pdsOrOfferRef === pdsOrOfferRef);
    }

    @autoSubscribeWithKey(PRODUCT_DATA_SHEET)
    savePDSContainers(ref: string) {
        const toSave = this.shiftAllByRef(ref);
        if (toSave) {
            let next = toSave.shift();
            while (next) {
                if (next.updateAfterSave) this.savePDSContainer(next.container, ref, next.updateAfterSave);
                next = toSave.shift();
            }
        }
    }

    private filter(pdsOrOfferRef: string, id?: string) {
        if (id !== undefined) {
            this.toSave = this.toSave.filter(
                (it) => it.pdsOrOfferRef.concat(it.container.id) !== pdsOrOfferRef.concat(id),
            );
        } else {
            this.toSave = this.toSave.filter((it) => it.pdsOrOfferRef !== pdsOrOfferRef);
        }
    }

    private shiftAllByRef(pdsOrOfferRef: string): ContainerSaveData[] | undefined {
        const found = this.toSave.filter((it) => it.pdsOrOfferRef === pdsOrOfferRef);
        this.filter(pdsOrOfferRef);
        return found.length ? found : undefined;
    }

    private savePDSContainer(containerView: ContainerView, pdsRef: string, updateAfterSave: () => void) {
        if (!isContainerValid(containerView)) return;
        const companyRef = CompanyStore.getSelected()?.links.self;
        if (companyRef && pdsRef && isUUID(containerView.id)) {
            ContainerService.removeProductDataSheetContainer(pdsRef, containerView.id).subscribe(() =>
                ContainerService.addProductDataSheetContainer(
                    companyRef,
                    ProductDataSheetDialogHelpers.getSanitizedContainerWriteView(containerView),
                    pdsRef,
                ).subscribe(() => {
                    updateAfterSave();
                    this.trigger(PRODUCT_DATA_SHEET);
                }),
            );
        } else if (companyRef && pdsRef && !isUUID(containerView.id)) {
            ContainerService.addProductDataSheetContainer(
                companyRef,
                ProductDataSheetDialogHelpers.getSanitizedContainerWriteView(containerView),
                pdsRef,
            ).subscribe(() => {
                updateAfterSave();
                this.trigger(PRODUCT_DATA_SHEET);
            });
        }
    }

    clear() {
        this.toSave = [];
    }
}

export const ContainerSaveStore = new ContainerSaveStoreClass();
export default ContainerSaveStore;
