import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Theme } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { WithStyles, WithTheme } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import ContainerChipsComponent from 'components/container/ContainerChipsComponent';
import ContainerComponent from 'components/container/ContainerComponent';
import HintComponent from 'components/hint/HintComponent';
import ContactSelectComponent from 'components/marketplace/ContactSelectComponent';
import AmountSelectComponentOld from 'components/marketplace/marketitem/AmountSelectComponenOld';
import MarketItemDialogHandlers from 'components/marketplace/marketitem/dialog/MarketItemDialogHandlers';
import { MarketItemDialogHelpers } from 'components/marketplace/marketitem/dialog/MarketItemDialogHelpers';
import PriceEditFieldsComponent from 'components/marketplace/marketitem/PriceEditFieldsComponent';
import ConfirmationDialog from 'components/messaging/chat/messagefactory/infocards/shared/ConfirmationDialog';
import OntofoodSelectComponent from 'components/ontofood/OntofoodSelectComponent';
import LevelOfProcessingComponent from 'components/product/LevelOfProcessingComponent';
import DeleteButtonWithDialog from 'components/shared/DeleteButtonWithDialog';

import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import { CompanyContacts, ContactType } from 'model/Contact';
import { ContainerView, ContainerWriteView } from 'model/ContainerView';
import { Employment, EmploymentStatus } from 'model/Employment';
import { MetaStaticEntityType } from 'model/Meta';
import { Offer, OfferContainers, OfferWriteView } from 'model/Offer';
import { MessageTermsStatus, Person } from 'model/Person';
import { Product } from 'model/Product';
import { Request, RequestWriteView } from 'model/Request';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ComponentBase } from 'resub';
import {
    CompanyContactsStore,
    ContactsStore,
    ContainerStore,
    EmploymentStore,
    MetaStore,
    OfferContainersStore,
    OfferStore,
    PersonStore,
    ProductStore,
    RequestStore,
} from 'store';
import { theme } from 'style/NearbuyStyle';
import { deepCopy, isDeepEquality } from 'util/deep-equality-helpers';
import { FormValidationHelper, withFormValidationHelper, WithFormValidationHelper } from 'util/FormErrors';
import { getUuidFromString, without } from 'util/helpers';
import { Transition, wrapButtonWithTooltip } from 'util/style-helpers';
import { Unit } from 'model/Unit';
import { captureWebEvent } from 'util/AnalyticUtils';

const styles = (theme: Theme) =>
    createStyles({
        dialogContent: {
            padding: theme.spacing(3),
        },
        mandatoryField: {
            fontSize: '14px',
            textAlign: 'right',
            marginBottom: '20px',
        },
        textFieldFullWidth: {
            margin: 0,
            flexGrow: 1,
            width: '100%',
        },
        formControl: {
            minWidth: 120,
        },
        formControlIsPermanent: {
            paddingLeft: 11,
        },
        select: {
            justifyContent: 'flex-end',
            display: 'flex',
            marginRight: theme.spacing(1),
        },
        divider: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        dateHint: {
            padding: 0,
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
            textAlign: 'left',
        },
        sectionHeader: {
            marginTop: theme.spacing(1),
            fontWeight: 'bold',
            LineHeight: '19px',
            fontSize: '15px',
        },
        employeeOverviewButton: {
            marginTop: theme.spacing(1),
        },
        offerDot: {
            fill: theme.palette.secondary.dark,
            marginRight: theme.spacing(1),
            '&:hover': {
                fill: theme.palette.secondary.dark,
            },
        },
        requestDot: {
            fill: theme.palette.primary.main,
            marginRight: theme.spacing(1),
            '&:hover': {
                fill: theme.palette.primary.main,
            },
        },
        hintText: {
            padding: '5px',
            color: '#55430C',
        },
        hintBox: {
            backgroundColor: '#F3E1AA',
            borderRadius: '3px',
            margin: `${theme.spacing(1.5)} 0 0 7`,
        },
    });

export interface MarketItemDialogProperties extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    isOpen: boolean;
    companyRef: string;
    offerRef?: string | null;
    requestRef?: string | null;
    onClose?: () => void;
    onDelete?: () => void;
    isReadOnly?: boolean;
    isOfferProvided?: boolean;
    limitingProductRef?: string;
    calledFrom: string;
}

export interface MarketItemDialogState extends WithFormValidationHelper {
    offer?: Offer;
    offerWriteView?: OfferWriteView;
    initialOfferWriteView?: OfferWriteView;

    request?: Request;
    requestWriteView?: RequestWriteView;
    initialRequestWriteView?: RequestWriteView;

    isOffer: boolean;
    isRequest: boolean;
    isCreateNew: boolean;

    selectedProduct?: Product;
    offerContainers?: OfferContainers;
    containers?: ContainerView[];
    newContainers?: ContainerWriteView[];
    containerIdsToRemove?: string[];
    units?: Unit[];

    currentEmployment?: Employment;
    currentCompanyContacts?: CompanyContacts;
    personBySelectedContact?: Person;
    automaticallySetContact: string | null;

    hasChanges: boolean;
    isSaveButtonEnabled: boolean;
    hasFormValidationErrors: boolean;

    isProductSelectOpen: boolean;
    isSublistOpen: boolean;
    isProductDialogOpen: boolean;
    isOpen: boolean;
    isDeleteDialogOpen: boolean;
    isSaveLeavePreventionOpen: boolean;

    isContactComponentShowing: boolean;
    isPriceUponRequest: boolean;
    isNoMessagingInUseHintShown: boolean;
}

export class MarketItemDialog extends ComponentBase<MarketItemDialogProperties, MarketItemDialogState> {
    @withFormValidationHelper<MarketItemDialog>('validate')
    protected _buildState(
        props: MarketItemDialogProperties,
        initialBuild: boolean,
        incomingState?: MarketItemDialogState,
    ): Partial<MarketItemDialogState> | undefined {
        const isDialogJustOpening = !!(initialBuild || (incomingState && props.isOpen && !incomingState.isOpen));
        const newState: Partial<MarketItemDialogState> = {
            ...incomingState,
            isOpen: props.isOpen,
            isOffer: !!(props.offerRef === null || props.offerRef),
            isRequest: !!(props.requestRef === null || props.requestRef),
            isSaveButtonEnabled: false,
            isCreateNew: props.requestRef === null || props.offerRef === null,
            currentEmployment: EmploymentStore.getSelected(),
            currentCompanyContacts: CompanyContactsStore.getOne(this.props.companyRef),
            offer: props.offerRef ? OfferStore.getOne(props.offerRef) : undefined,
            request: props.requestRef ? RequestStore.getOne(props.requestRef) : undefined,
        };

        if (!newState.isOffer && !newState.isRequest) return newState;

        if (isDialogJustOpening) {
            newState.isPriceUponRequest = false;
            newState.hasFormValidationErrors = false;
            newState.isContactComponentShowing = false;
            newState.automaticallySetContact = null;
            if (newState.isOffer) {
                if (props.offerRef) {
                    OfferStore.invalidateCache(props.offerRef);
                    OfferContainersStore.invalidateCache(props.offerRef);
                }
                newState.newContainers = new Array<ContainerWriteView>();
                newState.containerIdsToRemove = new Array<string>();
                if (newState.offer) {
                    newState.offerWriteView = OfferWriteView.create(newState.offer);
                } else {
                    newState.offerWriteView = OfferWriteView.create();
                }
                newState.initialOfferWriteView = deepCopy(newState.offerWriteView);
                newState.isPriceUponRequest =
                    newState.offerWriteView.pricePerUnit === null || newState.offerWriteView.pricePerUnit === undefined;
            }

            if (newState.isRequest) {
                if (props.requestRef) {
                    RequestStore.invalidateCache(props.requestRef);
                }
                if (newState.request) {
                    newState.requestWriteView = RequestWriteView.create(newState.request);
                } else {
                    newState.requestWriteView = RequestWriteView.create();
                }
                newState.initialRequestWriteView = deepCopy(newState.requestWriteView);
            }
        }

        newState.units = MetaStore.getOne(MetaStaticEntityType.UNIT)?.data as Unit[];

        const isReadyForOffer = newState.isOffer && newState.offerWriteView && newState.initialOfferWriteView;
        const isReadyForRequest = newState.isRequest && newState.requestWriteView && newState.initialRequestWriteView;

        if (!isReadyForOffer && !isReadyForRequest) return newState;

        if (newState.offerWriteView?.category) {
            newState.selectedProduct = incomingState?.offerWriteView?.category
                ? ProductStore.getOne(incomingState.offerWriteView.category)
                : undefined;
        } else if (newState.requestWriteView?.category) {
            newState.selectedProduct = incomingState?.requestWriteView?.category
                ? ProductStore.getOne(incomingState.requestWriteView.category)
                : undefined;
        }

        if (this.props.offerRef) {
            newState.offerContainers = OfferContainersStore.getOne(this.props.offerRef);
        }
        if (newState.offerContainers) {
            newState.containers = newState.offerContainers.containerRefs.map((cr) => ContainerStore.getOne(cr)!);
        }

        const contactRef = newState.offerWriteView?.contact ?? newState.requestWriteView?.contact;
        if (contactRef) {
            const contact = ContactsStore.getOne(contactRef);
            if (contact) {
                newState.personBySelectedContact = PersonStore.getOne(contact.links.person);
                if (newState.personBySelectedContact) {
                    newState.isNoMessagingInUseHintShown =
                        newState.personBySelectedContact.messageTermsStatus != MessageTermsStatus.ACCEPTED;
                }
            }
        }

        const hasWriteViewChanges = newState.isOffer
            ? !!(newState.offerWriteView && newState.initialOfferWriteView) &&
              !isDeepEquality(
                  newState.isCreateNew ? without(newState.offerWriteView, 'contact') : newState.offerWriteView,
                  newState.isCreateNew
                      ? without(newState.initialOfferWriteView, 'contact')
                      : newState.initialOfferWriteView,
              )
            : !!(newState.requestWriteView && newState.initialRequestWriteView) &&
              !isDeepEquality(
                  newState.isCreateNew ? without(newState.requestWriteView, 'contact') : newState.requestWriteView,
                  newState.isCreateNew
                      ? without(newState.initialRequestWriteView, 'contact')
                      : newState.initialRequestWriteView,
              );

        const areNewContainersToSave = !!(newState.newContainers && newState.newContainers?.length);

        const areContainersToDelete = !!newState.containerIdsToRemove?.length;

        const areMandatoryValuesGiven = newState.isOffer
            ? !!(
                  newState.offerWriteView?.totalAmount.amount !== undefined &&
                  newState.offerWriteView.totalAmount.unit &&
                  newState.offerWriteView.category
              )
            : !!(
                  newState.requestWriteView?.totalAmount.amount !== undefined &&
                  newState.requestWriteView.totalAmount.unit &&
                  newState.requestWriteView.category
              );

        if (props.isOfferProvided && newState.offerWriteView) {
            newState.offerWriteView.active = true;
        }

        const contactFromWriteView = newState.offerWriteView?.contact ?? newState.requestWriteView?.contact;
        newState.hasChanges =
            hasWriteViewChanges ||
            areNewContainersToSave ||
            areContainersToDelete ||
            (newState.isCreateNew ? newState.automaticallySetContact != contactFromWriteView : false);

        newState.isSaveButtonEnabled =
            (newState.hasChanges || (!this.state.offer?.active && this.props.isOfferProvided)) &&
            MarketItemDialogHelpers.areAllNewContainersValid(newState.newContainers) &&
            areMandatoryValuesGiven &&
            !newState.hasFormValidationErrors;

        return newState;
    }

    componentDidUpdate(
        prevProps: Readonly<MarketItemDialogProperties>,
        prevState: MarketItemDialogState,
        prevContext: any,
    ) {
        super.componentDidUpdate(prevProps, prevState, prevContext);
        const hasFormValidationErrors = this.state.formValidationHelper.hasErrors();
        if (prevState.hasFormValidationErrors !== hasFormValidationErrors) {
            this.setState({ hasFormValidationErrors });
        }
    }

    validate(
        formHelper: FormValidationHelper,
        prevState: MarketItemDialogState,
        props: MarketItemDialogProperties,
    ): void {
        MarketItemDialogHelpers.validate(formHelper, prevState, props);
    }

    getTitle(): string {
        const tOffer = this.props.t('offer:offer');
        const tRequest = this.props.t('offer:request');
        const tEdit = this.props.t('offer:edit');
        const tCreate = this.props.t('offer:create');

        return this.state.isOffer
            ? this.props.isReadOnly
                ? tOffer
                : `${tOffer} ${this.state.offer ? tEdit : tCreate}`
            : this.props.isReadOnly
              ? tRequest
              : `${tRequest} ${this.state.request ? tEdit : tCreate}`;
    }

    showPriceEditFields(handlers: MarketItemDialogHandlers, helpers: MarketItemDialogHelpers): ReactNode {
        const totalAmount = helpers.getTotalAmount();
        if (!this.state.isOffer || !this.state.offerWriteView || !totalAmount) return null;

        return (
            <PriceEditFieldsComponent
                usage={'marketItemDialog'}
                totalAmount={totalAmount}
                pricePerUnit={this.state.offerWriteView.pricePerUnit ?? null}
                pricePerUnitFormError={this.state.formValidationHelper.getFormError('pricePerUnit')}
                graduatedPrices={this.state.offerWriteView.graduatedPrices ?? []}
                isPriceHintVisible={this.state.isPriceUponRequest}
                marketItemDialogHandlers={handlers}
            />
        );
    }

    showSaveLeavePrevention(handlers: MarketItemDialogHandlers): React.ReactFragment {
        return (
            <ConfirmationDialog
                title={this.props.t('dialogs:saveChanges')}
                content={this.props.t('dialogs:savePreventionDialogContentLong')}
                defaultButtonText={this.props.t('dialogs:backToEdit')}
                buttonText2={this.props.t('dialogs:discard')}
                isOpen={this.state.isSaveLeavePreventionOpen}
                onClose={() => {
                    this.setState({ isSaveLeavePreventionOpen: false });
                }}
                buttonText1={this.props.t('dialogs:SAVE')}
                buttonAction1={handlers.onSave}
                isButtonAction1Disabled={!this.state.isSaveButtonEnabled}
                actionButton1Tooltip={
                    !this.state.isSaveButtonEnabled ? this.props.t('tooltips:disabledSaveButton') : ''
                }
                buttonAction2={handlers.onConfirmationDialogDiscardClick}
            />
        );
    }

    showDialogTitle(handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <Grid container direction={'row'} justifyContent={'space-between'}>
                <Grid container sm={6} item alignItems={'center'}>
                    <FiberManualRecordIcon
                        className={this.state.isOffer ? this.props.classes.offerDot : this.props.classes.requestDot}
                    />
                    {this.getTitle()}
                </Grid>
                <FormControlLabel
                    control={wrapButtonWithTooltip(
                        <Switch
                            onChange={(event) => {
                                if (this.state.isOffer) {
                                    handlers.updateOfferWriteView({
                                        active: event.target.checked,
                                    });
                                } else {
                                    handlers.updateRequestWriteView({
                                        active: event.target.checked,
                                    });
                                }
                            }}
                            checked={
                                this.state.isOffer
                                    ? this.state.offerWriteView?.active
                                    : this.state.requestWriteView?.active
                            }
                            data-testid="showOnMarketplaceToggle"
                            color="primary"
                            disabled={this.props.isOfferProvided}
                        />,
                        this.props.isOfferProvided ? this.props.t('offer:showOnMarketplaceHint') : undefined,
                    )}
                    label={this.props.t('offer:showInMarketplace') as string}
                />
            </Grid>
        );
    }

    showMandatoryLegend(): ReactNode {
        return (
            <Typography className={this.props.classes.mandatoryField}>{this.props.t('common:mandatory')}</Typography>
        );
    }

    showOntofoodSelect(handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <OntofoodSelectComponent
                calledFrom={'MarketItemDialog'}
                value={this.state.isOffer ? this.state.offerWriteView?.category : this.state.requestWriteView?.category}
                onChange={handlers.onOntofoodSelectChange}
                productType={'market'}
                limitingProductRef={this.props.limitingProductRef}
                isReadOnly={this.state.isOffer ? this.props.offerRef !== null : this.props.requestRef !== null}
            />
        );
    }

    showTotalAmountSelect(handlers: MarketItemDialogHandlers, helpers: MarketItemDialogHelpers): ReactNode {
        const totalAmount = helpers.getTotalAmount();
        if (!totalAmount) return null;
        return (
            <AmountSelectComponentOld
                amountValue={totalAmount.amount ? totalAmount.amount : ''}
                onAmountChange={handlers.handleTotalAmountAmountChange}
                amountLabel={
                    this.state.offerWriteView?.isPermanent
                        ? this.props.t('offer:maxQuantityPerDay')
                        : this.props.t('offer:amount')
                }
                amountFormError={this.state.formValidationHelper.getFormError('totalAmount')}
                unitValue={totalAmount.unit}
                onUnitChange={handlers.handleTotalAmountUnitChange}
                isUnitReadOnly={this.state.isOffer ? this.props.offerRef !== null : this.props.requestRef !== null}
                isAmountRequired
                fullLabelWidth
            />
        );
    }

    showMinAmountSelect(handlers: MarketItemDialogHandlers, helpers: MarketItemDialogHelpers): ReactNode {
        const totalAmount = helpers.getTotalAmount();
        const minAmount = helpers.getMinAmount();
        if (!totalAmount || !minAmount) return null;
        return (
            <AmountSelectComponentOld
                amountValue={minAmount.amount ? minAmount.amount : ''}
                onAmountChange={handlers.handleMinAmountAmountChange}
                amountLabel={this.props.t('offer:minimumPurchase')}
                amountFormError={this.state.formValidationHelper.getFormError('minAmount')}
                unitValue={minAmount.unit}
                onUnitChange={handlers.handleMinAmountUnitChange}
                typeLimit={helpers.getTotalAmountUnit()?.unitType}
                unitLimit={helpers.getTotalAmountUnit()}
                isUnitReadOnly={this.props.offerRef !== null}
            />
        );
    }

    showDateFromPicker(momentFrom: Moment, handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentFrom}
                    disablePast
                    label={this.props.t('offer:dateFrom')}
                    onChange={handlers.onDateFromChange}
                    readOnly={this.props.isReadOnly}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            {...this.state.formValidationHelper.getFormError('noFromDate')}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            required
                        />
                    )}
                />
            </LocalizationProvider>
        );
    }

    showDateEndPicker(momentEnd: Moment, momentFrom: Moment, handlers: MarketItemDialogHandlers): ReactNode {
        return (
            <LocalizationProvider dateAdapter={DateAdapter}>
                <StyledDatePickerComponent
                    value={momentEnd ?? ''}
                    label={`${this.props.t('offer:dateUntil')}`}
                    minDate={momentFrom}
                    readOnly={this.props.isReadOnly}
                    onChange={handlers.onDateEndChange}
                    renderInput={(props) => (
                        <TextField
                            {...props}
                            {...this.state.formValidationHelper.getFormError('noUntilDate')}
                            sx={{
                                button: {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            required={!this.state.offerWriteView?.isPermanent}
                        />
                    )}
                    disablePast
                />
            </LocalizationProvider>
        );
    }

    showDateHint(momentEnd: Moment): ReactNode {
        return momentEnd ? (
            <Typography className={this.props.classes.dateHint}>
                {this.state.isOffer ? this.props.t('offer:dateHint') : this.props.t('dialogs:dateHintRequest')}
            </Typography>
        ) : null;
    }

    showProductDescriptionInput(handlers: MarketItemDialogHandlers): ReactNode {
        const description = this.state.offerWriteView?.description ?? this.state.requestWriteView?.description;
        return (
            <TextField
                id="description"
                label={this.props.t('offer:productDescription')}
                className={this.props.classes.textFieldFullWidth}
                value={description}
                onChange={handlers.onProductDescriptionChange}
                multiline
                inputProps={{
                    readOnly: this.props.isReadOnly,
                }}
            />
        );
    }

    showLevelsOfProcessing(handlers: MarketItemDialogHandlers): ReactNode {
        const levelsOfProcessing =
            this.state.offerWriteView?.levelsOfProcessing ?? this.state.requestWriteView?.levelsOfProcessing ?? [];
        if (!levelsOfProcessing) return null;
        return (
            <LevelOfProcessingComponent
                selectedLopRefs={levelsOfProcessing}
                remove={handlers.removeLevelOfProcessing}
                update={handlers.updateLevelsOfProcessing}
            />
        );
    }

    showContainerTitleRowWithAddButton(
        handlers: MarketItemDialogHandlers,
        helpers: MarketItemDialogHelpers,
    ): ReactNode {
        const totalAmountUnit = helpers.getTotalAmountUnit();
        return [
            <Grid container item md={6} key={'container-title-grid'}>
                <Typography
                    className={this.props.classes.sectionHeader}
                    sx={{
                        color: !totalAmountUnit ? theme.palette.action.disabled : theme.palette.primary.dark,
                    }}
                >
                    {this.props.t('offer:container')}
                </Typography>
            </Grid>,
            <Grid container item md={6} justifyContent={'flex-end'} key={'container-add-button-grid'}>
                <Button onClick={handlers.addNewContainer} disabled={!totalAmountUnit} variant={'outlined'}>
                    {this.props.t('offer:addContainer')}
                </Button>
            </Grid>,
        ];
    }

    showOfferContainers(handlers: MarketItemDialogHandlers): ReactNode {
        return (
            !!(this.state.offerContainers && this.state.offerContainers.containerRefs.length) && (
                <ContainerChipsComponent
                    containerRefs={this.state.offerContainers.containerRefs.filter(
                        (ref) => !(this.state.containerIdsToRemove?.some((id) => ref.includes(id)) ?? true),
                    )}
                    removeById={handlers.removeContainerById}
                />
            )
        );
    }

    showNewContainers(handlers: MarketItemDialogHandlers, helpers: MarketItemDialogHelpers): ReactNode {
        const containers = this.state.containers
            ? this.state.containers.map((container) => {
                  return new ContainerWriteView(container.containerType, container.amount, container.unit);
              })
            : [];
        return (
            !!this.state.newContainers?.length &&
            containers.concat(this.state.newContainers).map((container, index) => {
                if (!container) return undefined;
                if (index >= containers.length) {
                    return (
                        <ContainerComponent
                            container={container}
                            onUpdate={handlers.handleContainerUpdate}
                            offerUnit={helpers.getTotalAmountUnit()}
                            remove={handlers.removeNewContainer}
                            key={index}
                            isDuplicate={MarketItemDialogHelpers.findDuplicatePositions(
                                containers.concat(this.state.newContainers!),
                            ).includes(index)}
                        />
                    );
                }
            })
        );
    }

    showContactSelection(handlers: MarketItemDialogHandlers): ReactNode {
        const contact = this.state.offerWriteView?.contact ?? this.state.requestWriteView?.contact;

        return (
            <ContactSelectComponent
                type={this.state.isOffer ? ContactType.SALES : ContactType.PROCUREMENT}
                preSelectedContactRef={
                    this.state.isCreateNew
                        ? contact != this.state.automaticallySetContact
                            ? contact ?? null
                            : undefined
                        : contact ?? null
                }
                companyRef={this.props.companyRef}
                onHiddenChange={handlers.onContactSelectComponentHiddenChange}
                onChange={handlers.onSelectedContactRefChange}
            />
        );
    }

    showDoesNotUseMessagingHint(): React.ReactFragment | null {
        if (this.state.isNoMessagingInUseHintShown) {
            return <HintComponent isWarningSymbolShown={true} text={this.props.t('dialogs:doesNotUseMessagingHint')} />;
        } else return null;
    }

    showContactMissingHint(): ReactElement | null {
        const selectedContact = this.state.offerWriteView?.contact ?? this.state.requestWriteView?.contact;
        if (!this.state.offerWriteView && !this.state.requestWriteView) return null;

        if (!this.state.currentEmployment) return null;

        const ownCompanyUuid = getUuidFromString(this.state.currentEmployment.links.company);

        const isContactNotAvailable = !this.state.currentCompanyContacts?.contactPersons.length;
        const hint = isContactNotAvailable
            ? this.state.isOffer
                ? 'offer:NoContactAvailable'
                : 'request:NoContactAvailable'
            : !selectedContact
              ? this.state.isOffer
                  ? this.state.currentEmployment.status === EmploymentStatus.MANAGER
                      ? 'offer:adminContactNotSelected'
                      : 'offer:employeeContactNotSelected'
                  : this.state.currentEmployment.status === EmploymentStatus.MANAGER
                    ? 'request:adminContactNotSelected'
                    : 'request:employeeContactNotSelected'
              : undefined;

        return (
            <>
                {!!hint && <HintComponent text={this.props.t(hint)} isWarningSymbolShown={true} />}
                {isContactNotAvailable && (
                    <Grid container item justifyContent={'end'} style={{ margin: 7 }} md={12}>
                        <Button
                            className={this.props.classes.employeeOverviewButton}
                            component={Link}
                            to={'/edit/company/' + ownCompanyUuid + '/employees'}
                            variant="outlined"
                        >
                            {this.props.t('offer:toEmployeeOverview')}
                        </Button>
                    </Grid>
                )}
            </>
        );
    }

    showDialogButtons(handlers: MarketItemDialogHandlers): ReactNode {
        return [
            !this.props.isReadOnly && this.props.onDelete && (this.state.offer || this.state.request) && (
                <DeleteButtonWithDialog
                    onDelete={() => {
                        if (this.props.onDelete) {
                            this.props.onDelete();
                            captureWebEvent(`delete-dialog-calledFrom-${this.props.calledFrom}`);
                        }
                    }}
                    key={'delete-button'}
                />
            ),
            !this.props.isReadOnly ? (
                <Grid container spacing={1} justifyContent={'flex-end'} key={'dialog-button-grid-container'}>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handlers.onCancel();
                                handlers.captureOfferAndRequestEvent('cancel');
                            }}
                            variant={'outlined'}
                        >
                            {this.props.t('dialogs:CANCEL')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => {
                                handlers.onSave();
                                handlers.captureOfferAndRequestEvent('save');
                            }}
                            variant="contained"
                            disabled={!this.state.isSaveButtonEnabled}
                        >
                            {this.props.t('dialogs:SAVE')}
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Button onClick={this.props.onClose}>{this.props.t('dialogs:BACK')}</Button>
            ),
        ];
    }

    showDialogContent(handlers: MarketItemDialogHandlers, helpers: MarketItemDialogHelpers): ReactNode {
        if (!this.state.offerWriteView && !this.state.requestWriteView) return null;

        const momentFrom = moment(
            this.state.isOffer ? this.state.offerWriteView!.dateFrom : this.state.requestWriteView!.dateFrom,
        );
        const momentEnd = moment(
            this.state.isOffer ? this.state.offerWriteView!.dateEnd : this.state.requestWriteView!.dateEnd,
        );

        return (
            <Container>
                {this.showProvideOfferHint()}
                {this.showMandatoryLegend()}
                <Grid container spacing={2} alignItems="flex-start">
                    <Grid
                        item
                        container
                        spacing={1}
                        sm={6}
                        direction={'column'}
                        sx={{
                            paddingLeft: theme.spacing(1),
                        }}
                    >
                        <Grid item sm={12}>
                            {this.showOntofoodSelect(handlers)}
                        </Grid>
                        {this.showTotalAmountSelect(handlers, helpers)}
                        {this.state.isOffer && this.showMinAmountSelect(handlers, helpers)}
                        {this.state.isOffer && this.showIsPermanentOfferSwitch(handlers)}
                    </Grid>
                    <Grid container item spacing={1} sm={6} direction={'column'}>
                        <Grid container item spacing={1} sm={12} direction={'row'} justifyContent={'flex-start'}>
                            <Grid item sm={6}>
                                {this.showDateFromPicker(momentFrom, handlers)}
                            </Grid>
                            <Grid item sm={6}>
                                {this.showDateEndPicker(momentEnd, momentFrom, handlers)}
                            </Grid>
                            <Grid item sm={12}>
                                {this.showDateHint(momentEnd)}
                            </Grid>
                            <Grid item sm={12} justifyContent={'flex-start'}>
                                {this.showProductDescriptionInput(handlers)}
                            </Grid>
                            <Grid item sm={12} justifyContent={'center'}>
                                {this.showLevelsOfProcessing(handlers)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider className={this.props.classes.divider} />
                {this.state.isOffer && <Grid>{this.showPriceEditFields(handlers, helpers)}</Grid>}
                {this.state.isOffer && <Divider className={this.props.classes.divider} />}
                {this.state.isOffer && (
                    <Grid container justifyContent={'space-around'}>
                        {this.showContainerTitleRowWithAddButton(handlers, helpers)}
                    </Grid>
                )}
                {this.state.isOffer && this.showOfferContainers(handlers)}
                {this.state.isOffer && this.showNewContainers(handlers, helpers)}
                {this.state.isOffer && <Divider className={this.props.classes.divider} />}
                <Grid container item style={{ margin: 0, padding: 0 }} md={12} alignItems="flex-start">
                    {this.showContactSelection(handlers)}
                </Grid>
                {this.showDoesNotUseMessagingHint()}
                {this.showContactMissingHint()}
            </Container>
        );
    }

    showProvideOfferHint(): ReactNode {
        if (this.props.isOfferProvided && this.state.offer) {
            return (
                <Grid sx={{ paddingBottom: theme.spacing(2) }}>
                    <HintComponent text={this.props.t('request:editOfferHint')} isInformationSymbolShown={true} />
                </Grid>
            );
        } else return null;
    }

    showIsPermanentOfferSwitch(handlers: MarketItemDialogHandlers): ReactNode {
        return process.env.REACT_APP_NEARBUY_FEATURE_SHOW_UNFINISHED_FEATURES === 'true' ? (
            <FormControlLabel
                control={wrapButtonWithTooltip(
                    <Switch
                        onChange={(event) => {
                            handlers.updateOfferWriteView({ isPermanent: event.target.checked });
                        }}
                        checked={this.state.offerWriteView?.isPermanent}
                        color="primary"
                        disabled={this.props.isOfferProvided}
                        data-testid={'suitableForPermanent'}
                    />,
                    this.props.isOfferProvided ? this.props.t('offer:suitableForPermanent') : undefined,
                )}
                label={this.props.t('offer:suitableForPermanent')}
                className={this.props.classes.formControlIsPermanent}
            />
        ) : null;
    }

    render(): React.ReactElement | null {
        const helpers = new MarketItemDialogHelpers(this.state);
        const handlers = new MarketItemDialogHandlers(this.props, this.state, this.setState.bind(this), helpers);

        if (!this.state.isOffer && !this.state.isRequest) return null;
        return (
            <Dialog
                open={this.props.isOpen ?? false}
                maxWidth="md"
                fullWidth
                sx={{ padding: theme.spacing(2) }}
                TransitionComponent={Transition}
                onClose={handlers.onClose}
            >
                {this.showSaveLeavePrevention(handlers)}
                <DialogTitle>{this.showDialogTitle(handlers)}</DialogTitle>
                <DialogContent className={this.props.classes.dialogContent}>
                    {this.showDialogContent(handlers, helpers)}
                </DialogContent>
                <DialogActions>{this.showDialogButtons(handlers)}</DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation(['offer', 'request', 'common', 'dialogs', 'tooltips'])(
    withStyles(styles, { withTheme: true })(MarketItemDialog),
);
