import { Box, Divider, Grid, ListItemButton, styled, Typography } from '@mui/material';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import ListItem from '@mui/material/ListItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { SearchFilterStore } from 'store';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { captureWebEvent } from 'util/AnalyticUtils';

type OrganicFilterComponentProperties = WithTranslation;

interface OrganicFilterComponentState {
    isOrganic: boolean;
    isInTransition: boolean;
    isSublistOpened: boolean;
}

export const CheckboxStyled = styled(Checkbox)(
    ({ theme }) => `
    &:hover {
        color: ${theme.palette.primary.dark};
    }
`,
);

export class OrganicFilterComponent extends ComponentBase<
    OrganicFilterComponentProperties,
    OrganicFilterComponentState
> {
    protected _buildState(): Partial<OrganicFilterComponentState> | undefined {
        return {
            isOrganic: SearchFilterStore.getIsOrganic(),
            isInTransition: SearchFilterStore.getIsInTransition(),
        };
    }

    handleClick = (): void => {
        this.setState({ isSublistOpened: !this.state.isSublistOpened });
    };

    searchIsOrganic(isChecked: boolean): void {
        this.setState({ isOrganic: isChecked });
        SearchFilterStore.setIsOrganic(isChecked);
    }

    searchIsInTransition(isChecked: boolean): void {
        this.setState({ isInTransition: isChecked });
        SearchFilterStore.setIsInTransition(isChecked);
    }

    getSubList(): React.ReactNode {
        return (
            <Collapse
                in={!this.state.isSublistOpened}
                timeout="auto"
                unmountOnExit
                addEndListener={(): void => {
                    return;
                }}
            >
                <List component="div" disablePadding sx={{ paddingLeft: 4 }}>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <CheckboxStyled
                                    color="primary"
                                    checked={this.state.isOrganic}
                                    onClick={() => captureWebEvent('search-organic-filter')}
                                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean): void =>
                                        this.searchIsOrganic(isChecked)
                                    }
                                />
                            }
                            label={this.props.t('search:certified')}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <CheckboxStyled
                                    color="primary"
                                    checked={this.state.isInTransition}
                                    onClick={() => captureWebEvent('search-certificate-filter')}
                                    onChange={(_event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean): void =>
                                        this.searchIsInTransition(isChecked)
                                    }
                                />
                            }
                            label={this.props.t('search:inTransition')}
                        />
                    </ListItem>
                </List>
            </Collapse>
        );
    }

    render(): React.ReactElement {
        return (
            <>
                <Divider />
                <ListItemButton
                    sx={{
                        '&:hover': {
                            color: 'common.black',
                        },
                    }}
                    onClick={this.handleClick}
                >
                    <Grid container gap={1} wrap={'nowrap'}>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {this.props.t('search:organic')}
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                            }}
                        >
                            <Tooltip title={this.props.t('search:organicTooltip')}>
                                <InfoOutlinedIcon
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'primary.main',
                                        borderRadius: '50%',
                                    }}
                                />
                            </Tooltip>
                        </Box>
                        {this.state.isSublistOpened ? <ExpandLess /> : <ExpandMore />}
                    </Grid>
                </ListItemButton>
                {this.getSubList()}
                <Divider />
            </>
        );
    }
}

export default withTranslation('search')(OrganicFilterComponent);
