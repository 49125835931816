import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import OntofoodProposeDialog from 'components/ontofood/OntofoodProposeDialog';
import OntofoodSearchField from 'components/ontofood/OntofoodSearchField';
import { Company } from 'model/Company';
import { Product } from 'model/Product';
import * as React from 'react';
import { CSSProperties } from 'react';
import { ComponentBase } from 'resub';
import { CompanyStore, ProductStore } from 'store';
import { captureWebEvent } from 'util/AnalyticUtils';

const _styles = (theme: Theme) =>
    createStyles({
        box: {
            marginRight: theme.spacing(1),
        },
        centerIt: {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            paddingLeft: theme.spacing(1),
        },
        iconButton: {
            padding: theme.spacing(1),
        },
        searchField: {
            flexGrow: 1,
        },
    });

interface OntofoodSelectComponentProps extends WithStyles<typeof _styles> {
    value: string | null | undefined;
    onChange: (productRef: string | undefined) => void;
    productType: keyof Pick<Product, 'company' | 'market'>;
    limitingProductRef?: string;
    isReadOnly?: boolean;
    hasErrors?: boolean;
    helperText?: string;
    label?: string;
    isProposeDialogHidden?: boolean;
    calledFrom: string;
    autoCompleteStyles?: CSSProperties;
}

interface OntofoodSelectComponentState {
    isHelpDialogOpen?: boolean;
    company: Company;
    selectedProduct: Product;
}

class OntofoodSelectComponent extends ComponentBase<OntofoodSelectComponentProps, OntofoodSelectComponentState> {
    protected _buildState(props: OntofoodSelectComponentProps): Partial<OntofoodSelectComponentState> | undefined {
        return {
            company: CompanyStore.getSelected(),
            selectedProduct: props.value ? ProductStore.getOne(props.value) : undefined,
        };
    }

    handleProductChange(product: Product | null | undefined) {
        this.props.onChange(product?.links.self);
    }

    render(): React.ReactElement | null {
        const autoCompleteProps = { autoCompleteStyles: { width: '80%' } };
        return (
            <Grid
                container
                item
                sm={this.props.isProposeDialogHidden ? undefined : 12}
                className={this.props.classes.box}
                sx={{ display: this.props.isProposeDialogHidden ? 'grid' : 'flex' }}
            >
                <OntofoodSearchField
                    {...autoCompleteProps}
                    label={this.props.label}
                    readOnly={this.props.isReadOnly ?? false}
                    value={this.state.selectedProduct}
                    onChange={(product) => this.handleProductChange(product?.value)}
                    productType={this.props.productType}
                    required
                    limitingProductRef={this.props.limitingProductRef}
                    hasErrors={this.props.hasErrors}
                    helperText={this.props.helperText}
                />
                {!this.props.isProposeDialogHidden && (
                    <Box className={this.props.classes.centerIt}>
                        <IconButton
                            onClick={(): void => {
                                captureWebEvent(
                                    `my_market-add-assortment-suggest-product-calledFrom-${this.props.calledFrom}`,
                                );
                                this.setState({
                                    isHelpDialogOpen: true,
                                });
                            }}
                            className={this.props.classes.iconButton}
                            size="large"
                        >
                            <ContactSupportIcon />
                        </IconButton>
                        {this.state.company && (
                            <OntofoodProposeDialog
                                open={this.state.isHelpDialogOpen}
                                companyId={this.state.company.id}
                                close={() => {
                                    this.setState({ isHelpDialogOpen: false });
                                }}
                                calledFrom={this.props.calledFrom}
                            />
                        )}
                    </Box>
                )}
            </Grid>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(OntofoodSelectComponent);
