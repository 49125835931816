import { WithStyles, WithTheme } from '@mui/styles';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { Button, InputAdornment, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { DeliveryStatus, RecurringOrder, RecurringOrderEntry } from 'model/RecurringOrder';
import * as React from 'react';
import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RecurringOrderTableRowData } from 'components/marketplace/recurring-order/RecurringOrderTableRow';
import IconButton from '@mui/material/IconButton';
import { captureWebEvent } from 'util/AnalyticUtils';
import IconComponent from 'components/shared/IconComponent';
import Chip from '@mui/material/Chip';
import withStyles from '@mui/styles/withStyles';
import DialogContent from '@mui/material/DialogContent';
import { theme } from 'style/NearbuyStyle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import moment, { Moment } from 'moment/moment';
import TextField from '@mui/material/TextField';
import TableHead from '@mui/material/TableHead';
import TradeTableHeaderSelect from 'components/marketplace/tradetable/TradeTrableHeaderSelect';
import { Offer } from 'model/Offer';
import { ContainerStore, OfferStore, RecurringOrderStore } from 'store';
import { Delivery, DeliveryEntry, DeliveryEntryUpdateWriteView, DeliveryUpdateWriteView } from 'model/Delivery';
import { ContainerView } from 'model/ContainerView';
import { Pair } from 'util/Types';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeliveryService from 'api/DeliveryService';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Amount } from 'model/Amount';
import { isNullOrUndefined } from 'util/helpers';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            marginRight: theme.spacing(0.5),
            marginBottom: theme.spacing(0.5),
        },
        bigBoldTypography: {
            fontSize: 'large',
            fontWeight: 'bold',
        },
        selectInput: {
            backgroundColor: theme.palette.primary.lighter,
            borderRadius: 5,
            paddingLeft: theme.spacing(0.3),
        },
        iconClose: {
            fontSize: '1.3em',
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.primary.dark,
            },
        },
        tableBorderBox: {
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            border: '1px solid',
            maxWidth: '100%',
            width: '100%',
            borderColor: theme.palette.grey['300'],
        },
        iconButton: {
            padding: 0,
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        detailsCell: {
            width: '25%',
            maxWidth: '25%',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: 0,
            paddingRight: 0,
        },
        editCell: {
            width: '17%',
            maxWidth: '17%',
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(0.5),
            borderTop: '1px solid',
            borderBottom: '1px solid',
            borderTopColor: theme.palette.grey['300'].toString() + '!important',
            borderBottomColor: theme.palette.grey['300'].toString() + '!important',
        },
        viewCell: {
            width: '17%',
            maxWidth: '17%',
            padding: theme.spacing(0.5),
            borderTop: '1px solid !important',
            borderBottom: '1px solid !important',
            borderTopColor: theme.palette.grey['300'].toString() + '!important',
            borderBottomColor: theme.palette.grey['300'].toString() + '!important',
        },
        smallCell: {
            width: '9%',
            maxWidth: '9%',
        },
        tinyCell: {
            width: '6%',
            maxWidth: '6%',
        },
        tableHeader: {
            backgroundColor: theme.palette.background.default,
            borderRadius: 0,
            padding: 0,
        },
        tableHeaderCell: {
            color: theme.palette.text.primary,
            fontSize: '12px',
            fontWeight: 'bold',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '10px',
            paddingRight: 0,
        },
        noTextTransform: {
            textTransform: 'none',
            padding: 0,
            textDecorationLine: 'underline',
            justifyContent: 'flex-start',
        },
    });

interface DeliveriesDialogFilter {
    dateFrom: Moment | null;
    dateEnd: Moment | null;
    status: DeliveryStatus | null;
}

export interface DeliveriesDialogProps extends WithStyles<typeof styles>, WithTheme, WithTranslation {
    rowData: RecurringOrderTableRowData;
    recurringOrderRef: string;
    isOpen: boolean;
    role: 'BUYER' | 'SELLER' | 'NONE';
    onClose: () => void;
}

export interface DeliveriesDialogState {
    filter: DeliveriesDialogFilter;
    offer: Offer | undefined;
    entry: RecurringOrderEntry | undefined;
    firstDelivery: Delivery | undefined;
    lastDelivery: Delivery | undefined;
    firstDeliveryEntry: DeliveryEntry | undefined;
    containers: Pair<number, ContainerView>[];
    deliveryRefInEditMode: string | undefined;
    deliveriesToShow: Delivery[];
    writeView: DeliveryUpdateWriteView;
    recurringOrder: RecurringOrder | undefined;
}

export class DeliveriesDialog extends ComponentBase<DeliveriesDialogProps, DeliveriesDialogState> {
    protected _buildState(
        props: DeliveriesDialogProps,
        initialBuild: boolean,
        incomingState: Readonly<DeliveriesDialogState> | undefined,
    ): Partial<DeliveriesDialogState> | undefined {
        const newState: Partial<DeliveriesDialogState> = {
            ...incomingState,
            offer: props.rowData.offerRef ? OfferStore.getOne(props.rowData.offerRef) : undefined,
            recurringOrder: RecurringOrderStore.getOne(props.recurringOrderRef),
        };

        if (initialBuild) {
            newState.deliveriesToShow = [];
            newState.filter = {
                dateFrom: moment(),
                dateEnd: moment().add(3, 'months'),
                status: null,
            };
            newState.writeView = this.getEmptyWriteView();
        }

        if (!newState.recurringOrder || !newState.filter) return newState;

        newState.firstDelivery = newState.recurringOrder.deliveries.length
            ? newState.recurringOrder.deliveries.reduce((acc, val) => (val.deliveryDate < acc.deliveryDate ? val : acc))
            : undefined;
        newState.lastDelivery = newState.recurringOrder.deliveries.length
            ? newState.recurringOrder.deliveries.reduce((acc, val) => (val.deliveryDate > acc.deliveryDate ? val : acc))
            : undefined;
        newState.entry = newState.recurringOrder.entries.length ? newState.recurringOrder.entries[0] : undefined;

        if (newState.firstDelivery) {
            newState.firstDeliveryEntry = newState.firstDelivery.entries.length
                ? newState.firstDelivery.entries[0]
                : undefined;
        }

        if (newState.entry) {
            newState.containers = newState.entry.containers
                ?.map((it) => {
                    const container = ContainerStore.getOne(it.links.container);
                    if (container) return { first: it.amount, second: container };
                    else return undefined;
                })
                .filter((it): it is Pair<number, ContainerView> => !!it);
        }

        newState.deliveriesToShow = this.applyFilter(newState.recurringOrder.deliveries, newState.filter).sort(
            (a, b) => (a.deliveryDate > b.deliveryDate ? 1 : -1),
        );

        return newState;
    }

    getEmptyWriteView(): DeliveryUpdateWriteView {
        return new DeliveryUpdateWriteView(
            [new DeliveryEntryUpdateWriteView('', undefined, undefined, undefined)],
            undefined,
            undefined,
            undefined,
        );
    }

    applyFilter(deliveries: Delivery[], filter: DeliveriesDialogFilter): Delivery[] {
        return deliveries.filter((it) => {
            const matchesDateFrom = filter.dateFrom
                ? moment(it.deliveryDate).startOf('day') >= filter.dateFrom.startOf('day')
                : true;
            const matchesDateEnd = filter.dateEnd
                ? moment(it.deliveryDate).startOf('day') <= filter.dateEnd.startOf('day')
                : true;
            const matchesStatus = filter.status ? (it.status ?? DeliveryStatus.PENDING) == filter.status : true;
            return matchesDateFrom && matchesDateEnd && matchesStatus;
        });
    }

    getContainerTypeTranslation(id: string) {
        if (!this.props.rowData.containerTypes) return '';
        else {
            const locizeKey = this.props.rowData.containerTypes.find((it) => it.id == id)?.slug;
            return locizeKey ? this.props.t(`containertype:${locizeKey}`) : '';
        }
    }

    getDetailsCell(content: string | undefined | null): ReactNode {
        return content ? (
            <TableCell className={this.props.classes.detailsCell}>
                <Typography>{content}</Typography>
            </TableCell>
        ) : (
            <TableCell className={this.props.classes.detailsCell} />
        );
    }

    showProductWithLops(): ReactNode {
        const translatedProduct = this.props.rowData.product
            ? this.props.t(`ontofood:${this.props.rowData.product.label}`)
            : this.props.t('common:deletedProduct');
        const lopsToShow = this.props.rowData?.levelsOfProcessing ?? [];
        return (
            <Grid item container md={12} spacing={theme.spacing(2)}>
                <Grid item>
                    <Typography className={this.props.classes.bigBoldTypography}>{translatedProduct}</Typography>
                </Grid>
                <Grid item>
                    {lopsToShow.map((lop) => {
                        return (
                            <Chip
                                key={lop.links.self}
                                label={this.props.t(`levelsOfProcessing:${lop.label}`)}
                                color="primary"
                                className={this.props.classes.chip}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        );
    }

    showBrandNameAndAmountRow(): ReactNode {
        const amount = this.state.entry?.amount;
        const amountString = amount?.amount.toLocaleString('de-DE', { maximumFractionDigits: 2 });
        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('offerDialog:brandName'))}
                {this.getDetailsCell(this.state.offer?.brandName)}
                {this.getDetailsCell(this.props.t('recurringOrder:amountPerDelivery'))}
                {this.getDetailsCell(
                    amount ? `${amountString} ${this.props.t(`shortunits:${amount.unit}`)}` : undefined,
                )}
            </TableRow>
        );
    }

    showOwnProductTitleAndAmountDetailsRow(): ReactNode {
        const { containers } = this.state;
        const containerString = containers?.length
            ? containers
                  .filter((it) => !!it.first)
                  .map((it) => {
                      const amountString = it.first.toFixed(0);
                      const translatedContainerType = this.getContainerTypeTranslation(it.second.containerType);
                      const containerSizeString = it.second.amount.toLocaleString('de-DE', {
                          maximumFractionDigits: 2,
                      });
                      const containerSizeWithUnit = `${containerSizeString} ${this.props.t(
                          `shortunits:${it.second.unit}`,
                      )}`;
                      return `${amountString}x ${translatedContainerType} à ${containerSizeWithUnit}`;
                  })
                  .join(', ')
            : undefined;
        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('recurringOrder:ownProductName'))}
                {this.getDetailsCell(this.state.offer?.productTitle)}
                {this.getDetailsCell(this.props.t('recurringOrder:amountDetails'))}
                {this.getDetailsCell(containerString)}
            </TableRow>
        );
    }

    showPricePerUnitAndFirstDeliveryRow(): ReactNode {
        const firstDeliveryDateString = moment(this.state.firstDelivery?.deliveryDate).format('DD.MM.YYYY');
        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('recurringOrder:pricePerUnit'))}
                {this.getDetailsCell(
                    this.state.entry?.pricePerUnit
                        ?.toLocaleString('de-DE', {
                            style: 'currency',
                            currency: 'EUR',
                        })
                        .concat(
                            ` ${this.props.t('offer:per')} ${this.props.t(
                                `shortunits:${this.props.rowData.amount.unit}`,
                            )}`,
                        ),
                )}
                {this.getDetailsCell(this.props.t('recurringOrder:firstDelivery'))}
                {this.getDetailsCell(firstDeliveryDateString)}
            </TableRow>
        );
    }

    showPricePerDeliveryAndLastDeliveryRow(): ReactNode {
        const lastDeliveryDateString = moment(this.state.lastDelivery?.deliveryDate).format('DD.MM.YYYY');
        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('offer:pricePerDelivery'))}
                {this.getDetailsCell(
                    this.state.entry?.totalPrice?.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                    }),
                )}
                {this.getDetailsCell(this.props.t('offer:lastShipment'))}
                {this.getDetailsCell(lastDeliveryDateString)}
            </TableRow>
        );
    }

    showPaymentMethodAndCompanyRow(): ReactNode {
        const payment = this.state.recurringOrder?.payment;
        const company =
            this.props.role == 'BUYER' ? this.props.rowData.sellingCompany : this.props.rowData.buyingCompany;
        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('recurringOrder:paymentMethod'))}
                {this.getDetailsCell(payment ? this.props.t(`payment:${payment}`) : undefined)}
                {this.getDetailsCell(this.props.t('company:company'))}
                <TableCell className={this.props.classes.detailsCell}>
                    {company ? (
                        <Button
                            className={this.props.classes.noTextTransform}
                            component={Link}
                            onClick={() => captureWebEvent('deliveriesDialog-company-link')}
                            to={{
                                pathname: '/company/' + company.id + '/detail',
                                state: { from: 'MY_MARKET' },
                            }}
                        >
                            <Typography>{company.name}</Typography>
                        </Button>
                    ) : (
                        <Typography>{this.props.t('company:deletedCompany')}</Typography>
                    )}
                </TableCell>
            </TableRow>
        );
    }

    showContactAndInvoiceAddressRow(): ReactNode {
        const contact =
            this.props.role == 'BUYER'
                ? `${this.props.rowData.sellingPerson?.firstname} ${this.props.rowData.sellingPerson?.lastname}`
                : `${this.props.rowData.buyingPerson?.firstname} ${this.props.rowData.buyingPerson?.lastname}`;
        let invoiceAddressString = '';
        if (this.props.rowData.invoiceAddress) {
            const { street, zipcode, city } = this.props.rowData.invoiceAddress;
            invoiceAddressString = `${street}, ${zipcode} ${city}`;
        }

        return (
            <TableRow>
                {this.getDetailsCell(this.props.t('company:contact'))}
                {this.getDetailsCell(contact)}
                {this.getDetailsCell(this.props.t('payment:billingAddress'))}
                {this.getDetailsCell(invoiceAddressString)}
            </TableRow>
        );
    }

    showEmptyAndDeliveryAddressRow(): ReactNode {
        let deliveryAddressString = '';
        if (this.props.rowData.deliveryAddress) {
            const { street, zipcode, city } = this.props.rowData.deliveryAddress;
            deliveryAddressString = `${street}, ${zipcode} ${city}`;
        }
        return (
            <TableRow>
                {this.getDetailsCell(undefined)}
                {this.getDetailsCell(undefined)}
                {this.getDetailsCell(this.props.t('payment:deliveryAddress'))}
                {this.getDetailsCell(deliveryAddressString)}
            </TableRow>
        );
    }

    showRecurringOrderDetailsSection(): ReactNode {
        return (
            <Grid container item md={12} spacing={theme.spacing(1)}>
                {this.showProductWithLops()}
                <Grid item container md={12}>
                    <Table>
                        <TableBody>
                            {this.showBrandNameAndAmountRow()}
                            {this.showOwnProductTitleAndAmountDetailsRow()}
                            {this.showPricePerUnitAndFirstDeliveryRow()}
                            {this.showPricePerDeliveryAndLastDeliveryRow()}
                            {this.showPaymentMethodAndCompanyRow()}
                            {this.showContactAndInvoiceAddressRow()}
                            {this.showEmptyAndDeliveryAddressRow()}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        );
    }

    handleClickAway = (event: any, reason: string) => {
        if (event && reason === 'backdropClick') {
            this.props.onClose();
        }
    };

    getResponsiblePerson(): string {
        const buyingPerson = this.props.rowData.buyingPerson;
        const sellingPerson = this.props.rowData.sellingPerson;

        return (
            (this.props.role == 'BUYER'
                ? buyingPerson
                    ? `${buyingPerson.firstname} ${buyingPerson.lastname}`
                    : undefined
                : sellingPerson
                  ? `${sellingPerson.firstname} ${sellingPerson.lastname}`
                  : undefined) ?? this.props.t('company:deletedPerson')
        );
    }

    showCloseIcon(): ReactNode {
        return (
            <IconButton
                onClick={(): void => {
                    captureWebEvent('close-deliveries-dialog');
                    this.props.onClose();
                }}
                className={this.props.classes.iconButton}
                size="small"
            >
                <IconComponent icon={'close'} className={this.props.classes.iconClose} />
            </IconButton>
        );
    }

    showDialogTitle(): ReactNode {
        return (
            <Grid container item md={12} alignItems={'center'} justifyItems={'center'}>
                <Grid item md={3}>
                    <Typography className={this.props.classes.bigBoldTypography}>
                        {this.props.t('recurringOrder:recurringOrder')}
                    </Typography>
                </Grid>
                <Grid container item md={8.5} justifyContent={'end'}>
                    <Grid item>
                        <Typography fontSize={'small'}>
                            {this.props.t('company:responsiblePerson') + ':\u2000'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography fontSize={'small'}>{this.getResponsiblePerson()}</Typography>
                    </Grid>
                </Grid>
                <Grid item md={0.5} justifyContent={'end'} sx={{ paddingLeft: '5px', paddingTop: '3px' }}>
                    {this.showCloseIcon()}
                </Grid>
            </Grid>
        );
    }

    setFilterValues(values: Partial<DeliveriesDialogFilter>) {
        this.setState((prevState) => {
            const filter = {
                ...prevState.filter,
                ...values,
            };
            return { filter };
        });
    }

    handleDateFromChange(date: Moment | null): void {
        if (date === null || date.isValid()) {
            captureWebEvent('deliveriesDialog-dateFrom-change');
            this.setFilterValues({ dateFrom: date });
        }
    }

    handleDateEndChange(date: Moment | null): void {
        if (date === null || date.isValid()) {
            captureWebEvent('deliveriesDialog-dateEnd-change');
            this.setFilterValues({ dateEnd: date });
        }
    }

    showDatePicker(): ReactNode {
        return (
            <>
                <Grid item md={3} container sx={{ paddingBottom: theme.spacing(0.75) }}>
                    <StyledDatePickerComponent
                        label={this.props.t('recurringOrder:from')}
                        value={this.state.filter.dateFrom}
                        onChange={(date): void => this.handleDateFromChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                inputProps={{ ...props.inputProps, readOnly: true }}
                                fullWidth
                                sx={{ button: { backgroundColor: 'transparent' } }}
                            />
                        )}
                        maxDate={this.state.filter.dateEnd}
                        disableManualInput
                        shouldShowClearButton
                    />
                </Grid>
                <Grid item md={3} container sx={{ paddingBottom: theme.spacing(0.75) }}>
                    <StyledDatePickerComponent
                        label={this.props.t('recurringOrder:until')}
                        value={this.state.filter.dateEnd}
                        onChange={(date) => this.handleDateEndChange(date as Moment)}
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                inputProps={{ ...props.inputProps, readOnly: true }}
                                fullWidth
                                sx={{ button: { backgroundColor: 'transparent' } }}
                            />
                        )}
                        minDate={this.state.filter.dateFrom}
                        disableManualInput
                        shouldShowClearButton
                    />
                </Grid>
            </>
        );
    }

    handleSortSettingChange(event: SelectChangeEvent) {
        const isValueValidStatus = Object.values(DeliveryStatus)
            .map((it) => it.toString())
            .includes(event.target.value);
        const status = isValueValidStatus ? DeliveryStatus[event.target.value as keyof typeof DeliveryStatus] : null;

        this.setFilterValues({ status });
    }

    showTitleRow(): ReactNode {
        const titles: (string | undefined)[] = [];

        const deliveryDate = this.props.t('recurringOrder:deliveryDate');
        const amountPerDelivery = this.props.t('recurringOrder:amountPerDelivery');
        const confirmedAmount = this.props.t('recurringOrder:confirmedAmount');
        const deliveredAmount = this.props.t('recurringOrder:deliveredAmount');
        const ownRef = this.props.t('recurringOrder:ownReferenceNumber');
        const status = this.props.t('common:status');
        const emptyHeaderForIconColumn = undefined;

        titles.push(deliveryDate);
        titles.push(amountPerDelivery);
        titles.push(confirmedAmount);
        titles.push(deliveredAmount);
        titles.push(ownRef);
        titles.push(status);
        titles.push(emptyHeaderForIconColumn);

        return (
            <TableRow
                sx={{
                    '& th:first-of-type': {
                        borderRadius: '12px 0 0 0',
                    },
                    '& th:last-child': {
                        borderRadius: '0 12px 0 0',
                    },
                }}
            >
                {titles.map((title, index) => {
                    let name = 'DEFAULT';
                    let onlyTitle = false;
                    let options: string[] = [];

                    switch (title) {
                        case status:
                            name = 'STATUS';
                            options = ['PENDING', 'CANCELED', 'DELIVERED', 'NONE'];
                            break;
                        default:
                            onlyTitle = true;
                    }

                    return (
                        <TableCell
                            key={index}
                            className={
                                name == 'STATUS'
                                    ? [this.props.classes.tableHeaderCell, this.props.classes.smallCell].join(' ')
                                    : title === undefined
                                      ? [this.props.classes.tableHeaderCell, this.props.classes.tinyCell].join(' ')
                                      : this.props.classes.tableHeaderCell
                            }
                        >
                            {onlyTitle ? (
                                <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{title}</Typography>
                            ) : (
                                <TradeTableHeaderSelect
                                    tradeItemType={'Delivery'}
                                    name={name}
                                    title={title}
                                    options={options}
                                    currentSortSettingPrefix={this.state.filter.status ? 'STATUS' : undefined}
                                    currentSortSettingValue={
                                        this.state.filter.status ? this.state.filter.status.toString() : undefined
                                    }
                                    handleSortSettingChange={this.handleSortSettingChange.bind(this)}
                                />
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    showDeliveriesTable(): ReactNode {
        return (
            <Box className={this.props.classes.tableBorderBox}>
                <Table>
                    <TableHead className={this.props.classes.tableHeader}>{this.showTitleRow()}</TableHead>
                    <TableBody>
                        {this.state.deliveriesToShow.map((delivery) =>
                            delivery.links.self == this.state.deliveryRefInEditMode
                                ? this.showDeliveryEditRow(delivery)
                                : this.showDeliveryViewRow(delivery),
                        )}
                    </TableBody>
                </Table>
            </Box>
        );
    }

    getViewCell(content: string | undefined | null, status?: DeliveryStatus): ReactNode {
        const additionalClassName = status ? this.props.classes.smallCell : undefined;
        const color =
            status == 'PENDING'
                ? theme.palette.grey['600']
                : status == 'CANCELED'
                  ? theme.palette.warning.main
                  : undefined;
        const fontWeight = status && ['DELIVERED', 'CANCELED'].includes(status) ? 500 : 400;
        const paddingLeft = !status ? '7px' : undefined;
        const sx = { color, fontWeight, paddingLeft };
        return (
            <TableCell
                className={
                    additionalClassName
                        ? [this.props.classes.viewCell, additionalClassName].join(' ')
                        : this.props.classes.viewCell
                }
            >
                <Typography sx={sx}>{content ?? ''}</Typography>
            </TableCell>
        );
    }

    showDeliveryViewRow(delivery: Delivery): ReactNode {
        const { classes } = this.props;
        const entry = delivery.entries.length ? delivery.entries[0] : undefined;
        const amountString = entry
            ? entry.amount.amount.toLocaleString('de-DE', { maximumFractionDigits: 2 })
            : undefined;
        const amountWithUnit = entry ? `${amountString} ${this.props.t(`shortunits:${entry.amount.unit}`)}` : undefined;
        const confirmedAmountString =
            entry && entry.confirmedAmount
                ? entry.confirmedAmount.amount.toLocaleString('de-DE', { maximumFractionDigits: 2 })
                : undefined;
        const confirmedAmountWithUnit =
            entry && entry.confirmedAmount
                ? `${confirmedAmountString} ${this.props.t(`shortunits:${entry.confirmedAmount.unit}`)}`
                : undefined;
        const deliveredAmountString =
            entry && entry.deliveredAmount
                ? entry.deliveredAmount.amount.toLocaleString('de-DE', { maximumFractionDigits: 2 })
                : undefined;
        const deliveredAmountWithUnit =
            entry && entry.deliveredAmount
                ? `${deliveredAmountString} ${this.props.t(`shortunits:${entry.deliveredAmount.unit}`)}`
                : undefined;
        const status = delivery.status ?? DeliveryStatus.PENDING;
        return (
            <TableRow>
                {this.getViewCell(moment(delivery.deliveryDate).format('DD.MM.YYYY'))}
                {this.getViewCell(amountWithUnit)}
                {this.getViewCell(confirmedAmountWithUnit)}
                {this.getViewCell(deliveredAmountWithUnit)}
                {this.getViewCell(delivery.referenceNumber)}
                {this.getViewCell(this.props.t(`delivery:${status}`), status)}
                <TableCell className={[classes.viewCell, classes.tinyCell].join(' ')}>
                    <IconButton
                        size={'small'}
                        onClick={() => {
                            this.updateWriteView({
                                offerRef: entry?.links.offer,
                                deliveryDate: moment(delivery.deliveryDate).endOf('day').toDate(),
                                referenceNumber: delivery.referenceNumber ?? undefined,
                                status: delivery.status ?? DeliveryStatus.PENDING,
                                amount: entry?.amount,
                                confirmedAmount: entry?.confirmedAmount ?? undefined,
                                deliveredAmount: entry?.deliveredAmount ?? undefined,
                            });
                            this.setState({ deliveryRefInEditMode: delivery.links.self });
                        }}
                        disabled={!!this.state.deliveryRefInEditMode}
                    >
                        <EditIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    saveChanges(): void {
        const { recurringOrder, deliveryRefInEditMode, writeView } = this.state;
        if (!recurringOrder || !deliveryRefInEditMode || !writeView.entryUpdates[0].offerRef) return;

        DeliveryService.updateDelivery(deliveryRefInEditMode, recurringOrder.links.self, writeView).subscribe(() =>
            this.setState({
                deliveryRefInEditMode: undefined,
                writeView: this.getEmptyWriteView(),
            }),
        );
    }

    updateWriteView(
        values: Partial<Omit<DeliveryUpdateWriteView, 'entryUpdates'>> & Partial<DeliveryEntryUpdateWriteView>,
    ) {
        const deliveryUpdateValues = values as Omit<DeliveryUpdateWriteView, 'entryUpdates'>;
        const deliveryEntryUpdateValues = values as DeliveryEntryUpdateWriteView;
        const writeView = {
            ...this.state.writeView,
            ...deliveryUpdateValues,
            entryUpdates: [
                {
                    ...(this.state.writeView.entryUpdates.length ? this.state.writeView.entryUpdates[0] : undefined),
                    ...deliveryEntryUpdateValues,
                },
            ],
        };
        this.setState({ writeView });
    }

    amountChangeHandler(
        type: 'amount' | 'confirmedAmount' | 'deliveredAmount',
        value: number | null | undefined,
        unit: string,
    ) {
        this.updateWriteView({ [type]: !isNullOrUndefined(value) ? new Amount(value!, unit) : undefined });
    }

    getAmountInputCell(
        type: 'amount' | 'confirmedAmount' | 'deliveredAmount',
        value: number | null | undefined,
        unit: string | undefined,
    ): ReactNode {
        const label =
            type == 'amount'
                ? this.props.t('offer:amountPerDelivery')
                : type == 'confirmedAmount'
                  ? this.props.t('recurringOrder:confirmedAmount')
                  : this.props.t('recurringOrder:deliveredAmount');
        return (
            <TableCell className={this.props.classes.editCell}>
                <NumberFormat
                    fullWidth
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    customInput={TextField}
                    label={label}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {unit ? this.props.t(`shortunits:${unit}`) : ''}
                            </InputAdornment>
                        ),
                    }}
                    size="small"
                    hiddenLabel
                    value={value ?? null}
                    onValueChange={(values: NumberFormatValues) => {
                        if (unit) this.amountChangeHandler(type, values.floatValue, unit);
                    }}
                />
            </TableCell>
        );
    }

    showDeliveryEditRow(delivery: Delivery): ReactNode {
        const entry = delivery.entries.length ? delivery.entries[0] : undefined;
        const { writeView } = this.state;
        const entryWriteView = writeView.entryUpdates[0];
        if (!entry || !entryWriteView) return null;
        const statusOptions = Object.values(DeliveryStatus);

        return (
            <TableRow>
                <TableCell className={this.props.classes.editCell}>
                    <StyledDatePickerComponent
                        label={this.props.t('recurringOrder:deliveryDate')}
                        value={moment(writeView.deliveryDate)}
                        onChange={(date): void =>
                            this.updateWriteView({ deliveryDate: (date as Moment).endOf('day').toDate() })
                        }
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                inputProps={{ ...props.inputProps, readOnly: true }}
                                fullWidth
                                sx={{ button: { backgroundColor: 'transparent' } }}
                            />
                        )}
                        disableManualInput
                        shouldShowClearButton
                    />
                </TableCell>
                {this.getAmountInputCell('amount', entryWriteView.amount?.amount, entry.amount.unit)}
                {this.getAmountInputCell('confirmedAmount', entryWriteView.confirmedAmount?.amount, entry.amount.unit)}
                {this.getAmountInputCell('deliveredAmount', entryWriteView.deliveredAmount?.amount, entry.amount.unit)}
                <TableCell className={this.props.classes.editCell}>
                    <TextField
                        label={this.props.t('delivery:ownRef')}
                        value={writeView.referenceNumber ?? ''}
                        onChange={(event) => this.updateWriteView({ referenceNumber: event.target.value })}
                        fullWidth
                    />
                </TableCell>
                <TableCell className={[this.props.classes.editCell, this.props.classes.smallCell].join(' ')}>
                    <Select
                        className={this.props.classes.selectInput}
                        label={this.props.t('recurringOrder:status')}
                        value={writeView.status}
                        onChange={(event: SelectChangeEvent<DeliveryStatus>) =>
                            this.updateWriteView({
                                status: DeliveryStatus[event.target.value as keyof typeof DeliveryStatus],
                            })
                        }
                        renderValue={(value) => this.props.t(`delivery:${value}`)}
                    >
                        {statusOptions.map((status) => (
                            <MenuItem key={status} value={status}>
                                {this.props.t(`delivery:${status}`)}
                            </MenuItem>
                        ))}
                    </Select>
                </TableCell>
                <TableCell className={[this.props.classes.editCell, this.props.classes.tinyCell].join(' ')}>
                    <IconButton size={'small'} onClick={() => this.saveChanges()}>
                        <CheckIcon />
                    </IconButton>
                    <IconButton
                        size={'small'}
                        onClick={() =>
                            this.setState({
                                deliveryRefInEditMode: undefined,
                                writeView: this.getEmptyWriteView(),
                            })
                        }
                    >
                        <CloseIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }

    render(): ReactNode {
        return (
            <Dialog open={this.props.isOpen} maxWidth="xl" fullWidth onClose={this.handleClickAway}>
                <DialogTitle>{this.showDialogTitle()}</DialogTitle>
                <DialogContent>
                    <Grid container md={12}>
                        {this.showRecurringOrderDetailsSection()}
                    </Grid>
                    <Grid container md={12} spacing={2} sx={{ paddingTop: '3%' }}>
                        {this.showDatePicker()}
                    </Grid>
                    <Grid container md={12}>
                        {this.showDeliveriesTable()}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withTranslation([
    'recurringOrder',
    'company',
    'levelsOfProcessing',
    'common',
    'ontofood',
    'offerDialog',
    'offer',
    'shortunits',
    'containertype',
    'delivery',
])(withStyles(styles, { withTheme: true })(DeliveriesDialog));
