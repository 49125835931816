export class Allergen {
    constructor(
        public allergenType: AllergenType,
        public specificationGrain: SpecificationGrain[] | null = null,
        public specificationNuts: SpecificationNuts[] | null = null,
    ) {}
}

export class AllergenTraces {
    constructor(
        public allergenType: AllergenType,
        public specificationGrain: SpecificationGrain[] | null = null,
        public specificationNuts: SpecificationNuts[] | null = null,
    ) {}
}

export enum AllergenType {
    GLUTEN_GRAINS = 'GLUTEN_GRAINS',
    CRUSTACEANS = 'CRUSTACEANS',
    EGGS = 'EGGS',
    FISH = 'FISH',
    PEANUTS = 'PEANUTS',
    SOYBEANS = 'SOYBEANS',
    MILK = 'MILK',
    NUTS = 'NUTS',
    CELERY = 'CELERY',
    MUSTARD = 'MUSTARD',
    SESAME_SEEDS = 'SESAME_SEEDS',
    SULPHUR_DIOXIDE_SULPHITES = 'SULPHUR_DIOXIDE_SULPHITES',
    LUPIN = 'LUPIN',
    MOLLUSCS = 'MOLLUSCS',
}

export enum SpecificationNuts {
    CASHEWS = 'CASHEWS',
    HAZELNUTS = 'HAZELNUTS',
    MACADAMIA_NUTS = 'MACADAMIA_NUTS',
    ALMONDS = 'ALMONDS',
    BRAZIL_NUTS = 'BRAZIL_NUTS',
    PECANS = 'PECANS',
    PISTACHIOS = 'PISTACHIOS',
    WALNUTS = 'WALNUTS',
}

export enum SpecificationGrain {
    WHEAT = 'WHEAT',
    SPELT = 'SPELT',
    EINKORN = 'EINKORN',
    EMMER = 'EMMER',
    KAMUT = 'KAMUT',
    RYE = 'RYE',
    BARLEY = 'BARLEY',
    OATS = 'OATS',
}

export enum AllergenFree {
    GLUTEN_FREE = 'GLUTEN_FREE',
    LOW_GLUTEN = 'LOW_GLUTEN',
    LACTOSE_FREE = 'LACTOSE_FREE',
    IODINE_FREE = 'IODINE_FREE',
}
