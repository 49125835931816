export class Husbandry {
    constructor(
        public productName?: string,
        public husbandryType?: HusbandryType,
    ) {}
}

export interface HusbandryWithKey {
    husbandry: Husbandry;
    key: number;
}

export enum HusbandryType {
    STABLE_1 = 'STABLE_1',
    STABLE_AND_SPACE_2 = 'STABLE_AND_SPACE_2',
    STABLE_FRESH_AIR_3 = 'STABLE_FRESH_AIR_3',
    STABLE_RUN_OR_PASTURE_4 = 'STABLE_RUN_OR_PASTURE_4',
    STABLE_BIO_5 = 'STABLE_BIO_5',
    EGGS_0 = 'EGGS_0',
    EGGS_1 = 'EGGS_1',
    EGGS_2 = 'EGGS_2',
    EGGS_3 = 'EGGS_3',
    FISH_AQUACULTURE = 'FISH_AQUACULTURE',
    FISH_INLAND_FISHING = 'FISH_INLAND_FISHING',
    FISH_SEA_FISHING = 'FISH_SEA_FISHING',
}

function updateHusbandryWithKey(
    husbandryWithKey: HusbandryWithKey,
    partialHusbandry: Partial<Husbandry>,
): HusbandryWithKey {
    const husbandry = { ...husbandryWithKey.husbandry, ...partialHusbandry };
    const key = husbandryWithKey.key;
    return { husbandry, key };
}

export function updateHusbandriesWithKey(
    husbandriesWithKey: HusbandryWithKey[] | undefined,
    key: number,
    partialHusbandry: Partial<Husbandry>,
): HusbandryWithKey[] | undefined {
    return husbandriesWithKey?.map((it) => (it.key === key ? updateHusbandryWithKey(it, partialHusbandry) : it));
}
