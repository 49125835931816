export class Grade {
    constructor(
        public label: string,
        public links: GradeLinks,
    ) {}
}

export class GradeLinks {
    constructor(public self: string) {}
}
