import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import LevelOfProcessingComponent from 'components/product/LevelOfProcessingComponent';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default interface StepLevelsOfProcessingProps {
    levelOfProcessingRefs?: string[];
}

export const StepLevelsOfProcessing = (props: StepComponentProps<StepLevelsOfProcessingProps>) => {
    const theme = useTheme();
    const { t } = useTranslation('levelsOfProcessing');

    useEffect(() => {
        props.setData('StepLevelsOfProcessing', {
            levelOfProcessingRefs: props.data.levelOfProcessingRefs ?? [],
        });
    }, []);

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_LEVEL_OF_PROCESSING_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_LEVEL_OF_PROCESSING_TEXT')}</Typography>
        </Grid>
    );

    const showLevelsOfProcessingSelect = (): ReactNode => (
        <Grid item md={12}>
            <LevelOfProcessingComponent
                selectedLopRefs={props.data.levelOfProcessingRefs ? props.data.levelOfProcessingRefs : []}
                remove={(ref: string) =>
                    props.setData('StepLevelsOfProcessing', {
                        ...props.data,
                        levelOfProcessingRefs: props.data.levelOfProcessingRefs?.filter((lopRef) => lopRef !== ref),
                    })
                }
                update={(lopRefs: string[]) =>
                    props.setData('StepLevelsOfProcessing', { ...props.data, levelOfProcessingRefs: lopRefs })
                }
            />
        </Grid>
    );

    return (
        <Grid item container sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showLevelsOfProcessingSelect()}
        </Grid>
    );
};
