import { OfferDialogProps, OfferDialogState } from 'components/search/result-items/offers/OfferDialog';
import {
    OfferDialogData,
    OfferStep,
    StepOfferComponentProps,
    structure,
} from 'components/search/result-items/offers/OfferDialogStructure';
import { deepCopy, isDeepEquality } from 'util/deep-equality-helpers';
import OfferStepProductInformationProps from 'components/search/result-items/offers/offerblocks/block1/OfferStepProductInformation';
import { SxProps } from '@mui/material';
import { theme } from 'style/NearbuyStyle';
import OfferStepAmountAndPackagingProps from 'components/search/result-items/offers/offerblocks/block1/OfferStepAmountAndPackaging';
import OfferStepProductDescriptionProps from 'components/search/result-items/offers/offerblocks/block1/OfferStepProductDescription';
import { isNullOrUndefined } from 'util/helpers';
import OfferStepPricingAndContactDetailsProps from 'components/search/result-items/offers/offerblocks/block1/OfferStepPricingAndContactDetails';

export const getOfferDialogAutoCompleteSX = (): SxProps => {
    return {
        button: {
            backgroundColor: 'transparent',
            color: theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.text.secondary,
            },
            '&:disabled': {
                backgroundColor: 'transparent',
            },
        },
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-error fieldset': {
                borderColor: theme.palette.error.main,
            },
        },
        '& .MuiFormHelperText-root.Mui-error': {
            color: theme.palette.error.main,
        },
    };
};

export default class OfferDialogHelpers {
    constructor(
        private props: OfferDialogProps,
        private state: OfferDialogState,
        private setState: <K extends keyof OfferDialogState>(
            state:
                | ((
                      prevState: Readonly<OfferDialogState>,
                      props: Readonly<OfferDialogProps>,
                  ) => Pick<OfferDialogState, K> | OfferDialogState | null)
                | Pick<OfferDialogState, K>
                | OfferDialogState
                | null,
            callback?: () => void,
        ) => void,
    ) {}

    hasDataStepMissingMandatory(dataStep: OfferStep): boolean {
        const step1_1 = this.state.data.OfferStepProductInformation;
        const step1_2 = this.state.data.OfferStepAmountAndPackaging;
        const step1_4 = this.state.data.OfferStepPricingAndContactDetails;
        switch (dataStep.dataId) {
            case 'OfferStepProductInformation':
                return !step1_1.productRef;
            case 'OfferStepAmountAndPackaging':
                return (
                    !step1_2.totalAmount?.amount ||
                    !step1_2.totalAmount?.unit ||
                    isNullOrUndefined(step1_2.minAmount?.amount) ||
                    !step1_2.minAmount?.unit
                );
            case 'OfferStepPricingAndContactDetails':
                return step1_4.pricePerUnit === 0;
            default:
                return false;
        }
    }

    getComponentProps(dataId: keyof OfferDialogData) {
        const partialData = deepCopy(this.state.data[dataId]);
        return {
            setData: (dataId, newPartialData, callback) => {
                this.setState((prevState) => {
                    const data = deepCopy(prevState.data);
                    data[dataId] = { ...data[dataId], ...newPartialData };
                    return { data };
                }, callback);
            },
            setShowAllFormErrors: (value: boolean) => this.setState({ showAllFormErrors: value }),
            isNewOffer: !this.props.offerRef,
            data: partialData,
            limitingProductRef: this.props.limitingProductRef,
        } as StepOfferComponentProps<typeof partialData>;
    }

    hasChanges(): boolean {
        return !isDeepEquality(this.state.initialOfferWriteView, this.state.offerWriteView);
    }

    isDataStepComplete(dataStep: OfferStep): boolean {
        if (dataStep.dataId === undefined) return false;

        const stepData = this.state.data[dataStep.dataId];

        const validators = {
            OfferStepProductInformation: (stepData: OfferStepProductInformationProps) => !!stepData.productRef,
            OfferStepAmountAndPackaging: (stepData: OfferStepAmountAndPackagingProps) =>
                (stepData.totalAmount?.amount ?? 0) > 0 &&
                !!stepData.totalAmount?.unit &&
                (stepData.minAmount?.amount ?? 0) >= 0 &&
                !!stepData.minAmount?.unit,
            OfferStepProductDescription: (stepData: OfferStepProductDescriptionProps) => !!stepData.description,
            OfferStepPricingAndContactDetails: (stepData: OfferStepPricingAndContactDetailsProps) =>
                !!stepData.pricePerUnit || stepData.pricePerUnit === null,
        };

        return validators[dataStep.dataId](stepData);
    }

    hasOfferFormErrors(): boolean {
        return structure.offerBlocks
            .map((block) =>
                block.offerSteps
                    .map((step) => this.hasDataStepFormValidationErrors(step))
                    .reduce((acc, val) => acc || val, false),
            )
            .reduce((acc, val) => acc || val, false);
    }

    hasOfferShownFormErrors(): boolean {
        return structure.offerBlocks
            .map((block) =>
                block.offerSteps
                    .map((step) => this.hasDataStepShownFormValidationErrors(step))
                    .reduce((acc, val) => acc || val, false),
            )
            .reduce((acc, val) => acc || val, false);
    }

    hasOfferMissingMandatories(): boolean {
        return structure.offerBlocks
            .map((block) =>
                block.offerSteps
                    .map((step) => this.hasDataStepMissingMandatory(step))
                    .reduce((acc, val) => acc || val, false),
            )
            .reduce((acc, val) => acc || val, false);
    }

    hasDataStepFormValidationErrors(dataStep: OfferStep): boolean {
        return this.hasDataStepFormValidationErrorsInternal(dataStep, false);
    }

    hasDataStepShownFormValidationErrors(dataStep: OfferStep): boolean {
        return this.hasDataStepFormValidationErrorsInternal(dataStep, true);
    }

    private hasDataStepFormValidationErrorsInternal(dataStep: OfferStep, shown: boolean): boolean {
        if (!dataStep.dataId) return false;

        const stepData = this.state.data[dataStep.dataId];

        if (stepData && typeof stepData === 'object' && 'formErrors' in stepData) {
            const formErrors = deepCopy(stepData.formErrors);

            const hasErrors = !!formErrors && !!Object.keys(formErrors).length;
            const hasShownErrors =
                !!formErrors && Object.keys(formErrors).some((errorKey) => formErrors[errorKey].showError);

            return shown ? hasShownErrors : hasErrors;
        }
        return false;
    }

    getTitleString(): string {
        return this.props.offerRef ? this.props.t('offerDialog:offerEdit') : this.props.t('offerDialog:offerCreate');
    }
}
