import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { theme } from 'style/NearbuyStyle';
import MenuItem from '@mui/material/MenuItem';
import { Select } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { ReactComponent as FilterIcon } from 'icons/Filter_arrows_24px.svg';
import { ReactComponent as ArrowUpCustom } from 'icons/arrow_up_alt_24px.svg';
import { ReactComponent as ArrowDownCustom } from 'icons/arrow_down_alt_24px.svg';
import { ReactComponent as AssignmentCancelCustom } from 'icons/assignment_cancel_22px.svg';
import { ReactComponent as AssignmentRejectCustom } from 'icons/assignment_reject_22px.svg';
import { ArrowDropDownRounded, ArrowDropUpRounded, ShoppingCart } from '@mui/icons-material';
import { SelectChangeEvent } from '@mui/material/Select';
import { captureWebEvent } from 'util/AnalyticUtils';
import CheckIcon from '@mui/icons-material/Check';

interface TradeTableHeaderSelectProps extends WithTranslation {
    name: string;
    title: string | undefined;
    options: string[];
    currentSortSettingPrefix: string | undefined;
    currentSortSettingValue: string | undefined;
    handleSortSettingChange: (event: SelectChangeEvent) => void;
    tradeItemType?: 'PreOrder' | 'Order' | 'Offer' | 'Request' | 'RecurringOrder' | 'Delivery';
    isUserBuyer?: boolean;
}

export class TradeTableHeaderSelect extends ComponentBase<TradeTableHeaderSelectProps> {
    getStandardSortMenuItem(option: string | undefined) {
        let menuItemText: string;
        let menuItemIcon: ReactNode;
        switch (option) {
            case 'ASC':
                menuItemText = this.props.t('common:SORT_ASC');
                menuItemIcon = <ArrowUpCustom />;
                break;
            case 'DESC':
                menuItemText = this.props.t('common:SORT_DESC');
                menuItemIcon = <ArrowDownCustom />;
                break;
            default:
                menuItemText = this.props.t('common:SORT_NONE');
                menuItemIcon = <DeleteIcon fontSize={'small'} />;
                break;
        }
        return (
            <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                <Grid item marginTop={'6px'}>
                    {menuItemIcon}
                </Grid>
                <Grid item>
                    <Typography>{menuItemText}</Typography>
                </Grid>
            </Grid>
        );
    }

    getStatusSortMenuItem(option: string | undefined) {
        let menuItemText: string;
        let menuItemIcon: ReactNode;

        switch (option) {
            case 'PENDING':
                menuItemText =
                    this.props.tradeItemType && ['PreOrder', 'RecurringOrder'].includes(this.props.tradeItemType)
                        ? this.props.t('preOrder:PENDING')
                        : this.props.tradeItemType == 'Order'
                          ? this.props.isUserBuyer
                              ? this.props.t('order:orderSent')
                              : this.props.t('order:orderReceived')
                          : this.props.tradeItemType == 'Delivery'
                            ? this.props.t('delivery:PENDING')
                            : 'ERROR: Faulty tradeItemType!';

                menuItemIcon = <AssignmentIcon sx={{ marginTop: '2px' }} fontSize={'small'} />;
                break;
            case 'ACCEPTED':
                menuItemText = this.props.t('preOrder:ACCEPTED');
                menuItemIcon = <AssignmentTurnedInIcon sx={{ marginTop: '2px' }} fontSize={'small'} />;
                break;
            case 'PRICE_ADDED':
                menuItemText = this.props.t('preOrder:PRICE_ADDED');
                menuItemIcon = <AssignmentTurnedInIcon sx={{ marginTop: '2px' }} fontSize={'small'} />;
                break;
            case 'INVOICE':
                menuItemText = this.props.t('order:INVOICE_ADDED');
                menuItemIcon = <AssignmentTurnedInIcon fontSize={'small'} />;
                break;
            case 'REJECTED':
                menuItemText = this.props.t('preOrder:REJECTED');
                menuItemIcon = <AssignmentRejectCustom />;
                break;
            case 'CANCELED':
                menuItemText =
                    this.props.tradeItemType == 'Delivery'
                        ? this.props.t('delivery:CANCELED')
                        : this.props.t('preOrder:CANCELED');
                menuItemIcon = <AssignmentCancelCustom />;
                break;
            case 'CANCELED_BY_BUYER':
                menuItemText = this.props.t('preOrder:CANCELED_BY_BUYER');
                menuItemIcon = <AssignmentCancelCustom />;
                break;
            case 'ORDERED':
                menuItemText = this.props.t('recurringOrder:ORDERED');
                menuItemIcon = <ShoppingCart fontSize={'small'} />;
                break;
            case 'INVOICE_PRESENT':
                menuItemText = this.props.t('order:COMPLETED_WITH_INVOICE');
                menuItemIcon = <AssignmentTurnedInIcon fontSize={'small'} />;
                break;
            case 'DELIVERED':
                menuItemText = this.props.t('delivery:DELIVERED');
                menuItemIcon = <CheckIcon />;
                break;
            default:
                menuItemText = this.props.t('common:SORT_NONE');
                menuItemIcon = <DeleteIcon fontSize={'small'} />;
                break;
        }

        return (
            <Grid container direction={'row'} alignItems={'center'} spacing={1}>
                <Grid item marginTop={'6px'}>
                    {' '}
                    {menuItemIcon}
                </Grid>
                <Grid item>
                    <Typography>{menuItemText}</Typography>
                </Grid>
            </Grid>
        );
    }

    getSortTableHeader(value: string | undefined): ReactNode {
        let icon: ReactNode;
        switch (value) {
            case 'ASC':
                icon = <ArrowDropUpRounded />;
                break;
            case 'DESC':
                icon = <ArrowDropDownRounded />;
                break;
            case 'PENDING':
                icon = <AssignmentIcon fontSize={'small'} />;
                break;
            case 'ACCEPTED':
            case 'PRICE_ADDED':
            case 'INVOICE':
                icon = <AssignmentTurnedInIcon fontSize={'small'} />;
                break;
            case 'REJECTED':
                icon = <AssignmentRejectCustom fill={'white'} />;
                break;
            case 'CANCELED':
                icon = <AssignmentCancelCustom fill={'white'} />;
                break;
            default:
                icon = <FilterIcon />;
                break;
        }
        return (
            <Grid container direction={'row'} alignItems={'center'} justifyContent={'start'}>
                <Grid item xs={10}>
                    <Typography
                        sx={{
                            fontSize: 14,
                            width: '200%',
                            overflow: 'hidden',
                            fontWeight: 'bold',
                            margin: `${0} ${theme.spacing(2)} ${0} ${0}`,
                        }}
                    >
                        {this.props.title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={1}
                    sx={{
                        paddingTop: '6px',
                    }}
                >
                    {icon}
                </Grid>
            </Grid>
        );
    }

    render(): React.ReactElement | null {
        return (
            <Select
                variant={'standard'}
                sx={{
                    padding: 0,
                    fontWeight: 'bold',
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                    '&:hover': {
                        backgroundColor: '#E0E0E0',
                    },
                    '& .MuiSelect-select': {
                        padding: `${0} ${theme.spacing(1.5)} ${0} ${theme.spacing(1)} !important`,
                    },
                    borderRadius: '8px',
                    marginLeft: '-8px',
                    ...(this.props.currentSortSettingPrefix == this.props.name
                        ? {
                              backgroundColor: theme.palette.primary.dark,
                              color: 'white',
                              '&:hover': {
                                  backgroundColor: theme.palette.primary.dark,
                              },
                          }
                        : undefined),
                }}
                IconComponent={() => null}
                name={this.props.name}
                value={
                    this.props.currentSortSettingPrefix == this.props.name &&
                    this.props.currentSortSettingValue &&
                    this.props.options.includes(this.props.currentSortSettingValue)
                        ? this.props.currentSortSettingValue
                        : 'NONE'
                }
                renderValue={(value) => this.getSortTableHeader(value)}
                onChange={(event) => this.props.handleSortSettingChange(event)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 119,
                    },
                }}
            >
                {this.props.options.map((option) => (
                    <MenuItem
                        onClick={() => captureWebEvent(`my_market-filter-${this.props.name}-by-${option}`)}
                        key={this.props.title + option}
                        value={option}
                        disableRipple
                    >
                        {this.props.name == 'STATUS'
                            ? this.getStatusSortMenuItem(option)
                            : this.getStandardSortMenuItem(option)}
                    </MenuItem>
                ))}
            </Select>
        );
    }
}

export default withTranslation(['common', 'preOrder', 'order', 'recurringOrder'])(TradeTableHeaderSelect);
