export default class ProductTrait {
    constructor(
        public trait: ProductTraitType | undefined,
        public description: string,
    ) {}
}

export enum ProductTraitType {
    PLANT_VARIETY = 'PLANT_VARIETY',
    ANIMAL_BREED = 'ANIMAL_BREED',
    ANIMAL_PART = 'ANIMAL_PART',
    FLOUR_TYPE = 'FLOUR_TYPE',
    FAT_CONTENT_ABSOLUTE_PERCENT = 'FAT_CONTENT_ABSOLUTE_PERCENT',
    FAT_CONTENT_DRY_PERCENT = 'FAT_CONTENT_DRY_PERCENT',
    FAT_CONTENT_MILK_PERCENT = 'FAT_CONTENT_MILK_PERCENT',
    FAT_LEVEL = 'FAT_LEVEL',
    RENNET_TYPE = 'RENNET_TYPE',
    CHEESE_GROUP_GERMANY = 'CHEESE_GROUP_GERMANY',
    ALCOHOL_CONTENT_PERCENT = 'ALCOHOL_CONTENT_PERCENT',
}

export interface ProductTraitWithKey {
    productTrait: ProductTrait;
    key: number;
}

function updateProductTraitWithKey(
    productTraitWithKey: ProductTraitWithKey,
    partialProductTrait: Partial<ProductTrait>,
): ProductTraitWithKey {
    const productTrait = { ...productTraitWithKey.productTrait, ...partialProductTrait };
    const key = productTraitWithKey.key;
    return { productTrait, key };
}

export function updateProductTraitsWithKey(
    productTraitsWithKey: ProductTraitWithKey[] | undefined,
    key: number,
    partialProductTrait: Partial<ProductTrait>,
): ProductTraitWithKey[] | undefined {
    return productTraitsWithKey?.map((it) =>
        it.key === key ? updateProductTraitWithKey(it, partialProductTrait) : it,
    );
}
