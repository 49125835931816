export enum AddressType {
    MAIN = 'MAIN',
    INVOICE = 'INVOICE',
    DELIVERY = 'DELIVERY',
}

export enum OrderAddressType {
    SELLER = 'SELLER',
    INVOICE = 'INVOICE',
    DELIVERY = 'DELIVERY',
}

export class OrderAddress {
    constructor(
        public street: string,
        public zipcode: string,
        public city: string,
        public type: OrderAddressType,
        public name: string,
        public suffix?: string,
    ) {}
}

export class Address {
    constructor(
        public street: string,
        public zipcode: string,
        public city: string,
        public type: AddressType,
        public lat: number,
        public lon: number,
        public name: string,
        public suffix: string,
        public country: string,
        public links: AddressLinks,
    ) {}
}

export class AddressLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
    ) {}
}

export class AddressWriteView {
    constructor(
        public street: string,
        public zipcode: string,
        public city: string,
        public type: AddressType,
        public lat?: number,
        public lon?: number,
        public name = '',
        public suffix = '',
    ) {}
}

export class AddressSuggestionView {
    constructor(
        public street: string,
        public zipcode: string,
        public city: string,
        public lat: number,
        public lon: number,
    ) {}
}

export class AddressSearchView {
    constructor(
        public street: string,
        public zipcode: string,
        public city: string,
    ) {}
}

export class AddressLinkView {
    constructor(
        public self: string,
        public type: AddressType,
    ) {}
}

/**
 * @param address
 * @param addressType only used, if new one is generated
 */
export function copy(address?: Address, addressType?: AddressType): Address {
    if (!address) {
        return new Address(
            '',
            '',
            '',
            addressType || AddressType.MAIN,
            0,
            0,
            '',
            '',
            ' ',
            new AddressLinks('', '', '', ''),
        );
    } else {
        return new Address(
            address.street,
            address.zipcode,
            address.city,
            address.type,
            address.lat,
            address.lon,
            address.name,
            address.suffix,
            address.country,
            address.links,
        );
    }
}

export function checkValidGeoPosition(address?: Address): boolean {
    if (address) {
        if (address.lat && address.lon) {
            return true;
        }
    }
    return false;
}

export function toOrderAddress(address: Address | undefined, type: OrderAddressType): OrderAddress | undefined {
    if (!address) return;
    return new OrderAddress(address.street, address.zipcode, address.city, type, address.name, address.suffix);
}
