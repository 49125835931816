import CancelIcon from '@mui/icons-material/Cancel';
import { Theme } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ContainerTraitType } from 'model/ContainerView';
import * as React from 'react';
import { ReactNode } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            marginRight: theme.spacing(0.5),
            height: theme.spacing(3),
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                color: theme.palette.common.black,
                backgroundColor: theme.palette.primary.light,
            },
        },
        closeIcon: {
            height: theme.spacing(2.5),
            fill: theme.palette.primary.main,
            '&:hover': {
                fill: theme.palette.primary.dark,
            },
        },
        selectInput: {
            '& .MuiSelect-icon': {
                marginRight: theme.spacing(1),
            },
        },
        formControl: {
            backgroundColor: 'transparent',
            minWidth: '100%',
            maxWidth: '100%',
            display: 'flex', // Flexbox layout for better alignment
            flexDirection: 'column',
        },
        checkBox: {
            padding: 2.5,
            margin: 2.5,
            color: 'grey',
            '&:hover': {
                color: theme.palette.primary.dark,
            },
        },
        menuList: {
            '& li.Mui-selected': {
                backgroundColor: 'transparent',
            },
        },
        selectableMenuItem: {
            padding: 0.1,
            margin: 0,
            '&:hover': {
                padding: 0.1,
                margin: 0,
            },
        },
        notSelectableMenuItem: {
            padding: 0.1,
            margin: 0,
        },
    });

interface ContainerTraitsProperties extends WithStyles<typeof styles>, WithTranslation {
    selectedItems: ContainerTraitType[];
    remove: (item: ContainerTraitType) => void;
    update: (item: ContainerTraitType[]) => void;
    excludeFromOptions?: string;
    isReadOnly?: boolean;
}

interface ContainerTraitsState {
    selectedItems: ContainerTraitType[];
    excludedItems: ContainerTraitType[];
}

export class ContainerTraitsComponent extends ComponentBase<ContainerTraitsProperties, ContainerTraitsState> {
    protected _buildState(
        props: ContainerTraitsProperties,
        initialBuild: boolean,
        _incomingState: Readonly<ContainerTraitsState> | undefined,
    ): Partial<ContainerTraitsState> | undefined {
        const newState: Partial<ContainerTraitsState> = {};

        if (initialBuild) {
            newState.excludedItems = [];
        }

        newState.selectedItems = props.selectedItems;

        if (newState.selectedItems) {
            const excludedItems: ContainerTraitType[] = [];
            newState.selectedItems.forEach((item) => {
                excludedItems.push(item);
            });
            newState.excludedItems = excludedItems;
        }

        return newState;
    }

    showChips(): ReactNode {
        if (this.props.selectedItems) {
            return Object.values(ContainerTraitType)
                .filter((item) => this.props.selectedItems.includes(item))
                .map((item) => {
                    return (
                        <Chip
                            className={this.props.classes.chip}
                            key={item}
                            label={this.props.t('containertype:' + item)}
                            color="primary"
                            onDelete={(): void => {
                                if (!this.props.isReadOnly) this.props.remove(item);
                            }}
                            deleteIcon={
                                <CancelIcon
                                    className={this.props.classes.closeIcon}
                                    onMouseDown={(event): void => event.stopPropagation()}
                                />
                            }
                        />
                    );
                });
        }
    }

    getSelectableMenuOptions(): ReactNode {
        return this.sortItems(Object.values(ContainerTraitType)).map((item) => (
            <MenuItem key={item} value={item} className={this.props.classes.selectableMenuItem}>
                <Checkbox className={this.props.classes.checkBox} checked={this.state.selectedItems.includes(item)} />
                <ListItemText primary={this.props.t('containertype:' + item)} sx={{ paddingBottom: 0.25 }} />
            </MenuItem>
        ));
    }

    sortItems(items: ContainerTraitType[]): ContainerTraitType[] {
        return items.sort((a, b) =>
            this.props.t('containertype:' + a).localeCompare(this.props.t('containertype:' + b)),
        );
    }

    render(): React.ReactElement {
        return (
            <Grid item md={12}>
                <FormControl className={this.props.classes.formControl}>
                    <TextField
                        select
                        label={this.props.t('productDataSheet:furtherOptions')}
                        value={this.props.selectedItems || []}
                        SelectProps={{
                            multiple: true,
                            renderValue: (): ReactNode => this.showChips(),
                            MenuProps: {
                                MenuListProps: {
                                    className: this.props.classes.menuList,
                                },
                            },
                        }}
                        onChange={(event): void => {
                            this.props.update(event.target.value as unknown as ContainerTraitType[]);
                        }}
                        className={this.props.classes.selectInput}
                        disabled={this.props.isReadOnly}
                    >
                        {this.getSelectableMenuOptions()}
                    </TextField>
                </FormControl>
            </Grid>
        );
    }
}

export default withTranslation(['productDataSheet', 'containertype'])(
    withStyles(styles, { withTheme: true })(ContainerTraitsComponent),
);
