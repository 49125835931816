export class ContainerView {
    constructor(
        public id: string,
        public containerType: string,
        public amount: number,
        public links: ContainerLinks,
        public unit?: string,
        public innerContainer?: string,
        public width?: number,
        public length?: number,
        public height?: number,
        public dimensionUnit?: string,
        public material?: string,
        public returnable?: boolean,
        public returnableNotice?: string,
        public traits?: ContainerTraitType[],
    ) {}
}

export class ContainerLinks {
    constructor(
        public self: string,
        public remove?: string,
    ) {}
}

export class ContainerWriteView {
    constructor(
        public containerType: string,
        public amount: number,
        public unit?: string,
        public innerContainer?: string,
        public width?: number,
        public length?: number,
        public height?: number,
        public dimensionUnit?: string,
        public material?: string,
        public returnable?: boolean,
        public returnableNotice?: string,
        public traits?: ContainerTraitType[],
    ) {}
}

export class ContainerType {
    constructor(
        public id: string,
        public slug: string,
    ) {}
}

export class OrderContainer {
    constructor(
        public amount: number,
        public links: {
            container: string;
        },
    ) {}
}

export interface ContainerWithKey {
    container: ContainerWriteView;
    key: number;
}

export enum ContainerTraitType {
    LABELED = 'LABELED',
    FOILED = 'FOILED',
    LOOSE = 'LOOSE',
    STACKABLE = 'STACKABLE',
}

export enum ContainerUsageType {
    OFFER = 'OFFER',
    PRODUCT_DATA_SHEET = 'PRODUCT_DATA_SHEET',
}
