export class Cultivation {
    constructor(
        public productName: string,
        public cultivationType?: CultivationType,
    ) {}
}

export interface CultivationWithKey {
    cultivation: Cultivation;
    key: number;
}

export enum CultivationType {
    AGROFORESTRY = 'AGROFORESTRY',
    AQUAPONICS = 'AQUAPONICS',
    IRRIGATION_FIELD_CULTIVATION = 'IRRIGATION_FIELD_CULTIVATION',
    FIELD_VEGETABLE_CULTIVATION = 'FIELD_VEGETABLE_CULTIVATION',
    FILM_TUNNEL_CULTIVATION = 'FILM_TUNNEL_CULTIVATION',
    OUTDOOR_CULTIVATION = 'OUTDOOR_CULTIVATION',
    MIXED_CULTIVATION = 'MIXED_CULTIVATION',
    WET_FIELD_CULTIVATION = 'WET_FIELD_CULTIVATION',
    RAINFED_CULTIVATION = 'RAINFED_CULTIVATION',
    DRY_FARMING = 'DRY_FARMING',
    GREENHOUSE_CULTIVATION = 'GREENHOUSE_CULTIVATION',
    VERTICAL_AGRICULTURE = 'VERTICAL_AGRICULTURE',
}

function updateCultivationWithKey(
    cultivationWithKey: CultivationWithKey,
    partialCultivation: Partial<Cultivation>,
): CultivationWithKey {
    const cultivation = {
        ...cultivationWithKey.cultivation,
        ...partialCultivation,
    };
    const key = cultivationWithKey.key;
    return { cultivation, key };
}

export function updateCultivationsWithKey(
    cultivationWithKey: CultivationWithKey[] | undefined,
    key: number,
    partialCultivation: Partial<Cultivation>,
): CultivationWithKey[] | undefined {
    return cultivationWithKey?.map((it) => (it.key === key ? updateCultivationWithKey(it, partialCultivation) : it));
}
