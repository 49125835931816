import AddressService from 'api/AddressService';
import AssociationService from 'api/AssociationService';
import AssortmentService from 'api/AssortmentService';
import CompanyService from 'api/CompanyService';
import ConsolidatorService from 'api/ConsolidatorService';
import ContactService from 'api/ContactService';
import ContainerService from 'api/ContainerService';
import DemandService from 'api/DemandService';
import EmploymentService from 'api/EmploymentService';
import ImageService from 'api/ImageService';
import InvitationService from 'api/InvitationService';
import InvoiceService from 'api/InvoiceService';
import MembershipService from 'api/MembershipService';
import MessageService from 'api/MessageService';
import MetaService from 'api/MetaService';
import OfferService, { OfferSearchQuery } from 'api/OfferService';
import ProductService from 'api/ProductService';
import OrderService from 'api/OrderService';
import PersonService from 'api/PersonService';
import PriceRequestService from 'api/PriceRequestService';
import ProductDataSheetService from 'api/ProductDataSheetService';
import PurchaseIntentService from 'api/PurchaseIntentService';
import RequestService, { RequestSearchQuery } from 'api/RequestService';
import SubstitutePersonService from 'api/SubstitutePersonService';
import VerificationService from 'api/VerificationService';
import { getWeightedPriority } from 'components/dashboard/DashletFactory';
import i18n from 'i18next';
import { Address } from 'model/Address';
import { Association } from 'model/Association';
import { Assortment, CompanyAssortment } from 'model/Assortment';
import { Consolidator } from 'model/Consolidator';
import { CompanyContacts, Contact } from 'model/Contact';
import { ContainerView } from 'model/ContainerView';
import { DashletProperties } from 'model/DashletProperties';
import { CompanyDemand, Demand } from 'model/Demand';
import { CompanyEmployments, PersonEmployments } from 'model/Employment';
import { FeatureOrFeatures } from 'model/Feature';
import { CompanyImages, DownloadLink, DownloadLinkWithId, ImageInfo, ImageType, PersonPortraits } from 'model/Image';
import { CompanyInvitations, Invitation } from 'model/Invitation';
import { Invoice } from 'model/Invoice';
import { CompanyMemberships, Membership } from 'model/Membership';
import { MessageUnseen } from 'model/Message';
import { MetaStaticEntity, MetaStaticEntityType } from 'model/Meta';
import { NotificationInfo } from 'model/NearbuyNotification';
import { CompanyRoles } from 'model/NearbuyRole';
import { Offer, OfferContainers } from 'model/Offer';
import { Order } from 'model/Order';
import { PersonFavourites } from 'model/Person';
import { PriceRequest } from 'model/PriceRequest';
import { Product } from 'model/Product';
import { ProductDataSheet } from 'model/ProductDataSheet';
import { PurchaseIntent } from 'model/PurchaseIntent';
import { Request } from 'model/Request';
import { SubstitutePerson } from 'model/SubstitutePerson';
import { Verification } from 'model/Verification';
import moment, { Moment } from 'moment/moment';
import { createDynamicLoadingStore, createEntityStore, createPersistentEntityStore } from 'resub-entity';
import { map } from 'rxjs/operators';
import { RecurringOrder } from 'model/RecurringOrder';
import RecurringOrderService from 'api/RecurringOrderService';

export const AddressStore = createDynamicLoadingStore<Address, string>({
    selectIdFunction: (address: Address) => address.links.self,
    loadFunction: (ref: string) => AddressService.getAddress(ref),
});
export const CompanyEmploymentsStore = createDynamicLoadingStore<CompanyEmployments, string>({
    selectIdFunction: (ce: CompanyEmployments) => ce.companyRef || '',
    loadFunction: (companyRef) => EmploymentService.getEmployees(companyRef),
});
export const PersonEmploymentStore = createDynamicLoadingStore<PersonEmployments, string>({
    selectIdFunction: (employments: PersonEmployments) => employments.userRef,
    loadFunction: (ref: string) => PersonService.getPersonEmployments(ref),
});
export const CompanyRolesStore = createDynamicLoadingStore<CompanyRoles, string>({
    selectIdFunction: (role: CompanyRoles) => role.companyRef,
    loadFunction: (companyRef) => CompanyService.getRolesOfCompany(companyRef),
});
export const PersonImagesStore = createDynamicLoadingStore<PersonPortraits, string>({
    selectIdFunction: (portraits: PersonPortraits) => portraits.userId,
    loadFunction: (id) => PersonService.getPersonImage(id, ImageType.PERSON_PORTRAIT),
    dynamicValidUntilFunction: (personPortraits) => {
        // get shortest valid until
        let shortestDate: Date | undefined;
        personPortraits.images.forEach((image) => {
            if (image.validUntil && (!shortestDate || Date.now().valueOf() - image.validUntil.valueOf() < 0)) {
                shortestDate = new Date(image.validUntil);
            }
        });
        return shortestDate;
    },
});
export const ProductStore = createDynamicLoadingStore<Product, string>({
    selectIdFunction: (product: Product) => product.links.self,
    loadFunction: (productRef) => ProductService.getProduct(productRef),
    // Should be valid for at least an hour. Is only updated on startup of server.
    hashTimeSec: 3600,
    throttleMs: 100,
    searchFunction: (searchParameter, entity) => {
        const translatedLabel = i18n.t(entity.label, { ns: 'ontofood' });

        if (translatedLabel) {
            return translatedLabel.toUpperCase().includes(searchParameter.toUpperCase());
        }
        return false;
    },
});
export const OfferStore = createDynamicLoadingStore<Offer, string, OfferSearchQuery>({
    selectIdFunction: (offer: Offer) => offer.links.self,
    loadFunction: (offerRef) => OfferService.getOffer(offerRef),
    searchLoadFunction: (query: OfferSearchQuery) => {
        return OfferService.searchOffersAsRefs(query);
    },
});

export const CompanyOfferStore = createDynamicLoadingStore<Offer, string, string>({
    selectIdFunction: (offer: Offer) => offer.links.self,
    loadFunction: (offerRef) => OfferService.getOffer(offerRef),
    searchLoadFunction: (companyRef: string) => {
        return OfferService.getOfferIds(companyRef);
    },
});

export const InvoiceStore = createDynamicLoadingStore<Invoice, string>({
    selectIdFunction: (invoice: Invoice) => invoice.links.self,
    loadFunction: (invoiceRef) => InvoiceService.getInvoice(invoiceRef),
});

export const RequestStore = createDynamicLoadingStore<Request, string, OfferSearchQuery>({
    selectIdFunction: (request: Request) => request.links.self,
    loadFunction: (requestRef) => RequestService.getRequest(requestRef),
    searchLoadFunction: (query: RequestSearchQuery) => {
        return RequestService.searchRequestsAsRefs(query);
    },
});

export const CompanyRequestStore = createDynamicLoadingStore<Request, string, string>({
    selectIdFunction: (request: Request) => request.links.self,
    loadFunction: (requestRef) => RequestService.getRequest(requestRef),
    searchLoadFunction: (companyRef: string) => {
        return RequestService.getRequestIds(companyRef);
    },
});

export const CompanyDemandStore = createDynamicLoadingStore<CompanyDemand, string>({
    selectIdFunction: (companyDemand: CompanyDemand) => companyDemand.companyRef,
    loadFunction: (companyRef) => DemandService.getCompanyDemand(companyRef),
});
export const CompanyAssortmentStore = createDynamicLoadingStore<CompanyAssortment, string>({
    selectIdFunction: (companyAssortment: CompanyAssortment) => companyAssortment.companyRef,
    loadFunction: (companyRef) => AssortmentService.getCompanyAssortment(companyRef),
});
export const AssortmentStore = createDynamicLoadingStore<Assortment, string>({
    selectIdFunction: (assortment: Assortment) => assortment.links.self,
    loadFunction: (assortmentRef) => AssortmentService.getAssortment(assortmentRef),
});
export const DemandStore = createDynamicLoadingStore<Demand, string>({
    selectIdFunction: (demand: Demand) => demand.links.self,
    loadFunction: (demandRef) => DemandService.getDemand(demandRef),
});
export const InvitationStore = createDynamicLoadingStore<Invitation, string>({
    selectIdFunction: (invitation: Invitation) => invitation.links.self || '',
    loadFunction: (ref: string) => InvitationService.getInvitation(ref),
});
export const CompanyInvitationsStore = createDynamicLoadingStore<CompanyInvitations, string>({
    selectIdFunction: (companyInvitations: CompanyInvitations) => companyInvitations.companyRef,
    loadFunction: (companyRef) => InvitationService.getInvitations(companyRef),
});
export const CompanyContactsStore = createDynamicLoadingStore<CompanyContacts, string>({
    selectIdFunction: (companyContacts: CompanyContacts) => companyContacts.companyRef,
    loadFunction: (companyRef) => ContactService.getContacts(companyRef),
});
export const ContactsStore = createDynamicLoadingStore<Contact, string>({
    selectIdFunction: (contact: Contact) => contact.links.self,
    loadFunction: (contactRef) => ContactService.getContact(contactRef),
    searchLoadFunction: (companyRef: string) => {
        return ContactService.getContactIds(companyRef);
    },
});
export const MembershipStore = createDynamicLoadingStore<Membership, string>({
    selectIdFunction: (membership: Membership) => membership.links.self,
    loadFunction: (membershipRef) => MembershipService.getMembership(membershipRef),
});
export const CompanyMembershipsStore = createDynamicLoadingStore<CompanyMemberships, string>({
    selectIdFunction: (companyMemberships: CompanyMemberships) => companyMemberships.companyRef,
    loadFunction: (companyRef) => MembershipService.getMemberships(companyRef),
});
export const AssociationStore = createDynamicLoadingStore<Association, string>({
    selectIdFunction: (association: Association) => association.links.self,
    loadFunction: (associationRef) => AssociationService.getAssociation(associationRef),
});
export const DashletStore = createPersistentEntityStore<DashletProperties, DashletProperties['id']>({
    selectIdFunction: (props) => props.id,
    loadOnInit: true,
    storageKey: 'DashletStore',
    sortFunction: (entity1, entity2) => {
        const priority1 = getWeightedPriority(entity1);
        const priority2 = getWeightedPriority(entity2);
        return priority1 - priority2;
    },
    replacer: (_key, value) => (value instanceof Set ? Array.from(value) : value),
    reviver: (key, value) => (key === 'rolesRequired' ? new Set(value as FeatureOrFeatures[]) : value),
});
export const NotificationStore = createEntityStore<NotificationInfo, NotificationInfo['timestamp']>({
    selectIdFunction: (props) => props.timestamp,
});
export const CompanyImagesStore = createDynamicLoadingStore<CompanyImages, string>({
    selectIdFunction: (entity: Readonly<CompanyImages>) => entity.companyId,
    loadFunction: (companyLogoRef: string) => ImageService.getImagesForCompany(companyLogoRef),
    dynamicValidUntilFunction: (entity) => entity.images[0]?.validUntil,
});
export const ImageStore = createDynamicLoadingStore<ImageInfo, string>({
    selectIdFunction: (entity: Readonly<ImageInfo>) => entity.links.self,
    loadFunction: (imageRef: string) => ImageService.getImage(imageRef),
    dynamicValidUntilFunction: (entity) => entity.validUntil,
});
export const ContainerStore = createDynamicLoadingStore<ContainerView, string>({
    selectIdFunction: (container: ContainerView) => container.links.self,
    loadFunction: (containerRef) => ContainerService.getContainer(containerRef),
});
export const OfferContainersStore = createDynamicLoadingStore<OfferContainers, string>({
    selectIdFunction: (offerContainers: OfferContainers) => offerContainers.offerRef,
    loadFunction: (offerRef) => OfferService.getContainerByOffer(offerRef),
});
export const CertificateDownloadLinkStore = createDynamicLoadingStore<DownloadLinkWithId, string>({
    selectIdFunction: (entity: Readonly<DownloadLinkWithId>) => entity.id,
    loadFunction: (id: string) =>
        MembershipService.getCertificateLink(id).pipe(
            map((link: DownloadLink) => {
                return { ...link, id: id } as DownloadLinkWithId;
            }),
        ),
    dynamicValidUntilFunction: (entity) => moment(entity.expiresAt).toDate(),
});
export const PersonFavouritesStore = createDynamicLoadingStore<PersonFavourites, string>({
    selectIdFunction: (personFavourites: PersonFavourites) => personFavourites.personRef,
    loadFunction: (personRef) => PersonService.getFavouriteCompanies(personRef),
});
export const ConsolidatorStore = createDynamicLoadingStore<Consolidator, string, string>({
    selectIdFunction: (consolidator: Consolidator) => consolidator.links.self,
    loadFunction: (consolidatorRef) => ConsolidatorService.getConsolidator(consolidatorRef),
    searchFunction: (companyRef, consolidator) => companyRef === consolidator.links.company,
    searchLoadFunction: (companyRef: string) => ConsolidatorService.getCompanyConsolidators(companyRef),
});
export const MetaStore = createDynamicLoadingStore<MetaStaticEntity, MetaStaticEntityType, MetaStaticEntityType>({
    selectIdFunction: (entity: MetaStaticEntity) => entity.type,
    loadFunction: (type) => {
        switch (type) {
            case MetaStaticEntityType.CONTAINER_TYPE:
                return MetaService.getContainerTypes().pipe(
                    map((entity) => new MetaStaticEntity(entity, MetaStaticEntityType.CONTAINER_TYPE)),
                );
            case MetaStaticEntityType.UNIT:
                return MetaService.getUnits().pipe(
                    map((entity) => new MetaStaticEntity(entity, MetaStaticEntityType.UNIT)),
                );
            case MetaStaticEntityType.MESSAGE_TERMS_STATUS:
                return MetaService.getUnits().pipe(
                    map((entity) => new MetaStaticEntity(entity, MetaStaticEntityType.MESSAGE_TERMS_STATUS)),
                );
        }
    },
});
export const MessagesUnseenStore = createDynamicLoadingStore<MessageUnseen, MessageUnseen['companyRef']>({
    selectIdFunction: (props) => props.companyRef,
    loadFunction: (companyRef) =>
        MessageService.getUnseenMessageCount(companyRef).pipe(
            map((messagesUnseenCount) => new MessageUnseen(companyRef, messagesUnseenCount, false)),
        ),
});

export const SubstitutePersonStore = createDynamicLoadingStore<SubstitutePerson, string>({
    selectIdFunction: (substitutePerson: SubstitutePerson) => substitutePerson.links.self,
    loadFunction: (substitutePersonRef) => SubstitutePersonService.getSubstitutePerson(substitutePersonRef),
});
export const VerificationStore = createDynamicLoadingStore<Verification, string>({
    selectIdFunction: (verification: Verification) => verification.links.self,
    loadFunction: (reference: string) => VerificationService.getVerification(reference),
    dynamicValidUntilFunction: (verification) =>
        verification.links.downloadLink?.expiresAt?.toDate() || moment().add(1, 'd').toDate(),
});

export interface TradeItemSearchParams {
    dateFrom: Moment;
    dateUntil: Moment;
}

export const PurchaseIntentStore = createDynamicLoadingStore<PurchaseIntent, string, TradeItemSearchParams>({
    selectIdFunction: (purchaseIntent: PurchaseIntent) => purchaseIntent.links.self,
    loadFunction: (purchaseIntentRef) => PurchaseIntentService.getPurchaseIntent(purchaseIntentRef),
    searchLoadFunction: (searchParams: TradeItemSearchParams) =>
        PurchaseIntentService.getList(
            searchParams.dateFrom.format('YYYY-MM-DD'),
            searchParams.dateUntil.format('YYYY-MM-DD'),
        ),
});

export const RecurringOrderStore = createDynamicLoadingStore<RecurringOrder, string>({
    selectIdFunction: (recurringOrder: RecurringOrder) => recurringOrder.links.self,
    loadFunction: (recurringOrderRef) => RecurringOrderService.getRecurringOrder(recurringOrderRef),
});

export const PriceRequestStore = createDynamicLoadingStore<PriceRequest, string, TradeItemSearchParams>({
    selectIdFunction: (priceRequest: PriceRequest) => priceRequest.links.self,
    loadFunction: (priceRequestRef) => PriceRequestService.getPriceRequest(priceRequestRef),
    searchLoadFunction: (searchParams: TradeItemSearchParams) =>
        PriceRequestService.getList(
            searchParams.dateFrom.format('YYYY-MM-DD'),
            searchParams.dateUntil.format('YYYY-MM-DD'),
        ),
});

export const OrderStore = createDynamicLoadingStore<Order, string, TradeItemSearchParams>({
    selectIdFunction: (order: Order) => order.links.self,
    loadFunction: (orderRef) => OrderService.getOrder(orderRef),
    searchLoadFunction: (searchParams: TradeItemSearchParams) =>
        OrderService.getList(searchParams.dateFrom.format('YYYY-MM-DD'), searchParams.dateUntil.format('YYYY-MM-DD')),
});

export const ProductDataSheetStore = createDynamicLoadingStore<ProductDataSheet, string>({
    selectIdFunction: (productDataSheet: ProductDataSheet) => productDataSheet.links.self,
    loadFunction: (productDataSheetRef) => ProductDataSheetService.getProductDataSheet(productDataSheetRef),
});
