import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ContainerStore, MetaStore, OfferContainersStore } from 'store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { createStyles, withStyles, WithStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { ContainerType, OrderContainer } from 'model/ContainerView';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ComponentBase } from 'resub';
import Tooltip from '@mui/material/Tooltip';
import { Order } from 'model/Order';
import { PurchaseIntent } from 'model/PurchaseIntent';
import { MetaStaticEntityType } from 'model/Meta';
import { PriceRequest } from 'model/PriceRequest';
import { theme } from 'style/NearbuyStyle';
import { getUnitFromString } from 'model/Unit';

const _styles = (theme: Theme) =>
    createStyles({
        text: {
            textAlign: 'left',
        },
        textBold: {
            textAlign: 'left',
            fontWeight: 'bold',
        },
        gridContainer: {
            display: 'inline-flex',
            flexWrap: 'nowrap',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        icon: {
            marginTop: '5px',
            backgroundColor: 'transparent',
            color: theme.palette.secondary.main,
            borderRadius: '50%',
            '&:selected': {
                backgroundColor: 'transparent',
            },
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        priceRequestStyle: {
            color: theme.palette.action.active,
        },
    });

interface AmountAndPriceInfoProps extends WithStyles<typeof _styles>, WithTranslation {
    order?: Order;
    purchaseIntent?: PurchaseIntent;
    priceRequest?: PriceRequest;
    isContainerSectionShown?: boolean;
    isAmountContainerMismatchHidden?: boolean;
}

interface AmountAndPriceInfoState {
    amount: number;
    unit: string;
    totalPrice: number;
    pricePerUnit: number;
    containerSelections: OrderContainer[];
    containerTypes: ContainerType[];
    offerContainerSizesInBaseUnit?: number[];
    isMismatchIconShown: boolean;
}

export function isAmountFittingInSizes(sizesInBaseUnit: number[], amountInBaseUnit: number): boolean {
    const sizes = sizesInBaseUnit.sort((a, b) => a - b);
    const stack: { sizeIndex: number; remainingAmount: number }[] = [
        { sizeIndex: 0, remainingAmount: amountInBaseUnit },
    ];

    while (stack.length) {
        const { sizeIndex, remainingAmount } = stack.pop()!;

        if (remainingAmount === 0) return true;
        const size = sizes[sizeIndex];
        if (sizeIndex === sizesInBaseUnit.length || !size) continue;

        const maxAmountOfThisSize = Math.floor(remainingAmount / sizes[sizeIndex]);
        for (let i = 0; i <= maxAmountOfThisSize; i++) {
            stack.push({
                sizeIndex: sizeIndex + 1,
                remainingAmount: remainingAmount - i * sizes[sizeIndex],
            });
        }
    }

    return false;
}

class AmountAndPriceInfo extends ComponentBase<AmountAndPriceInfoProps, AmountAndPriceInfoState> {
    protected _buildState(
        props: AmountAndPriceInfoProps,
        initialBuild: boolean,
        incomingState: Readonly<AmountAndPriceInfoState> | undefined,
    ): Partial<AmountAndPriceInfoState> | undefined {
        const newState: Partial<AmountAndPriceInfoState> = {
            amount:
                props.purchaseIntent?.amount?.amount ??
                props.priceRequest?.amount?.amount ??
                props.order?.amount?.amount,
            unit: props.purchaseIntent?.amount?.unit ?? props.priceRequest?.amount?.unit ?? props.order?.amount?.unit,
            totalPrice: props.purchaseIntent?.totalPrice ?? props.priceRequest?.totalPrice ?? props.order?.totalPrice,
            pricePerUnit:
                props.purchaseIntent?.pricePerUnit ?? props.priceRequest?.pricePerUnit ?? props.order?.pricePerUnit,
            containerSelections:
                props.purchaseIntent?.containers ?? props.priceRequest?.containers ?? props.order?.containers,
            containerTypes: MetaStore.getOne(MetaStaticEntityType.CONTAINER_TYPE)?.data as ContainerType[],
        };

        if (!props.purchaseIntent && !props.priceRequest && !props.order) return newState;

        if (initialBuild) {
            newState.isMismatchIconShown = false;
        }

        const offerRef =
            props.purchaseIntent?.links.offer ?? props.priceRequest?.links.offer ?? props.order!.links.offer;
        if (!offerRef) return newState;

        newState.offerContainerSizesInBaseUnit = OfferContainersStore.getOne(offerRef)
            ?.containerRefs?.map((containerRef) => ContainerStore.getOne(containerRef))
            .filter((container) => container !== undefined)
            .map((container) => {
                const conversionFactorOfContainer = getUnitFromString(container!.unit)?.conversionFactor ?? 1;
                return container!.amount * conversionFactorOfContainer;
            });

        if (
            !newState.containerSelections?.length &&
            newState.offerContainerSizesInBaseUnit?.length &&
            newState.amount &&
            newState.unit
        ) {
            const conversionFactor = getUnitFromString(newState.unit)?.conversionFactor;
            if (conversionFactor) {
                newState.isMismatchIconShown = !isAmountFittingInSizes(
                    newState.offerContainerSizesInBaseUnit,
                    newState.amount * conversionFactor,
                );
            }
        }

        return newState;
    }

    showPrices(): React.ReactElement | undefined {
        if (this.props.purchaseIntent && (!this.state.totalPrice || !this.state.pricePerUnit)) return;
        return (
            <Grid container item spacing={1}>
                <Grid item xs={1} />
                {this.state.totalPrice && this.state.pricePerUnit ? (
                    <>
                        <Grid item xs={4}>
                            <Typography className={this.props.classes.text}>
                                {this.props.t('offer:totalPrice')}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography className={this.props.classes.textBold}>
                                {this.state.totalPrice.toFixed(2) + ' ' + this.props.t('offer:currency')}
                            </Typography>
                        </Grid>
                        {this.props.purchaseIntent && (
                            <>
                                <Grid item xs={2.5}>
                                    <Typography className={this.props.classes.text}>
                                        {this.props.t('offer:pricePer') +
                                            ' ' +
                                            this.props.t('shortunits:' + this.state.unit)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <Typography className={this.props.classes.textBold}>
                                        {this.state.pricePerUnit.toFixed(2) + ' ' + this.props.t('offer:currency')}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                        {this.props.priceRequest && (
                            <Grid item xs={4}>
                                <Typography className={this.props.classes.priceRequestStyle}>
                                    {this.state.pricePerUnit.toFixed(2) +
                                        ' ' +
                                        this.props.t('offer:currency') +
                                        '/' +
                                        this.props.t('shortunits:' + this.state.unit)}
                                </Typography>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid item marginTop={theme.spacing(2)}>
                        <Grid item xs={1} />
                        <Grid item>
                            <Typography className={this.props.classes.textBold}>
                                {this.props.t('offer:priceUponRequest')}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }

    showContainers(): React.ReactElement | undefined {
        if (!this.props.isContainerSectionShown || !this.state.containerSelections.length || !this.state.containerTypes)
            return;

        return (
            <Grid item container marginTop={theme.spacing(2)} marginBottom={theme.spacing(3)}>
                {this.state.containerSelections.map((containerSelection) => {
                    const container = ContainerStore.getOne(containerSelection.links.container);
                    if (container && containerSelection.amount > 0) {
                        return (
                            <Grid key={containerSelection.links.container} item container spacing={1}>
                                <Grid item xs={1} />
                                <Grid item xs={4} borderBottom={`1px solid ${theme.palette.secondary.light}`}>
                                    <Typography>
                                        {this.props.t(
                                            'containertype:' +
                                                this.state.containerTypes.find(
                                                    (containerType) => container.containerType === containerType.id,
                                                )?.slug,
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={0.75} borderBottom={`1px solid ${theme.palette.secondary.light}`}>
                                    <Typography>{containerSelection.amount}</Typography>
                                </Grid>
                                <Grid item xs={0.5} borderBottom={`1px solid ${theme.palette.secondary.light}`}>
                                    <Typography>{this.props.t(`common:multiplicationSign`)}</Typography>
                                </Grid>
                                <Grid item xs={3} borderBottom={`1px solid ${theme.palette.secondary.light}`}>
                                    <Typography>
                                        {container.amount + ' ' + this.props.t('shortunits:' + container.unit)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }
                })}
            </Grid>
        );
    }

    showPurchaseAmount(): React.ReactElement | undefined {
        if (!this.state.amount || !this.state.unit) return;

        return (
            <Grid container>
                <Grid container spacing={1}>
                    <Grid item xs={1} />
                    <Grid container item xs={4}>
                        <Typography>{this.props.t('messaging:purchaseIntentAmount')}</Typography>
                    </Grid>
                    <Grid container item xs={7}>
                        <Typography className={this.props.classes.textBold}>
                            {this.state.amount + ' ' + this.props.t('shortunits:' + this.state.unit)}
                        </Typography>
                    </Grid>
                    {this.state.isMismatchIconShown && !this.props.isAmountContainerMismatchHidden && (
                        <Grid item xs={1}>
                            <Tooltip title={this.props.t(`order:amountContainerMismatch`)}>
                                <InfoOutlinedIcon className={this.props.classes.icon} />
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <div>
                {this.showPurchaseAmount()}
                {this.showContainers()}
                {this.showPrices()}
            </div>
        );
    }
}

export default withTranslation(['order', 'shortunits', 'messaging', 'common', 'offer', 'containertype'])(
    withStyles(_styles, {
        withTheme: true,
    })(AmountAndPriceInfo),
);
