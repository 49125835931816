export enum SettingsKey {
    SEARCH_FILTERS = 'SEARCH_FILTERS',
    MAP_NAVIGATION_INSTRUCTIONS = 'MAP_NAVIGATION_INSTRUCTIONS',
    SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES = 'SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES',
    SHOW_ONLY_OWN_TRADE_RESULTS_SALES = 'SHOW_ONLY_OWN_TRADE_RESULTS_SALES',
    SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS = 'SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS',
    SHOW_ONLY_OWN_TRADE_RESULTS_SALES_REQUESTS = 'SHOW_ONLY_OWN_TRADE_RESULTS_SALES_REQUESTS',
    SORT_PURCHASES_ACTIVE = 'SORT_PURCHASES_ACTIVE',
    SORT_PURCHASES_TERMINATED = 'SORT_PURCHASES_TERMINATED',
    SORT_PURCHASE_REQUESTS_ACTIVE = 'SORT_PURCHASE_REQUESTS_ACTIVE',
    SORT_PURCHASE_REQUESTS_TERMINATED = 'SORT_PURCHASE_REQUESTS_TERMINATED',
    SORT_SALES_ACTIVE = 'SORT_SALES_ACTIVE',
    SORT_SALES_TERMINATED = 'SORT_SALES_TERMINATED',
    SORT_SALES_REQUESTS_ACTIVE = 'SORT_SALES_REQUESTS_ACTIVE',
    SORT_SALES_REQUESTS_TERMINATED = 'SORT_SALES_REQUESTS_TERMINATED',
    SORT_OFFERS_ACTIVE = 'SORT_OFFERS_ACTIVE',
    SORT_OFFERS_TERMINATED = 'SORT_OFFERS_TERMINATED',
    SORT_REQUESTS_ACTIVE = 'SORT_REQUESTS_ACTIVE',
    SORT_REQUESTS_TERMINATED = 'SORT_REQUESTS_TERMINATED',

    RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS = 'RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASE_REQUESTS',
    RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES = 'RECURRING_ORDER_SHOW_ONLY_OWN_TRADE_RESULTS_PURCHASES',
    RECURRING_ORDER_SORT_REQUESTS_ACTIVE = 'RECURRING_ORDER_SORT_REQUESTS_ACTIVE',
    RECURRING_ORDER_SORT_REQUESTS_TERMINATED = 'RECURRING_ORDER_SORT_REQUESTS_TERMINATED',
    RECURRING_ORDER_SORT_PURCHASES_ACTIVE = 'RECURRING_ORDER_SORT_PURCHASES_ACTIVE',
    RECURRING_ORDER_SORT_PURCHASES_TERMINATED = 'RECURRING_ORDER_SORT_PURCHASES_TERMINATED',
}

export interface UserSetting {
    key: SettingsKey;
    settings: string;
    links: {
        self: string;
        update: string;
        remove: string;
        person: string;
    };
}

export interface EmptySetting {
    key: SettingsKey;
    settings: null;
}

export interface UserSettingWriteView {
    key: SettingsKey;
    setting: string;
}
