import { Grid, Theme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import OfferService from 'api/OfferService';
import DeleteDialog from 'components/shared/DeleteDialog';
import { DeleteIcon, EditIcon } from 'components/shared/Icons';
import { Offer } from 'model/Offer';
import { Product } from 'model/Product';
import * as React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { OfferStore, ProductStore } from 'store';
import { captureWebEvent } from 'util/AnalyticUtils';
import OfferDialog from 'components/search/result-items/offers/OfferDialog';

const styles = (theme: Theme) =>
    createStyles({
        tableRow: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.background.default,
            },
        },
        tableCell: {
            whiteSpace: 'pre-wrap',
            border: 'none',
        },
        roundedBtn: {
            padding: theme.spacing(0.5),
            margin: theme.spacing(0.5),
        },
    });

interface OfferTableRowProperties extends WithStyles<typeof styles>, WithTranslation {
    offerRef: string;
    readOnly?: boolean;
    onClick?: () => void;
}

interface OfferTableRowState {
    offer?: Offer;
    readOnly: boolean;
    isOfferViewOpen: boolean;
    product?: Product;
    deleteOpen: boolean;
}

class OfferTableRow extends ComponentBase<OfferTableRowProperties, OfferTableRowState> {
    protected _buildState(
        props: OfferTableRowProperties,
        initialBuild: boolean,
    ): Partial<OfferTableRowState> | undefined {
        if (this.props.offerRef) {
            const offer = OfferStore.getOne(this.props.offerRef);
            return {
                offer: offer,
                product: offer ? ProductStore.getOne(offer.links.category) : undefined,
            };
        }
    }

    deleteOffer(): void {
        captureWebEvent('my_market-confirm-delete-offer-button');
        if (this.state.offer) {
            this.setState({ isOfferViewOpen: false });
            OfferService.deleteOffer(this.state.offer.id, this.state.offer.links.company).subscribe();
        }
    }

    render(): React.ReactElement | null {
        if (!this.state.offer) {
            return null;
        }

        return (
            <>
                <DeleteDialog
                    isOpen={this.state.deleteOpen}
                    quickRemovePhrase
                    onClose={(remove) => {
                        this.setState({ deleteOpen: false });
                        if (remove) {
                            this.deleteOffer();
                        }
                    }}
                    title={this.props.t('offer:removeOfferQuestion')}
                    calledFrom={'OfferTableRow'}
                />
                {this.state.isOfferViewOpen && (
                    <OfferDialog
                        isOpen
                        onClose={(): void => this.setState({ isOfferViewOpen: false })}
                        companyRef={this.state.offer.links.company}
                        offerRef={this.props.offerRef}
                    />
                )}
                <TableRow
                    key={this.state.offer.links.self}
                    className={this.props.classes.tableRow}
                    sx={
                        this.props.onClick
                            ? {
                                  '&:hover': {
                                      cursor: 'pointer',
                                  },
                              }
                            : undefined
                    }
                >
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography fontWeight={'bold'}>
                            {this.state.product ? this.props.t('ontofood:' + this.state.product.label) : ''}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>
                            {this.state.offer?.totalAmount.amount.toLocaleString('de-DE') +
                                ' ' +
                                this.props.t('shortunits:' + this.state.offer.totalAmount.unit)}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>
                            {this.state.offer.pricePerUnit !== null && this.state.offer.pricePerUnit !== undefined
                                ? this.state.offer.pricePerUnit.toLocaleString('de-DE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                  }) +
                                  ' ' +
                                  this.props.t('offer:per') +
                                  ' ' +
                                  this.props.t('shortunits:' + this.state.offer.totalAmount.unit)
                                : this.props.t('offer:uponRequest')}{' '}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>
                            {!this.state.offer.dateEnd || this.state.offer.dateEnd.toString() === 'Invalid date'
                                ? `${this.props.t('offer:from')} ${this.state.offer.dateFrom?.format('DD.MM.YY')}`
                                : `${this.state.offer.dateFrom?.format('DD.MM.YY')} - ${this.state.offer.dateEnd.format(
                                      'DD.MM.YY',
                                  )}`}
                        </Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell} onClick={this.props.onClick}>
                        <Typography>{this.state.offer.description ? this.state.offer.description : ''}</Typography>
                    </TableCell>
                    <TableCell className={this.props.classes.tableCell}>
                        {!this.props.readOnly && (
                            <Grid container wrap={'nowrap'} justifyContent={'flex-end'}>
                                <IconButton
                                    className={this.props.classes.roundedBtn}
                                    onClick={() => {
                                        captureWebEvent('my_market-edit-existing-offer-button');
                                        this.setState({
                                            isOfferViewOpen: true,
                                        });
                                    }}
                                    size="large"
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className={this.props.classes.roundedBtn}
                                    onClick={(
                                        event:
                                            | React.MouseEvent<HTMLAnchorElement>
                                            | React.MouseEvent<HTMLButtonElement>,
                                    ) => {
                                        captureWebEvent('my_market-delete-offer-button');
                                        event.ctrlKey || event.metaKey
                                            ? this.deleteOffer()
                                            : this.setState({
                                                  deleteOpen: true,
                                              });
                                    }}
                                    size="large"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        )}
                    </TableCell>
                </TableRow>
            </>
        );
    }
}

export default withTranslation(['offer', 'shortunits', 'ontofood'])(
    withStyles(styles, { withTheme: true })(OfferTableRow),
);
