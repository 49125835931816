import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default interface StepBrandNameProps {
    description?: string;
    brandName?: string;
}

export const StepBrandName = (props: StepComponentProps<StepBrandNameProps>) => {
    const theme = useTheme();
    const { t } = useTranslation();

    useEffect(() => {
        props.setData('StepBrandName', {
            description: props.data.description ?? '',
            brandName: props.data.brandName ?? '',
        });
    }, []);

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_BRAND_NAME_AND_LABEL_TITLE')}
            </Typography>
        </Grid>
    );

    const showDescriptionInput = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(0.5) }}>
            <TextField
                inputProps={{ maxLength: 64 }}
                sx={{ width: '100%' }}
                value={props.data.description ?? ''}
                onChange={(event) =>
                    props.setData('StepBrandName', {
                        ...props.data,
                        description: event.target.value,
                    })
                }
                label={t('productDataSheet:DATASTEP_BRAND_NAME_MY_DESCRIPTION')}
            />
        </Grid>
    );

    const showBrandNameInput = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <TextField
                inputProps={{ maxLength: 56 }}
                sx={{ width: '100%' }}
                value={props.data.brandName ?? ''}
                onChange={(event) =>
                    props.setData('StepBrandName', {
                        ...props.data,
                        brandName: event.target.value,
                    })
                }
                label={t('productDataSheet:DATASTEP_BRAND_NAME')}
            />
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_BRAND_NAME_AND_LABEL_TEXT')}</Typography>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showDescriptionInput()}
            {showBrandNameInput()}
        </Grid>
    );
};
