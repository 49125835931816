import { SxProps } from '@mui/material';
import { theme } from 'style/NearbuyStyle';

export const isGtinValid = (inputValue: string): boolean => {
    const gtinRegex = /^\d{13,14}$/;
    if (!gtinRegex.test(inputValue)) {
        return false;
    }

    const checkDigit = Number(inputValue[inputValue.length - 1]);
    const startIndex = inputValue.length === 13 ? 0 : 1;
    const sum = inputValue
        .slice(0, -1)
        .split('')
        .map((digit, index) => (index % 2 === startIndex ? Number(digit) * 3 : Number(digit)))
        .reduce((acc, curr) => acc + curr, 0);
    const calculatedCheckDigit = (10 - (sum % 10)) % 10;

    return checkDigit === calculatedCheckDigit;
};

export const getProductDataSheetDialogAutoCompleteSX = (): SxProps => {
    return {
        '& .MuiAutocomplete-clearIndicator': {
            backgroundColor: 'transparent',
            color: theme.palette.grey[700],
            '&:hover': {
                color: theme.palette.grey[700],
            },
        },
        '& .MuiAutocomplete-popupIndicator': {
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
    };
};

export const getAutoCompleteTextFieldSX = (): SxProps => {
    return {
        button: {
            backgroundColor: 'transparent',
            color: theme.palette.text.secondary,
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme.palette.text.secondary,
            },
            '&:disabled': {
                backgroundColor: 'transparent',
            },
        },
    };
};
