import { Pair } from 'util/Types';
import { Amount } from 'model/Amount';
import { OrderContainer } from 'model/ContainerView';
import { LevelOfProcessing } from 'model/LevelOfProcessing';

export class PurchaseIntentWriteView {
    constructor(
        public offerRef: string,
        public message?: string,
        public containers?: Pair<number, string>[],
        public totalAmount?: Amount,
    ) {}
}

export class PurchaseIntent {
    constructor(
        public dateCreated: Date,
        public amount: Amount,
        public totalPrice: number,
        public pricePerUnit: number,
        public status: PurchaseIntentStatus,
        public containers: OrderContainer[],
        public levelsOfProcessing: LevelOfProcessing[],
        public links: PurchaseIntentLinks,
    ) {}
}

export class PurchaseIntentLinks {
    constructor(
        public self: string,
        public update: string,
        public offer: string,
        public category: string,
        public buyingCompany: string,
        public buyingPerson: string,
        public sellingCompany: string,
        public sellingPerson: string,
    ) {}
}

export enum PurchaseIntentStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    CANCELED_BY_SELLER = 'CANCELED_BY_SELLER',
    CANCELED_BY_BUYER = 'CANCELED_BY_BUYER',
    COMPLETED = 'COMPLETED',
}

export interface PurchaseIntentStatusWriteView {
    status: PurchaseIntentStatus;
}
