import AssortmentService from 'api/AssortmentService';
import ProductDataSheetService from 'api/ProductDataSheetService';
import {
    Allergen,
    AllergenTraces,
    AllergenType,
    SpecificationGrain,
    SpecificationNuts,
} from 'components/productdatasheet/datablocks/block3/AllergenType';
import { ProductDataSheetProps, ProductDataSheetState } from 'components/productdatasheet/ProductDataSheetDialog';
import {
    DataBlock,
    DataStep,
    ProductDataSheetData,
    StepComponentProps,
} from 'components/productdatasheet/ProductDataSheetStructure';
import { AssortmentWriteView } from 'model/Assortment';
import { ContainerLinks, ContainerView, ContainerWriteView } from 'model/ContainerView';
import { Ingredient, IngredientLinks, IngredientWriteView } from 'model/Ingredient';
import { NutritionInformation, ProductDataSheet, ProductDataSheetWriteView } from 'model/ProductDataSheet';
import { ContainerSaveStore, ProductStore } from 'store';
import { deepCopy, isDeepEquality } from 'util/deep-equality-helpers';
import { getProductNameFromString, getUuidFromString, isUUID } from 'util/helpers';
import StepAllergensProps, {
    AllergenTraceWithKey,
    AllergenWithKey,
} from 'components/productdatasheet/datablocks/block3/StepAllergens';
import StepProductInformationProps from 'components/productdatasheet/datablocks/block1/StepProductInformation';
import StepBrandNameProps from 'components/productdatasheet/datablocks/block1/StepBrandName';
import StepLevelsOfProcessingProps from 'components/productdatasheet/datablocks/block1/StepLevelsOfProcessing';
import StepGradeProps from 'components/productdatasheet/datablocks/block1/StepGrade';
import StepTaxProps from 'components/productdatasheet/datablocks/block1/StepTax';
import {
    ProductNumberWithKey,
    StepProductNumberProps,
} from 'components/productdatasheet/datablocks/block1/StepProductNumber';
import StepDimensionAndWeightProps from 'components/productdatasheet/datablocks/block2/StepDimensionAndWeight';
import StepTemperatureProps from 'components/productdatasheet/datablocks/block2/StepTemperature';
import StepIngredientsProps, { IngredientWithKey } from 'components/productdatasheet/datablocks/block3/StepIngredients';
import { AdditiveWithKey, StepAdditivesProps } from 'components/productdatasheet/datablocks/block3/StepAdditives';
import { StepNutritionProps } from 'components/productdatasheet/datablocks/block3/StepNutrition';
import { StepDietTypeProps } from 'components/productdatasheet/datablocks/block3/StepDietType';
import { isGtinValid } from 'components/productdatasheet/ProductDataSheetHelpers';
import { ProductNumber } from 'model/ProductNumber';
import { Additive } from 'model/Additive';
import { AmountRange } from 'model/AmountRange';
import { Husbandry, HusbandryWithKey } from 'components/productdatasheet/datablocks/block4/Husbandry';
import { Cultivation, CultivationWithKey } from 'components/productdatasheet/datablocks/block4/Cultivation';
import { StepHusbandryCultivationProps } from 'components/productdatasheet/datablocks/block4/StepHusbandryCultivation';
import ProductTrait, { ProductTraitWithKey } from 'model/ProductTrait';
import { StepProductTraitProps } from 'components/productdatasheet/datablocks/block1/StepProductTrait';
import { Product } from 'model/Product';
import ContainerService from 'api/ContainerService';
import { StepContainerProps } from 'components/productdatasheet/datablocks/block2/StepContainer';
import { StepOuterContainerProps } from 'components/productdatasheet/datablocks/block2/StepOuterContainer';
import { Unit, UnitString, UnitType } from 'model/Unit';

export default class ProductDataSheetDialogHelpers {
    constructor(
        private props: ProductDataSheetProps,
        private state: ProductDataSheetState,
        private setState: <K extends keyof ProductDataSheetState>(
            state:
                | ((
                      prevState: Readonly<ProductDataSheetState>,
                      props: Readonly<ProductDataSheetProps>,
                  ) => Pick<ProductDataSheetState, K> | ProductDataSheetState | null)
                | Pick<ProductDataSheetState, K>
                | ProductDataSheetState
                | null,
            callback?: () => void,
        ) => void,
    ) {}

    save(shouldSaveAssortment: boolean, callback?: () => void): void {
        this.insertOrUpdateProductDataSheet(
            shouldSaveAssortment ? this.state.data.StepProductInformation.assortmentToAdd : undefined,
            callback,
        );

        if (this.state.productDataSheetRef) {
            ContainerSaveStore.savePDSContainers(this.state.productDataSheetRef);
        }
        this.discardInvalidInputValues();
    }

    insertOrUpdateProductDataSheet(assortmentToAdd: Map<string, boolean> | undefined, callback?: () => void) {
        this.setState({ isSaveAllowed: false });
        const writeView = this.composeWriteView();
        if (this.state.productDataSheetRef) {
            ProductDataSheetService.updateProductDataSheet(this.state.productDataSheetRef, writeView).subscribe(() => {
                if (assortmentToAdd && assortmentToAdd.size) {
                    this.saveAssortment(assortmentToAdd);
                }
                callback?.();
                this.setState({ isSaveAllowed: true });
            });
        } else {
            ProductDataSheetService.addProductDataSheet(writeView).subscribe((value: ProductDataSheet) => {
                if (assortmentToAdd && assortmentToAdd.size) {
                    this.saveAssortment(assortmentToAdd);
                }
                callback?.();
                this.setState({ productDataSheetRef: value.links.self, isSaveAllowed: true });
            });
        }
    }

    saveAssortment(assortmentToAdd: Map<string, boolean>): void {
        Array.from(assortmentToAdd)
            .reduce(
                (array, [productRef, shouldAdd]) => (shouldAdd ? array.concat(productRef) : array),
                new Array<string>(),
            )
            .forEach((productRef) => {
                const companyId = getUuidFromString(this.props.companyRef);
                if (companyId) {
                    AssortmentService.addAssortment(new AssortmentWriteView(productRef), companyId).subscribe();
                }
            });
    }

    hasDateStepError(dataStep: DataStep): boolean {
        const step1_1 = this.state.data.StepProductInformation;
        const step2_1 = this.state.data.StepDimensionAndWeight;

        switch (dataStep.dataId) {
            case 'StepProductInformation':
                return !step1_1.productRef;
            case 'StepDimensionAndWeight':
                return !step2_1.productUnit;
            default:
                return false;
        }
    }

    composeWriteView(): ProductDataSheetWriteView {
        return new ProductDataSheetWriteView(
            this.state.data.StepProductInformation.productRef,
            undefined,
            this.state.data.StepBrandName.description?.trim()
                ? this.state.data.StepBrandName.description?.trim()
                : undefined,
            this.state.data.StepBrandName.brandName?.trim()
                ? this.state.data.StepBrandName.brandName?.trim()
                : undefined,
            this.state.levelsOfProcessing,
            this.state.productTraits,
            this.state.data.StepProductInformation.productType,
            this.state.selectedGrades?.filter((it) => !!it),
            this.state.data.StepTax.tax,
            this.state.productNumbers,
            this.state.data.StepDimensionAndWeight?.productUnit?.unit,
            this.state.caliber,
            this.state.weight,
            this.state.temperatureMaxStorage,
            this.state.temperatureMaxTransport,
            this.state.data.StepTemperature?.temperatureText,
            this.state.ingredients,
            this.state.additives,
            this.state.allergens,
            this.state.allergenTraces,
            this.state.freeOfAllergens,
            this.state.nutritionInformation,
            this.state.diets,
            this.state.husbandries,
            this.state.cultivations,
        );
    }

    getComponentProps(dataId: keyof ProductDataSheetData) {
        const partialData = deepCopy(this.state.data[dataId]);
        return {
            setData: (dataId, newPartialData) => {
                this.setState((prevState) => {
                    const data = deepCopy(prevState.data);
                    data[dataId] = { ...data[dataId], ...newPartialData };
                    return { data };
                });
            },
            data: partialData,
        } as StepComponentProps<typeof partialData>;
    }

    private hasStepAllergensChanges(step3_3: StepAllergensProps, pds: ProductDataSheet | undefined) {
        const typesWithSpec = [AllergenType.NUTS, AllergenType.GLUTEN_GRAINS];

        const areAllergenTypesEqual = isDeepEquality(
            step3_3.allergens
                ?.filter((a) => !!a.allergen)
                ?.map((it) => it.allergen)
                ?.sort() ?? [],
            pds?.allergens?.map((it) => it.allergenType.toString())?.sort() ?? [],
            true,
        );
        const stateAllergensWithSpec = this.state.allergens?.filter((it) => typesWithSpec.includes(it.allergenType));
        const areAllergenSpecsEqual = stateAllergensWithSpec?.length
            ? stateAllergensWithSpec.every((stateAllergen) => {
                  if (stateAllergen.allergenType == AllergenType.NUTS) {
                      return isDeepEquality(
                          stateAllergen.specificationNuts?.map((it) => it.toString())?.sort() ?? [],
                          pds?.allergens
                              ?.find((it) => it.allergenType == AllergenType.NUTS)
                              ?.specificationNuts?.map((it) => it.toString())
                              ?.sort() ?? [],
                      );
                  } else {
                      return isDeepEquality(
                          stateAllergen.specificationGrain?.map((it) => it.toString())?.sort() ?? [],
                          pds?.allergens
                              ?.find((it) => it.allergenType == AllergenType.GLUTEN_GRAINS)
                              ?.specificationGrain?.map((it) => it.toString())
                              ?.sort() ?? [],
                      );
                  }
              })
            : true;

        const haveAllergensChanges = !areAllergenTypesEqual || !areAllergenSpecsEqual;

        const areAllergenTraceTypesEqual = isDeepEquality(
            step3_3.allergenTraces
                ?.filter((a) => !!a.allergen)
                ?.map((it) => it.allergen)
                ?.sort() ?? [],
            pds?.allergenTraces?.map((it) => it.allergenType.toString())?.sort() ?? [],
        );
        const stateAllergenTracesWithSpec = this.state.allergenTraces?.filter((it) =>
            typesWithSpec.includes(it.allergenType),
        );
        const areAllergenTraceSpecsEqual = stateAllergenTracesWithSpec?.length
            ? stateAllergenTracesWithSpec.every((stateAllergenTraces) => {
                  if (stateAllergenTraces.allergenType == AllergenType.NUTS) {
                      return isDeepEquality(
                          stateAllergenTraces.specificationNuts?.map((it) => it.toString())?.sort() ?? [],
                          pds?.allergenTraces
                              ?.find((it) => it.allergenType == AllergenType.NUTS)
                              ?.specificationNuts?.map((it) => it.toString())
                              ?.sort() ?? [],
                      );
                  } else {
                      return isDeepEquality(
                          stateAllergenTraces.specificationGrain?.map((it) => it.toString())?.sort() ?? [],
                          pds?.allergenTraces
                              ?.find((it) => it.allergenType == AllergenType.GLUTEN_GRAINS)
                              ?.specificationGrain?.map((it) => it.toString())
                              ?.sort() ?? [],
                      );
                  }
              })
            : true;

        const haveAllergenTracesChanges = !areAllergenTraceTypesEqual || !areAllergenTraceSpecsEqual;
        const haveFreeOfAllergensChanges = !isDeepEquality(this.state.freeOfAllergens, pds?.freeOfAllergens);

        return haveAllergensChanges || haveAllergenTracesChanges || haveFreeOfAllergensChanges;
    }

    hasDataStepChanges(dataStep: keyof ProductDataSheetData): boolean {
        const pds = this.state.productDataSheet;
        const stepData = this.state.data[dataStep];

        const validators = {
            StepProductInformation: (stepData: StepProductInformationProps) =>
                stepData.productRef != pds?.links.product || stepData.productType != pds?.productType,
            StepBrandName: (stepData: StepBrandNameProps) =>
                (stepData.brandName ?? '') != (pds?.brandName ?? '') ||
                (stepData.description ?? '') != (pds?.description ?? ''),
            StepLevelsOfProcessing: (stepData: StepLevelsOfProcessingProps) =>
                !isDeepEquality(
                    stepData.levelOfProcessingRefs ?? [],
                    pds?.levelsOfProcessing?.map((it) => it.links.self) ?? [],
                ),
            StepProductTrait: (stepData: StepProductTraitProps) =>
                !isDeepEquality(getSanitizedProductTraits(stepData.productTraits)?.sort(), pds?.productTraits?.sort()),
            StepGrade: (stepData: StepGradeProps) =>
                !isDeepEquality(stepData.selectedGrades?.filter((it) => !!it.links.self) ?? [], pds?.grades ?? []),
            StepTax: (stepData: StepTaxProps) => stepData.tax != pds?.tax,
            StepProductNumber: (stepData: StepProductNumberProps) =>
                !isDeepEquality(
                    getSanitizedProductNumbers(stepData.productNumbers)
                        ?.map((it) => JSON.stringify(it))
                        ?.sort() ?? [],
                    pds?.productNumbers?.map((it) => JSON.stringify(it))?.sort() ?? [],
                ),
            StepDimensionAndWeight: (stepData: StepDimensionAndWeightProps) =>
                stepData.productUnit?.unit != pds?.productUnit ||
                !isDeepEquality(getSanitizedAmountRange(stepData.weight), pds?.weight ?? undefined) ||
                !isDeepEquality(getSanitizedAmountRange(stepData.caliber), pds?.caliber ?? undefined),
            StepTemperature: (stepData: StepTemperatureProps) =>
                getSanitizedTemperature(stepData.temperatureMaxTransport) != pds?.temperatureMaxTransport ||
                getSanitizedTemperature(stepData.temperatureMaxStorage) != pds?.temperatureMaxStorage ||
                (stepData.temperatureText ?? '') != (pds?.temperatureText ?? ''),
            StepIngredients: (stepData: StepIngredientsProps) => {
                return !isDeepEquality(getSanitizedIngredients(stepData.ingredients) ?? [], pds?.ingredients ?? []);
            },
            StepAdditives: (stepData: StepAdditivesProps) =>
                !isDeepEquality(getSanitizedAdditives(stepData.additives) ?? [], pds?.additives ?? []),
            StepAllergens: (stepData: StepAllergensProps) => this.hasStepAllergensChanges(stepData, pds),
            StepNutrition: (stepData: StepNutritionProps) =>
                !isDeepEquality(
                    getSanitizedNutritionInformation(stepData.nutritionInformation),
                    getSanitizedNutritionInformation(pds?.nutritionInformation),
                ),
            StepDietType: (stepData: StepDietTypeProps) => !isDeepEquality(stepData.diets?.sort(), pds?.diets?.sort()),
            StepHusbandryCultivation: (stepData: StepHusbandryCultivationProps) => {
                const hasHusbandryChanges = !isDeepEquality(
                    getSanitizedHusbandries(stepData.husbandries)?.sort() ?? [],
                    pds?.husbandry?.sort() ?? [],
                );
                const hasCultivationChanges = !isDeepEquality(
                    getSanitizedCultivations(stepData.cultivations)?.sort() ?? [],
                    pds?.cultivation?.sort() ?? [],
                );
                return hasHusbandryChanges || hasCultivationChanges;
            },
            StepContainer: (stepData: StepContainerProps) =>
                stepData.pdsRef ? !!ContainerSaveStore.peekAllByRef(stepData.pdsRef)?.length : false,
            StepOuterContainer: (stepData: StepOuterContainerProps) =>
                stepData.pdsRef ? !!ContainerSaveStore.peekAllByRef(stepData.pdsRef)?.length : false,
        };

        return validators[dataStep](stepData);
    }

    isDataStepComplete(dataStep: DataStep): boolean {
        if (dataStep.dataId === undefined) return false;

        const stepData = this.state.data[dataStep.dataId];

        const validators = {
            StepProductInformation: (stepData: StepProductInformationProps) => !!stepData.productRef,
            StepBrandName: (stepData: StepBrandNameProps) => !!(stepData.brandName || stepData.description),
            StepLevelsOfProcessing: (stepData: StepLevelsOfProcessingProps) => !!stepData.levelOfProcessingRefs?.length,
            StepProductTrait: (stepData: StepProductTraitProps) =>
                !!getSanitizedProductTraits(stepData.productTraits)?.length,
            StepGrade: (stepData: StepGradeProps) =>
                (stepData.selectedGrades?.length ?? 0) > 1 || !!stepData.selectedGrades?.at(0)?.label,
            StepTax: (stepData: StepTaxProps) => stepData.tax !== undefined,
            StepProductNumber: (stepData: StepProductNumberProps) =>
                (stepData.productNumbers?.length ?? 0) > 1 &&
                !!stepData.productNumbers?.at(0)?.productNumber?.number &&
                !!stepData.productNumbers?.at(0)?.productNumber?.type,
            StepDimensionAndWeight: (stepData: StepDimensionAndWeightProps) => !!stepData.productUnit,
            StepTemperature: (stepData: StepTemperatureProps) =>
                !!getSanitizedTemperature(stepData.temperatureMaxTransport) ||
                !!getSanitizedTemperature(stepData.temperatureMaxStorage) ||
                !!stepData.temperatureText,
            StepIngredients: (stepData: StepIngredientsProps) =>
                (stepData.ingredients?.length ?? 0) > 1 ||
                (!!stepData.ingredients?.at(0)?.ingredient?.links.product &&
                    !!stepData.ingredients?.at(0)?.ingredient?.percentage),
            StepAdditives: (stepData: StepAdditivesProps) =>
                (stepData.additives?.length ?? 0) > 1 ||
                (!!stepData.additives?.at(0)?.additive?.enumber && !!stepData.additives?.at(0)?.additive?.usage),
            StepAllergens: (stepData: StepAllergensProps) =>
                (stepData.allergens?.length ?? 0) > 1 ||
                !!stepData.allergens?.at(0)?.allergen ||
                (stepData.allergenTraces?.length ?? 0) > 1 ||
                !!stepData.allergenTraces?.at(0)?.allergen ||
                !!stepData.freeOfAllergens?.length,
            StepNutrition: (stepData: StepNutritionProps) =>
                hasNutritionInformationValidValues(stepData.nutritionInformation),
            StepDietType: (stepData: StepDietTypeProps) => !!stepData.diets?.length,
            StepHusbandryCultivation: (stepData: StepHusbandryCultivationProps) =>
                !!getSanitizedHusbandries(stepData.husbandries)?.length ||
                !!getSanitizedCultivations(stepData.cultivations)?.length,
            StepContainer: (stepData: StepContainerProps) =>
                !!stepData.normalContainers?.length &&
                !!stepData.normalContainers.at(0)?.containerType &&
                !!stepData.normalContainers.at(0)?.unit &&
                !!stepData.normalContainers.at(0)?.amount,
            StepOuterContainer: (stepData: StepOuterContainerProps) =>
                !!stepData.outerContainers?.length &&
                !!stepData.outerContainers.at(0)?.containerType &&
                !!stepData.outerContainers.at(0)?.innerContainer &&
                !!stepData.outerContainers.at(0)?.amount,
        };

        return validators[dataStep.dataId](stepData);
    }

    discardInvalidInputValues() {
        this.setState((prevState) => {
            const pdsProductNames = getPDSProducts(prevState)?.map((p) => getProductNameFromString(p.links.self)) ?? [];
            return {
                data: {
                    ...prevState.data,
                    StepProductTrait: {
                        productTraits: prevState.data.StepProductTrait.productTraits?.filter(
                            (it) => !!it.productTrait.trait && !!it.productTrait.description,
                        ),
                    },
                    StepProductNumber: {
                        productNumbers: prevState.data.StepProductNumber.productNumbers?.filter((it) => {
                            return (
                                prevState.productNumbers?.map((it) => it.type).includes(it.productNumber.type) ?? false
                            );
                        }),
                    },
                    StepGrade: {
                        selectedGrades: prevState.data.StepGrade.selectedGrades?.filter((it) => !!it.label),
                    },
                    StepDimensionAndWeight: {
                        productUnit: prevState.data.StepDimensionAndWeight.productUnit,
                        weight: prevState.weight,
                        caliber: prevState.caliber,
                    },
                    StepTemperature: {
                        temperatureText: prevState.data.StepTemperature.temperatureText,
                        temperatureMaxTransport: getSanitizedTemperature(prevState.temperatureMaxTransport),
                        temperatureMaxStorage: getSanitizedTemperature(prevState.temperatureMaxStorage),
                    },
                    StepIngredients: {
                        companyId: prevState.currentStepId,
                        ingredients: prevState.data.StepIngredients.ingredients?.filter(
                            (iwp) => !!iwp.ingredient.links.product,
                        ),
                    },
                    StepAdditives: {
                        additives: prevState.data.StepAdditives.additives?.filter(
                            (a) => !!a.additive.enumber && !!a.additive.usage,
                        ),
                    },
                    StepAllergens: {
                        allergens: prevState.data.StepAllergens.allergens?.filter((a) => !!a.allergen),
                        allergenTraces: prevState.data.StepAllergens.allergenTraces?.filter((a) => !!a.allergen),
                        freeOfAllergens: prevState.data.StepAllergens.freeOfAllergens?.filter((a) => !!a),
                    },
                    StepNutrition: {
                        nutritionInformation: getSanitizedNutritionInformation(
                            prevState.data.StepNutrition.nutritionInformation,
                        ),
                    },
                    StepHusbandryCultivation: {
                        husbandries: prevState.data.StepHusbandryCultivation.husbandries
                            ?.filter((it) => !!it.husbandry.husbandryType && !!it.husbandry.productName)
                            ?.filter((it) => pdsProductNames.includes(it.husbandry.productName)),
                        cultivations: prevState.data.StepHusbandryCultivation.cultivations
                            ?.filter((it) => !!it.cultivation.cultivationType && !!it.cultivation.productName)
                            ?.filter((it) => pdsProductNames.includes(it.cultivation.productName)),
                    },
                    StepContainer: {
                        ...prevState.data.StepContainer,
                        normalContainers: prevState.data.StepContainer.normalContainers
                            ?.map((container) => {
                                const isValid = !!container.containerType && !!container.unit && !!container.amount;
                                if (!isValid) {
                                    if (isUUID(container.id)) {
                                        return prevState.data.StepContainer.allContainers?.find(
                                            (it) => it.id == container.id,
                                        );
                                    } else return undefined;
                                } else return container;
                            })
                            ?.filter((it): it is ContainerView => !!it),
                    },
                    StepOuterContainer: {
                        ...prevState.data.StepOuterContainer,
                        outerContainers: prevState.data.StepOuterContainer.outerContainers
                            ?.map((container) => {
                                const isValid =
                                    !!container.containerType && !!container.innerContainer && !!container.amount;
                                if (!isValid) {
                                    if (isUUID(container.id)) {
                                        return prevState.data.StepOuterContainer.allContainers?.find(
                                            (it) => it.id == container.id,
                                        );
                                    } else return undefined;
                                } else return container;
                            })
                            ?.filter((it): it is ContainerView => !!it),
                    },
                },
            };
        });
    }

    isSaveButtonDisabled(): boolean {
        return (
            !this.state.isSaveAllowed ||
            (!!this.state.currentStepId && !this.hasDataStepChanges(this.state.currentStepId))
        );
    }

    isDataBlockComplete(dataBlock: DataBlock): boolean {
        return (
            dataBlock.dataSteps.length > 0 &&
            !dataBlock.dataSteps.some((dataStep) => !this.isDataStepComplete(dataStep))
        );
    }

    hasDataBlockErrors(dataBlock: DataBlock): boolean {
        return (
            dataBlock.dataSteps.length > 0 && dataBlock.dataSteps.some((dataStep) => this.hasDateStepError(dataStep))
        );
    }

    getTitleString(): string {
        return this.state.selectedProduct
            ? this.props.t('ontofood:' + this.state.selectedProduct.label) +
                  this.getDescriptionInTitle() +
                  this.getBrandNameInTitle()
            : this.props.t('productDataSheet:productDataSheet');
    }

    getBrandNameInTitle(): string {
        return this.state.data.StepBrandName.brandName ? ' - ' + this.state.data.StepBrandName.brandName : '';
    }

    getDescriptionInTitle(): string {
        return this.state.data.StepBrandName.description ? ' - ' + this.state.data.StepBrandName.description : '';
    }

    static ingredientsToWriteView(ingredients: Ingredient[] | undefined): IngredientWriteView[] | undefined {
        return ingredients?.map(
            (ingredient) =>
                new IngredientWriteView(
                    ingredient.links.product!,
                    ingredient.isMainIngredient,
                    ingredient.percentage
                        ? isNaN(Number(ingredient.percentage))
                            ? undefined
                            : Number(ingredient.percentage)
                        : undefined,
                ),
        );
    }

    static toContainerWriteView(container: ContainerView): ContainerWriteView {
        return new ContainerWriteView(
            container.containerType ?? undefined,
            container.amount ?? undefined,
            container.unit ?? undefined,
            container.innerContainer ?? undefined,
            container.width ?? undefined,
            container.length ?? undefined,
            container.height ?? undefined,
            container.dimensionUnit ?? undefined,
            container.material ?? undefined,
            container.returnable ?? undefined,
            container.returnableNotice ?? undefined,
            container.traits ?? undefined,
        );
    }

    static getSanitizedContainerWriteView(container: ContainerView): ContainerWriteView {
        const areDimensionFieldsValid =
            (!container.dimensionUnit && !container.width && !container.height && !container.length) ||
            (!!container.dimensionUnit && (!!container.width || !!container.height || !!container.length));
        const writeView = this.toContainerWriteView(container);
        if (!areDimensionFieldsValid) {
            writeView.dimensionUnit = undefined;
            writeView.width = undefined;
            writeView.height = undefined;
            writeView.length = undefined;
        }
        if (writeView.traits && writeView.traits.length === 0) {
            writeView.traits = undefined;
        }
        writeView.returnable = writeView.returnable ? writeView.returnable : undefined;

        return writeView;
    }
}

export function getSanitizedTemperature(input: number | string | undefined | null): number | undefined {
    if (input === undefined || input === null || input === '') {
        return undefined;
    }

    if (typeof input === 'number') {
        return Number.isInteger(input) ? input : undefined;
    }

    const sanitizedInput = input.trim();

    if (/^-?\d*$/.test(sanitizedInput)) {
        return Number(sanitizedInput);
    }

    return undefined;
}

export function hasNutritionInformationValidValues(ni: NutritionInformation | undefined): boolean {
    return (
        (ni?.energy && !!ni.energy.unit && !!ni.energy.amount) ||
        !!ni?.fat ||
        !!ni?.saturatedFattyAcids ||
        !!ni?.polyunsaturatedFattyAcids ||
        !!ni?.monounsaturatedFattyAcids ||
        !!ni?.carbs ||
        !!ni?.sugar ||
        !!ni?.protein ||
        !!ni?.salt
    );
}

export function getSanitizedNutritionInformation(
    ni: NutritionInformation | undefined,
): NutritionInformation | undefined {
    return hasNutritionInformationValidValues(ni)
        ? new NutritionInformation(
              ni?.energy && ni.energy.unit && ni.energy.amount ? ni.energy : undefined,
              ni?.fat ? ni.fat : undefined,
              ni?.saturatedFattyAcids ? ni.saturatedFattyAcids : undefined,
              ni?.polyunsaturatedFattyAcids ? ni.polyunsaturatedFattyAcids : undefined,
              ni?.monounsaturatedFattyAcids ? ni.monounsaturatedFattyAcids : undefined,
              ni?.carbs ? ni.carbs : undefined,
              ni?.sugar ? ni.sugar : undefined,
              ni?.protein ? ni.protein : undefined,
              ni?.salt ? ni.salt : undefined,
          )
        : undefined;
}

export function getSanitizedProductNumbers(pnwk: ProductNumberWithKey[] | undefined): ProductNumber[] | undefined {
    const sanitized = pnwk
        ?.map((pn) => pn.productNumber)
        ?.filter((pn) => !!pn.number && !!pn.type)
        ?.filter((it) => (it.type == 'GTIN (ehemals EAN)' ? isGtinValid(it.number) : it));
    return sanitized?.length ? sanitized : undefined;
}

export function getSanitizedProductTraits(ptwk: ProductTraitWithKey[] | undefined): ProductTrait[] | undefined {
    const sanitized = ptwk?.map((it) => it.productTrait)?.filter((it) => !!it.description && !!it.trait);
    return sanitized?.length ? sanitized : undefined;
}

export function getSanitizedIngredients(iwk: IngredientWithKey[] | undefined): Ingredient[] | undefined {
    const sanitized = iwk
        ?.map(
            (ingWithKey) =>
                new Ingredient(
                    ingWithKey.ingredient.percentage ? ingWithKey.ingredient.percentage : null,
                    ingWithKey.ingredient.isMainIngredient,
                    new IngredientLinks(ingWithKey.ingredient.links.product),
                ),
        )
        ?.filter((ingredient) => !!ingredient.links.product);
    return sanitized?.length ? sanitized : undefined;
}

export function getSanitizedAdditives(awk: AdditiveWithKey[] | undefined): Additive[] | undefined {
    const sanitized = awk
        ?.map((addWithKey) => addWithKey.additive)
        ?.filter((additive) => !!additive.enumber && !!additive.usage);
    return sanitized?.length ? sanitized : undefined;
}

export function getSanitizedAmountRange(amountRange: AmountRange | undefined): AmountRange | undefined {
    return (amountRange?.min ?? undefined) !== undefined &&
        (amountRange?.max ?? undefined) !== undefined &&
        !!amountRange?.unit
        ? { min: amountRange.min, max: amountRange.max, unit: amountRange.unit }
        : undefined;
}

export function getSanitizedAllergens(allergens: AllergenWithKey[] | undefined): Allergen[] | undefined {
    return getSanitizedAllergensOrTraces(allergens) as Allergen[] | undefined;
}

export function getSanitizedAllergenTraces(
    allergens: AllergenTraceWithKey[] | undefined,
): AllergenTraces[] | undefined {
    return getSanitizedAllergensOrTraces(allergens) as AllergenTraces[] | undefined;
}

function getSanitizedAllergensOrTraces(
    aot: AllergenWithKey[] | AllergenTraceWithKey[] | undefined,
): Allergen[] | AllergenTraces[] | undefined {
    return aot
        ?.filter((it) => !!it.allergen)
        ?.map((withKey) => {
            const specificationGrain = (withKey.specification?.filter((spec) =>
                Object.values(SpecificationGrain).includes(spec as SpecificationGrain),
            ) ?? []) as SpecificationGrain[];

            const specificationNuts = (withKey.specification?.filter((spec) =>
                Object.values(SpecificationNuts).includes(spec as SpecificationNuts),
            ) ?? []) as SpecificationNuts[];

            return new Allergen(
                withKey.allergen as AllergenType,
                specificationGrain.length > 0 ? specificationGrain : null,
                specificationNuts.length > 0 ? specificationNuts : null,
            );
        });
}

export function getSanitizedHusbandries(husbandriesWithKey: HusbandryWithKey[] | undefined): Husbandry[] | undefined {
    return husbandriesWithKey?.map((it) => it.husbandry)?.filter((it) => !!it.husbandryType && !!it.productName);
}

export function getSanitizedCultivations(
    cultivationsWithKey: CultivationWithKey[] | undefined,
): Cultivation[] | undefined {
    return cultivationsWithKey?.map((it) => it.cultivation)?.filter((it) => !!it.cultivationType && !!it.productName);
}

export function getPDSProducts(state: Partial<ProductDataSheetState>): Product[] | undefined {
    if (!state.data) return undefined;
    const ingredientProducts = state.data.StepIngredients?.ingredients?.map((it) => it.ingredient.links.product) ?? [];
    return [state.data.StepProductInformation.productRef, ...ingredientProducts]
        .filter((it): it is string => it !== undefined && it !== null && it !== '')
        .map((it) => ProductStore.getOne(it))
        .filter((it): it is Product => it !== undefined);
}

export function isContainerValid(container: ContainerView): boolean {
    if (container) {
        return !!container.amount && !!container.containerType && (!!container.unit || !!container.innerContainer);
    } else return false;
}

export function updatePDSContainers(
    containers: ContainerView[] | undefined,
    id: string,
    partial: Partial<ContainerView>,
    pdsRef: string,
    updateAfterSave: () => void,
): ContainerView[] | undefined {
    return containers?.map((it) => {
        if (it.id === id) {
            const container = { ...deepCopy(it), ...partial };
            ContainerSaveStore.addOrReplace({ container, updateAfterSave, pdsOrOfferRef: pdsRef });
            return container;
        } else return it;
    });
}

export function removePDSContainer(id: string, pdsRef: string) {
    if (pdsRef && id) ContainerSaveStore.removeByContainerIdAndRef(id, pdsRef);
    if (isUUID(id) && pdsRef) {
        ContainerService.removeProductDataSheetContainer(pdsRef, id).subscribe();
    }
}

export function getSanitizedContainers(containers: ContainerView[] | undefined): ContainerView[] | undefined {
    const sanitized = deepCopy(containers)
        ?.filter((it) => isContainerValid(it) && isUUID(it.id))
        ?.map((it) => {
            const areDimensionFieldsValid =
                (!it.dimensionUnit && !it.width && !it.height && !it.length) ||
                (!!it.dimensionUnit && (!!it.width || !!it.height || !!it.length));
            if (!areDimensionFieldsValid) {
                it.dimensionUnit = undefined;
                it.length = undefined;
                it.height = undefined;
                it.width = undefined;
            }
            return it;
        });
    return sanitized?.length ? sanitized : undefined;
}

export function getNewInnerContainer(pdsUnit: Unit | undefined): ContainerView {
    return {
        id: Math.random().toString(),
        containerType: '',
        unit: pdsUnit?.unitType === UnitType.PIECE || pdsUnit?.unitType === UnitType.VOLUME ? pdsUnit.unit : '',
        amount: 1,
        dimensionUnit: UnitString.millimeter,
        links: new ContainerLinks('', ''),
    };
}

export function getNewOuterContainer(): ContainerView {
    return {
        id: Math.random().toString(),
        containerType: '',
        innerContainer: '',
        amount: 2,
        links: new ContainerLinks('', ''),
        dimensionUnit: UnitString.millimeter,
    };
}
