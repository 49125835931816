import { ComponentBase } from 'resub';
import { Company } from 'model/Company';
import { Address } from 'model/Address';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { Contact } from 'model/Contact';
import { Person } from 'model/Person';
import { AddressStore, CompanyStore, ContactsStore, PersonImagesStore, PersonStore } from 'store';
import { ImageInfo } from 'model/Image';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import PublicContactMessageButton from 'components/messaging/PublicContactMessageButton';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import PersonIcon from '@mui/icons-material/Person';
import CompanyAvatarComponent from 'components/company/view/CompanyAvatarComponent';
import StarComponent from 'components/shared/Popovers/StarComponent';
import { ExtendedTheme } from 'style/NearbuyStyle';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { captureWebEvent } from 'util/AnalyticUtils';
import { Link } from 'react-router-dom';

const _styles = (theme: ExtendedTheme) =>
    createStyles({
        card: {
            display: 'flex',
            justifyItems: 'start',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: theme.spacing(1),
            height: '96px',
            maxWidth: '405px',
            backgroundColor: theme.palette.background.default,
            boxShadow: 'none',
            transition: 'box-shadow 0.3s ease-in-out',
            flexDirection: 'row',
        },
        cardContent: {
            flex: 3,
            display: 'flex',
            alignItems: 'center',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
        cardActions: {
            padding: theme.spacing(1),
        },
        companyName: {
            fontSize: '14px',
            fontWeight: '700',
        },
        companyAddress: {
            fontSize: '12px',
        },
        linkLess: {
            color: 'black',
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.text.secondary,
            },
        },
    });

interface SimpleContactCardProps extends WithStyles<typeof _styles> {
    companyRef?: string;
    contactRef?: string;
    offerRef?: string;
    messageTopic?: string;
}

interface SimpleContactCardState {
    company?: Company;
    companyAddress?: Address;
    contact?: Contact;
    person?: Person;
    personPortrait?: ImageInfo | null;
}

class SimpleContactCard extends ComponentBase<SimpleContactCardProps, SimpleContactCardState> {
    protected _buildState(props: SimpleContactCardProps): Partial<SimpleContactCardState> {
        const newState: Partial<SimpleContactCardState> = {};

        if (props.companyRef) {
            newState.company = CompanyStore.getOne(props.companyRef);
        }

        if (props.contactRef) {
            newState.contact = ContactsStore.getOne(props.contactRef);
        }

        if (newState.company && newState.company.address) {
            newState.companyAddress = AddressStore.getOne(newState.company.address);
        }

        if (newState.contact) {
            newState.person = PersonStore.getOne(newState.contact.links.person);
            const userId = newState.contact.links.person.split('/').pop();
            if (userId) {
                newState.personPortrait = PersonImagesStore.getOne(userId)?.getLatestImage();
            }
        }

        return newState;
    }

    getPersonImage(): ReactNode {
        return this.state.personPortrait ? (
            <Avatar sx={{ height: '64px', width: '64px' }} src={this.state.personPortrait.source} />
        ) : (
            <Avatar sx={{ height: '64px', width: '64px' }}>
                <PersonIcon />
            </Avatar>
        );
    }

    showAvatar(): ReactNode {
        if (this.props.contactRef) {
            return <Grid>{this.getPersonImage()}</Grid>;
        }

        if (this.props.companyRef) {
            return (
                <Grid>
                    <CompanyAvatarComponent companyRef={this.props.companyRef} isSmSize />
                </Grid>
            );
        }

        return null;
    }

    showContent(): ReactNode {
        if (this.props.companyRef && this.state.company) {
            return (
                <Link
                    className={this.props.classes.linkLess}
                    onClick={() => captureWebEvent('marketplace-company-link')}
                    to={{
                        pathname: '/company/' + this.state.company.id + '/detail',
                        state: { from: 'MARKETPLACE' },
                    }}
                >
                    <Grid marginLeft={1}>
                        <Typography className={this.props.classes.companyName}>{this.state.company.name}</Typography>
                        <Typography className={this.props.classes.companyAddress}>
                            {this.state.companyAddress?.street + ','}
                        </Typography>
                        <Typography className={this.props.classes.companyAddress}>
                            {this.state.companyAddress?.zipcode + ' ' + this.state.companyAddress?.city}
                        </Typography>
                    </Grid>
                </Link>
            );
        }

        if (this.props.contactRef) {
            return (
                <Grid container item marginLeft={1}>
                    <Typography sx={{ marginBottom: '38px' }} className={this.props.classes.companyName}>
                        {this.state.person?.firstname + ' ' + this.state.person?.lastname}
                    </Typography>
                </Grid>
            );
        }
        return null;
    }

    showMessageOrStarButton(): ReactNode {
        if (this.props.contactRef) {
            return (
                <PublicContactMessageButton
                    calledFrom={'SimpleContactCard'}
                    offerRef={this.props.offerRef}
                    messageTopic={this.props.messageTopic}
                    contactRef={this.props.contactRef}
                    useIconButton={true}
                />
            );
        }

        if (this.props.companyRef) {
            return <StarComponent companyRef={this.props.companyRef} calledFrom={'SimpleContactCard'} />;
        }

        return null;
    }

    render(): ReactNode {
        const { classes } = this.props;
        const isCompany = !!this.state.company;

        return (
            <Card
                className={`${classes.card}`}
                style={{
                    cursor: isCompany ? 'pointer' : 'default',
                }}
            >
                <CardContent
                    sx={{ flexGrow: 3 }}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {this.showAvatar()}
                    <Grid item>{this.showContent()}</Grid>
                </CardContent>
                <CardActions sx={{ marginBottom: '23px' }}>{this.showMessageOrStarButton()}</CardActions>
            </Card>
        );
    }
}

export default withStyles(_styles, { withTheme: true })(SimpleContactCard);
