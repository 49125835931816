import { OfferDialogData, structure } from 'components/search/result-items/offers/OfferDialogStructure';
import { OfferWriteView } from 'model/Offer';
import {
    OfferDialogFormErrors,
    OfferDialogProps,
    OfferDialogState,
} from 'components/search/result-items/offers/OfferDialog';
import { ProductType } from 'model/ProductType';
import moment from 'moment';
import { Amount } from 'model/Amount';
import { getUnitFromString } from 'model/Unit';

export function _buildState_getInitialData(offerWriteView?: OfferWriteView): OfferDialogData {
    return {
        OfferStepProductInformation: {
            productRef: offerWriteView?.category,
            dateFrom: offerWriteView?.dateFrom,
            dateEnd: offerWriteView?.dateEnd,
            productTitle: offerWriteView?.productTitle,
            brandName: offerWriteView?.brandName,
            levelsOfProcessing: offerWriteView?.levelsOfProcessing?.length
                ? offerWriteView?.levelsOfProcessing
                : undefined,
            isPermanent: offerWriteView?.isPermanent,
            productType: offerWriteView?.productType,
            productTraits: offerWriteView?.productTraits?.map((it) => {
                return { productTrait: it, key: Math.random() };
            }),
        },
        OfferStepAmountAndPackaging: {
            isPermanent: offerWriteView?.isPermanent,
            totalAmount: offerWriteView?.totalAmount,
            minAmount: offerWriteView?.minAmount,
            caliber: offerWriteView?.caliber,
            weight: offerWriteView?.weight,
            containers: offerWriteView?.containers?.map((it) => ({
                container: it,
                key: Math.random(),
            })),
        },
        OfferStepProductDescription: {
            description: offerWriteView?.description,
        },
        OfferStepPricingAndContactDetails: {
            pricePerUnit: offerWriteView?.pricePerUnit,
            graduatedPrices: offerWriteView?.graduatedPrices,
            totalAmount: offerWriteView?.totalAmount,
            contactRef: offerWriteView?.contact,
            automaticallyContact: null,
        },
    };
}

export function _buildState_updateCurrentStepValues(newState: Partial<OfferDialogState>): Partial<OfferDialogState> {
    const updatedNewState = { ...newState };
    if (updatedNewState.activeDataBlock !== undefined && updatedNewState.activeDataStep !== undefined) {
        if (structure.offerBlocks[0].offerSteps[updatedNewState.activeDataStep]) {
            updatedNewState.currentStepComponent =
                structure.offerBlocks[0].offerSteps[updatedNewState.activeDataStep].component;
            updatedNewState.currentStepId = structure.offerBlocks[0].offerSteps[updatedNewState.activeDataStep].dataId;
        } else {
            updatedNewState.currentStepComponent = undefined;
            updatedNewState.currentStepId = undefined;
        }
    }
    return updatedNewState;
}

export function _buildState_ensureStateConsistency(newState: Partial<OfferDialogState>): Partial<OfferDialogState> {
    const updatedNewState = { ...newState };
    if (!updatedNewState?.data) return updatedNewState;

    return updatedNewState;
}

export function _buildState_applyDefaultsToStepData(
    newState: Partial<OfferDialogState>,
    props: OfferDialogProps,
): Partial<OfferDialogState> {
    const updatedNewState = { ...newState };
    if (!updatedNewState?.data) return updatedNewState;

    if (updatedNewState.data.OfferStepAmountAndPackaging && updatedNewState.data.OfferStepProductInformation) {
        updatedNewState.data.OfferStepAmountAndPackaging.isPermanent =
            updatedNewState.data.OfferStepProductInformation.isPermanent;
    }
    if (updatedNewState.data.OfferStepAmountAndPackaging) {
        updatedNewState.data.OfferStepAmountAndPackaging.offerRef = updatedNewState.offerRef;
    }
    if (newState.data?.OfferStepPricingAndContactDetails) {
        newState.data.OfferStepPricingAndContactDetails.companyRef = props.companyRef;
        newState.data.OfferStepPricingAndContactDetails.offerRef = props.offerRef;
        newState.data.OfferStepPricingAndContactDetails.isNoMessagingInUseHintShown =
            newState.isNoMessagingInUseHintShown;
        newState.data.OfferStepPricingAndContactDetails.totalAmount =
            newState.data.OfferStepAmountAndPackaging?.totalAmount;
    }

    return updatedNewState;
}

export function _buildState_updateOfferWriteView(newState: Partial<OfferDialogState>): Partial<OfferDialogState> {
    const updatedNewState = { ...newState };

    if (!updatedNewState.offerWriteView) return updatedNewState;
    updatedNewState.offerWriteView.active = newState.isActive ?? false;

    // OfferStepProductInformation
    updatedNewState.offerWriteView.dateFrom = newState.data?.OfferStepProductInformation?.dateFrom;
    updatedNewState.offerWriteView.dateEnd = newState.data?.OfferStepProductInformation?.dateEnd;
    updatedNewState.offerWriteView.category = newState.data?.OfferStepProductInformation?.productRef ?? '';
    updatedNewState.offerWriteView.productTitle = newState.data?.OfferStepProductInformation?.productTitle
        ? newState.data?.OfferStepProductInformation?.productTitle
        : undefined;
    updatedNewState.offerWriteView.brandName = newState.data?.OfferStepProductInformation?.brandName
        ? newState.data?.OfferStepProductInformation?.brandName
        : undefined;
    updatedNewState.offerWriteView.levelsOfProcessing = newState.data?.OfferStepProductInformation?.levelsOfProcessing
        ?.length
        ? newState.data?.OfferStepProductInformation?.levelsOfProcessing
        : undefined;
    updatedNewState.offerWriteView.isPermanent = newState.data?.OfferStepProductInformation?.isPermanent ?? false;
    updatedNewState.offerWriteView.productType =
        newState.data?.OfferStepProductInformation?.productType ?? ProductType.PROCESSED;
    updatedNewState.offerWriteView.productTraits = newState.data?.OfferStepProductInformation?.productTraits?.map(
        (it) => it.productTrait,
    );

    // OfferStepAmountAndPackaging
    updatedNewState.offerWriteView.totalAmount =
        newState.data?.OfferStepAmountAndPackaging?.totalAmount ?? new Amount(1, '');
    updatedNewState.offerWriteView.minAmount =
        newState.data?.OfferStepAmountAndPackaging?.minAmount ?? new Amount(0, '');
    updatedNewState.offerWriteView.containers = newState.data?.OfferStepAmountAndPackaging?.containers?.map(
        (it) => it.container,
    );

    updatedNewState.offerWriteView.weight = newState.data?.OfferStepAmountAndPackaging.weight;
    updatedNewState.offerWriteView.caliber = newState.data?.OfferStepAmountAndPackaging.caliber;

    // OfferStepProductDescription
    updatedNewState.offerWriteView.description = newState.data?.OfferStepProductDescription?.description
        ? newState.data?.OfferStepProductDescription?.description
        : undefined;

    // OfferStepPricingAndContactDetails;
    updatedNewState.offerWriteView.contact = newState.data?.OfferStepPricingAndContactDetails.contactRef;
    if (newState.data?.OfferStepPricingAndContactDetails.graduatedPrices !== undefined) {
        updatedNewState.offerWriteView.graduatedPrices =
            newState.data?.OfferStepPricingAndContactDetails.graduatedPrices;
    }
    if (newState.data?.OfferStepPricingAndContactDetails.pricePerUnit !== undefined) {
        updatedNewState.offerWriteView.pricePerUnit = newState.data?.OfferStepPricingAndContactDetails.pricePerUnit;
    }

    return updatedNewState;
}

export function _buildState_validateAndSetFormErrors(
    newState: Partial<OfferDialogState>,
    props: OfferDialogProps,
): Partial<OfferDialogState> {
    const updatedNewState = { ...newState };
    const productInformationFormErrors: OfferDialogFormErrors = {};
    const amountAndPackagingFormErrors: OfferDialogFormErrors = {};
    const pricingAndContactDetailsFormErrors: OfferDialogFormErrors = {};
    const { initialOfferWriteView, offerWriteView, showAllFormErrors } = updatedNewState;
    const { t } = props;

    if (!offerWriteView) {
        return updatedNewState;
    }

    // OfferStepProductInformation
    if (!offerWriteView.category) {
        productInformationFormErrors.category = {
            helperText: t('offerDialog:missingProductHelperText'),
            showError: showAllFormErrors ? showAllFormErrors : false,
        };
    }

    if (
        offerWriteView.dateFrom &&
        offerWriteView.dateEnd &&
        moment(offerWriteView.dateEnd).isSameOrBefore(offerWriteView.dateFrom)
    ) {
        productInformationFormErrors.dateEnd = {
            helperText: t('offerDialog:dateEndHelperText'),
            showError: showAllFormErrors ? showAllFormErrors : true,
        };
    }

    if (
        offerWriteView.dateFrom &&
        moment(offerWriteView.dateFrom).isBefore(moment().startOf('day')) &&
        !(initialOfferWriteView?.dateFrom?.toLocaleDateString() === offerWriteView.dateFrom.toLocaleDateString())
    ) {
        productInformationFormErrors.dateFrom = {
            helperText: t('offerDialog:dateFromInPast'),
            showError: showAllFormErrors ? showAllFormErrors : true,
        };
    }

    if (offerWriteView.dateEnd === undefined && !offerWriteView.isPermanent) {
        productInformationFormErrors.dateEnd = {
            helperText: t('offerDialog:noEndDate'),
            showError: showAllFormErrors ? showAllFormErrors : true,
        };
    }

    if (offerWriteView.dateEnd?.toString() === 'Invalid Date') {
        productInformationFormErrors.dateEnd = {
            helperText: t('offerDialog:invalidDate'),
            showError: showAllFormErrors ? showAllFormErrors : false,
        };
    }

    if (offerWriteView.dateFrom === undefined) {
        productInformationFormErrors.dateFrom = {
            helperText: t('offerDialog:noFromDate'),
            showError: showAllFormErrors ? showAllFormErrors : true,
        };
    }

    if (offerWriteView.dateFrom?.toString() === 'Invalid Date') {
        productInformationFormErrors.dateFrom = {
            helperText: t('offerDialog:invalidDate'),
            showError: showAllFormErrors ? showAllFormErrors : false,
        };
    }

    if (offerWriteView.productTraits) {
        offerWriteView.productTraits.forEach((productTraitWithKey, index) => {
            const { trait, description } = productTraitWithKey;

            if (!trait || !description) {
                let helperText = '';
                if (!trait && !description) {
                    helperText = t('offerDialog:missingProductTraitAndTraitDescriptionHelperText');
                } else if (!trait) {
                    helperText = t('offerDialog:missingProductTraitHelperText');
                } else if (!description) {
                    helperText = t('offerDialog:missingTraitDescriptionHelperText');
                }
                productInformationFormErrors[`productTrait_${index}`] = {
                    helperText: helperText,
                    showError: showAllFormErrors ? showAllFormErrors : false,
                };
            }
        });
    }

    // OfferStepAmountAndPackaging
    const { totalAmount, minAmount, containers } = offerWriteView;
    if (
        containers &&
        containers.some((container) => !container.containerType || !container.amount || !container.unit)
    ) {
        amountAndPackagingFormErrors['containers'] = {
            helperText: t('offer:errorInvalidContainer'),
            showError: showAllFormErrors ? showAllFormErrors : false,
        };
    }

    if (totalAmount && minAmount) {
        if (totalAmount.amount <= 0) {
            amountAndPackagingFormErrors['totalAmountAmount'] = {
                helperText: t('offer:errorInvalidAmount'),
                showError: showAllFormErrors ? showAllFormErrors : true,
            };
        }
        if (!totalAmount.unit) {
            amountAndPackagingFormErrors['totalAmountUnit'] = {
                helperText: t('offer:errorInvalidUnit'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
        if (minAmount.amount < 0 || Object.is(minAmount.amount, -0)) {
            amountAndPackagingFormErrors['minAmountAmount'] = {
                helperText: t('offer:errorInvalidAmount'),
                showError: showAllFormErrors ? showAllFormErrors : true,
            };
        }
        if (!minAmount.unit) {
            amountAndPackagingFormErrors['minAmountUnit'] = {
                helperText: t('offer:errorInvalidUnit'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
        const isMinAmountGreaterThanTotalAmount =
            (minAmount?.amount ?? 0) * (getUnitFromString(minAmount?.unit)?.conversionFactor ?? 1) >
            (totalAmount?.amount ?? 0) * (getUnitFromString(totalAmount?.unit)?.conversionFactor ?? 1);
        if (minAmount && totalAmount && isMinAmountGreaterThanTotalAmount) {
            amountAndPackagingFormErrors['minAmountAmount'] = {
                helperText: t('offer:minAmountGeTotalAmount'),
                showError: showAllFormErrors ? showAllFormErrors : true,
            };
        }
    }

    const { caliber } = offerWriteView;
    if (caliber) {
        if (!caliber.min || caliber.min < 0) {
            amountAndPackagingFormErrors.caliber = {
                helperText: t('offerDialog:invalidInput'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
        if (!caliber.max || caliber.max < 0) {
            amountAndPackagingFormErrors.caliber = {
                helperText: t('offerDialog:invalidInput'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }

        if (caliber.max && caliber.min && caliber.max < caliber.min) {
            amountAndPackagingFormErrors.caliber = {
                helperText: t('offerDialog:maxIsSmallerThanMin'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
    }

    const { weight } = offerWriteView;
    if (weight) {
        if (!weight.min || weight.min < 0) {
            amountAndPackagingFormErrors.weight = {
                helperText: t('offerDialog:invalidInput'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
        if (!weight.max || weight.max < 0) {
            amountAndPackagingFormErrors.weight = {
                helperText: t('offerDialog:invalidInput'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
        if (weight.max && weight.min && weight.max < weight.min) {
            amountAndPackagingFormErrors.weight = {
                helperText: t('offerDialog:maxIsSmallerThanMin'),
                showError: showAllFormErrors ? showAllFormErrors : false,
            };
        }
    }

    // OfferStepPricingAndContactDetails
    if (
        offerWriteView.pricePerUnit !== null &&
        offerWriteView.pricePerUnit !== undefined &&
        offerWriteView.pricePerUnit <= 0
    ) {
        pricingAndContactDetailsFormErrors.pricePerUnit = {
            helperText: t('offerDialog:errorInvalidPrice'),
            showError: showAllFormErrors ? showAllFormErrors : false,
        };
    }
    if (
        !!offerWriteView.graduatedPrices?.length &&
        new Set(offerWriteView.graduatedPrices.map((it) => it.amount)).size <
            offerWriteView.graduatedPrices.map((it) => it.amount).length
    ) {
        pricingAndContactDetailsFormErrors.graduatedPrices = {
            helperText: t('offer:errorInvalidUnit'),
            showError: showAllFormErrors ? showAllFormErrors : true,
        };
    }

    if (offerWriteView.graduatedPrices) {
        offerWriteView.graduatedPrices.forEach((graduatedPrice, index) => {
            const { price, amount } = graduatedPrice;
            if (price <= 0) {
                pricingAndContactDetailsFormErrors[`graduatedPrice_price_${index}`] = {
                    helperText: 'stubErrorOnlyForSaveButtonHandling',
                    showError: showAllFormErrors ? showAllFormErrors : true,
                };
            }
            if (amount <= 0) {
                pricingAndContactDetailsFormErrors[`graduatedPrice_amount_${index}`] = {
                    helperText: 'stubErrorOnlyForSaveButtonHandling',
                    showError: showAllFormErrors ? showAllFormErrors : true,
                };
            }
        });
    }

    if (updatedNewState.data) {
        updatedNewState.data.OfferStepProductInformation.formErrors = productInformationFormErrors;
        updatedNewState.data.OfferStepAmountAndPackaging.formErrors = amountAndPackagingFormErrors;
        updatedNewState.data.OfferStepPricingAndContactDetails.formErrors = pricingAndContactDetailsFormErrors;
    }

    return updatedNewState;
}
