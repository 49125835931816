import * as React from 'react';
import { ComponentBase } from 'resub';
import { WithTranslation, withTranslation } from 'react-i18next';
import withStyles from '@mui/styles/withStyles';
import { Button, Dialog, Grid, IconButton, TextField, Theme, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import NearbuyWindow from 'components/shared/NearbuyWindow';
import CloseIcon from '@mui/icons-material/Close';
import { classNames, Transition } from 'util/style-helpers';
import { OrderAddress, OrderAddressType } from 'model/Address';

const _styles = (theme: Theme) =>
    createStyles({
        title: {
            color: theme.palette.primary.dark,
            lineHeight: '25px',
            fontSize: '20px',
            fontWeight: 'bold',
        },
        saveButton: {
            color: theme.palette.common.black,
            paddingRight: '20px',
            paddingLeft: '20px',
            backgroundColor: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
                color: theme.palette.common.white,
            },
        },
        marginBottom: {
            marginBottom: '20px',
        },
        iconButton: {
            padding: 0,
        },
        closeButton: {
            fontSize: '1.4em',
            color: theme.palette.primary.main,
        },
    });

interface ChangeAddressDialogProps extends WithStyles<typeof _styles>, WithTranslation {
    isOpen: boolean;
    onClose: () => void;
    addressToEdit: OrderAddress;
    changeAddress: (address: OrderAddress) => void;
}

interface ChangeAddressDialogState {
    addressName: string;
    addressSupplement?: string;
    streetAndNumber: string;
    zipCode: string;
    city: string;
    country: string;
}

class ChangeAddressDialog extends ComponentBase<ChangeAddressDialogProps, ChangeAddressDialogState> {
    public onChange(event: { target: { name: any; value: any } }) {
        const newState = { [event.target.name]: event.target.value } as Pick<
            ChangeAddressDialogState,
            keyof ChangeAddressDialogState
        >;
        this.setState(newState);
    }

    closeDialog(): void {
        this.props.onClose();
    }

    showTitleAndCloseIcon(): React.ReactFragment {
        return (
            <Grid container direction={'row'}>
                <Grid item md={11.5}>
                    <Typography
                        className={`${this.props.classes.title}
                        ${this.props.classes.marginBottom}`}
                    >
                        {this.props.addressToEdit.type === OrderAddressType.DELIVERY
                            ? this.props.t('payment:deliveryAddressChange')
                            : this.props.t('payment:billingAddressChange')}
                    </Typography>
                </Grid>
                <Grid item md={0.5}>
                    <IconButton
                        onClick={(): void => this.props.onClose()}
                        className={this.props.classes.iconButton}
                        size="large"
                    >
                        <CloseIcon className={classNames(this.props.classes.closeButton)} />
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

    showPromptAndInputFields(): React.ReactFragment {
        return (
            <Grid container direction={'column'} justifyContent={'flex-start'} width={'40%'}>
                <Grid item>
                    <Typography className={this.props.classes.marginBottom}>
                        {this.props.t('payment:enterAddress')}
                    </Typography>
                </Grid>
                <Grid item>
                    <TextField
                        name={'addressName'}
                        fullWidth
                        label={this.props.t('payment:addressName')}
                        required
                        onChange={(event) => this.onChange(event)}
                        defaultValue={this.props.addressToEdit.name}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        name={'addressSupplement'}
                        fullWidth
                        label={this.props.t('payment:addressSupplement')}
                        onChange={(event) => this.onChange(event)}
                        defaultValue={this.props.addressToEdit.suffix}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        name={'streetAndNumber'}
                        fullWidth
                        label={this.props.t('payment:streetAndNumber')}
                        required
                        onChange={(event) => this.onChange(event)}
                        defaultValue={this.props.addressToEdit.street}
                    />
                </Grid>
                <Grid container item direction={'row'} spacing={1}>
                    <Grid item width={'30%'}>
                        <TextField
                            name={'zipCode'}
                            fullWidth
                            label={this.props.t('payment:zipCode')}
                            required
                            onChange={(event) => this.onChange(event)}
                            defaultValue={this.props.addressToEdit.zipcode}
                        />
                    </Grid>
                    <Grid item width={'70%'}>
                        <TextField
                            name={'city'}
                            fullWidth
                            label={this.props.t('payment:city')}
                            required
                            onChange={(event) => this.onChange(event)}
                            defaultValue={this.props.addressToEdit.city}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    showButtons(): React.ReactFragment {
        return (
            <Grid container item direction={'row'} justifyContent={'flex-end'} spacing={2}>
                <Grid item>
                    <Button variant="outlined" onClick={() => this.closeDialog()}>
                        {this.props.t('dialogs:CANCEL')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        className={this.props.classes.saveButton}
                        type="submit"
                        onClick={() => {
                            this.props.changeAddress({
                                type: this.props.addressToEdit.type,
                                city: this.state.city ?? this.props.addressToEdit.city,
                                suffix: this.state.addressSupplement ?? this.props.addressToEdit.suffix,
                                zipcode: this.state.zipCode ?? this.props.addressToEdit.zipcode,
                                street: this.state.streetAndNumber ?? this.props.addressToEdit.street,
                                name: this.state.addressName ?? this.props.addressToEdit.name,
                            });
                            this.closeDialog();
                        }}
                    >
                        {this.props.t('dialogs:SAVE')}
                    </Button>
                </Grid>
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <form>
                <Dialog
                    open={this.props.isOpen}
                    onBackdropClick={() => this.closeDialog()}
                    TransitionComponent={Transition}
                    maxWidth={'md'}
                    fullWidth
                >
                    <NearbuyWindow>
                        {this.showTitleAndCloseIcon()}
                        {this.showPromptAndInputFields()}
                        {this.showButtons()}
                    </NearbuyWindow>
                </Dialog>
            </form>
        );
    }
}

export default withTranslation(['payment', 'dialogs'])(withStyles(_styles, { withTheme: true })(ChangeAddressDialog));
