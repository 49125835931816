import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import { FormGroup } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import AllergenSpecificationComponent from 'components/productdatasheet/datablocks/block3/AllergenSpecificationComponent';
import {
    AllergenFree,
    AllergenType,
    SpecificationGrain,
    SpecificationNuts,
} from 'components/productdatasheet/datablocks/block3/AllergenType';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
} from 'components/productdatasheet/ProductDataSheetHelpers';

export default interface StepAllergensProps {
    allergens?: AllergenWithKey[] | null;
    allergenTraces?: AllergenTraceWithKey[] | null;
    freeOfAllergens?: AllergenFree[] | null;
}

export interface AllergenWithKey {
    allergen: string;
    specification?: string[] | null;
    key: number;
}

export interface AllergenTraceWithKey {
    allergen: string;
    specification?: string[] | null;
    key: number;
}

export const StepAllergens = (props: StepComponentProps<StepAllergensProps>) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        autocomplete: {
            '& .MuiAutocomplete-clearIndicator': {
                backgroundColor: 'transparent',
                color: theme.palette.grey[700],
                '&:hover': {
                    color: theme.palette.grey[700],
                },
            },
            '& .MuiAutocomplete-popupIndicator': {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });
    const classes = useStyles();
    const { t } = useTranslation(['allergens', 'productDataSheet', 'common']);
    const [allergensInputValue, setAllergensInputValue] = useState<string | undefined>(undefined);
    const [tracesInputValue, setTracesInputValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        handleFreeOfAllergensDefaults(null, null);
    }, []);

    const handleFreeOfAllergensDefaults = (
        updatedAllergens: AllergenWithKey[] | null,
        updatedAllergenTraces: AllergenWithKey[] | null,
    ) => {
        let freeOfAllergens = props.data.freeOfAllergens ? props.data.freeOfAllergens : [];
        const allergens = updatedAllergens !== null ? updatedAllergens : props.data.allergens ?? [];
        const allergenTraces = updatedAllergenTraces !== null ? updatedAllergenTraces : props.data.allergenTraces ?? [];
        const hasAllergens =
            (allergens.length ?? 0) > 1 ||
            !!allergens.at(0)?.allergen ||
            (allergenTraces.length ?? 0) > 1 ||
            !!allergenTraces.at(0)?.allergen;
        if (
            updatedAllergens == null &&
            updatedAllergenTraces == null &&
            (props.data.freeOfAllergens?.length || hasAllergens)
        ) {
            return;
        }
        freeOfAllergens = freeOfAllergens.includes(AllergenFree.IODINE_FREE) ? [AllergenFree.IODINE_FREE] : [];
        const containsGluten = allergens?.some((it) => it.allergen === AllergenType.GLUTEN_GRAINS);
        const containsGlutenTraces = allergenTraces?.some((it) => it.allergen === AllergenType.GLUTEN_GRAINS);
        const containsLactose = (allergens ?? props.data.allergens)?.some((it) => it.allergen === AllergenType.MILK);
        if (!containsGluten && !containsGlutenTraces) {
            freeOfAllergens.push(AllergenFree.GLUTEN_FREE);
        } else if (!containsGluten && containsGlutenTraces) {
            freeOfAllergens.push(AllergenFree.LOW_GLUTEN);
        }
        if (!containsLactose) {
            freeOfAllergens.push(AllergenFree.LACTOSE_FREE);
        }
        props.setData('StepAllergens', { freeOfAllergens: freeOfAllergens });
    };

    const handleFreeOfAllergensChange = (option: AllergenFree) => (event: React.ChangeEvent<HTMLInputElement>) => {
        let updatedFreeOfAllergens = event.target.checked
            ? [...(props.data.freeOfAllergens ?? []), option]
            : (props.data.freeOfAllergens ?? []).filter((allergenFree) => allergenFree !== option);
        if (option === AllergenFree.GLUTEN_FREE) {
            updatedFreeOfAllergens = updatedFreeOfAllergens.filter(
                (allergenFree) => allergenFree !== AllergenFree.LOW_GLUTEN,
            );
        } else if (option === AllergenFree.LOW_GLUTEN) {
            updatedFreeOfAllergens = updatedFreeOfAllergens.filter(
                (allergenFree) => allergenFree !== AllergenFree.GLUTEN_FREE,
            );
        }
        props.setData('StepAllergens', { freeOfAllergens: updatedFreeOfAllergens });
    };

    const handleAllergenChange = (index: number, newValue: string | null) => {
        setAllergensInputValue(undefined);
        const updatedAllergens = (props.data.allergens ?? []).map((allergen) =>
            allergen.key === index
                ? {
                      allergen: newValue ?? '',
                      specification: null,
                      key: allergen.key,
                  }
                : allergen,
        );
        handleFreeOfAllergensDefaults(updatedAllergens, null);
        props.setData('StepAllergens', {
            allergens: updatedAllergens,
        });
    };

    const addNewAllergen = () => {
        const key = Math.random();
        const newAllergen: AllergenWithKey = {
            allergen: '',
            key,
        };
        const updatedAllergens = [...(props.data.allergens ?? [])];
        updatedAllergens.push(newAllergen);
        props.setData('StepAllergens', { allergens: updatedAllergens });
    };

    const handleDeleteAllergens = (key: number) => {
        const updatedAllergens = (props.data.allergens ?? [])?.filter((it) => it.key !== key);
        handleFreeOfAllergensDefaults(updatedAllergens, null);
        props.setData('StepAllergens', {
            allergens: updatedAllergens,
        });
    };

    const handleAllergensSpecificationChange = (newValues: string[] | undefined, key: number) => {
        props.setData('StepAllergens', {
            allergens: (props.data.allergens ?? []).map((allergen) =>
                allergen.key === key ? { ...allergen, specification: newValues ?? undefined } : allergen,
            ),
        });
    };

    const handleTracesChange = (index: number, newValue: string | null) => {
        setTracesInputValue(undefined);
        const updatedAllergenTraces = (props.data.allergenTraces ?? []).map((trace) =>
            trace.key === index
                ? {
                      allergen: newValue ?? '',
                      specification: null,
                      key: trace.key,
                  }
                : trace,
        );
        handleFreeOfAllergensDefaults(null, updatedAllergenTraces);
        props.setData('StepAllergens', {
            allergenTraces: updatedAllergenTraces,
        });
    };

    const addNewTraces = () => {
        const key = Math.random();
        const newTraces: AllergenTraceWithKey = {
            allergen: '',
            key,
        };
        const updatedTraces = [...(props.data.allergenTraces ?? [])];
        updatedTraces.push(newTraces);
        props.setData('StepAllergens', { allergenTraces: updatedTraces });
    };

    const handleDeleteTraces = (key: number) => {
        const updatedAllergenTraces = (props.data.allergenTraces ?? []).filter((it) => it.key !== key);
        handleFreeOfAllergensDefaults(null, updatedAllergenTraces);
        props.setData('StepAllergens', {
            allergenTraces: updatedAllergenTraces,
        });
    };

    const handleTracesSpecificationChange = (newValues: string[] | undefined, key: number) => {
        props.setData('StepAllergens', {
            allergenTraces: (props.data.allergenTraces ?? []).map((traces) =>
                traces.key === key ? { ...traces, specification: newValues ?? undefined } : traces,
            ),
        });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_ALLERGENS_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_ALLERGENS_TEXT')}</Typography>
        </Grid>
    );

    const showAllergensTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(1) }}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('productDataSheet:DATASTEP_ALLERGENS')}</Typography>
        </Grid>
    );

    const showAllergensTracesTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 'bold' }}>
                {t('productDataSheet:DATASTEP_ALLERGENS_CONTAIN_TRACES')}
            </Typography>
        </Grid>
    );

    const showFreeOfAllergensTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 'bold' }}>{t('productDataSheet:DATASTEP_ALLERGENS_FREE')}</Typography>
        </Grid>
    );

    const showAllergenSpecification = (allergen: AllergenWithKey): ReactNode => {
        if (allergen.allergen === AllergenType.NUTS || allergen.allergen === AllergenType.GLUTEN_GRAINS)
            return (
                <Grid item md={5.25} justifyContent={'center'}>
                    <AllergenSpecificationComponent
                        selectedItems={allergen.specification ?? []}
                        remove={(itemToRemove: string) => {
                            const updatedSpecifications = allergen.specification?.filter(
                                (item) => item !== itemToRemove,
                            );
                            handleAllergensSpecificationChange(updatedSpecifications, allergen.key);
                        }}
                        update={(itemsToAdd) => handleAllergensSpecificationChange(itemsToAdd, allergen.key)}
                        items={
                            allergen.allergen === AllergenType.NUTS
                                ? Object.values(SpecificationNuts)
                                : allergen.allergen === AllergenType.GLUTEN_GRAINS
                                  ? Object.values(SpecificationGrain)
                                  : undefined
                        }
                    />
                </Grid>
            );
    };

    const showAllergensSelect = (): ReactNode => {
        return props.data.allergens?.map((allergen) => {
            return (
                <Grid item container md={12} key={allergen.key} sx={{ justifyContent: 'space-between' }}>
                    <Grid item md={5.25} sx={{ alignItems: 'end' }}>
                        <Autocomplete
                            sx={getProductDataSheetDialogAutoCompleteSX()}
                            className={classes.autocomplete}
                            filterOptions={(options) => {
                                let filteredOptions = [...options].filter(
                                    (option) =>
                                        option && !props.data.allergens?.map((it) => it.allergen).includes(option),
                                );
                                if (allergensInputValue) {
                                    filteredOptions = filteredOptions.filter((option) =>
                                        t(`allergens:${option.toString()}`)
                                            .toLowerCase()
                                            .includes(allergensInputValue.toLowerCase()),
                                    );
                                }
                                return filteredOptions;
                            }}
                            noOptionsText={t('common:noOptions')}
                            disableClearable={false}
                            options={Object.values(AllergenType)}
                            getOptionLabel={(option) => t(`allergens:${option.toString()}`)}
                            value={allergen.allergen ? allergen.allergen : null}
                            onInputChange={(_, value) => {
                                if (value != t(`allergens:${allergen.allergen.toString()}`) || value === '') {
                                    setAllergensInputValue(value);
                                }
                            }}
                            onChange={(_, value) => handleAllergenChange(allergen.key, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('productDataSheet:DATASTEP_ALLERGENS')}
                                    fullWidth
                                    sx={getAutoCompleteTextFieldSX()}
                                />
                            )}
                        />
                    </Grid>
                    {showAllergenSpecification(allergen)}
                    <Grid
                        item
                        md={1}
                        justifyContent={'left'}
                        sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(0.5) }}
                    >
                        {showDeleteAllergensButton(allergen.key)}
                    </Grid>
                </Grid>
            );
        });
    };

    const showDeleteAllergensButton = (key: number): ReactNode => {
        const hasAllergens = (props.data.allergens?.length ?? 0) > 1 || !!props.data.allergens?.at(0)?.allergen;
        return (
            <Grid item md={1}>
                <IconButton onClick={() => handleDeleteAllergens(key)} disabled={!hasAllergens}>
                    <Delete />
                </IconButton>
            </Grid>
        );
    };

    const showAddAllergenButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={!props.data.allergens || props.data.allergens.some((it) => !it.allergen || !it.allergen)}
                onClick={() => addNewAllergen()}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showTracesSpecification = (traces: AllergenTraceWithKey): ReactNode => {
        if (traces.allergen === AllergenType.NUTS || traces.allergen === AllergenType.GLUTEN_GRAINS)
            return (
                <Grid item md={5.25}>
                    <AllergenSpecificationComponent
                        selectedItems={traces.specification ?? []}
                        remove={(itemToRemove: string) => {
                            const updatedSpecifications = traces.specification?.filter((item) => item !== itemToRemove);
                            handleTracesSpecificationChange(updatedSpecifications, traces.key);
                        }}
                        update={(itemsToAdd) => handleTracesSpecificationChange(itemsToAdd, traces.key)}
                        items={
                            traces.allergen === AllergenType.NUTS
                                ? Object.values(SpecificationNuts)
                                : traces.allergen === AllergenType.GLUTEN_GRAINS
                                  ? Object.values(SpecificationGrain)
                                  : undefined
                        }
                    />
                </Grid>
            );

        return null;
    };

    const showTracesSelect = (): ReactNode => {
        return props.data.allergenTraces?.map((trace) => {
            return (
                <Grid item container md={12} key={trace.key} sx={{ justifyContent: 'space-between' }}>
                    <Grid item md={5.25} sx={{ alignItems: 'end' }}>
                        <Autocomplete
                            sx={getProductDataSheetDialogAutoCompleteSX()}
                            className={classes.autocomplete}
                            filterOptions={(options) => {
                                let filteredOptions = [...options].filter(
                                    (option) =>
                                        option && !props.data.allergenTraces?.map((it) => it.allergen).includes(option),
                                );
                                if (tracesInputValue) {
                                    filteredOptions = filteredOptions.filter((option) =>
                                        t(`allergens:${option.toString()}`)
                                            .toLowerCase()
                                            .includes(tracesInputValue.toLowerCase()),
                                    );
                                }
                                return filteredOptions;
                            }}
                            noOptionsText={t('common:noOptions')}
                            disableClearable={false}
                            options={Object.values(AllergenType)}
                            getOptionLabel={(option) => t(`allergens:${option.toString()}`)}
                            value={trace.allergen ? trace.allergen : null}
                            onInputChange={(_, value) => {
                                if (value != t(`allergens:${trace.allergen.toString()}`) || value === '') {
                                    setTracesInputValue(value);
                                }
                            }}
                            onChange={(_, value) => handleTracesChange(trace.key, value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('productDataSheet:DATASTEP_ALLERGENS_TRACES')}
                                    fullWidth
                                    sx={getAutoCompleteTextFieldSX()}
                                />
                            )}
                        />
                    </Grid>
                    {showTracesSpecification(trace)}
                    <Grid
                        item
                        md={1}
                        justifyContent={'left'}
                        sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(0.5) }}
                    >
                        {showDeleteTracesButton(trace.key)}
                    </Grid>
                </Grid>
            );
        });
    };

    const showDeleteTracesButton = (key: number): ReactNode => {
        const hasAllergenTraces =
            (props.data.allergenTraces?.length ?? 0) > 1 || !!props.data.allergenTraces?.at(0)?.allergen;
        return (
            <Grid item md={1}>
                <IconButton onClick={() => handleDeleteTraces(key)} disabled={!hasAllergenTraces}>
                    <Delete />
                </IconButton>
            </Grid>
        );
    };

    const showAddTracesButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={!props.data.allergenTraces || props.data.allergenTraces.some((it) => !it.allergen)}
                onClick={() => addNewTraces()}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showFreeOfAllergensOptions = (): ReactNode => {
        const containsGluten = props.data.allergens?.map((it) => it.allergen).includes(AllergenType.GLUTEN_GRAINS);
        const containsGlutenTraces = props.data.allergenTraces
            ?.map((it) => it.allergen)
            .includes(AllergenType.GLUTEN_GRAINS);
        const isInData = (allergenFree: AllergenFree) => props.data.freeOfAllergens?.includes(allergenFree) || false;
        const isDisabled = (allergenFreeType: AllergenFree) =>
            allergenFreeType === AllergenFree.GLUTEN_FREE ? containsGluten || containsGlutenTraces : false;
        return (
            <FormGroup>
                {Object.values(AllergenFree).map((allergenFree, index) => (
                    <FormControlLabel
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                        control={
                            <Checkbox
                                sx={{
                                    '&:hover': {
                                        color: theme.palette.primary.dark,
                                    },
                                }}
                                checked={isInData(allergenFree)}
                                disabled={isDisabled(allergenFree)}
                                onChange={handleFreeOfAllergensChange(allergenFree)}
                                value={allergenFree}
                            />
                        }
                        label={t(`productDataSheet:${allergenFree}`)}
                    />
                ))}
            </FormGroup>
        );
    };

    return (
        <Grid container sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showAllergensTitle()}
            {showAllergensSelect()}
            {showAddAllergenButton()}
            {showAllergensTracesTitle()}
            {showTracesSelect()}
            {showAddTracesButton()}
            {showFreeOfAllergensTitle()}
            {showFreeOfAllergensOptions()}
        </Grid>
    );
};
