import { Amount } from 'model/Amount';
import { OrderContainer } from 'model/ContainerView';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { Moment } from 'moment';
import { DeliveryStatus } from 'model/RecurringOrder';

export class Delivery {
    constructor(
        public deliveryDate: Moment,
        public referenceNumber: string | null,
        public entries: DeliveryEntry[],
        public status: DeliveryStatus,
        public links: DeliveryLinks,
    ) {}
}

export class DeliveryLinks {
    constructor(
        public self: string,
        public recurringOrder: string,
    ) {}
}

export class DeliveryEntry {
    constructor(
        public amount: Amount,
        public confirmedAmount: Amount | null,
        public deliveredAmount: Amount | null,
        public totalPrice: number,
        public pricePerUnit: number,
        public productLabel: string,
        public containers: OrderContainer[],
        public levelsOfProcessing: LevelOfProcessing[],
        public links: DeliveryEntryLinks,
    ) {}
}

export class DeliveryEntryLinks {
    constructor(
        public offer: string,
        public category?: string,
    ) {}
}

export class DeliveryUpdateWriteView {
    constructor(
        public entryUpdates: DeliveryEntryUpdateWriteView[],
        public deliveryDate: Date | null | undefined,
        public referenceNumber: string | undefined,
        public status: DeliveryStatus | undefined,
    ) {}
}

export class DeliveryEntryUpdateWriteView {
    constructor(
        public offerRef: string,
        public amount: Amount | undefined,
        public confirmedAmount: Amount | undefined,
        public deliveredAmount: Amount | undefined,
    ) {}
}
