export class Additive {
    constructor(
        public enumber: string,
        public usage: string,
    ) {}
}

export class Enumber {
    constructor(
        public enumber: string,
        public usages: AdditiveUsage[],
    ) {}
}

export enum AdditiveUsage {
    ACIDIFYING_AGENT = 'ACIDIFYING_AGENT',
    ACIDITY_REGULATOR = 'ACIDITY_REGULATOR',
    ANTIOXIDANT = 'ANTIOXIDANT',
    ANTI_FOAMING_AGENT = 'ANTI_FOAMING_AGENT',
    BAKING_AGENT = 'BAKING_AGENT',
    COATING_AGENT = 'COATING_AGENT',
    DYE = 'DYE',
    EMULSIFIER = 'EMULSIFIER',
    FILLER_MATERIAL = 'FILLER_MATERIAL',
    FIRMING_AGENT = 'FIRMING_AGENT',
    FLAVOUR_ENHANCER = 'FLAVOUR_ENHANCER',
    FLOUR_TREATMENT_AGENT = 'FLOUR_TREATMENT_AGENT',
    GELLING_AGENT = 'GELLING_AGENT',
    HUMECTANT = 'HUMECTANT',
    MODIFIED_STARCH = 'MODIFIED_STARCH',
    PRESERVATIVE = 'PRESERVATIVE',
    PROPELLANT = 'PROPELLANT',
    RELEASE_AGENT = 'RELEASE_AGENT',
    SMELTING_SALT = 'SMELTING_SALT',
    STABILIZING_AGENT = 'STABILIZING_AGENT',
    SWEETENER = 'SWEETENER',
    THICKENING_AGENT = 'THICKENING_AGENT',
}
