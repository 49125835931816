import { Amount } from 'model/Amount';
import { Moment } from 'moment';
import moment from 'moment/moment';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { ProductType } from 'model/ProductType';
import ProductTrait from 'model/ProductTrait';
import { AmountRange } from 'model/AmountRange';
import { ContainerView, ContainerWriteView } from 'model/ContainerView';

export class Offer {
    constructor(
        public id: string,
        public description: string | undefined,
        public dateFrom: Moment | undefined,
        public dateEnd: Moment | undefined,
        public totalAmount: Amount,
        public minAmount: Amount,
        public pricePerUnit: number | null,
        public graduatedPrices: GraduatedPrice[] | undefined = [],
        public levelsOfProcessing: LevelOfProcessing[] | undefined = [],
        public links: OfferLinks,
        public active: boolean,
        public isDeleted: boolean,
        public dateCreated: Moment | undefined,
        public dateModified: Moment | undefined,
        public isPermanent = false,
        public brandName: string | undefined,
        public productType: ProductType,
        public productTitle: string | undefined,
        public productTraits: ProductTrait[] = [],
        public containers: ContainerView[] | undefined = [],
        public caliber: AmountRange | undefined,
        public weight: AmountRange | undefined,
    ) {}
}

export class OfferLinks {
    constructor(
        public self: string,
        public update: string,
        public remove: string,
        public company: string,
        public category: string,
        public contact: string | undefined,
        public latestTradeItem?: string,
    ) {}
}

export class OfferWriteView {
    constructor(
        public dateFrom: Date | undefined | null,
        public dateEnd: Date | undefined | null,
        public description: string | undefined,
        public totalAmount: Amount,
        public minAmount: Amount,
        public pricePerUnit: number | null | undefined,
        public category: string,
        public graduatedPrices: GraduatedPrice[] | undefined,
        public levelsOfProcessing: string[] | undefined,
        public active: boolean,
        public contact: string | undefined | null,
        public isPermanent = false,
        public brandName: string | undefined,

        public productType: ProductType,
        public productTitle: string | undefined,
        public productTraits: ProductTrait[] | undefined,
        public containers: ContainerWriteView[] | undefined,
        public caliber: AmountRange | undefined,
        public weight: AmountRange | undefined,
    ) {
        // TODO be sure to send non-mandatory fields as undefined if not set, not as '' or []
        if (!description) this.description = undefined;
        if (!brandName) this.brandName = undefined;
        if (!productTitle) this.productTitle = undefined;
        if (!graduatedPrices?.length) this.graduatedPrices = undefined;
        if (!levelsOfProcessing?.length) this.levelsOfProcessing = undefined;
        if (!productTraits?.length) this.productTraits = undefined;
        if (pricePerUnit === null) this.pricePerUnit = undefined;
        if (!contact) this.contact = undefined;
    }

    static create(offer?: Offer): OfferWriteView {
        if (offer) {
            return new OfferWriteView(
                offer.dateFrom?.isValid() ? offer.dateFrom?.toDate() : undefined,
                offer.dateEnd?.isValid() ? offer.dateEnd?.toDate() : undefined,
                offer.description,
                offer.totalAmount,
                offer.minAmount,
                offer.pricePerUnit,
                offer.links.category,
                offer.graduatedPrices,
                offer.levelsOfProcessing?.map((lop) => lop.links.self),
                offer.active,
                offer.links.contact,
                offer.isPermanent,
                offer.brandName,
                offer.productType,
                offer.productTitle,
                offer.productTraits,
                offer.containers?.length ? offer.containers : undefined,
                offer.caliber,
                offer.weight,
            );
        } else {
            return new OfferWriteView(
                new Date(moment().startOf('day').format('YYYY-MM-DD')),
                new Date(moment().startOf('day').add(10, 'days').format('YYYY-MM-DD')),
                '',
                new Amount(1, ''),
                new Amount(0, ''),
                0,
                '',
                new Array<GraduatedPrice>(),
                new Array<string>(),
                true,
                null,
                false,
                '',
                ProductType.PROCESSED,
                '',
                undefined,
                undefined,
                undefined,
                undefined,
            );
        }
    }
}

export class CompanyOffers {
    constructor(
        public companyRef: string,
        public offers: Offer[],
    ) {}
}

export class GraduatedPrice {
    constructor(
        public price: number,
        public amount: number,
    ) {}
}

export class OfferContainers {
    constructor(
        public offerRef: string,
        public containerRefs: string[],
    ) {}
}
