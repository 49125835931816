export class Person {
    constructor(
        public firstname: string,
        public lastname: string,
        public email: string,
        // public id: string,
        public messageTermsStatus: MessageTermsStatus,
        public links: PersonLinks,
        public language?: string,
        public trackingEnabled?: boolean,
    ) {}
}

export class PersonCreateWriteView {
    constructor(
        public firstname: string,
        public lastname: string,
    ) {}
}

export class PersonUpdateWriteView {
    constructor(
        public firstname: string,
        public lastname: string,
        public language?: string,
        public trackingEnabled?: boolean,
    ) {}
}

export class PersonLinks {
    constructor(
        public self: string,
        public update?: string,
        public remove?: string,
    ) {}
}

export enum MessageTermsStatus {
    ACCEPTED = 'ACCEPTED',
    PENDING = 'PENDING',
    DECLINED = 'DECLINED',
}

export class MessageTermsStatusWriteView {
    constructor(
        public user: string,
        public status: MessageTermsStatus,
    ) {}
}

export class PersonFavourites {
    constructor(
        public personRef: string,
        public companyRefs: string[],
    ) {}
}

export function copy(person?: Person): Person {
    if (!person) {
        return new Person('', '', '', MessageTermsStatus.PENDING, { self: '' });
    }
    return new Person(
        person.firstname,
        person.lastname,
        person.email,
        person.messageTermsStatus,
        // person.id,
        person.links,
        person.language,
        person.trackingEnabled,
    );
}
