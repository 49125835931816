import StepGradeProps, { StepGrade } from 'components/productdatasheet/datablocks/block1/StepGrade';
import StepLevelsOfProcessingProps, {
    StepLevelsOfProcessing,
} from 'components/productdatasheet/datablocks/block1/StepLevelsOfProcessing';
import {
    StepProductNumber,
    StepProductNumberProps,
} from 'components/productdatasheet/datablocks/block1/StepProductNumber';
import StepTaxProps, { StepTax } from 'components/productdatasheet/datablocks/block1/StepTax';
import { StepContainer, StepContainerProps } from 'components/productdatasheet/datablocks/block2/StepContainer';
import StepDimensionAndWeightProps, {
    StepDimensionAndWeight,
} from 'components/productdatasheet/datablocks/block2/StepDimensionAndWeight';
import {
    StepOuterContainer,
    StepOuterContainerProps,
} from 'components/productdatasheet/datablocks/block2/StepOuterContainer';
import StepTemperatureProps, { StepTemperature } from 'components/productdatasheet/datablocks/block2/StepTemperature';
import StepAllergensProps, { StepAllergens } from 'components/productdatasheet/datablocks/block3/StepAllergens';
import StepIngredientsProps, { StepIngredients } from 'components/productdatasheet/datablocks/block3/StepIngredients';
import { ComponentClass, FunctionComponent } from 'react';
import StepBrandNameProps, { StepBrandName } from 'components/productdatasheet/datablocks/block1/StepBrandName';
import StepProductInformationProps, {
    StepProductInformation,
} from 'components/productdatasheet/datablocks/block1/StepProductInformation';
import { StepNutrition, StepNutritionProps } from 'components/productdatasheet/datablocks/block3/StepNutrition';
import { StepAdditives, StepAdditivesProps } from 'components/productdatasheet/datablocks/block3/StepAdditives';
import { StepDietType, StepDietTypeProps } from 'components/productdatasheet/datablocks/block3/StepDietType';
import {
    StepHusbandryCultivation,
    StepHusbandryCultivationProps,
} from 'components/productdatasheet/datablocks/block4/StepHusbandryCultivation';
import {
    StepProductTrait,
    StepProductTraitProps,
} from 'components/productdatasheet/datablocks/block1/StepProductTrait';

export interface StepComponentProps<T> {
    setData: (dataId: keyof ProductDataSheetData, data: T) => void;
    data: T;
}

export interface DataStep {
    title: string;
    dataId?: keyof ProductDataSheetData;
    component?: FunctionComponent<any> | ComponentClass<any, any>;
}

export interface DataBlock {
    title: string;
    subtitle?: string;
    dataSteps: DataStep[];
}

export default interface ProductDataSheetStructure {
    dataBlocks: DataBlock[];
}

export interface ProductDataSheetData {
    StepProductInformation: StepProductInformationProps;
    StepBrandName: StepBrandNameProps;
    StepLevelsOfProcessing: StepLevelsOfProcessingProps;
    StepProductTrait: StepProductTraitProps;
    StepGrade: StepGradeProps;
    StepTax: StepTaxProps;
    StepProductNumber: StepProductNumberProps;
    StepDimensionAndWeight: StepDimensionAndWeightProps;
    StepTemperature: StepTemperatureProps;
    StepIngredients: StepIngredientsProps;
    StepAdditives: StepAdditivesProps;
    StepAllergens: StepAllergensProps;
    StepNutrition: StepNutritionProps;
    StepDietType: StepDietTypeProps;
    StepHusbandryCultivation: StepHusbandryCultivationProps;
    StepContainer: StepContainerProps;
    StepOuterContainer: StepOuterContainerProps;
}

export const structure: ProductDataSheetStructure = {
    dataBlocks: [
        {
            title: 'DATABLOCK_TITLE_GENERAL',
            subtitle: 'DATABLOCK_SUBTITLE_GENERAL',
            dataSteps: [
                {
                    title: 'DATASTEP_PRODUCT_INFORMATION',
                    component: StepProductInformation,
                    dataId: 'StepProductInformation',
                },
                {
                    title: 'DATASTEP_BRAND_NAME',
                    component: StepBrandName,
                    dataId: 'StepBrandName',
                },
                {
                    title: 'DATASTEP_LEVEL_OF_PROCESSING',
                    component: StepLevelsOfProcessing,
                    dataId: 'StepLevelsOfProcessing',
                },
                {
                    title: 'DATASTEP_PRODUCT_TRAIT',
                    component: StepProductTrait,
                    dataId: 'StepProductTrait',
                },
                {
                    title: 'DATASTEP_GRADE',
                    component: StepGrade,
                    dataId: 'StepGrade',
                },
                {
                    title: 'DATASTEP_TAX',
                    component: StepTax,
                    dataId: 'StepTax',
                },
                {
                    title: 'DATASTEP_PRODUCT_ID',
                    component: StepProductNumber,
                    dataId: 'StepProductNumber',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_CONTAINER',
            subtitle: 'DATABLOCK_SUBTITLE_CONTAINER',
            dataSteps: [
                {
                    title: 'DATASTEP_DIMENSION_AND_WEIGHT',
                    component: StepDimensionAndWeight,
                    dataId: 'StepDimensionAndWeight',
                },
                {
                    title: 'DATASTEP_CONTAINER',
                    component: StepContainer,
                    dataId: 'StepContainer',
                },
                {
                    title: 'DATASTEP_OUTER_CONTAINER',
                    component: StepOuterContainer,
                    dataId: 'StepOuterContainer',
                },
                {
                    title: 'DATASTEP_TEMPERATURE',
                    component: StepTemperature,
                    dataId: 'StepTemperature',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_INGREDIENTS',
            subtitle: 'DATABLOCK_SUBTITLE_INGREDIENTS',
            dataSteps: [
                {
                    title: 'DATASTEP_INGREDIENTS',
                    component: StepIngredients,
                    dataId: 'StepIngredients',
                },
                {
                    title: 'DATASTEP_ADDITIVES',
                    component: StepAdditives,
                    dataId: 'StepAdditives',
                },
                {
                    title: 'DATASTEP_ALLERGENS',
                    component: StepAllergens,
                    dataId: 'StepAllergens',
                },
                {
                    title: 'DATASTEP_NUTRITION',
                    component: StepNutrition,
                    dataId: 'StepNutrition',
                },
                {
                    title: 'DATASTEP_DIET_TYPE',
                    component: StepDietType,
                    dataId: 'StepDietType',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_SUSTAINABILITY',
            subtitle: 'DATABLOCK_SUBTITLE_SUSTAINABILITY',
            dataSteps: [
                {
                    title: 'DATASTEP_HUSBANDRY_CULTIVATION',
                    component: StepHusbandryCultivation,
                    dataId: 'StepHusbandryCultivation',
                },
                {
                    title: 'Section B',
                },
                {
                    title: 'Section C',
                },
                {
                    title: 'Section D',
                },
                {
                    title: 'Section E',
                },
                {
                    title: 'Section F',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_ORIGIN',
            dataSteps: [
                {
                    title: 'Section A',
                },
                {
                    title: 'Section B',
                },
                {
                    title: 'Section C',
                },
                {
                    title: 'Section D',
                },
                {
                    title: 'Section E',
                },
                {
                    title: 'Section F',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_FURTHER_PROCESSING',
            subtitle: 'DATABLOCK_SUBTITLE_FURTHER_PROCESSING',
            dataSteps: [
                {
                    title: 'Section A',
                },
                {
                    title: 'Section B',
                },
                {
                    title: 'Section C',
                },
                {
                    title: 'Section D',
                },
                {
                    title: 'Section E',
                },
                {
                    title: 'Section F',
                },
            ],
        },
        {
            title: 'DATABLOCK_TITLE_OTHER',
            dataSteps: [
                {
                    title: 'Section A',
                },
                {
                    title: 'Section B',
                },
                {
                    title: 'Section C',
                },
                {
                    title: 'Section D',
                },
                {
                    title: 'Section E',
                },
                {
                    title: 'Section F',
                },
            ],
        },
    ],
};
