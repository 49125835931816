import { Allergen, AllergenFree, AllergenTraces } from 'components/productdatasheet/datablocks/block3/AllergenType';
import { Additive } from 'model/Additive';
import { AmountRange } from 'model/AmountRange';
import { ContainerView } from 'model/ContainerView';
import { Grade } from 'model/Grade';
import { Ingredient, IngredientWriteView } from 'model/Ingredient';
import { LevelOfProcessing } from 'model/LevelOfProcessing';
import { ProductNumber } from 'model/ProductNumber';
import { Amount } from 'model/Amount';
import ProductTrait from 'model/ProductTrait';
import { Husbandry } from 'components/productdatasheet/datablocks/block4/Husbandry';
import { Cultivation } from 'components/productdatasheet/datablocks/block4/Cultivation';
import { ProductType } from 'model/ProductType';

export class ProductDataSheet {
    constructor(
        public links: ProductDataSheetLinks,
        public dateCreated: Date,
        public dateModified: Date,
        public tax?: number,
        public productNumbers?: ProductNumber[],
        public levelsOfProcessing?: LevelOfProcessing[],
        public productTraits?: ProductTrait[],
        public productType?: ProductType,
        public grades?: Grade[],
        public description?: string,
        public brandName?: string,
        public productUnit?: string,
        public caliber?: AmountRange,
        public weight?: AmountRange,
        public temperatureMaxStorage?: number,
        public temperatureMaxTransport?: number,
        public temperatureText?: string,
        public containers?: ContainerView[],
        public ingredients?: Ingredient[],
        public additives?: Additive[],
        public allergens?: Allergen[],
        public allergenTraces?: AllergenTraces[],
        public freeOfAllergens?: AllergenFree[],
        public nutritionInformation?: NutritionInformation,
        public diets?: DietType[],
        public husbandry?: Husbandry[],
        public cultivation?: Cultivation[],
    ) {}
}

export class ProductDataSheetLinks {
    constructor(
        public self: string,
        public company: string,
        public employment?: string,
        public product?: string,
    ) {}
}

export class ProductDataSheetListView {
    constructor(
        public links: ProductDataSheetLinks,
        public dateModified: Date,
        public isComplete: boolean,
        public productLabel?: string,
        public levelsOfProcessing?: LevelOfProcessing[],
        public brandName?: string,
        public description?: string,
        public editorName?: string,
    ) {}
}

export class ProductDataSheetWriteView {
    constructor(
        public product?: string,
        public name?: string,
        public description?: string,
        public brandName?: string,
        public levelsOfProcessing?: string[],
        public productTraits?: ProductTrait[],
        public productType?: ProductType,
        public grades?: string[],
        public tax?: number,
        public productNumbers?: ProductNumber[],
        public productUnit?: string,
        public caliber?: AmountRange,
        public weight?: AmountRange,
        public temperatureMaxStorage?: number,
        public temperatureMaxTransport?: number,
        public temperatureText?: string,
        public ingredients?: IngredientWriteView[],
        public additives?: Additive[],
        public allergens?: Allergen[],
        public allergenTraces?: AllergenTraces[],
        public freeOfAllergens?: AllergenFree[],
        public nutritionInformation?: NutritionInformation,
        public diets?: DietType[],
        public husbandry?: Husbandry[],
        public cultivation?: Cultivation[],
    ) {}
}

export class NutritionInformation {
    constructor(
        public energy?: Amount,
        public fat?: number,
        public saturatedFattyAcids?: number,
        public polyunsaturatedFattyAcids?: number,
        public monounsaturatedFattyAcids?: number,
        public carbs?: number,
        public sugar?: number,
        public protein?: number,
        public salt?: number,
    ) {}
}

export enum DietType {
    VEGAN = 'VEGAN',
    VEGETARIAN = 'VEGETARIAN',
    HALAL = 'HALAL',
    KOSHER = 'KOSHER',
}
