import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ContainerType, ContainerView, OrderContainer } from 'model/ContainerView';
import { ContainerStore } from 'store';
import { Typography } from '@mui/material';
import { ComponentBase } from 'resub';

interface ContainerSelectionDetailProps extends WithTranslation {
    containerSelection: OrderContainer;
    containerTypes: ContainerType[];
}

interface ContainerSelectionDetailState {
    container: ContainerView;
}

class ContainerSelectionDetailComponent extends ComponentBase<
    ContainerSelectionDetailProps,
    ContainerSelectionDetailState
> {
    protected _buildState(
        props: ContainerSelectionDetailProps,
        initialBuild: boolean,
        incomingState?: ContainerSelectionDetailState,
    ): Partial<ContainerSelectionDetailState> | undefined {
        const newState: Partial<ContainerSelectionDetailState> = {
            ...incomingState,
        };
        newState.container = ContainerStore.getOne(props.containerSelection.links.container);

        return newState;
    }

    render(): React.ReactElement | null {
        return this.state.container ? (
            <Typography>
                {this.props.containerSelection.amount +
                    'x' +
                    ' ' +
                    this.props.t(
                        'containertype:' +
                            this.props.containerTypes.find(
                                (containerType) => this.state.container.containerType === containerType.id,
                            )?.slug,
                    ) +
                    ' ' +
                    this.state.container.amount +
                    ' ' +
                    this.props.t('shortunits:' + this.state.container.unit)}
            </Typography>
        ) : null;
    }
}

export default withTranslation(['containertype', 'shortunits'])(ContainerSelectionDetailComponent);
