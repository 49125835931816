import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { Grade, GradeLinks } from 'model/Grade';
import * as React from 'react';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
} from 'components/productdatasheet/ProductDataSheetHelpers';

export default interface StepGradeProps {
    grades?: Grade[];
    selectedGrades?: Grade[];
}

export const StepGrade = (props: StepComponentProps<StepGradeProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['dialogs', 'productDataSheet', 'grade']);
    const options = props.data.grades ?? [];
    const [inputValue, setInputValue] = useState<string | undefined>(undefined);

    useEffect(() => {
        const initialGrades = props.data.selectedGrades ?? [];
        const emptyGradeExists = initialGrades.some((grade) => grade === undefined);
        if (!emptyGradeExists) {
            props.setData('StepGrade', {
                selectedGrades: [...initialGrades],
            });
        }
    }, []);

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_GRADE_TITLE')}
            </Typography>
        </Grid>
    );

    const addButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={props.data.selectedGrades?.some((g) => g?.label === '')}
                onClick={addNewGrade}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const handleGradeChange = (index: number, newValue: string | null) => {
        const newGrade = props.data.grades?.find((it) => t(`grade:${it.label}`) === newValue);
        const updatedGrades = props.data.selectedGrades
            ?.slice()
            .map((it, i) => (i == index ? newGrade ?? new Grade('', new GradeLinks('')) : it))
            .filter((it): it is Grade => it != undefined);
        props.setData('StepGrade', { selectedGrades: updatedGrades });
        setInputValue(undefined);
    };

    const addNewGrade = () => {
        const newGrade = new Grade('', new GradeLinks(''));
        const updatedGrades = [...(props.data.selectedGrades ?? [])];
        updatedGrades.push(newGrade);
        props.setData('StepGrade', { selectedGrades: updatedGrades });
    };

    const showGradeSelect = (): ReactNode => (
        <Grid container>
            {props.data.selectedGrades?.map((grade, index) => {
                return (
                    <Grid container key={index}>
                        <Grid item md={10}>
                            <Autocomplete
                                sx={getProductDataSheetDialogAutoCompleteSX()}
                                filterOptions={(options) => {
                                    let filteredOptions = [...options].filter(
                                        (option) =>
                                            option &&
                                            !props.data.selectedGrades
                                                ?.map((it) => t(`grade:${it.label}`))
                                                .includes(option),
                                    );
                                    if (inputValue) {
                                        filteredOptions = filteredOptions.filter((option) =>
                                            option.toLowerCase().includes(inputValue.toLowerCase()),
                                        );
                                    }
                                    return filteredOptions;
                                }}
                                noOptionsText={t('common:noOptions')}
                                disableClearable={false}
                                options={options.map((it) => t(`grade:${it.label}`))}
                                value={grade.label ? t(`grade:${grade.label}`) : null}
                                onInputChange={(_, value) => {
                                    if (value !== t(`grade:${grade.label}`)) {
                                        setInputValue(value);
                                    }
                                }}
                                onFocus={() => setInputValue(undefined)}
                                onBlur={() => setInputValue(undefined)}
                                onChange={(_, value) => handleGradeChange(index, value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('productDataSheet:DATASTEP_GRADE')}
                                        fullWidth
                                        sx={getAutoCompleteTextFieldSX()}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid
                            item
                            md={1}
                            justifyContent={'left'}
                            sx={{ paddingLeft: theme.spacing(2), paddingTop: theme.spacing(0.5) }}
                        >
                            <IconButton
                                onClick={() => {
                                    const filteredGrades = props.data.selectedGrades!.filter((_, idx) => idx !== index);
                                    props.setData('StepGrade', { selectedGrades: filteredGrades });
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );

    return (
        <Grid container sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showGradeSelect()}
            {addButton()}
        </Grid>
    );
};
