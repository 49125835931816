import { Switch } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import UnitSelectComponent from 'components/marketplace/UnitSelectComponent';
import ContainerTraitsComponent from 'components/productdatasheet/datablocks/block2/ContainerTraitsComponent';
import { ContainerTraitType, ContainerView, ContainerWriteView } from 'model/ContainerView';
import { getUnitFromString, Unit, UnitType } from 'model/Unit';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { deepCopy } from 'util/deep-equality-helpers';

export interface ContainerPropsComponentProps {
    container: ContainerView;
    update: (container: ContainerView) => void;
    isReadOnly?: boolean;
}

export const ContainerPropsComponent = (props: ContainerPropsComponentProps) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        textField: {
            paddingRight: theme.spacing(1),
        },
    });
    const classes = useStyles();
    const { t } = useTranslation(['productDataSheet']);

    type ContainerProps = Omit<ContainerView, 'id' | 'containerType' | 'amount' | 'links' | 'unit' | 'innerContainer'>;

    const update = <K extends keyof ContainerProps>(key: K, value: ContainerProps[K]) => {
        const containerProps: ContainerProps = {};
        containerProps[key] = value;
        const container: ContainerView = { ...deepCopy(props.container), ...containerProps };
        props.update(container);
    };

    const handleTraitChange = (traits: ContainerTraitType[]) => {
        update('traits', traits);
    };

    const handleTraitRemove = (value: string) => {
        update('traits', props.container.traits?.filter((it) => it != value));
    };

    const handleMaterialChange = (value: string) => {
        update('material', value ? value : '');
    };

    const handleReturnableNoticeChange = (value: string) => {
        update('returnableNotice', value ? value : '');
    };

    const handleDimensionUnitChange = (value: Unit) => {
        update('dimensionUnit', value ? value.unit : undefined);
    };

    const handleReturnableChange = (value: boolean) => {
        update('returnable', value);
    };

    const handleDimensionInput = (
        value: string,
        dimensionProp: keyof Pick<ContainerWriteView, 'width' | 'length' | 'height'>,
    ) => {
        const numberValue = Number(value);
        if (!isNaN(numberValue) && numberValue > 0) {
            update(dimensionProp, Number(value));
        } else update(dimensionProp, undefined);
    };

    const showContainerMaterialInput = (container: ContainerView) => (
        <Grid item md={12}>
            <TextField
                value={container.material}
                label={t('productDataSheet:containerMaterial')}
                sx={{ width: '100%' }}
                onChange={(event) => handleMaterialChange(event.target.value)}
                disabled={props.isReadOnly ?? false}
            />
        </Grid>
    );

    const showReturnableToggle = (container: ContainerView) => (
        <Grid item md={12} container>
            <Grid item>
                <Switch
                    checked={container.returnable}
                    onChange={(event): void => handleReturnableChange(event.target.checked)}
                    disabled={props.isReadOnly ?? false}
                />
            </Grid>
            <Grid item sx={{ alignSelf: 'center' }}>
                <Typography>{t('productDataSheet:DATASTEP_CONTAINER_DEPOSIT_PACKAGING')}</Typography>
            </Grid>
        </Grid>
    );

    const showTraitsSelect = (container: ContainerView) => (
        <Grid item md={12}>
            <ContainerTraitsComponent
                key={container.id}
                selectedItems={container.traits ?? []}
                update={(items: ContainerTraitType[]) => handleTraitChange(items)}
                remove={(item: string) => handleTraitRemove(item)}
                isReadOnly={props.isReadOnly ?? false}
            />
        </Grid>
    );

    const showFurtherInformationInput = (container: ContainerView) => {
        if (!container.returnable) return;
        return (
            <Grid item md={12}>
                <TextField
                    value={container.returnableNotice}
                    fullWidth
                    label={t('productDataSheet:additionalDepositInfo')}
                    onChange={(event) => handleReturnableNoticeChange(event.target.value)}
                />
            </Grid>
        );
    };

    const isAllowed = (values: NumberFormatValues) => {
        const { floatValue } = values;
        return floatValue ? floatValue >= 0 : true;
    };

    const showDimensionsTitle = () => (
        <Grid item md={12}>
            <Typography sx={{ fontWeight: 600, fontSize: '16' }}>
                {t('productDataSheet:DATASTEP_CONTAINER_DIMENSION_OF_CONTAINER')}
            </Typography>
        </Grid>
    );

    const showDimensionLengthInput = () => (
        <Grid item md={3}>
            <NumberFormat
                value={props.container.length}
                className={classes.textField}
                label={t('productDataSheet:containerLength')}
                customInput={TextField}
                onValueChange={(event) => handleDimensionInput(event.value, 'length')}
                decimalScale={2}
                decimalSeparator={','}
                isAllowed={isAllowed}
                disabled={props.isReadOnly ?? false}
            />
        </Grid>
    );

    const showDimensionWidthInput = () => (
        <Grid item md={3}>
            <NumberFormat
                value={props.container.width}
                className={classes.textField}
                label={t('productDataSheet:containerWidth')}
                customInput={TextField}
                onValueChange={(event) => handleDimensionInput(event.value, 'width')}
                decimalScale={2}
                decimalSeparator={','}
                isAllowed={isAllowed}
                disabled={props.isReadOnly ?? false}
            />
        </Grid>
    );

    const showDimensionHeightInput = () => (
        <Grid item md={3}>
            <NumberFormat
                value={props.container.height}
                className={classes.textField}
                label={t('productDataSheet:containerHeight')}
                customInput={TextField}
                onValueChange={(event) => handleDimensionInput(event.value, 'height')}
                decimalScale={2}
                decimalSeparator={','}
                isAllowed={isAllowed}
                disabled={props.isReadOnly ?? false}
            />
        </Grid>
    );

    const showDimensionUnitSelect = () => (
        <Grid item md={3}>
            <UnitSelectComponent
                selectedUnit={getUnitFromString(props.container.dimensionUnit)}
                onChange={(selected) => handleDimensionUnitChange(selected)}
                typeToLimitTo={UnitType.LENGTH}
                conversationFactorToLimitTo={getUnitFromString('Centimeter')?.conversionFactor}
                isReadOnly={props.isReadOnly ?? false}
            />
        </Grid>
    );

    return (
        <Grid container item md={12} sx={{ paddingTop: theme.spacing(1.5) }}>
            {showDimensionsTitle()}
            {showDimensionLengthInput()}
            {showDimensionWidthInput()}
            {showDimensionHeightInput()}
            {showDimensionUnitSelect()}
            {showContainerMaterialInput(props.container)}
            {showReturnableToggle(props.container)}
            {showFurtherInformationInput(props.container)}
            {showTraitsSelect(props.container)}
        </Grid>
    );
};
