import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import StyledDatePickerComponent from 'components/shared/Popovers/StyledDatePickerComponent';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Stack, styled } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { RRule, Weekday } from 'rrule';
import { Offer } from 'model/Offer';
import i18next from 'i18next';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: '18px',
        fontWeight: 'bold',
        paddingBottom: theme.spacing(0.5),
    },
    select: {
        width: '100%',
        backgroundColor: theme.palette.primary.lighter,
        border: 'none',
        '& .MuiSelect-select': {
            padding: theme.spacing(1),
            border: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
        },
    },
    menuItem: {
        padding: theme.spacing(1),
    },
    selectedMenuItem: {
        backgroundColor: theme.palette.action.selected,
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    },
    dayButton: {
        width: 36,
        height: 36,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: theme.spacing(0.5),
        cursor: 'pointer',
        '&.selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '&:not(.selected)': {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.text.primary,
        },
    },
    customIconButton: {
        backgroundColor: 'transparent',
        '&:disabled': {
            backgroundColor: 'transparent',
            '& .MuiSvgIcon-root': {
                color: 'lightgray',
            },
        },
    },
}));

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    all: 'unset',
    width: 15,
    height: 15,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background-color 0.3s, transform 0.3s, border-radius 0.3s',
    '&:hover': {
        backgroundColor: theme.palette.background.default,
        borderRadius: '50%',
    },
}));

export enum DeliveryFrequency {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
}

const frequencyMapping = {
    [DeliveryFrequency.Daily]: RRule.DAILY,
    [DeliveryFrequency.Weekly]: RRule.WEEKLY,
    [DeliveryFrequency.Monthly]: RRule.MONTHLY,
};

enum DaysOfWeek {
    MONDAY = 'Mo',
    TUESDAY = 'Tu',
    WEDNESDAY = 'We',
    THURSDAY = 'Th',
    FRIDAY = 'Fr',
    SATURDAY = 'Sa',
    SUNDAY = 'Su',
}

const weekdayMapping: { [key in DaysOfWeek]: Weekday } = {
    [DaysOfWeek.MONDAY]: RRule.MO,
    [DaysOfWeek.TUESDAY]: RRule.TU,
    [DaysOfWeek.WEDNESDAY]: RRule.WE,
    [DaysOfWeek.THURSDAY]: RRule.TH,
    [DaysOfWeek.FRIDAY]: RRule.FR,
    [DaysOfWeek.SATURDAY]: RRule.SA,
    [DaysOfWeek.SUNDAY]: RRule.SU,
};

interface MonthlyOptions {
    value: string;
    label: string;
}

export type DeliveryDateFormErrors = Record<string, string | null>;

export default interface DeliveryDateSelectionProps {
    isRecurringOrder: boolean;
    offer: Offer;
    onRRuleStringChange: (rruleString: string) => void;
    onFormErrorsChange: (errors: DeliveryDateFormErrors) => void;
}
export const DeliveryDateSelection = (props: DeliveryDateSelectionProps) => {
    const theme = useTheme();
    const { t } = useTranslation(['preOrder', 'common']);
    const classes = useStyles();

    const allFrequencies = Object.values(DeliveryFrequency);
    const daysOfWeek = Object.values(DaysOfWeek);

    const [rruleString, setRruleString] = useState<string>('');

    const [selectedFrequency, setSelectedFrequency] = useState<DeliveryFrequency>(DeliveryFrequency.Weekly);

    const [frequencyCountValue, setFrequencyCountValue] = useState<number | undefined>(1);

    const [selectedLastDeliveryOption, setSelectedLastDeliveryOption] = useState<string>(
        t('preOrder:lastDeliveryNever'),
    );

    const [selectedFirstDeliveryDate, setSelectedFirstDeliveryDate] = useState<moment.Moment | null>(moment());

    const [selectedLastDeliveryDate, setSelectedLastDeliveryDate] = useState<moment.Moment | null>(moment());

    const [lastDeliveryCountValue, setLastDeliveryCountValue] = useState<number | undefined>(2);

    const [selectedDays, setSelectedDays] = useState<string[]>([]);

    const [selectMonthlyOption, setSelectMonthlyOption] = useState('');

    const [monthlyOptions, setMonthlyOptions] = useState<MonthlyOptions[]>([]);

    const [bySetPos, setBySetPos] = useState<number | null>(null);

    const [formErrors, setFormErrors] = useState<DeliveryDateFormErrors>({});

    useEffect(() => {
        props.onRRuleStringChange(rruleString);
    }, [rruleString, props]);

    useEffect(() => {
        props.onFormErrorsChange(formErrors);
    }, [formErrors, props]);

    useEffect(() => {
        try {
            const ruleOptions = {
                freq: frequencyMapping[selectedFrequency],
                interval: frequencyCountValue || 1,
                byweekday:
                    selectedDays.length > 0
                        ? selectedDays.map((day) => weekdayMapping[day as DaysOfWeek]).filter(Boolean)
                        : undefined,
                dtstart: selectedFirstDeliveryDate ? selectedFirstDeliveryDate.clone().endOf('day').toDate() : null,
                until: selectedLastDeliveryDate ? selectedLastDeliveryDate.clone().endOf('day').toDate() : null,
                bysetpos: bySetPos || null,
            };

            const rule = new RRule(ruleOptions);
            setRruleString(rule.toString());
        } catch (error) {
            console.debug('Failed to create RRule:', error);
        }
    }, [
        selectedFrequency,
        frequencyCountValue,
        selectedDays,
        selectedFirstDeliveryDate,
        selectedLastDeliveryDate,
        lastDeliveryCountValue,
        rruleString,
        bySetPos,
        props.isRecurringOrder,
    ]);

    const updateSelectedDays = useCallback(() => {
        if (selectedFrequency === DeliveryFrequency.Weekly && selectedFirstDeliveryDate) {
            const dayOfFirstDelivery = moment(selectedFirstDeliveryDate).locale('en').format('dd');
            setSelectedDays([dayOfFirstDelivery]);
        } else if (selectedFrequency !== DeliveryFrequency.Weekly) {
            setSelectedDays([]);
        }
    }, [selectedFirstDeliveryDate, selectedFrequency]);

    useEffect(() => {
        updateSelectedDays();
    }, [updateSelectedDays]);

    const calculateLastDeliveryDate = useCallback(() => {
        const rule = RRule.fromString(rruleString);

        if (lastDeliveryCountValue && selectedLastDeliveryOption === t('preOrder:after')) {
            rule.options.until = null;
            const occurrences = rule.all((_: Date, i: number) => {
                return i < lastDeliveryCountValue;
            });

            const lastOccurrence = occurrences[occurrences.length - 1];
            setSelectedLastDeliveryDate(moment(lastOccurrence).clone().endOf('day'));
        }

        if (selectedLastDeliveryOption === t('preOrder:lastDeliveryNever')) {
            setSelectedLastDeliveryDate(moment().add(2, 'years'));
        }
    }, [rruleString, frequencyCountValue, lastDeliveryCountValue, selectedLastDeliveryOption]);

    useEffect(() => {
        calculateLastDeliveryDate();
    }, [calculateLastDeliveryDate]);

    const validateForm = useCallback(() => {
        const errors: Record<string, string | null> = {};

        const firstDeliveryDate = selectedFirstDeliveryDate?.toDate();
        const offerDateFrom = props.offer?.dateFrom?.toDate();
        const offerDateEnd = props.offer?.dateEnd?.toDate();

        if (offerDateEnd && firstDeliveryDate && firstDeliveryDate > offerDateEnd) {
            errors['firstDeliveryDate'] = t('preOrder:checkFirstDeliveryDateWithinAvailability');
        }

        if (offerDateFrom && firstDeliveryDate && firstDeliveryDate <= moment(offerDateFrom).toDate()) {
            errors['firstDeliveryDate'] = t('preOrder:checkFirstDeliveryDateWithinAvailability');
        }

        if ((firstDeliveryDate && !moment(firstDeliveryDate).isValid()) || !firstDeliveryDate) {
            errors['firstDeliveryDate'] = t('preOrder:selectedDateInvalid');
        }

        if (!props.isRecurringOrder) {
            setFormErrors(errors);
            return;
        }

        const lastDeliveryDate = selectedLastDeliveryDate?.toDate();

        if (offerDateEnd && lastDeliveryDate && lastDeliveryDate > moment(offerDateEnd).add(1, 'day').toDate()) {
            errors['lastDeliveryDate'] = t('preOrder:checkLastDeliveryDateWithinAvailability');
            errors['lastDeliveryDateField'] = t('preOrder:checkLastDeliveryDateWithinAvailability');
        }

        if (offerDateFrom && lastDeliveryDate && lastDeliveryDate <= moment(offerDateFrom).add(1, 'day').toDate()) {
            errors['lastDeliveryDate'] = t('preOrder:checkLastDeliveryDateWithinAvailability');
            errors['lastDeliveryDateField'] = t('preOrder:checkLastDeliveryDateWithinAvailability');
        }

        if ((lastDeliveryDate && !moment(lastDeliveryDate).isValid()) || !lastDeliveryDate) {
            errors['lastDeliveryDateField'] = t('preOrder:selectedDateInvalid');
        }

        if (selectedDays.length === 0 && selectedFrequency === DeliveryFrequency.Weekly) {
            errors['selectedFrequency'] = t('preOrder:missingSelectedDay');
        }

        setFormErrors(errors);
    }, [
        selectedLastDeliveryDate,
        selectedFirstDeliveryDate,
        selectedDays,
        selectedFrequency,
        frequencyCountValue,
        props.isRecurringOrder,
    ]);

    useEffect(() => {
        validateForm();
    }, [validateForm]);
    const updateMonthlyOptions = useCallback(() => {
        let options: MonthlyOptions[] = [];
        if (selectedFrequency === DeliveryFrequency.Monthly) {
            if (selectedFirstDeliveryDate) {
                const dayOfMonth = selectedFirstDeliveryDate.date();
                const weekdayName = selectedFirstDeliveryDate.format('dddd');
                const weekdayNumber = Math.ceil(dayOfMonth / 7);

                const translatedWeekdayName = t(`common:${weekdayName}`);

                if (i18next.language === 'de') {
                    options = [
                        { value: 'monthlyOnDay', label: t(`preOrder:monthlyAt`) + ` ${dayOfMonth}.` },
                        {
                            value: 'monthlyOnWeekday',
                            label: t(`preOrder:monthlyAt`) + ` ${weekdayNumber}. ${translatedWeekdayName}`,
                        },
                    ];
                } else {
                    options = [
                        { value: 'monthlyOnDay', label: t(`preOrder:monthlyAt`) + ` ${dayOfMonth}rd.` },
                        {
                            value: 'monthlyOnWeekday',
                            label: t(`preOrder:monthlyAt`) + ` ${weekdayNumber}th  ${translatedWeekdayName}.`,
                        },
                    ];
                }

                setMonthlyOptions(options);

                if (!selectMonthlyOption && options.length > 0) {
                    setSelectMonthlyOption(options[0].value);
                }

                if (selectMonthlyOption === 'monthlyOnWeekday') {
                    const weekday = moment(selectedFirstDeliveryDate).locale('en').format('dd');
                    setSelectedDays([weekday]);
                    setBySetPos(weekdayNumber);
                } else {
                    setSelectedDays([]);
                    setBySetPos(null);
                }
            } else {
                setMonthlyOptions([]);
                setSelectedDays([]);
            }
        } else {
            setMonthlyOptions([]);
        }
    }, [selectedFirstDeliveryDate, selectedFrequency, selectMonthlyOption]);

    useEffect(() => {
        updateMonthlyOptions();
    }, [updateMonthlyOptions]);
    const handleFrequencyChange = (event: SelectChangeEvent<DeliveryFrequency>) => {
        const newFrequency = event.target.value as DeliveryFrequency;

        if (newFrequency !== DeliveryFrequency.Monthly) {
            setSelectedDays([]);
            setBySetPos(null);
        }

        setSelectedFrequency(newFrequency);
    };
    const handleIncreaseFrequencyCount = () => {
        setFrequencyCountValue((prevValue) => {
            const newValue = prevValue !== undefined ? prevValue + 1 : 1;
            return newValue;
        });
    };
    const handleDecreaseFrequencyCount = () => {
        setFrequencyCountValue((prevValue) => {
            const newValue = prevValue !== undefined && prevValue > 1 ? prevValue - 1 : 1;
            return newValue;
        });
    };
    const handleDayClick = (day: string) => {
        setSelectedDays((prevSelectedDays) => {
            const isSelected = prevSelectedDays.includes(day);
            const updatedSelectedDays = isSelected
                ? prevSelectedDays.filter((d) => d !== day)
                : [...prevSelectedDays, day];
            return updatedSelectedDays;
        });
    };

    const handleMonthlyOptionChange = (event: SelectChangeEvent<string>) => {
        setSelectMonthlyOption(event.target.value);
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedLastDeliveryOption(event.target.value);

        if (event.target.value === t('preOrder:On')) {
            setSelectedLastDeliveryDate(moment(selectedFirstDeliveryDate).add(7, 'day'));
        }
    };

    const handleFirstDeliveryDateChange = (date: moment.Moment | null) => {
        setSelectedFirstDeliveryDate(date ? date.clone().endOf('day') : null);
    };

    const handleLastDeliveryDateChange = (date: moment.Moment | null) => {
        setSelectedLastDeliveryDate(date ? date.clone().endOf('day') : null);
    };

    const handleLastDeliveryCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const numberValue = Number(event.target.value);
        if (isNaN(numberValue) || numberValue < 1) return;
        setLastDeliveryCountValue(numberValue);
    };

    const handleIncreaseLastDeliveryCount = () => {
        setLastDeliveryCountValue((prevValue) => (prevValue !== undefined ? prevValue + 1 : 1));
    };

    const handleDecreaseLastDeliveryCount = () => {
        setLastDeliveryCountValue((prevValue) => (prevValue !== undefined ? (prevValue > 1 ? prevValue - 1 : 1) : 1));
    };

    const showTitle = () => (
        <Grid item>
            <Typography className={classes.heading}>{t('preOrder:requestDeliveryDate')}</Typography>
        </Grid>
    );

    const showFrequencySelection = () => (
        <Grid item xs={12} container alignItems="center">
            <Grid item xs={2.3}>
                <Typography fontWeight={450}>{t('preOrder:repeat')}</Typography>
            </Grid>
            <Grid item xs={1.7}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                            sx: { pr: 1 },
                        }}
                        value={frequencyCountValue}
                        sx={{ width: '50%' }}
                    />
                    <Stack direction="column" spacing={0.5} sx={{ ml: 0.5 }}>
                        <CustomIconButton onClick={handleIncreaseFrequencyCount}>
                            <ArrowDropUp sx={{ color: 'black', fontSize: 16 }} />
                        </CustomIconButton>
                        <CustomIconButton onClick={handleDecreaseFrequencyCount}>
                            <ArrowDropDown sx={{ color: 'black', fontSize: 16 }} />
                        </CustomIconButton>
                    </Stack>
                </Box>
            </Grid>
            <Grid item xs={5}>
                <Select
                    label={t('preOrder:rotation')}
                    value={selectedFrequency}
                    onChange={handleFrequencyChange}
                    className={classes.select}
                    renderValue={(value) => t(`preOrder:${value}`)}
                >
                    {allFrequencies.map((frequency) => (
                        <MenuItem
                            key={frequency}
                            value={frequency}
                            className={`${classes.menuItem} ${
                                selectedFrequency === frequency ? classes.selectedMenuItem : ''
                            }`}
                        >
                            {t(frequency)}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );

    const showFirstDeliverySelection = () => (
        <Grid item xs={12} container alignItems="center">
            {props.isRecurringOrder && (
                <Grid item xs={4}>
                    <Typography fontWeight={450}>{t('preOrder:firstDelivery')}</Typography>
                </Grid>
            )}
            <Grid item xs={5}>
                <LocalizationProvider dateAdapter={DateAdapter}>
                    <StyledDatePickerComponent
                        value={selectedFirstDeliveryDate}
                        disablePast
                        label={t('preOrder:deliveryDate')}
                        onChange={handleFirstDeliveryDateChange}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                helperText={formErrors['firstDeliveryDate']}
                                error={!!formErrors['firstDeliveryDate']}
                                sx={{
                                    button: {
                                        backgroundColor: 'transparent',
                                    },
                                    width: '100%',
                                }}
                                required
                            />
                        )}
                    />
                </LocalizationProvider>
            </Grid>
            {!props.isRecurringOrder && (
                <Grid item xs={8} sx={{ mt: 1 }}>
                    <Typography fontWeight={450}>{t('preOrder:chatDetails')}</Typography>
                </Grid>
            )}
        </Grid>
    );

    const showDaySelection = () => (
        <Grid item xs={12} marginBottom={1} marginTop={1}>
            <Grid container spacing={1}>
                <Grid item xs={2.2} />
                <Grid item xs={8} container spacing={1}>
                    {daysOfWeek.map((day) => (
                        <Grid item key={day}>
                            <Box
                                className={`${classes.dayButton} ${selectedDays.includes(day) ? 'selected' : ''}`}
                                onClick={() => handleDayClick(day)}
                            >
                                {`${t(`preOrder:${day}`)}`}
                            </Box>
                        </Grid>
                    ))}
                    {!!formErrors['selectedFrequency'] && (
                        <Grid item xs={12}>
                            <Typography color="error">{formErrors['selectedFrequency']}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );

    const showMonthlyOptions = () => (
        <Grid item xs={12} container alignItems="center">
            <Grid item xs={4}></Grid>
            <Grid item xs={5}>
                <Select
                    value={selectMonthlyOption || ''}
                    onChange={handleMonthlyOptionChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Monthly Options' }}
                    className={classes.select}
                >
                    {monthlyOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {t(option.label)}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );

    const showLastDeliverySelection = () => {
        const isDateFieldEnabled = selectedLastDeliveryOption === t('preOrder:On');
        const lastDeliveryDateDisplayValue = isDateFieldEnabled
            ? selectedLastDeliveryDate
            : moment(selectedFirstDeliveryDate).add(7, 'day');
        const isLastDeliveryOptionAfter = selectedLastDeliveryOption === t('preOrder:after');
        const shouldShowError =
            !!formErrors['lastDeliveryDate'] &&
            (selectedLastDeliveryOption === t('preOrder:after') ||
                selectedLastDeliveryOption === t('preOrder:lastDeliveryNever'));

        return (
            <Grid item xs={12} container alignItems="center" sx={{ mt: 2 }}>
                <Grid item container xs={12}>
                    <Grid item xs={2.2}>
                        <Typography fontWeight={450}>{t('preOrder:lastDelivery')}</Typography>
                    </Grid>
                    <Grid item container xs={9.8}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={12} sx={{ mb: 1 }}>
                                <Box display="flex" alignItems="center">
                                    <input
                                        type="radio"
                                        name="lastDeliveryOption"
                                        value={t('preOrder:lastDeliveryNever')}
                                        checked={selectedLastDeliveryOption === t('preOrder:lastDeliveryNever')}
                                        onChange={handleOptionChange}
                                    />
                                    <Typography sx={{ ml: 1 }}>{t('preOrder:lastDeliveryNever')}</Typography>
                                </Box>
                            </Grid>

                            <Grid item container xs={12} alignItems="center" sx={{ mt: -2 }}>
                                <Grid item xs={2.2} container alignItems="center">
                                    <input
                                        type="radio"
                                        name="lastDeliveryOption"
                                        value={t('preOrder:On')}
                                        checked={selectedLastDeliveryOption === t('preOrder:On')}
                                        onChange={handleOptionChange}
                                    />
                                    <Typography sx={{ ml: 1 }}>{t('preOrder:On')}</Typography>
                                </Grid>
                                <Grid item xs={3.4}>
                                    <LocalizationProvider dateAdapter={DateAdapter}>
                                        <StyledDatePickerComponent
                                            disabled={!isDateFieldEnabled}
                                            label={t('preOrder:date')}
                                            value={lastDeliveryDateDisplayValue}
                                            disablePast
                                            onChange={handleLastDeliveryDateChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{
                                                        width: '100%',
                                                        '& .Mui-disabled .MuiButtonBase-root': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                        '& .MuiButtonBase-root': {
                                                            backgroundColor: 'transparent',
                                                        },
                                                    }}
                                                    {...params}
                                                    helperText={
                                                        isDateFieldEnabled
                                                            ? formErrors['lastDeliveryDateField']
                                                            : undefined
                                                    }
                                                    error={
                                                        isDateFieldEnabled
                                                            ? !!formErrors['lastDeliveryDateField']
                                                            : false
                                                    }
                                                    disabled={!isDateFieldEnabled}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        readOnly: !isDateFieldEnabled,
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                            </Grid>

                            <Grid item container xs={12} alignItems="center" sx={{ mt: -2 }}>
                                <Grid item xs={2.2} container alignItems="center">
                                    <input
                                        type="radio"
                                        name="lastDeliveryOption"
                                        value={t('preOrder:after')}
                                        checked={selectedLastDeliveryOption === t('preOrder:after')}
                                        onChange={handleOptionChange}
                                    />
                                    <Typography sx={{ ml: 1 }}>{t('preOrder:after')}</Typography>
                                </Grid>
                                <Grid item xs={8} container alignItems="center">
                                    <Grid item xs={3.2}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                disabled={!isLastDeliveryOptionAfter}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                value={lastDeliveryCountValue}
                                                onChange={handleLastDeliveryCountChange}
                                                sx={{ width: '50%' }}
                                            />
                                            <Stack direction="column" spacing={0.5} sx={{ ml: 0.5 }}>
                                                <CustomIconButton
                                                    disabled={!isLastDeliveryOptionAfter}
                                                    onClick={handleIncreaseLastDeliveryCount}
                                                    className={classes.customIconButton}
                                                >
                                                    <ArrowDropUp sx={{ color: 'black', fontSize: 16 }} />
                                                </CustomIconButton>
                                                <CustomIconButton
                                                    disabled={!isLastDeliveryOptionAfter}
                                                    onClick={handleDecreaseLastDeliveryCount}
                                                    className={classes.customIconButton}
                                                >
                                                    <ArrowDropDown sx={{ color: 'black', fontSize: 16 }} />
                                                </CustomIconButton>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                    <Typography
                                        sx={{
                                            marginLeft: -3,
                                            color: !isLastDeliveryOptionAfter ? 'lightgray' : 'inherit',
                                        }}
                                    >
                                        {t('preOrder:Deliveries')}
                                    </Typography>
                                </Grid>
                                {shouldShowError && (
                                    <Grid item xs={12}>
                                        <Typography color="error" sx={{ mt: 2 }}>
                                            {formErrors['lastDeliveryDate']}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={theme.spacing(1)}>
            {showTitle()}
            {showFirstDeliverySelection()}
            {props.isRecurringOrder && (
                <Grid item container>
                    {showFrequencySelection()}
                    {selectedFrequency === DeliveryFrequency.Weekly && showDaySelection()}
                    {selectedFrequency === DeliveryFrequency.Monthly && showMonthlyOptions()}
                    {showLastDeliverySelection()}
                </Grid>
            )}
        </Grid>
    );
};
