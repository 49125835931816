import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import OntofoodSelectComponent from 'components/ontofood/OntofoodSelectComponent';
import ProductDataSheetProductChip from 'components/productdatasheet/ProductDataSheetProductChip';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import * as React from 'react';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { deepCopy } from 'util/deep-equality-helpers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ProductType } from 'model/ProductType';

export default interface StepProductInformationProps {
    productRef?: string;
    assortmentToAdd?: Map<string, boolean>;
    productType?: ProductType;
    isProductTypeReadOnly?: boolean;
}

export const StepProductInformation = (props: StepComponentProps<StepProductInformationProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['productDataSheet']);

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TEXT')}</Typography>
        </Grid>
    );

    const showProductSelect = (): ReactNode => (
        <Grid item md={12}>
            <OntofoodSelectComponent
                value={props.data.productRef ?? null}
                onChange={(productRef) => props.setData('StepProductInformation', { productRef })}
                productType={'market'}
                calledFrom={'StepProductInformation'}
            />
        </Grid>
    );

    const showAssortmentTitle = (): ReactNode =>
        props.data.assortmentToAdd && props.data.assortmentToAdd?.size > 0 ? (
            <Grid item md={12} sx={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(2) }}>
                <Typography sx={{ fontWeight: 600, fontSize: '17px' }}>
                    {t('productDataSheet:DATASTEP_BRAND_NAME_AND_LABEL_ASSORTMENT_TITLE')}
                </Typography>
            </Grid>
        ) : null;

    const showAssortmentText = (): ReactNode =>
        props.data.assortmentToAdd && props.data.assortmentToAdd?.size > 0 ? (
            <Grid item md={12} sx={{ paddingBottom: theme.spacing(1) }}>
                <Typography>{t('productDataSheet:DATASTEP_BRAND_NAME_AND_LABEL_ASSORTMENT_TEXT')}</Typography>
            </Grid>
        ) : null;

    const assortmentToAddProductChips = (): ReactNode => {
        return Array.from(props.data.assortmentToAdd ?? []).map(([productRef, isChosen]) => (
            <ProductDataSheetProductChip
                key={productRef}
                productRef={productRef}
                type="company"
                color="secondary"
                isChosen={isChosen}
                toggleProduct={() => {
                    const assortmentToAdd = deepCopy(props.data.assortmentToAdd)?.set(productRef, !isChosen);
                    props.setData('StepProductInformation', { assortmentToAdd });
                }}
            />
        ));
    };

    const showProductTypeTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(1), paddingTop: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600 }}>
                {t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TYPE_TITLE')}
            </Typography>
        </Grid>
    );

    const showProductType = (): ReactNode => (
        <Grid item md={12}>
            {props.data.isProductTypeReadOnly && (
                <Alert
                    sx={{
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        backgroundColor: theme.palette.secondary.lighter,
                        color: theme.palette.common.black,
                        borderRadius: 2,
                    }}
                    icon={
                        <InfoOutlinedIcon
                            sx={{
                                height: '100%',
                                color: theme.palette.secondary.dark,
                            }}
                        />
                    }
                >
                    {t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TYPE_PROCESSED_INFO')}
                </Alert>
            )}
            <FormControl>
                <RadioGroup
                    row
                    value={props.data.productType ?? null}
                    onChange={(event) => {
                        props.setData('StepProductInformation', {
                            productType: event.target.value as ProductType,
                        });
                    }}
                >
                    <FormControlLabel
                        value={ProductType.RAW}
                        control={<Radio />}
                        label={t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TYPE_RAW')}
                        disabled={props.data.isProductTypeReadOnly}
                    />
                    <FormControlLabel
                        value={ProductType.PROCESSED}
                        control={<Radio />}
                        label={t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TYPE_PROCESSED')}
                        disabled={props.data.isProductTypeReadOnly}
                    />
                </RadioGroup>
            </FormControl>
        </Grid>
    );

    return (
        <Grid container sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showProductSelect()}
            {showAssortmentTitle()}
            {showAssortmentText()}
            {assortmentToAddProductChips()}
            {showProductTypeTitle()}
            {showProductType()}
        </Grid>
    );
};
