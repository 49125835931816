import moment from 'moment/moment';

/** Checks recursively for deep equality of arbitrary objects. Logs if desired. <p>
 *  Currently there is explicit support for Set, Map, Array, Date and Moment.
 **/
export function isDeepEquality<T>(obj1: T, obj2: T, logChanges = false, path = '', outputs: string[] = []): boolean {
    let isEqual = true;

    const logOutput = (message: string) => {
        if (logChanges) {
            outputs.push(message);
        }
    };

    if (obj1 instanceof Date && obj2 instanceof Date) {
        isEqual = isEqual && obj1.getTime() === obj2.getTime();

        if (!isEqual) {
            const obj1Log = obj1.toString() === 'Invalid Date' ? obj1.toString() : obj1.toISOString();
            const obj2Log = obj2.toString() === 'Invalid Date' ? obj2.toString() : obj2.toISOString();
            logOutput(`[${path}]: ${obj1Log} <!> ${obj2Log}`);
        }
    } else if (moment.isMoment(obj1) && moment.isMoment(obj2)) {
        isEqual = isEqual && obj1.isSame(obj2);

        if (!isEqual) {
            logOutput(`[${path}]: ${obj1.format()} <!> ${obj2.format()}`);
        }
    } else if (obj1 instanceof Set && obj2 instanceof Set) {
        if (obj1.size !== obj2.size) {
            isEqual = false;

            logOutput(`[${path}.size]: ${obj1.size} <!> ${obj2.size}`);
        } else {
            const array1 = Array.from(obj1);
            const array2 = Array.from(obj2);
            isEqual = isDeepEquality(array1, array2, logChanges, path, outputs);
        }
    } else if (obj1 instanceof Map && obj2 instanceof Map) {
        if (obj1.size !== obj2.size) {
            isEqual = false;

            logOutput(`[${path}.size]: ${obj1.size} <!> ${obj2.size}`);
        } else {
            for (const [key, value] of obj1) {
                const nestedPath = `${path}.${key}`;
                const nestedEqual = isDeepEquality(value, obj2.get(key), logChanges, nestedPath, outputs);
                isEqual = isEqual && nestedEqual;
            }
        }
    } else if (Array.isArray(obj1) && Array.isArray(obj2)) {
        if (obj1.length !== obj2.length) {
            isEqual = false;

            logOutput(`[${path}.length]: ${obj1.length} <!> ${obj2.length}`);
        } else {
            for (let i = 0; i < obj1.length; i++) {
                const nestedPath = `${path}[${i}]`;
                const nestedEqual = isDeepEquality(obj1[i], obj2[i], logChanges, nestedPath, outputs);
                isEqual = isEqual && nestedEqual;
            }
        }
    } else if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
        for (const key in obj1) {
            const nestedPath = `${path}.${key}`;

            if (key in obj2) {
                const nestedEqual = isDeepEquality(obj1[key], obj2[key], logChanges, nestedPath, outputs);
                isEqual = isEqual && nestedEqual;
            } else if (obj1[key] !== undefined) {
                isEqual = false;
                logOutput(`[${path}]: ${key} of obj1 !in obj2`);
            }
        }

        for (const key in obj2) {
            if (!(key in obj1) && obj2[key] !== undefined) {
                isEqual = false;
                logOutput(`[${path}]: ${key} of obj2 !in obj1`);
            }
        }
    } else if (obj1 !== obj2) {
        isEqual = false;
        const o1 = obj1 as unknown;
        const o2 = obj2 as unknown;

        const obj1Out =
            typeof o1 === 'string'
                ? o1 === ''
                    ? "''"
                    : o1.length > 22
                      ? "'" + o1.slice(0, 8) + ' … ' + o1.slice(o1.length - 9, o1.length) + "'"
                      : obj1
                : obj1;
        const obj2Out =
            typeof o2 === 'string'
                ? o2 === ''
                    ? "''"
                    : o2.length > 22
                      ? "'" + o2.slice(0, 8) + ' … ' + o2.slice(o2.length - 9, o2.length) + "'"
                      : obj2
                : obj2;
        logOutput(`[${path}]: ${obj1Out} <!> ${obj2Out}`);
    }

    if (path === '' && outputs.length > 0) {
        console.log('Δ\n' + outputs.join('\n'));
    }

    return isEqual;
}

/** Returns a deep copy of arbitrary objects.<p>
 *  Currently there is explicit support for Set, Map, Array, Date and Moment.
 **/
export function deepCopy<T>(obj: T): T {
    function copy(obj: any): any {
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        if (obj instanceof Map) {
            const copiedMap = new Map();
            obj.forEach((value: any, key: any) => {
                copiedMap.set(key, copy(value));
            });
            return copiedMap;
        }

        if (obj instanceof Set) {
            const copiedSet = new Set();
            obj.forEach((value: any) => {
                copiedSet.add(copy(value));
            });
            return copiedSet;
        }

        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }

        if (moment.isMoment(obj)) {
            return moment(obj);
        }

        const copiedObj: any = Array.isArray(obj) ? [] : {};

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                copiedObj[key] = copy(obj[key]);
            }
        }

        return copiedObj;
    }
    return copy(obj) as T;
}
