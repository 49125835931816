import AddIcon from '@mui/icons-material/Add';
import Delete from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { Additive, AdditiveUsage, Enumber } from 'model/Additive';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
} from 'components/productdatasheet/ProductDataSheetHelpers';

export interface StepAdditivesProps {
    enumbers?: Enumber[];
    additives?: AdditiveWithKey[];
    isRawProduct?: boolean;
}

export interface AdditiveWithKey {
    additive: Additive;
    key: number;
}

export const StepAdditives = (props: StepComponentProps<StepAdditivesProps>) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        autocomplete: {
            '& .MuiAutocomplete-clearIndicator': {
                backgroundColor: 'transparent',
                color: theme.palette.grey[700],
                '&:hover': {
                    color: theme.palette.grey[700],
                },
            },
            '& .MuiAutocomplete-popupIndicator': {
                backgroundColor: 'transparent',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
        },
    });
    const classes = useStyles();
    const { t } = useTranslation(['eNumbers', 'productDataSheet', 'additivesUsage', 'common']);
    const [enumberInputValue, setEnumberInputValue] = useState<string | undefined>(undefined);
    const [usageInputValue, setUsageInputValue] = useState<string | undefined>(undefined);

    const enumberTMap = new Map<string, string>();
    if (props.data.enumbers) {
        props.data.enumbers.forEach((en) =>
            enumberTMap.set(en.enumber.toString(), t(`eNumbers:${en.enumber.toString()}`)),
        );
    }
    const usageTMap = new Map<string, string>();
    Object.values(AdditiveUsage).forEach((u) => usageTMap.set(u.toString(), t(`additivesUsage:${u.toString()}`)));

    const handleEnumberChange = (newValue: Enumber | undefined, key: number) => {
        const selectedEnumber = newValue
            ? props.data.enumbers?.find((value) => value == newValue) ?? undefined
            : undefined;
        const changedUsage = selectedEnumber
            ? selectedEnumber.usages.length == 1
                ? selectedEnumber.usages[0]
                : undefined
            : undefined;

        props.setData('StepAdditives', {
            additives: (props.data.additives ?? []).map((additive) => {
                if (additive.key === key) {
                    return changedUsage
                        ? {
                              ...additive,
                              additive: {
                                  enumber: newValue?.enumber ?? '',
                                  usage: changedUsage.toString(),
                              },
                          }
                        : { ...additive, additive: { ...additive.additive, enumber: newValue?.enumber ?? '' } };
                } else return additive;
            }),
        });
    };

    const handleUsageChange = (newValue: string | undefined, key: number) => {
        props.setData('StepAdditives', {
            additives: (props.data.additives ?? []).map((additive) =>
                additive.key === key
                    ? { ...additive, additive: { ...additive.additive, usage: newValue ?? '' } }
                    : additive,
            ),
        });
    };

    const handleDelete = (key: number) => {
        props.setData('StepAdditives', {
            additives: props.data.additives?.filter((it) => it.key !== key),
        });
    };

    const handleAddAdditive = () => {
        const key = Math.random();
        const newAdditive: AdditiveWithKey = {
            additive: {
                enumber: '',
                usage: '',
            },
            key,
        };
        const updatedAdditives = [...(props.data.additives ?? [])];
        updatedAdditives.push(newAdditive);
        props.setData('StepAdditives', { additives: updatedAdditives });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_ADDITIVES_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={11} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography>{t('productDataSheet:DATASTEP_ADDITIVES_TEXT')}</Typography>
        </Grid>
    );

    const showAddButton = (): ReactNode => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    !props.data.additives ||
                    props.data.additives.some((it) => !it.additive.enumber || !it.additive.usage)
                }
                onClick={handleAddAdditive}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showDeleteButton = (key: number): ReactNode => {
        return (
            <Grid item md={1}>
                <IconButton onClick={() => handleDelete(key)}>
                    <Delete />
                </IconButton>
            </Grid>
        );
    };

    const showAdditivesWithKeys = (): ReactNode => {
        const additives = props.data.additives;
        return additives?.map((additive) => {
            if (props.data.enumbers === undefined) return null;
            const enumberValue = additive.additive.enumber
                ? props.data.enumbers?.find((value) => value.enumber == additive.additive.enumber) ?? undefined
                : undefined;
            const usageValue = additive.additive.usage
                ? Object.values(AdditiveUsage).find((value) => value == additive.additive.usage) ?? undefined
                : undefined;

            return (
                <Grid
                    key={additive.key}
                    item
                    container
                    md={12}
                    sx={{ alignItems: 'center', justifyContent: 'space-between' }}
                >
                    <Grid item md={5.25}>
                        <Autocomplete
                            className={classes.autocomplete}
                            value={enumberValue ?? null}
                            options={props.data.enumbers}
                            filterOptions={(options) => {
                                const preFilteredOptions = options.filter(
                                    (it) =>
                                        !props.data.additives
                                            ?.map((it) => it.additive.enumber)
                                            ?.includes(it.enumber.toString()),
                                );
                                const filteredOptions =
                                    usageValue && preFilteredOptions
                                        ? preFilteredOptions.filter((enumber) =>
                                              enumber.usages.includes(usageValue as AdditiveUsage),
                                          )
                                        : preFilteredOptions;
                                return enumberInputValue
                                    ? filteredOptions.filter(
                                          (o) =>
                                              enumberTMap
                                                  .get(o.enumber.toString())
                                                  ?.toLowerCase()
                                                  .includes(enumberInputValue.toLowerCase()) ?? false,
                                      )
                                    : filteredOptions;
                            }}
                            noOptionsText={t('common:noOptions')}
                            getOptionLabel={(option) => t(`eNumbers:${option.enumber}`)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('productDataSheet:DATASTEP_ADDITIVES_ENUMBER_AND_DESCRIPTION')}
                                    fullWidth
                                    sx={getAutoCompleteTextFieldSX()}
                                />
                            )}
                            onFocus={() => setEnumberInputValue(undefined)}
                            onBlur={() => setEnumberInputValue(undefined)}
                            onInputChange={(_, value) => {
                                const eNumberTranslations = Array.from(enumberTMap.values());
                                if (!eNumberTranslations.includes(value)) {
                                    setEnumberInputValue(value);
                                }
                            }}
                            onChange={(_, newValue) => {
                                handleEnumberChange(newValue ?? undefined, additive.key);
                            }}
                        />
                    </Grid>
                    <Grid item md={5.25}>
                        <Autocomplete
                            sx={getProductDataSheetDialogAutoCompleteSX()}
                            className={classes.autocomplete}
                            value={usageValue ?? null}
                            options={Object.values(AdditiveUsage)}
                            getOptionLabel={(option) => t(`additivesUsage:${option.toString()}`)}
                            filterOptions={(options) => {
                                const filteredOptions = enumberValue
                                    ? options.filter((value) => enumberValue.usages.includes(value))
                                    : options;
                                return usageInputValue
                                    ? filteredOptions.filter(
                                          (o) =>
                                              usageTMap
                                                  .get(o.toString())
                                                  ?.toLowerCase()
                                                  .includes(usageInputValue.toLowerCase()) ?? false,
                                      )
                                    : filteredOptions;
                            }}
                            noOptionsText={t('common:noOptions')}
                            onFocus={() => setUsageInputValue(undefined)}
                            onBlur={() => setUsageInputValue(undefined)}
                            onInputChange={(_, value) => setUsageInputValue(value)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('productDataSheet:DATASTEP_ADDITIVES_USAGE')}
                                    fullWidth
                                    sx={getAutoCompleteTextFieldSX()}
                                />
                            )}
                            onChange={(_, newValue) => {
                                handleUsageChange(newValue?.toString(), additive.key);
                            }}
                        />
                    </Grid>
                    {showDeleteButton(additive.key)}
                </Grid>
            );
        });
    };

    const showProductTypeRawInfo = (): ReactNode => (
        <Grid
            item
            md={12}
            sx={{
                fontSize: 14,
                borderRadius: 5,
                backgroundColor: theme.palette.grey[100],
                padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
            }}
        >
            <Typography sx={{ fontWeight: 600 }}>
                {t('productDataSheet:DATASTEP_PRODUCT_INFORMATION_TYPE_RAW_ADDITIVES_INFO')}
            </Typography>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {props.data.isRawProduct && showProductTypeRawInfo()}
            {!props.data.isRawProduct && (
                <>
                    {showTitle()}
                    {showText()}
                    {showAdditivesWithKeys()}
                    {showAddButton()}
                </>
            )}
        </Grid>
    );
};
