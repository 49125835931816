import {
    PreOrderDialogProps,
    PreOrderDialogState,
    PreOrderDialogType,
} from 'components/marketplace/pre-order/dialog/PreOrderDialog';
import { rrulestr } from 'rrule';

export default class PreOrderDialogHelpers {
    constructor(
        private props: PreOrderDialogProps,
        private state: PreOrderDialogState,
        private setState: <K extends keyof PreOrderDialogState>(
            state:
                | ((
                      prevState: Readonly<PreOrderDialogState>,
                      props: Readonly<PreOrderDialogProps>,
                  ) => Pick<PreOrderDialogState, K> | PreOrderDialogState | null)
                | Pick<PreOrderDialogState, K>
                | PreOrderDialogState
                | null,
            callback?: () => void,
        ) => void,
    ) {}

    getTitleLocale(): string {
        if (this.props.type == PreOrderDialogType.PurchaseIntent) {
            return 'offer:purchaseIntent';
        } else {
            return 'offer:priceRequest';
        }
    }

    getDatesFromRRule(rruleString: string) {
        if (!rruleString) return { startDate: null, untilDate: null };

        const rrule = rrulestr(rruleString);
        const startDate = rrule.options.dtstart || null;
        const untilDate = rrule.options.until || null;

        return { startDate, untilDate };
    }
}
