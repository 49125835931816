import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';
import { common } from '@mui/material/colors';
import { Flavours, getFlavour } from 'style/flavour-helper';

declare module '@mui/material/styles' {
    type DefaultTheme = Theme;

    interface PaletteColor {
        lightest: string;
        lighter: string;
        light: string;
        main: string;
        dark: string;
        darker: string;
        darkest: string;
    }

    interface PaletteColorOptions {
        lightest?: string;
        lighter?: string;
        light?: string;
        main?: string;
        dark?: string;
        darker?: string;
        darkest?: string;
    }
}

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

let colors: Record<
    1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 'white' | 'black' | 'defaultBackground' | 'error',
    string
> & { mode: 'light' | 'dark' };

// colors should not be exported, use the theme directly instead!
switch (getFlavour()) {
    case Flavours.AROMA:
        colors = {
            1: '#004337',
            2: '#adc177',
            3: '#f0ead1',
            4: '#fbbe49',
            5: '#ec6b1c',
            6: '#a78465',
            7: '#f44336',
            8: '',
            9: '',
            10: '#FEEEE9', //error light
            11: '#FFF3D0',
            12: '#55430C',
            mode: 'light',
            defaultBackground: '#F2F2F2',
            white: common.white,
            black: common.black,
            error: '#EE5521',
        };
        break;
    default:
    case Flavours.NEARBUY:
        colors = {
            1: '#486F50', // primary dark
            2: '#92BE9B', // primary main
            3: '#B7D7C3', // primary light
            4: '#E3C44F', // secondary main
            5: '#B79244', // secondary dark
            6: '#b5b5b5', // secondary light
            7: '#715D00', // secondary darker
            8: '#DBEBE1', // primary lighter
            9: '#F3E1AA', // secondary lighter
            10: '#FEEEE9', //error lighter
            11: '#FFF3D0', //secondary lightest
            12: '#55430C', //secondary darkest
            mode: 'light',
            defaultBackground: '#F2F2F2',
            white: common.white,
            black: common.black,
            error: '#EE5521',
        };
        break;
}

// this describes all the behaviour, that all themes have in common
const defaultTheme = createTheme({
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                    fontFamily: 'quicksand',
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiAutocomplete-popupIndicator': {
                        backgroundColor: 'transparent',
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.common.black,
                        },
                    },
                }),
                paper: {
                    border: '1px solid #F2F2F2',
                    boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.04)',
                    borderRadius: '12px !important',
                },
                listbox: ({ theme }) => ({
                    padding: '8px 8px 4px',
                    color: theme.palette.getContrastText(theme.palette.background.paper),
                    '& li': {
                        alignItems: 'center',
                        marginBottom: '4px',
                        '&:hover': {
                            backgroundColor: `${theme.palette.primary.lighter} !important`,
                            borderRadius: '8px',
                            marginBottom: '4px',
                        },
                        '&:focus': {
                            backgroundColor: 'transparent',
                        },
                    },
                }),
            },
        },
        MuiLink: {
            defaultProps: {
                underline: 'none',
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.dark,
                    fontWeight: '600',
                }),
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                margin: 'normal',
                size: 'small',
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    input: {
                        backgroundColor: theme.palette.primary.lighter,
                        borderRadius: '8px',
                        '&::placeholder': {
                            color: theme.palette.primary.dark,
                            opacity: 1,
                        },
                        '&:disabled': {
                            borderRadius: 8,
                            color: theme.palette.action.disabled,
                        },
                        '&:focus': {
                            backgroundColor: 'transparent',
                        },
                    },
                    border: 'none',
                    '& .MuiFormHelperText': {
                        backgroundColor: 'transparent',
                        fontWeight: 'bold',
                    },
                    '& .Mui-error': {
                        backgroundColor: 'transparent',
                        fontWeight: 'bold',
                    },

                    '&. MuiInputBase-root': {
                        borderRadius: '8px',
                    },
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: theme.palette.primary.lighter,
                        fontWeight: 'normal',
                        fontSize: '16px',
                        borderRadius: '8px',
                        '& fieldset': {
                            borderColor: 'transparent',
                        },
                        '&:hover fieldset': {
                            borderColor: theme.palette.primary.light,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: 'transparent',
                        },
                        '&.Mui-disabled fieldset': {
                            borderColor: 'transparent',
                        },
                    },
                }),
            },
        },
        MuiInput: {
            defaultProps: {
                disableUnderline: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    borderRadius: '8px',
                    color: theme.palette.getContrastText(theme.palette.primary.lighter),
                }),
            },
        },
        MuiSelect: {
            defaultProps: {
                type: 'basic',
                size: 'small',
            },
            styleOverrides: {
                select: ({ theme }) => ({
                    borderRadius: 8,
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                    '&:disabled': {
                        borderRadius: 8,
                    },
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                }),
            },
        },
        MuiModal: {
            //This fixes not being able to write inside the feedback dialog while a modal is open
            //TODO: Research whether usability would be better,
            // if this property is dynamically set on the condition of the feedback dialog being open
            defaultProps: {
                disableEnforceFocus: true,
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '&:hover': {
                        color: theme.palette.primary.dark,
                    },
                }),
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'standard',
                size: 'small',
            },
            styleOverrides: {
                marginNormal: {
                    marginTop: 6,
                    marginBottom: 6,
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {},
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    width: '85%',
                    color: theme.palette.primary.dark,
                }),
                shrink: ({ theme }) => ({
                    fontWeight: 'bold',
                    color: theme.palette.primary.dark,
                    '&.Mui-focused': {
                        color: theme.palette.primary.dark,
                    },
                }),
            },
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    textTransform: 'none',
                    '&:hover': {
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.primary.dark,
                        ),
                    },
                    '&.active': {
                        backgroundColor: theme.palette.primary.dark,
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.primary.dark,
                        ),
                    },
                }),
            },
        },
        MuiButton: {
            styleOverrides: {
                text: ({ ownerState, theme }) => ({
                    color: theme.palette.getContrastText(
                        ownerState.style?.backgroundColor || theme.palette.background.default,
                    ),
                    '&:hover': {
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.primary.main,
                        ),
                    },
                }),
                textSecondary: ({ ownerState, theme }) => ({
                    color: theme.palette.getContrastText(
                        ownerState.style?.backgroundColor || theme.palette.secondary.main,
                    ),
                    '&:hover': {
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.secondary.main,
                        ),
                    },
                }),
                outlinedPrimary: ({ ownerState, theme }) => ({
                    color: theme.palette.primary.dark,
                    borderColor: theme.palette.primary.dark,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.primary.main,
                        ),
                        backgroundColor: theme.palette.primary.lighter,
                    },
                }),
                outlinedSecondary: ({ theme }) => ({
                    color: theme.palette.secondary.dark,
                    borderColor: theme.palette.secondary.dark,
                }),
                outlined: ({ ownerState, theme }) => ({
                    '&:hover': {
                        color: theme.palette.getContrastText(
                            ownerState.style?.backgroundColor || theme.palette.primary.main,
                        ),
                    },
                }),
                contained: {
                    borderRadius: 33,
                    boxShadow: 'none',
                    '&:hover': {
                        boxShadow: '0 3px 7px 4px rgba(60,64,67,0.209)',
                    },
                },
                root: {
                    textTransform: 'none',
                    borderRadius: 33,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.primary.light,
                    color: theme.palette.text.primary,
                    '&:hover': {
                        backgroundColor: theme.palette.primary.dark,
                    },
                    '&:disabled': {
                        backgroundColor: theme.palette.action.disabledBackground,
                    },
                }),
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiCollapse: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontSize: 13,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.primary.contrastText,
                    '&.Mui-focused': {
                        color: theme.palette.primary.contrastText,
                    },
                }),
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    borderRadius: '8px',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    border: '1px solid #F2F2F2',
                    boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.04)',
                    borderRadius: '12px !important',
                },
                list: ({ theme }) => ({
                    backgroundColor: theme.palette.background.paper,
                    padding: '8px 8px 4px',
                    color: theme.palette.getContrastText(theme.palette.background.paper),
                    '& li:hover, & li.Mui-selected:hover': {
                        backgroundColor: theme.palette.primary.lighter,
                        color: theme.palette.getContrastText(theme.palette.primary.light),
                    },
                    '& li.Mui-selected': {
                        backgroundColor: theme.palette.primary.light,
                        borderRadius: '8px',
                        color: theme.palette.getContrastText(theme.palette.primary.light),
                    },
                }),
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({ theme }) => ({
                    alignItems: 'center',
                    marginBottom: '4px',
                    '&:hover': {
                        backgroundColor: theme.palette.primary.lighter,
                        borderRadius: '8px',
                        marginBottom: '4px',
                    },
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                }),
            },
        },
        MuiDialog: {
            defaultProps: {
                style: {
                    backdropFilter: 'blur(2px)',
                },
            },
            styleOverrides: {
                paper: {
                    borderRadius: 12,
                    boxShadow: ' 0px 20px 100px 50px rgba(0, 0, 0, 0.3)',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: theme.spacing(2),
                    paddingLeft: theme.spacing(5),
                    fontSize: '18px',
                    fontWeight: 'bold',
                    boxShadow: '0px 0px 5px 2px rgba(0, 0, 0, 0.06)',
                }),
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: `${theme.spacing(3)}  ${theme.spacing(5)}`,
                    marginTop: theme.spacing(2),
                    fontSize: 16,
                }),
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: theme.spacing(2),
                    boxShadow: '0px 0px 3px 2px rgba(0, 0, 0, 0.02)',
                }),
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: ({ theme }) => ({
                    border: 'none',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme.palette.background.default,
                    '& th:first-of-type': {
                        borderRadius: '12px 0 0 12px',
                        border: 'none',
                    },
                    '& th:last-child': {
                        borderRadius: '0 12px 12px 0',
                        border: 'none',
                    },
                    '& td:first-of-type': {
                        borderRadius: '12px 0 0 12px',
                        border: 'none',
                    },
                    '& td:last-child': {
                        borderRadius: '0 12px 12px 0',
                        border: 'none',
                    },
                }),
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    '& th:first-of-type': {
                        borderRadius: '12px 0 0 12px',
                        border: 'none',
                    },
                    '& th:last-child': {
                        borderRadius: '0 12px 12px 0',
                        border: 'none',
                    },
                    '& td:first-of-type': {
                        borderRadius: '12px 0 0 12px',
                        border: 'none',
                    },
                    '& td:last-child': {
                        borderRadius: '0 12px 12px 0',
                        border: 'none',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#FFF3D0',
                    border: '1px solid #B79244',
                    color: '#55430C',
                    fontSize: '12px',
                    borderRadius: '8px',
                    boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                },
            },
        },
    },
});

export type ExtendedThemeOptions = ThemeOptions & {
    spacingValue: (spacing: number) => number;
};
export type ExtendedTheme = Theme & {
    spacingValue: (spacing: number) => number;
};

const themeOptions: ExtendedThemeOptions = {
    spacingValue: (spacing: number) => parseInt(defaultTheme.spacing(spacing), 10),
    typography: {
        fontFamily: 'quicksand',
    },
    palette: {
        primary: {
            dark: colors[1],
            main: colors[2],
            light: colors[3],
            lighter: colors[8],
        },
        secondary: {
            dark: colors[5],
            darker: colors[7],
            darkest: colors[12],
            main: colors[4],
            light: colors[6],
            lighter: colors[9],
            lightest: colors[11],
        },
        mode: colors.mode,
        background: {
            default: colors.defaultBackground,
            paper: colors.white,
        },
        common: {
            white: colors.white,
            black: colors.black,
        },
        error: {
            main: colors.error,
            lighter: colors[10],
        },
    },
};

// our standard theme
export const theme = createTheme({ ...defaultTheme, ...themeOptions });

// the admin theme has other colors, so that it can highlight admin features
export const adminTheme: Theme = createTheme({
    ...defaultTheme,
    typography: {
        fontFamily: 'quicksand',
    },
    palette: {
        primary: {
            main: colors[4],
            lighter: colors[9],
        },
        secondary: {
            main: colors[1],
        },
        mode: colors.mode,
        background: {
            default: colors.defaultBackground,
            paper: colors.white,
        },
        common: {
            white: colors.white,
            black: colors.black,
        },
        error: {
            main: colors.error,
            lighter: colors[10],
        },
    },
});
