import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import ContainerComponent from 'components/container/ContainerComponent';
import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { ContainerUsageType, ContainerView, ContainerWriteView } from 'model/ContainerView';
import { Unit } from 'model/Unit';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    getNewInnerContainer,
    removePDSContainer,
    updatePDSContainers,
} from 'components/productdatasheet/ProductDataSheetDialogHelpers';
import { isNullOrUndefined } from 'util/helpers';
import { deepCopy } from 'util/deep-equality-helpers';
import { wrapButtonWithTooltip } from 'util/style-helpers';
import { ContainerPropsComponent } from 'components/container/ContainerPropsComponent';

export interface StepContainerProps {
    productUnit?: Unit;
    normalContainers?: ContainerView[];
    allContainers?: ContainerView[];
    updateAfterSave?: () => void;
    pdsRef?: string;
}

export const StepContainer = (props: StepComponentProps<StepContainerProps>) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        textField: {
            paddingRight: theme.spacing(1),
        },
        emptyStateHint: {
            backgroundColor: theme.palette.grey[100],
            borderRadius: 10,
            color: theme.palette.primary.dark,
            fontWeight: 600,
            padding: theme.spacing(3),
            textAlign: 'center',
        },
    });
    const classes = useStyles();
    const { t } = useTranslation(['productDataSheet', 'tooltips']);
    const [collapseStates, setCollapseStates] = useState<Record<string, boolean>>({});

    const toggleCollapse = (id: string) => {
        setCollapseStates((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const isUsedAsInnerContainer = (container: ContainerView): boolean => {
        return (
            props.data.allContainers?.some((it) => !!it.innerContainer && it.innerContainer === container.id) ?? false
        );
    };

    const update = (id: string, partial: Partial<ContainerView>) => {
        const containers =
            props.data.pdsRef && props.data.updateAfterSave
                ? updatePDSContainers(
                      props.data.normalContainers,
                      id,
                      partial,
                      props.data.pdsRef,
                      props.data.updateAfterSave,
                  )
                : [];
        props.setData('StepContainer', { normalContainers: containers });
    };

    const handleContainerUpdate = (updatedContainer: ContainerWriteView, id: string) => {
        const container = props.data.normalContainers?.find((it) => it.id === id);
        update(id, { ...container, ...updatedContainer });
    };

    const handleContainerPropsUpdate = (updatedContainer: ContainerView) => {
        const container = props.data.normalContainers?.find((it) => it.id === updatedContainer.id);
        update(updatedContainer.id, { ...container, ...updatedContainer });
    };

    const handleContainerRemove = (id: string) => {
        if (id && props.data.pdsRef) {
            removePDSContainer(id, props.data.pdsRef);
        }
        props.setData('StepContainer', {
            normalContainers: props.data.normalContainers?.filter((it) => it.id !== id),
        });
    };

    const handleAddContainer = () => {
        const containers = deepCopy(props.data.normalContainers) ?? [];
        containers.push(getNewInnerContainer(props.data.productUnit));
        props.setData('StepContainer', { normalContainers: containers });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_CONTAINER_TITLE')}
            </Typography>
        </Grid>
    );

    const showText = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            {props.data.productUnit ? (
                <Typography>{t('productDataSheet:DATASTEP_CONTAINER_TEXT')}</Typography>
            ) : (
                <Typography className={classes.emptyStateHint}>
                    {t('productDataSheet:DATASTEP_CONTAINER_NO_DIMENSION_HINT')}
                </Typography>
            )}
        </Grid>
    );

    const showCollapsedInputFields = (container: ContainerView): ReactNode => {
        return collapseStates[container.id] ? (
            <ContainerPropsComponent
                container={container}
                update={handleContainerPropsUpdate.bind(this)}
                isReadOnly={isUsedAsInnerContainer(container)}
            />
        ) : null;
    };

    const showContainerSelect = (container: ContainerView) => (
        <Grid container item md={12}>
            <Grid item md={11}>
                {wrapButtonWithTooltip(
                    <ContainerComponent
                        pdsUnit={props.data.productUnit}
                        container={container}
                        containerId={container.id}
                        isDuplicate={false}
                        remove={(_) => handleContainerRemove(container.id)}
                        onUpdate={(updatedContainer?: ContainerWriteView, id?: string) => {
                            updatedContainer && !isNullOrUndefined(id)
                                ? handleContainerUpdate(updatedContainer, id!)
                                : undefined;
                        }}
                        containerUsageType={ContainerUsageType.PRODUCT_DATA_SHEET}
                        isReadOnly={isUsedAsInnerContainer(container)}
                    />,
                    isUsedAsInnerContainer(container) ? t('tooltips:noEditOrDeleteOfInnerContainers') : '',
                    'top-start',
                    container.id,
                    { width: '100%' },
                )}
            </Grid>
            <Grid item md={1}>
                <IconButton
                    sx={{
                        backgroundColor: 'transparent',
                        padding: theme.spacing(0.65),
                        margin: `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(2.5)}`,
                    }}
                    data-testid="collapse"
                    onClick={() => toggleCollapse(container.id)}
                >
                    {collapseStates[container.id] ? <ArrowDropUp /> : <ArrowDropDown />}
                </IconButton>
            </Grid>
            {showCollapsedInputFields(container)}
        </Grid>
    );

    const showContainerInputSection = (): ReactNode => {
        return props.data.productUnit
            ? props.data.normalContainers?.map((container) => (
                  <Grid
                      key={container.id}
                      container
                      item
                      md={12}
                      sx={{
                          border: 1,
                          borderRadius: 5,
                          borderColor: theme.palette.grey[300],
                          padding: `${theme.spacing(1.5)} ${theme.spacing(2)} ${theme.spacing(1.5)} ${theme.spacing(
                              2,
                          )}`,
                          marginBottom: theme.spacing(1.5),
                      }}
                  >
                      {showContainerSelect(container)}
                  </Grid>
              ))
            : null;
    };

    const showAddButton = () => (
        <Grid item sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    !props.data.normalContainers ||
                    props.data.normalContainers.some((it) => !it.containerType || !it.amount || !it.unit)
                }
                onClick={() => handleAddContainer()}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    return (
        <Grid item container md={12} sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}>
            {showTitle()}
            {showText()}
            {showContainerInputSection()}
            {props.data.productUnit ? showAddButton() : null}
        </Grid>
    );
};
