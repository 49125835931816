import { Observable } from 'rxjs';
import http from 'api/http';
import { map, tap } from 'rxjs/operators';
import { RecurringOrderStore } from 'store';
import { Delivery, DeliveryUpdateWriteView } from 'model/Delivery';

class DeliveryService {
    public updateDelivery(
        deliveryRef: string,
        recurringOrderRef: string,
        writeView: DeliveryUpdateWriteView,
    ): Observable<Delivery> {
        return http
            .getAxios()
            .put<Delivery>(deliveryRef, writeView)
            .pipe(
                map((value) => value.data),
                tap(() => RecurringOrderStore.loadOne(recurringOrderRef)),
            );
    }
}

export default new DeliveryService();
