import { StepComponentProps } from 'components/productdatasheet/ProductDataSheetStructure';
import { useTheme } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import React, { ReactNode } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {
    Husbandry,
    HusbandryType,
    HusbandryWithKey,
    updateHusbandriesWithKey,
} from 'components/productdatasheet/datablocks/block4/Husbandry';
import {
    Cultivation,
    CultivationType,
    CultivationWithKey,
    updateCultivationsWithKey,
} from 'components/productdatasheet/datablocks/block4/Cultivation';
import { Autocomplete } from '@mui/material';
import {
    getAutoCompleteTextFieldSX,
    getProductDataSheetDialogAutoCompleteSX,
} from 'components/productdatasheet/ProductDataSheetHelpers';
import { Product } from 'model/Product';
import TextField from '@mui/material/TextField';
import { getProductNameFromString } from 'util/helpers';
import IconButton from '@mui/material/IconButton';
import Delete from '@mui/icons-material/Delete';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';

export interface StepHusbandryCultivationProps {
    husbandries?: HusbandryWithKey[];
    cultivations?: CultivationWithKey[];
    products?: Product[];
    mainProductRef?: string;
}

export const StepHusbandryCultivation = (props: StepComponentProps<StepHusbandryCultivationProps>) => {
    const theme = useTheme();
    const { t } = useTranslation(['dialogs', 'productDataSheet', 'common', 'husbandry', 'cultivation']);

    const useStyles = makeStyles({
        customDisabledTextField: {
            '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: theme.palette.text.primary,
                color: theme.palette.text.primary,
            },
            '& .MuiInputBase-root.Mui-disabled': {
                color: theme.palette.text.primary,
            },
            '& .MuiInputLabel-root.Mui-disabled': {
                color: theme.palette.primary.dark,
            },
            '& .MuiInputLabel-shrink.Mui-disabled': {
                color: theme.palette.primary.dark,
            },
        },
        bolderTypography: {
            fontWeight: 500,
        },
        divider: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: '100%',
        },
    });
    const classes = useStyles();

    const handleHusbandryTypeChange = (value: HusbandryType | null, key: number) => {
        const husbandryType = value ?? undefined;
        const husbandries = updateHusbandriesWithKey(props.data.husbandries, key, { husbandryType });
        props.setData('StepHusbandryCultivation', { husbandries });
    };

    const handleHusbandryProductChange = (value: Product | null, key: number) => {
        const productName = getProductNameFromString(value?.links.self);
        const husbandries = updateHusbandriesWithKey(props.data.husbandries, key, { productName });
        props.setData('StepHusbandryCultivation', { husbandries });
    };

    const handleCultivationTypeChange = (value: CultivationType | null, key: number) => {
        const cultivationType = value ?? undefined;
        const cultivations = updateCultivationsWithKey(props.data.cultivations, key, { cultivationType });
        props.setData('StepHusbandryCultivation', { cultivations });
    };

    const handleCultivationProductChange = (value: Product | null, key: number) => {
        const productName = getProductNameFromString(value?.links.self);
        const cultivations = updateCultivationsWithKey(props.data.cultivations, key, { productName });
        props.setData('StepHusbandryCultivation', { cultivations });
    };

    const handleHusbandryDelete = (key: number) => {
        const husbandries = props.data.husbandries?.filter((h) => h.key !== key);
        props.setData('StepHusbandryCultivation', { husbandries });
    };

    const handleCultivationDelete = (key: number) => {
        const cultivations = props.data.cultivations?.filter((c) => c.key !== key);
        props.setData('StepHusbandryCultivation', { cultivations });
    };

    const showTitle = (): ReactNode => (
        <Grid item md={12} sx={{ paddingBottom: theme.spacing(3) }}>
            <Typography sx={{ fontWeight: 600, fontSize: '24px' }}>
                {t('productDataSheet:DATASTEP_HUSBANDRY_CULTIVATION_TITLE')}
            </Typography>
        </Grid>
    );

    const showAddHusbandryButton = (): ReactNode => (
        <Grid item md={12} sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    props.data.husbandries &&
                    props.data.husbandries.some((it) => !it.husbandry.productName || !it.husbandry.husbandryType)
                }
                onClick={() => {
                    const husbandries = props.data.husbandries ?? [];
                    husbandries.push({
                        key: Math.random(),
                        husbandry: new Husbandry('', undefined),
                    });
                    props.setData('StepHusbandryCultivation', { husbandries });
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const showAddCultivationButton = (): ReactNode => (
        <Grid item md={12} sx={{ paddingTop: theme.spacing(1) }}>
            <Button
                startIcon={<AddIcon />}
                variant={'contained'}
                disabled={
                    props.data.cultivations &&
                    props.data.cultivations.some((it) => !it.cultivation.productName || !it.cultivation.cultivationType)
                }
                onClick={() => {
                    const cultivations = props.data.cultivations ?? [];
                    cultivations.push({
                        key: Math.random(),
                        cultivation: new Cultivation('', undefined),
                    });
                    props.setData('StepHusbandryCultivation', { cultivations });
                }}
            >
                {t('dialogs:ADD')}
            </Button>
        </Grid>
    );

    const { products } = props.data;

    const getOptionLabel = (option: Product) =>
        option
            ? option.links.self == props.data.mainProductRef
                ? t('common:productOverall')
                : t('ontofood:' + option.label)
            : '';

    const renderOption = (p: React.HTMLAttributes<HTMLLIElement>, option: Product) =>
        option ? (
            option.links.self == props.data.mainProductRef ? (
                <ListItem {...p}>
                    <Grid container direction={'column'}>
                        <Grid item>
                            <Typography className={classes.bolderTypography}>{t('common:productOverall')}</Typography>
                        </Grid>
                        {(products?.length ?? 0) > 1 ? (
                            <Grid item>
                                <Divider variant={'fullWidth'} className={classes.divider} />
                            </Grid>
                        ) : null}
                    </Grid>
                </ListItem>
            ) : (
                <ListItem {...p}>
                    <Typography>{t('ontofood:' + option.label)}</Typography>
                </ListItem>
            )
        ) : null;

    const findProduct = (name?: string | null): Product | null => {
        return name ? products?.find((p) => getProductNameFromString(p.links.self) == name) ?? null : null;
    };

    const showHusbandries = (): ReactNode => {
        const { husbandries } = props.data;
        const types = Object.values(HusbandryType);
        return (
            <Grid container item md={12} direction={'row'}>
                <Grid item md={12} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{t('husbandry:husbandry')}</Typography>
                </Grid>
                {husbandries?.length
                    ? husbandries.map((husbandryWithKey) => {
                          const productValue = findProduct(husbandryWithKey.husbandry.productName);
                          const typeValue = husbandryWithKey.husbandry.husbandryType ?? null;
                          return (
                              <Grid container item md={12} spacing={2} key={husbandryWithKey.key}>
                                  <Grid item md={5.5}>
                                      <Autocomplete
                                          sx={getProductDataSheetDialogAutoCompleteSX()}
                                          getOptionLabel={getOptionLabel}
                                          renderOption={renderOption}
                                          noOptionsText={t('common:noOptions')}
                                          options={products ?? []}
                                          value={productValue}
                                          onChange={(_, newValue) =>
                                              handleHusbandryProductChange(newValue, husbandryWithKey.key)
                                          }
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  className={classes.customDisabledTextField}
                                                  disabled
                                                  label={t('common:product')}
                                                  fullWidth
                                                  sx={getAutoCompleteTextFieldSX()}
                                              />
                                          )}
                                      />
                                  </Grid>
                                  <Grid item md={5.5}>
                                      <Autocomplete
                                          sx={getProductDataSheetDialogAutoCompleteSX()}
                                          getOptionLabel={(option) =>
                                              option ? t('husbandry:' + option.toString()) : ''
                                          }
                                          noOptionsText={t('common:noOptions')}
                                          options={types ?? []}
                                          value={typeValue}
                                          onChange={(_, newValue) =>
                                              handleHusbandryTypeChange(newValue, husbandryWithKey.key)
                                          }
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  className={classes.customDisabledTextField}
                                                  disabled
                                                  label={t('husbandry:husbandry')}
                                                  fullWidth
                                                  sx={getAutoCompleteTextFieldSX()}
                                              />
                                          )}
                                      />
                                  </Grid>
                                  <Grid
                                      item
                                      md={1}
                                      sx={{
                                          paddingTop: theme.spacing(0.5),
                                          marginTop: theme.spacing(0.75),
                                      }}
                                  >
                                      <IconButton onClick={() => handleHusbandryDelete(husbandryWithKey.key)}>
                                          <Delete />
                                      </IconButton>
                                  </Grid>
                              </Grid>
                          );
                      })
                    : null}
                {showAddHusbandryButton()}
            </Grid>
        );
    };

    const showCultivations = (): ReactNode => {
        const { cultivations, products } = props.data;
        const types = Object.values(CultivationType);
        return (
            <Grid container item md={12} direction={'row'}>
                <Grid item md={12} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Typography sx={{ fontWeight: 600, fontSize: '20px' }}>{t('cultivation:cultivation')}</Typography>
                </Grid>
                {cultivations?.length
                    ? cultivations.map((cultivationWithKey) => {
                          const productValue = findProduct(cultivationWithKey.cultivation.productName);
                          const typeValue = cultivationWithKey.cultivation.cultivationType ?? null;
                          return (
                              <Grid container item md={12} spacing={2} key={cultivationWithKey.key}>
                                  <Grid item md={5.5}>
                                      <Autocomplete
                                          sx={getProductDataSheetDialogAutoCompleteSX()}
                                          getOptionLabel={getOptionLabel}
                                          renderOption={renderOption}
                                          noOptionsText={t('common:noOptions')}
                                          options={products ?? []}
                                          value={productValue}
                                          onChange={(_, newValue) =>
                                              handleCultivationProductChange(newValue, cultivationWithKey.key)
                                          }
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  className={classes.customDisabledTextField}
                                                  disabled
                                                  label={t('common:product')}
                                                  fullWidth
                                                  sx={getAutoCompleteTextFieldSX()}
                                              />
                                          )}
                                      />
                                  </Grid>
                                  <Grid item md={5.5}>
                                      <Autocomplete
                                          sx={getProductDataSheetDialogAutoCompleteSX()}
                                          getOptionLabel={(option) =>
                                              option ? t('cultivation:' + option.toString()) : ''
                                          }
                                          noOptionsText={t('common:noOptions')}
                                          options={types ?? []}
                                          value={typeValue}
                                          onChange={(_, newValue) =>
                                              handleCultivationTypeChange(newValue, cultivationWithKey.key)
                                          }
                                          renderInput={(params) => (
                                              <TextField
                                                  {...params}
                                                  className={classes.customDisabledTextField}
                                                  disabled
                                                  label={t('cultivation:cultivation')}
                                                  fullWidth
                                                  sx={getAutoCompleteTextFieldSX()}
                                              />
                                          )}
                                      />
                                  </Grid>
                                  <Grid
                                      item
                                      md={1}
                                      sx={{
                                          paddingTop: theme.spacing(0.5),
                                          marginTop: theme.spacing(0.75),
                                      }}
                                  >
                                      <IconButton onClick={() => handleCultivationDelete(cultivationWithKey.key)}>
                                          <Delete />
                                      </IconButton>
                                  </Grid>
                              </Grid>
                          );
                      })
                    : null}
                {showAddCultivationButton()}
            </Grid>
        );
    };

    return (
        <Grid
            item
            container
            md={12}
            spacing={theme.spacing(2)}
            sx={{ paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) }}
        >
            {showTitle()}
            {showCultivations()}
            {showHusbandries()}
        </Grid>
    );
};
