import Check from '@mui/icons-material/Check';
import * as React from 'react';
import { ComponentBase } from 'resub';
import Chip from '@mui/material/Chip';
import { PropTypes, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Product } from 'model/Product';
import { ProductStore } from 'store';
import { withTranslation, WithTranslation } from 'react-i18next';
import { classNames } from 'util/style-helpers';

const styles = (theme: Theme) =>
    createStyles({
        chip: {
            margin: theme.spacing(1),
            marginLeft: theme.spacing(0),
            fontWeight: 500,
        },
        mw100: {
            maxWidth: '100%',
        },
        assortmentChip: {
            backgroundColor: theme.palette.secondary.main,
            border: `1px solid ${theme.palette.secondary.dark}`,
            color: theme.palette.getContrastText(theme.palette.secondary.main),
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.getContrastText(theme.palette.secondary.main),
            },
        },
        demandChip: {
            backgroundColor: theme.palette.primary.light,
            border: `1px solid ${theme.palette.primary.main}`,
            color: theme.palette.getContrastText(theme.palette.primary.light),
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.getContrastText(theme.palette.primary.main),
            },
        },
        disabledChip: {
            pointerEvents: 'unset',
            opacity: 0.7,
            cursor: 'pointer',
        },
    });

interface ProductDataSheetProductChipProperties extends WithStyles<typeof styles>, WithTranslation {
    productRef: string;
    type: 'company' | 'market';
    style?: React.CSSProperties;
    color: PropTypes.Color;
    isChosen: boolean;
    toggleProduct: () => void;
}

interface ProductDataSheetProductChipState {
    product?: Product;
    isDisabled: boolean;
}

class ProductDataSheetProductChip extends ComponentBase<
    ProductDataSheetProductChipProperties,
    ProductDataSheetProductChipState
> {
    protected _buildState(
        props: ProductDataSheetProductChipProperties,
    ): Partial<ProductDataSheetProductChipState> | undefined {
        return {
            product: ProductStore.getOne(props.productRef),
        };
    }

    render(): React.ReactElement | null {
        if (this.state.product) {
            if (
                (this.props.type === 'market' && this.state.product.market) ||
                (this.props.type === 'company' && this.state.product.company)
            ) {
                return (
                    <Chip
                        label={this.props.t(`ontofood:${this.state.product.label}`)}
                        style={this.props.style}
                        color={this.props.color as 'primary' | 'secondary' | 'default'}
                        className={classNames(
                            this.props.classes.chip,
                            this.props.classes.mw100,
                            this.props.color === 'secondary'
                                ? this.props.classes.assortmentChip
                                : this.props.classes.demandChip,
                            !this.props.isChosen ? this.props.classes.disabledChip : undefined,
                        )}
                        icon={this.props.isChosen ? <Check /> : undefined}
                        onClick={this.props.toggleProduct}
                    />
                );
            }
        }
        return null;
    }
}

export default withTranslation(['ontofood'])(withStyles(styles, { withTheme: true })(ProductDataSheetProductChip));
