import { Table } from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import ContainerSelectionDetailComponent from 'components/marketplace/tradetable/ContainerSelectionDetailComponent';
import { OrderAddress, OrderAddressType } from 'model/Address';
import { ContainerType, OrderContainer } from 'model/ContainerView';
import { Order } from 'model/Order';
import { Person } from 'model/Person';
import { PurchaseIntent } from 'model/PurchaseIntent';
import * as React from 'react';
import { ReactNode } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ComponentBase } from 'resub';
import { BoldTypography } from 'shared/typographies';
import { theme } from 'style/NearbuyStyle';
import { PriceRequest } from 'model/PriceRequest';
import { RecurringOrder } from 'model/RecurringOrder';

const _styles = () =>
    createStyles({
        cell: {
            minWidth: '20%',
            border: 'none',
            padding: `${0} ${theme.spacing(2)} ${0} ${0}`,
            verticalAlign: 'top',
        },
    });

interface TradeDetailsTableProps extends WithStyles<typeof _styles>, WithTranslation {
    sellingPerson: Person | undefined;
    buyingPerson: Person | undefined;
    ownCompanyRef: string;
    containerSelections: OrderContainer[];
    containerTypes: ContainerType[];
    unit: string;
    purchaseIntent?: PurchaseIntent;
    priceRequest?: PriceRequest;
    recurringOrder?: RecurringOrder;
    order?: Order;
    isContainerSectionShown: boolean;
    isHidden?: boolean;
}

interface TradeDetailsTableState {
    isSellingCompany?: boolean;
    invoiceAddress: OrderAddress;
    deliveryAddress: OrderAddress;
}

class TradeDetailsTable extends ComponentBase<TradeDetailsTableProps, TradeDetailsTableState> {
    protected _buildState(
        props: TradeDetailsTableProps,
        _initialBuild: boolean,
        _incomingState: Readonly<TradeDetailsTableState>,
    ): Partial<TradeDetailsTableState> | undefined {
        const purchaseIntentSellingCompanyRef = props.purchaseIntent?.links.sellingCompany;
        const priceRequestSellingCompanyRef = props.priceRequest?.links.sellingCompany;
        const orderSellingCompanyRef = props.order?.links.sellingCompany;
        const recOrderSellingCompanyRef = props.recurringOrder?.links.sellingCompany;
        const sellingCompanyRef =
            purchaseIntentSellingCompanyRef ??
            priceRequestSellingCompanyRef ??
            orderSellingCompanyRef ??
            recOrderSellingCompanyRef ??
            '';
        const addresses = props.order?.addresses ?? props.recurringOrder?.addresses;

        return {
            isSellingCompany: sellingCompanyRef == props.ownCompanyRef,
            invoiceAddress: addresses?.find((value) => value.type == OrderAddressType.INVOICE),
            deliveryAddress: addresses?.find((value) => value.type == OrderAddressType.DELIVERY),
        };
    }

    getAmountDetailCell() {
        return (
            <TableCell className={this.props.classes.cell}>
                <BoldTypography>{this.props.t('offer:amountDetails')}</BoldTypography>
                {this.props.containerSelections.map((selection) => {
                    return selection.amount > 0 ? (
                        <ContainerSelectionDetailComponent
                            containerSelection={selection}
                            containerTypes={this.props.containerTypes}
                        />
                    ) : null;
                })}
            </TableCell>
        );
    }

    getContactPersonCell(person: Person | undefined | null) {
        return (
            <TableCell className={this.props.classes.cell}>
                <BoldTypography>{this.props.t('sales:contact')}</BoldTypography>
                <Typography display="inline" sx={{ flexWrap: 'wrap' }}>
                    {person ? `${person.firstname} ${person.lastname}` : this.props.t('company:deletedPerson')}
                </Typography>
            </TableCell>
        );
    }

    getPaymentDetailCell(): ReactNode {
        const paymentType = this.props.order?.payment ?? this.props.recurringOrder?.payment;

        return paymentType ? (
            <TableCell className={this.props.classes.cell}>
                <BoldTypography>{this.props.t('subscription:paymentMethod')}</BoldTypography>
                <Typography>{this.props.t(`payment:${paymentType}`)}</Typography>
            </TableCell>
        ) : null;
    }

    getAddressCell(address: OrderAddress): React.ReactFragment {
        return (
            <TableCell className={this.props.classes.cell}>
                <BoldTypography>
                    {address.type == OrderAddressType.INVOICE
                        ? this.props.t('payment:billingAddress')
                        : address.type == OrderAddressType.DELIVERY
                          ? this.props.t('payment:deliveryAddress')
                          : 'ERROR: Wrong Address type!'}
                </BoldTypography>
                <Typography>{address.name}</Typography>
                <Typography>{address.street}</Typography>
                <Typography>
                    {address.zipcode} {address.city}
                </Typography>
            </TableCell>
        );
    }

    showRow(tradeItemType: 'PreOrder' | 'Order'): React.ReactFragment {
        const isOrder = tradeItemType == 'Order';
        const isRecurringOrder = !!this.props.recurringOrder;

        return (
            <Grid container justifyContent={'column'}>
                <Grid container item sx={{ paddingBottom: theme.spacing(1) }}>
                    <Divider sx={{ width: '100%' }} flexItem />
                </Grid>
                <Grid container item>
                    <TableRow
                        sx={{
                            borderSpacing: theme.spacing(0.5),
                        }}
                    >
                        {this.props.isContainerSectionShown ? this.getAmountDetailCell() : null}
                        {isOrder || isRecurringOrder ? this.getPaymentDetailCell() : null}
                        {(isOrder || isRecurringOrder) && this.state.invoiceAddress
                            ? this.getAddressCell(this.state.invoiceAddress)
                            : null}
                        {(isOrder || isRecurringOrder) && this.state.deliveryAddress
                            ? this.getAddressCell(this.state.deliveryAddress)
                            : null}
                        {this.state.isSellingCompany
                            ? this.getContactPersonCell(this.props.buyingPerson)
                            : this.getContactPersonCell(this.props.sellingPerson)}
                    </TableRow>
                </Grid>
            </Grid>
        );
    }

    render(): React.ReactElement {
        return (
            <>
                <Grid container item direction={'row'}>
                    <Grid item flexGrow={1}>
                        <Table
                            size="small"
                            sx={{
                                marginTop: theme.spacing(1),
                                borderSpacing: theme.spacing(2),
                                borderCollapse: 'separate',
                            }}
                        >
                            {this.showRow(this.props.order ? 'Order' : 'PreOrder')}
                        </Table>
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withTranslation(['company', 'sales', 'offer', 'subscription', 'payment'])(
    withStyles(_styles, { withTheme: true })(TradeDetailsTable),
);
