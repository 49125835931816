import { Pair } from 'util/Types';
import { Amount } from 'model/Amount';
import { OrderContainer } from 'model/ContainerView';
import { LevelOfProcessing } from 'model/LevelOfProcessing';

export class PriceRequestWriteView {
    constructor(
        public offerRef: string,
        public message?: string,
        public containers?: Pair<number, string>[],
        public totalAmount?: Amount,
        public pricePerUnit?: number,
    ) {}
}

export class PriceRequest {
    constructor(
        public dateCreated: Date,
        public amount: Amount,
        public totalPrice: number | null,
        public pricePerUnit: number | null,
        public status: PriceRequestStatus,
        public containers: OrderContainer[],
        public levelsOfProcessing: LevelOfProcessing[],
        public links: PriceRequestLinks,
    ) {}
}

export class PriceRequestLinks {
    constructor(
        public self: string,
        public update: string,
        public offer: string,
        public category: string,
        public buyingCompany: string,
        public buyingPerson: string,
        public sellingCompany: string,
        public sellingPerson: string,
    ) {}
}

export enum PriceRequestStatus {
    PENDING = 'PENDING',
    PRICE_ADDED = 'PRICE_ADDED',
    REJECTED = 'REJECTED',
    CANCELED_BY_SELLER = 'CANCELED_BY_SELLER',
    CANCELED_BY_BUYER = 'CANCELED_BY_BUYER',
    COMPLETED = 'COMPLETED',
}

export interface PriceRequestStatusWriteView {
    status: PriceRequestStatus;
}
